import type {
  Node,
  NodeOrigin,
  Rect,
  Box,
  NodePositionChange,
  XYPosition,
  Edge,
} from "@xyflow/react";
import {
  boxToRect,
  getNodePositionWithOrigin,
  rectToBox,
} from "@xyflow/system";

import { azure_nodes, azure_edges_icons } from "./assets/azure/azure_nodes.js";
import { protocols_edges_icons } from "./assets/protocol_clean/protocols_nodes.js";

import { azure_groups } from "./assets/azure/azure_groups.js";

import { aws_nodes } from "./assets/aws/aws_nodes.js";
import { aws_groups } from "./assets/aws/aws_groups.js";

import { gcp_nodes } from "./assets/gcp/gcp_nodes.js";
// import { gcp_groups } from "./assets/gcp/gcp_groups.js";

import { kubernetes_nodes } from "./assets/kubernetes/kubernetes_nodes.js";
import { kubernetes_groups } from "./assets/kubernetes/kubernetes_groups.js";

import { devops_nodes } from "./assets/devops/devops_nodes.js";
// import { devops_groups } from "./assets/devops/devops_groups.js";

import { software_nodes } from "./assets/software/software_nodes.js";
// import { software_groups } from "./assets/software/software_groups.js";

import { vendor_nodes } from "./assets/vendor/vendor_nodes.js";
// import { vendor_groups } from "./assets/vendor/vendor_groups.js";

import { misc_nodes } from "./assets/misc/misc_nodes.js";
import { merge } from "lodash";
import { MarkerType } from "reactflow";

type GetHelperLinesResult = {
  horizontal?: number;
  vertical?: number;
  snapPosition: Partial<XYPosition>;
};

const iconBaseUrl = "https://spnodedata.blob.core.windows.net/nodes";

interface Item {
  id: number;
  name: string;
  icon: string;
}

interface CategoryItem {
  name: string;
  type: string;
  url: string;
}

interface CategoryWithIcon {
  name: string;
  categoryIcon: string;
  items: (CategoryItem | string)[];
}

interface CategoryWithoutIcon {
  name: string;
  items: string[];
}

type Category = CategoryWithIcon | CategoryWithoutIcon;

interface FinalGroupsArr {
  name: string;
  url: string;
  style: any;
  subdirectory: string;
}

interface CategoryData {
  name: string;
  categoryIcon?: string;
  data: Item[];
}

export const sortNodes = (a: Node, b: Node): number => {
  if (a.type === b.type) {
    return 0;
  }
  return a.type === "group" && b.type !== "group" ? -1 : 1;
};

export const getId = (prefix = "node") => `${prefix}_${Math.random() * 10000}`;

export const getNodePositionInsideParent = (
  node: Partial<Node>,
  groupNode: Node
) => {
  const position = node.position ?? { x: 0, y: 0 };
  const nodeWidth = node.measured?.width ?? 0;
  const nodeHeight = node.measured?.height ?? 0;
  const groupWidth = groupNode.measured?.width ?? 0;
  const groupHeight = groupNode.measured?.height ?? 0;

  if (position.x < groupNode.position.x) {
    position.x = 0;
  } else if (position.x + nodeWidth > groupNode.position.x + groupWidth) {
    position.x = groupWidth - nodeWidth;
  } else {
    position.x = position.x - groupNode.position.x;
  }

  if (position.y < groupNode.position.y) {
    position.y = 0;
  } else if (position.y + nodeHeight > groupNode.position.y + groupHeight) {
    position.y = groupHeight - nodeHeight;
  } else {
    position.y = position.y - groupNode.position.y;
  }

  return position;
};

export const getBoundsOfBoxes = (box1: Box, box2: Box): Box => ({
  x: Math.min(box1.x, box2.x),
  y: Math.min(box1.y, box2.y),
  x2: Math.max(box1.x2, box2.x2),
  y2: Math.max(box1.y2, box2.y2),
});

export const getRelativeNodesBounds = (
  nodes: Node[],
  nodeOrigin: NodeOrigin = [0, 0]
): Rect => {
  if (nodes.length === 0) {
    return { x: 0, y: 0, width: 0, height: 0 };
  }

  const box = nodes.reduce(
    (currBox, node) => {
      const { x, y } = getNodePositionWithOrigin(node, nodeOrigin);
      return getBoundsOfBoxes(
        currBox,
        rectToBox({
          x,
          y,
          width: node.width || 0,
          height: node.height || 0,
        })
      );
    },
    { x: Infinity, y: Infinity, x2: -Infinity, y2: -Infinity }
  );

  return boxToRect(box);
};

export const iconsList = (
  type: string,
  searchTerm?: string
): CategoryData[] => {
  // If there's a search term, get icons from all categories
  if (searchTerm && searchTerm.length > 0) {
    const allIcons: CategoryData[] = [];

    // Get icons from all available categories
    const categories = [
      { type: "azure", nodes: azure_nodes },
      { type: "aws", nodes: aws_nodes },
      { type: "gcp", nodes: gcp_nodes },
      { type: "kubernetes", nodes: kubernetes_nodes },
      { type: "devops", nodes: devops_nodes },
      { type: "software", nodes: software_nodes },
      { type: "misc", nodes: misc_nodes },
      { type: "vendor", nodes: vendor_nodes },
      { type: "protocols", nodes: protocols_edges_icons },
      // Add vendor nodes when available
      // { type: 'vendor', nodes: vendor_nodes },
    ];

    // Process each category
    categories.forEach(({ type: categoryType, nodes }) => {
      if (!nodes) return;

      const processedIcons = nodes
        .map((category, categoryIndex) => ({
          name: `${categoryType} - ${category.name}`,
          categoryIcon: "categoryIcon" in category ? category.categoryIcon : "",
          data: Array.isArray(category.items)
            ? (category.items as Array<string | CategoryItem>)
                .filter((item) => {
                  const itemName = typeof item === "string" ? item : item.name;
                  return itemName
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase());
                })
                .map((item, itemIndex) => ({
                  id: categoryIndex * 100 + itemIndex + 1,
                  name:
                    typeof item === "string"
                      ? item
                          .split("-")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")
                      : item.name
                          .split("-")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" "),
                  icon: typeof item === "string" ? item : item.url,
                  type: typeof item === "string" ? null : item.type,
                }))
            : [],
        }))
        .filter((category) => category.data.length > 0); // Only include categories with matching items

      allIcons.push(...processedIcons);
    });

    return allIcons;
  }

  // If no search term, return icons only for the selected type (existing logic)
  const categoriesDataList: Category[] | undefined =
    type === "azure"
      ? azure_nodes
      : type === "aws"
        ? aws_nodes
        : type === "gcp"
          ? gcp_nodes
          : type === "kubernetes"
            ? kubernetes_nodes
            : type === "devops"
              ? devops_nodes
              : type === "software"
                ? software_nodes
                : type === "vendor"
                  ? vendor_nodes
                  : type === "misc"
                    ? misc_nodes
                    : undefined;

  if (!categoriesDataList) {
    return [];
  }

  return categoriesDataList.map((category) => ({
    name: category.name,
    categoryIcon: "categoryIcon" in category ? category.categoryIcon : "",
    data: category.items.map((item, itemIndex) => ({
      id: itemIndex * 100 + 1,
      name:
        typeof item === "string"
          ? item
              .split("-")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")
          : item.name
              .split("-")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" "),
      icon: typeof item === "string" ? item : item.url,
      type: typeof item === "string" ? null : item.type,
    })),
  }));
};

export const iconsListForEdge = (type: string): CategoryData[] => {
  const categoriesDataList: Category[] | undefined =
    type === "azure"
      ? azure_edges_icons
      : type === "protocols"
        ? protocols_edges_icons
        : type === "kubernetes"
          ? kubernetes_nodes
          : type === "devops"
            ? devops_nodes
            : type === "misc"
              ? misc_nodes
              : undefined;

  if (!categoriesDataList) {
    return [];
  }

  return categoriesDataList.map((category, categoryIndex) => ({
    name: category.name,
    categoryIcon: "categoryIcon" in category ? category.categoryIcon : "",
    data: category.items.map((item, itemIndex) => ({
      id: categoryIndex * 100 + itemIndex + 1,
      name:
        typeof item === "string"
          ? item
              .split("-")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")
          : item.name
              .split("-")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" "),
      icon: typeof item === "string" ? item : item.url,
    })),
  }));
};

export const getNodeGroupsList = (type: string): FinalGroupsArr[] => {
  const categoriesDataList: FinalGroupsArr[] | undefined =
    type === "azure"
      ? azure_groups
      : type === "aws"
        ? aws_groups
        : // type === "gcp" ? gcp_groups :
          type === "kubernetes"
          ? kubernetes_groups
          : // type === "devops" ? devops_groups :
            // type === "software" ? software_groups :
            // type === "vendor" ? vendor_groups :
            undefined;

  if (!categoriesDataList) {
    return [];
  }

  const leftSideGroupsList = [...categoriesDataList];

  return leftSideGroupsList.map((data, groupIndex) => ({
    id: groupIndex * 100 + 1,
    name: data.name
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" "),
    url: data.url,
    style: data?.style,
    subdirectory: data.subdirectory,
  }));
};

// this utility function can be called with a position change (inside onNodesChange)
// it checks all other nodes and calculated the helper line positions and the position where the current node should snap to
export function getHelperLines(
  change: NodePositionChange,
  nodes: Node[],
  distance = 5
): GetHelperLinesResult {
  const defaultResult = {
    horizontal: undefined,
    vertical: undefined,
    snapPosition: { x: undefined, y: undefined },
  };
  const nodeA = nodes.find((node) => node.id === change.id);

  if (!nodeA || !change.position) {
    return defaultResult;
  }

  const nodeABounds = {
    left: change.position.x,
    right: change.position.x + (nodeA.measured?.width ?? 0),
    top: change.position.y,
    bottom: change.position.y + (nodeA.measured?.height ?? 0),
    width: nodeA.measured?.width ?? 0,
    height: nodeA.measured?.height ?? 0,
  };

  let horizontalDistance = distance;
  let verticalDistance = distance;

  return nodes
    .filter((node) => node.id !== nodeA.id)
    .reduce<GetHelperLinesResult>((result, nodeB) => {
      const nodeBBounds = {
        left: nodeB.position.x,
        right: nodeB.position.x + (nodeB.measured?.width ?? 0),
        top: nodeB.position.y,
        bottom: nodeB.position.y + (nodeB.measured?.height ?? 0),
        width: nodeB.measured?.width ?? 0,
        height: nodeB.measured?.height ?? 0,
      };

      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |
      //  |___________|
      //  |
      //  |
      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     B     |
      //  |___________|
      const distanceLeftLeft = Math.abs(nodeABounds.left - nodeBBounds.left);

      if (distanceLeftLeft < verticalDistance) {
        result.snapPosition.x = nodeBBounds.left;
        result.vertical = nodeBBounds.left;
        verticalDistance = distanceLeftLeft;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |
      //  |___________|
      //              |
      //              |
      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     B     |
      //  |___________|
      const distanceRightRight = Math.abs(
        nodeABounds.right - nodeBBounds.right
      );

      if (distanceRightRight < verticalDistance) {
        result.snapPosition.x = nodeBBounds.right - nodeABounds.width;
        result.vertical = nodeBBounds.right;
        verticalDistance = distanceRightRight;
      }

      //              |‾‾‾‾‾‾‾‾‾‾‾|
      //              |     A     |
      //              |___________|
      //              |
      //              |
      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     B     |
      //  |___________|
      const distanceLeftRight = Math.abs(nodeABounds.left - nodeBBounds.right);

      if (distanceLeftRight < verticalDistance) {
        result.snapPosition.x = nodeBBounds.right;
        result.vertical = nodeBBounds.right;
        verticalDistance = distanceLeftRight;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |
      //  |___________|
      //              |
      //              |
      //              |‾‾‾‾‾‾‾‾‾‾‾|
      //              |     B     |
      //              |___________|
      const distanceRightLeft = Math.abs(nodeABounds.right - nodeBBounds.left);

      if (distanceRightLeft < verticalDistance) {
        result.snapPosition.x = nodeBBounds.left - nodeABounds.width;
        result.vertical = nodeBBounds.left;
        verticalDistance = distanceRightLeft;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|‾‾‾‾‾|‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |     |     B     |
      //  |___________|     |___________|
      const distanceTopTop = Math.abs(nodeABounds.top - nodeBBounds.top);

      if (distanceTopTop < horizontalDistance) {
        result.snapPosition.y = nodeBBounds.top;
        result.horizontal = nodeBBounds.top;
        horizontalDistance = distanceTopTop;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |
      //  |___________|_________________
      //                    |           |
      //                    |     B     |
      //                    |___________|
      const distanceBottomTop = Math.abs(nodeABounds.bottom - nodeBBounds.top);

      if (distanceBottomTop < horizontalDistance) {
        result.snapPosition.y = nodeBBounds.top - nodeABounds.height;
        result.horizontal = nodeBBounds.top;
        horizontalDistance = distanceBottomTop;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|     |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |     |     B     |
      //  |___________|_____|___________|
      const distanceBottomBottom = Math.abs(
        nodeABounds.bottom - nodeBBounds.bottom
      );

      if (distanceBottomBottom < horizontalDistance) {
        result.snapPosition.y = nodeBBounds.bottom - nodeABounds.height;
        result.horizontal = nodeBBounds.bottom;
        horizontalDistance = distanceBottomBottom;
      }

      //                    |‾‾‾‾‾‾‾‾‾‾‾|
      //                    |     B     |
      //                    |           |
      //  |‾‾‾‾‾‾‾‾‾‾‾|‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾
      //  |     A     |
      //  |___________|
      const distanceTopBottom = Math.abs(nodeABounds.top - nodeBBounds.bottom);

      if (distanceTopBottom < horizontalDistance) {
        result.snapPosition.y = nodeBBounds.bottom;
        result.horizontal = nodeBBounds.bottom;
        horizontalDistance = distanceTopBottom;
      }

      return result;
    }, defaultResult);
}

// Edges toolbar data

export const colors = [
  "#0f172a", // Black
  "#94a3b8", // Slate 400
  "#9ca3af", // Gray 400
  "#a1a1aa", // Zinc 400
  "#a3a3a3", // Neutral 400
  "#a8a29e", // Stone 400
  "#f6f6f6", // Light Gray
  "#f87171", // Red 400
  "#fb923c", // Orange 400
  "#fbbf24", // Amber 400
  "#facc15", // Yellow 400
  "#a3e635", // Lime 400
  "#4ade80", // Green 400
  "#34d399", // Emerald 400
  "#2dd4bf", // Teal 400
  "#22d3ee", // Cyan 400
  "#38bdf8", // Sky 400
  "#60a5fa", // Blue 400
  "#818cf8", // Indigo 400
  "#a78bfa", // Violet 400
  "#c084fc", // Purple 400
  "#e879f9", // Fuchsia 400
  "#f472b6", // Pink 400
  "#fb7185", // Rose 400
  "#ffffff", // White
];

export const lightColors = [
  "#d9dde6", // Lightened Black
  "#edf0f5", // Lightened Slate 400
  "#eff1f3", // Lightened Gray 400
  "#f6f6f6", // Lightened Light Gray
  "#fde8e8", // Lightened Red 400
  "#ffe9d9", // Lightened Orange 400
  "#ffeed5", // Lightened Amber 400
  "#fffbdb", // Lightened Yellow 400
  "#eefcd8", // Lightened Lime 400
  "#e7f9eb", // Lightened Green 400
  "#e6faf1", // Lightened Emerald 400
  "#e5faf6", // Lightened Teal 400
  "#e4f9fc", // Lightened Cyan 400
  "#e7f5fd", // Lightened Sky 400
  "#ebf3fd", // Lightened Blue 400
  "#eff0fd", // Lightened Indigo 400
  "#f4edfd", // Lightened Violet 400
  "#f8f1fe", // Lightened Purple 400
  "#fce8fe", // Lightened Fuchsia 400
  "#fdeadf", // Lightened Pink 400
  "#fce9eb", // Lightened Rose 400
  "#ffffff", // Lightened White
];

export const edgeStyles = [
  {
    label: "Solid",
    style: { stroke: "#858790", strokeWidth: 2, strokeDasharray: "none" },
  },

  {
    label: "Dashe",
    style: { stroke: "#858790", strokeWidth: 2, strokeDasharray: "10,10" },
  },
  {
    label: "Fine Dashed",
    style: { stroke: "#858790", strokeWidth: 2, strokeDasharray: "5,5" },
  },
];

export const objectAnimatedArray = [
  { color: "#94a3b8" }, // Slate 400
  { color: "#9ca3af" }, // Gray 400
  { color: "#a1a1aa" }, // Zinc 400
  { color: "#a3a3a3" }, // Neutral 400
  { color: "#a8a29e" }, // Stone 400
  { color: "#f87171" }, // Red 400
  { color: "#fb923c" }, // Orange 400
  { color: "#fbbf24" }, // Amber 400
  { color: "#facc15" }, // Yellow 400
  { color: "#4ade80" }, // Green 400
  { color: "#34d399" }, // Emerald 400
  { color: "#2dd4bf" }, // Teal 400
  { color: "#22d3ee" }, // Cyan 400
  { color: "#38bdf8" }, // Sky 400
  { color: "#60a5fa" }, // Blue 400
  { color: "#818cf8" }, // Indigo 400
  { color: "#a78bfa" }, // Violet 400
  { color: "#c084fc" }, // Purple 400
  { color: "#e879f9" }, // Fuchsia 400
  { color: "#f472b6" }, // Pink 400
  { color: "#fb7185" }, // Rose 400
];

export const edgeTypesList = [
  {
    type: "straight",
    label: "Straight",
  },
  {
    type: "step",
    label: "Step",
  },
  {
    type: "smoothstep",
    label: "Smoothstep",
  },
  {
    type: "default",
    label: "Bezier",
  },
];

export const edgeSpeedsList = [
  {
    value: "4",
    label: "Slow",
  },
  {
    value: "2",
    label: "Medium",
  },
  {
    value: "1",
    label: "Fast",
  },
];

/******************************/
/********* Node Order *********/
/******************************/

// Move the node one layer up
// const bringNodeToFront = (nodeId: string, nodes: any[]) => {
//   const index = nodes.findIndex((n) => n.id === nodeId);
//   if (index === -1 || index === nodes.length - 1) return nodes; // Already at the top

//   const newNodes = [...nodes];
//   const [node] = newNodes.splice(index, 1); // Remove the node
//   newNodes.splice(index + 1, 0, node); // Insert it one position higher

//   return newNodes;
// };

// // Move the node one layer down
// const sendNodeToBack = (nodeId: string, nodes: any[]) => {
//   const index = nodes.findIndex((n) => n.id === nodeId);
//   if (index <= 0) return nodes; // Already at the bottom or not found

//   const newNodes = [...nodes];
//   const [node] = newNodes.splice(index, 1); // Remove the node
//   newNodes.splice(index - 1, 0, node); // Insert it one position lower

//   return newNodes;
// };

// // Bring the node to the absolute top
// const bringNodeToTop = (nodeId: string, nodes: any[]) => {
//   const index = nodes.findIndex((n) => n.id === nodeId);
//   if (index === -1 || index === nodes.length - 1) return nodes; // Already at the top

//   const newNodes = [...nodes];
//   const [node] = newNodes.splice(index, 1); // Remove the node
//   newNodes.push(node); // Push it to the end

//   return newNodes;
// };

// // Send the node to the absolute bottom
// const sendNodeToBottom = (nodeId: string, nodes: any[]) => {
//   const index = nodes.findIndex((n) => n.id === nodeId);
//   if (index <= 0) return nodes; // Already at the bottom or not found

//   const newNodes = [...nodes];
//   const [node] = newNodes.splice(index, 1); // Remove the node
//   newNodes.unshift(node); // Insert it at the start

//   return newNodes;
// };

/***********************************/
/********* Diagram Patches *********/
/***********************************/

type PatchOperation = "add" | "delete" | "replace";

type PatchTarget = "node" | "edge";

export interface Patch {
  op: PatchOperation;
  target: PatchTarget;
  id?: string; // Optional for 'add' operations
  value: any;
}

const defaultGroupConfig = {
  position: { x: 0, y: 0 },
  selected: false,
  data: {
    border_style: "dashed",
    color: "#0f172a",
    background: "#f3f3f3",
  },
  style: { zIndex: 1 },
  width: 300,
  height: 300,
  parentId: null,
  extent: "parent",
  dragging: false,
  className: "",
  resizing: false,
};

const defaultNodeConfig: Partial<Node> = {
  type: "node",
  position: {
    x: 0,
    y: 0,
  },
  selected: false,
  data: {
    border_style: "",
    color: "",
    background: "",
  },
  style: {
    zIndex: 3000,
  },
  width: 120,
  dragging: false,
};

const defaultEdgeConfig: Partial<Edge> = {
  style: {
    strokeWidth: 2,
    strokeDasharray: "5,5",
    stroke: "#b1b1b7",
  },
  markerEnd: {
    type: MarkerType.ArrowClosed,
    color: "#b1b1b7",
  },
  animated: true,
  type: "default",
  selected: false,
};

export function applyPatches(
  nodes: Node[],
  edges: Edge[],
  patches: Patch[]
): { nodes: Node[]; edges: Edge[] } {
  // Create immutable copies of nodes and edges
  let newNodes = [...nodes];
  let newEdges = [...edges];

  patches.forEach((patch) => {
    const { op, target, id, value } = patch;

    if (target === "node") {
      switch (op) {
        case "add":
          if (value.type === "group") {
            const lastGroupIndex = newNodes.reduce((lastIndex, node, index) => {
              return node.type === "group" ? index : lastIndex;
            }, -1);
            const lastGroupZIndex =
              newNodes[lastGroupIndex]?.style?.zIndex ?? 0;

            const newGroupNode = merge({}, defaultGroupConfig, value, {
              style: {
                zIndex:
                  typeof lastGroupZIndex === "number"
                    ? lastGroupZIndex + 1000
                    : 1000,
              },
            });

            newNodes = [
              ...newNodes.slice(0, lastGroupIndex + 1),
              newGroupNode,
              ...newNodes.slice(lastGroupIndex + 1),
            ];
          } else if (value.type === "node") {
            const lastNodeZIndex =
              newNodes[newNodes.length - 1]?.style?.zIndex ?? 0;
            const newNode = merge({}, defaultNodeConfig, value, {
              style: {
                zIndex:
                  typeof lastNodeZIndex === "number"
                    ? lastNodeZIndex + 1000
                    : 1000,
              },
            });

            newNodes = [...newNodes, newNode];
          } else {
            newNodes = [...newNodes, value];
          }
          break;
        case "delete":
          newNodes = newNodes.filter((node) => node.id !== id);
          break;
        case "replace":
          const nodeIndex = newNodes.findIndex((node) => node.id === id);

          if (nodeIndex !== -1) {
            newNodes[nodeIndex] = { ...newNodes[nodeIndex], ...value };
          }
          break;
      }
    } else if (target === "edge") {
      switch (op) {
        case "add":
          const newEdge = merge({}, defaultEdgeConfig, value);

          newEdges = [...newEdges, newEdge];
          break;
        case "delete":
          newEdges = newEdges.filter((edge) => edge.id !== id);
          break;
        case "replace":
          const edgeIndex = newEdges.findIndex((edge) => edge.id === id);

          if (edgeIndex !== -1) {
            newEdges[edgeIndex] = { ...newEdges[edgeIndex], ...value };
          }
          break;
      }
    }
  });

  // Return a new diagram object
  return { nodes: newNodes, edges: newEdges };
}

export const fontSizesOptions = [
  { size: 1, name: "Tiny" },
  { size: 2, name: "Small" },
  { size: 3, name: "Normal" },
  { size: 4, name: "Medium" },
  { size: 5, name: "Large" },
  { size: 6, name: "Extra Large" },
  { size: 7, name: "Huge" },
];
