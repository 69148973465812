import {
  ArrowTopRightOnSquareIcon,
  PencilIcon,
  TrashIcon,
  SquaresPlusIcon,
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
  // ChevronDownIcon,
  // ChevronUpIcon,
  // RectangleStackIcon,
  // Cog6ToothIcon,
} from "@heroicons/react/24/outline";
import {
  Handle,
  NodeResizer,
  NodeToolbar,
  Position,
  useReactFlow,
  useStore,
} from "@xyflow/react";
import { memo, useEffect, useRef, useState, useCallback, useMemo } from "react";
import {
  setOpenNodeSetting,
  setSelectedNodeSetting,
} from "../../../redux/diagrams/diagramsSlice";

import { Tooltip } from "react-tooltip";
import { debounce } from "lodash";
import { getRelativeNodesBounds } from "./utils";
import { useAppDispatch } from "../../../hooks";
import useDetachNodes from "./useDetachNodes";
import Popover from "../../../components/popover";
import NodeIconsDropdown from "./NodeIconsDropdown";

const lineStyle = { borderColor: "white" };

const SimpleNode = memo(({ id, data, width, style, position }: any) => {
  const [isEditText, setIsEditText] = useState(false);
  const [nodeTitle, setNodeTitle] = useState("");

  const hasParent = useStore((store) => !!store.nodeLookup.get(id)?.parentId);
  const parentId = useStore((store) => store.nodeLookup.get(id)?.parentId);
  const { deleteElements, setNodes, getNodes } = useReactFlow();
  const detachNodes = useDetachNodes();

  const dispatch = useAppDispatch();
  const nodesList = getNodes();

  const resizeObserverRef = useRef<ResizeObserver | null>(null);
  const nodeRef = useRef<any>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // Get Complete Node Object
  const nodeObject = useMemo(
    () => nodesList.find((n) => n.id === id),
    [nodesList, id]
  );

  // Get Parent Object
  const parentObject = useMemo(
    () => nodesList.find((n) => n.id === parentId),
    [nodesList, parentId]
  );

  const { minWidth, minHeight } = useStore((store) => {
    const childNodes = Array.from(store.nodeLookup.values()).filter(
      (n) => n.parentId === id
    );
    const rect = getRelativeNodesBounds(childNodes);

    return {
      minWidth: rect.x + rect.width,
      minHeight: rect.y + rect.height,
      hasChildNodes: childNodes.length > 0,
    };
  }, isEqual);

  // Use values from node data, defaulting to 5 if not set
  const leftHandles = data?.leftHandles ?? 5;
  const rightHandles = data?.rightHandles ?? 5;
  const topHandles = data?.topHandles ?? 5;
  const bottomHandles = data?.bottomHandles ?? 5;

  // Update handle counts in node data
  // const updateHandleCounts = useCallback((type: string, value: number) => {
  //   setNodes((nodes) =>
  //     nodes.map((node) =>
  //       node.id === id
  //         ? {
  //             ...node,
  //             data: {
  //               ...node.data,
  //               [type]: value,
  //             },
  //           }
  //         : node
  //     )
  //   );
  // }, [id, setNodes]);

  /**************************************
   ** Check Toolbar Position Code Start ***
   **************************************/

  // Define the node's width and height (static or dynamic based on the node)
  const nodeWidth = nodeObject?.measured?.width ?? 0;
  const nodeHeight = nodeObject?.measured?.height ?? 0;

  const nodePosition = nodeObject?.position ?? { x: 0, y: 0 };

  // Get the canvas transform and viewport size
  const [x, y, scale] = useStore((state) => state.transform);
  const viewportWidth =
    document.querySelector(".react-flow__renderer")?.clientWidth ?? 0; // Viewport width (React Flow wrapper width)
  const viewportHeight =
    document.querySelector(".react-flow__renderer")?.clientHeight ?? 0; // Viewport height (React Flow wrapper height)

  const toolbarOffset = nodeWidth; // Offset for the toolbar from the node

  // Calculate node position in the viewport
  const nodeViewportX = nodePosition?.x * scale + x + nodeWidth / 2;
  const nodeViewportY = nodePosition?.y * scale + y + nodeHeight / 2;

  // Determine the toolbar position based on the node's position in the viewport
  const determineToolbarPosition = () => {
    const isAtTop = nodeViewportY < toolbarOffset;
    const isAtBottom = viewportHeight - nodeViewportY < toolbarOffset;
    const isAtLeft = nodeViewportX < toolbarOffset;
    const isAtRight = viewportWidth - nodeViewportX < toolbarOffset;

    if (isAtTop) return "top"; // Place toolbar below the node
    if (isAtBottom) return "bottom"; // Place toolbar above the node
    if (isAtLeft) return "left"; // Place toolbar to the right
    if (isAtRight) return "right"; // Place toolbar to the left

    return "top"; // Default position
  };

  const toolbarPosition = determineToolbarPosition();

  /**************************************
   ** Check Toolbar Position Code End ***
   **************************************/

  const fontSize = useMemo(() => {
    const baseSize = 14; // Default font size
    return Math.max(baseSize, Math.min(width) / 16); // Adjust font size
  }, [width]);

  useEffect(() => {
    if (parentObject?.style?.zIndex) {
      setNodes((nds) => {
        // Find the maximum zIndex of nodes with the same parent
        const maxSiblingZIndex = nds
          .filter((n) => n.parentId === parentId)
          .reduce((max, n) => Math.max(max, Number(n.style?.zIndex || 0)), 0);

        return nds.map((n) =>
          n.id === id
            ? {
                ...n,
                style: {
                  ...n.style,
                  zIndex: maxSiblingZIndex + 1, // Set zIndex to max sibling zIndex + 1
                },
              }
            : n
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDelete = useCallback(
    () => deleteElements({ nodes: [{ id }] }),
    [deleteElements, id]
  );
  const onDetach = useCallback(() => detachNodes([id]), [detachNodes, id]);

  const onOpenNodeEditSetting = useCallback(() => {
    dispatch(setSelectedNodeSetting({ id, data }));
    dispatch(setOpenNodeSetting(true));
  }, [dispatch, id, data]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleResize = useCallback(
    debounce(() => {
      // Resize logic
    }, 100),
    []
  );

  const onUpdateNodes = useCallback(() => {
    setNodes((nodes) =>
      nodes.map((node) =>
        node.id === id
          ? { ...node, data: { ...node.data, label: nodeTitle || data?.label } }
          : node
      )
    );
    setNodeTitle("");
  }, [setNodes, id, nodeTitle, data]);

  useEffect(() => {
    const element = document.getElementById(id);
    if (!element) return;

    if (resizeObserverRef.current) {
      resizeObserverRef.current.disconnect();
    }

    resizeObserverRef.current = new ResizeObserver(() => {
      handleResize();
    });

    resizeObserverRef.current.observe(element);

    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, [handleResize, id, onUpdateNodes]);

  const onEditNodeText = useCallback(() => {
    setNodeTitle(data?.label);
    setIsEditText((prev) => !prev);
  }, [data]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === "Enter" && !event.shiftKey) {
        onUpdateNodes();
        setIsEditText(false);
        event.preventDefault();
      }
    },
    [onUpdateNodes]
  );

  const onChangeNodeTitle = useCallback((e: any) => {
    setNodeTitle(e.target.value);
  }, []);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        textareaRef.current &&
        !textareaRef.current.contains(event.target as Node)
      ) {
        onUpdateNodes();
        setIsEditText(false);
        setNodeTitle("");
      }
    },
    [onUpdateNodes]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const onChangeNodeIcon = useCallback(
    (url: string, name: string) => {
      setNodes((prevNodes) =>
        prevNodes.map((node) =>
          node.id === id
            ? {
                ...node,
                data: { ...node.data, icon_url: url, label: name },
              }
            : node
        )
      );
    },
    [setNodes, id]
  );

  const findMaxZIndex = useCallback((nodesListArray: any[]) => {
    return nodesListArray.length > 0
      ? Math.max(
          ...nodesListArray
            .map((n) => n.style?.zIndex)
            .filter((z): z is number => z !== undefined)
        )
      : 0;
  }, []);

  const findMinZIndex = useCallback((nodesListArray: any[]) => {
    return nodesListArray.length > 0
      ? Math.min(
          ...nodesListArray
            .map((n) => n.style?.zIndex)
            .filter((z): z is number => z !== undefined)
        )
      : 0;
  }, []);

  function findClosestBiggerZIndex(
    selectedZIndex: number,
    nodesListArray: any[]
  ) {
    // Filter the array for zIndex values smaller than the selected one
    const biggestZIndices = nodesListArray
      .map((node) => node?.style?.zIndex)
      .filter((zIndex) => zIndex > selectedZIndex);

    // Return the maximum of the smaller zIndex values or null if none exists
    return biggestZIndices.length > 0 ? Math.min(...biggestZIndices) : 1300;
  }

  const bringNodeToFront = useCallback(
    (nodeId: string, absolute = false) => {
      setNodes((nds) => {
        const index = nds.findIndex((n) => n.id === nodeId);
        // if (index === -1 || (absolute && index === nds.length - 1)) return nds;

        const newNodes = [...nds];
        const node = newNodes[index];

        const maxZIndex =
          newNodes.length > 0
            ? Math.max(
                ...newNodes
                  .map((n) => n.style?.zIndex)
                  .filter((z): z is number => z !== undefined)
              )
            : 0;

        const greaterThenSelectedNode = findClosestBiggerZIndex(
          Number(node?.style?.zIndex),
          newNodes
        );

        if (absolute) {
          node.style = {
            ...node.style,
            zIndex: maxZIndex + 1000,
          };
        } else {
          node.style = {
            ...node.style,
            zIndex: greaterThenSelectedNode + 300,
          };
        }

        return newNodes;
      });
      handleNodeMouseEnter();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setNodes]
  );

  function findClosestSmallerZIndex(
    selectedZIndex: number,
    nodesListArray: any[]
  ) {
    // Filter the array for zIndex values smaller than the selected one
    const smallerZIndices = nodesListArray
      .map((node) => node?.style?.zIndex)
      .filter((zIndex) => zIndex < selectedZIndex);

    // Return the maximum of the smaller zIndex values or null if none exists
    return smallerZIndices.length > 0 ? Math.max(...smallerZIndices) : 1300;
  }

  const sendNodeToBack = useCallback(
    (nodeId: string, absolute = false) => {
      setNodes((nds) => {
        const index = nds.findIndex((n) => n.id === nodeId);
        if (index === -1) return nds;

        const newNodes = [...nds];
        const node = newNodes[index];

        const minZIndex =
          newNodes.length > 0
            ? Math.min(
                ...newNodes
                  .map((n) => n.style?.zIndex)
                  .filter((z): z is number => z !== undefined)
              )
            : 0;

        const lessThenSelectedNode = findClosestSmallerZIndex(
          Number(node?.style?.zIndex),
          newNodes
        );

        // Determine the new zIndex
        let newZIndex = absolute ? minZIndex - 300 : lessThenSelectedNode - 300;

        // Ensure the node's zIndex does not go below its parent's zIndex
        const parentNode = nds.find((n) => n.id === node.parentId);
        if (parentNode && newZIndex < Number(parentNode.style?.zIndex || 0)) {
          newZIndex = Number(parentNode.style?.zIndex || 0) + 1;
        }

        node.style = {
          ...node.style,
          zIndex: newZIndex,
        };

        return newNodes;
      });

      handleNodeMouseEnter();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setNodes]
  );

  const selectedNodesCount = nodesList.filter((node) => node.selected).length;

  const handleNodeMouseEnter = useCallback(
    (e?: any) => {
      // Clear selection of all nodes except when multiple nodes are selected
      if (!nodeObject?.selected && !e?.ctrlKey && selectedNodesCount <= 1) {
        setNodes((nds) =>
          nds.map((n) => ({
            ...n,
            selected: false,
          }))
        );
      }
    },
    [setNodes, nodeObject, selectedNodesCount]
  );

  const handleNodeMouseLeave = useCallback(() => {
    if (nodeTitle) {
      onUpdateNodes();
      setIsEditText(false);
      setNodeTitle("");
    }
  }, [onUpdateNodes, nodeTitle]);

  const iconStyle = "h-[20px] text-[#6f717c]";

  const iconWrapper =
    "cursor-pointer p-[10px] rounded-[5px] hover:bg-[#f3f4f5]";

  // Create arrays of positions based on handle counts
  const getPositions = useMemo(() => {
    return (count: number) => {
      const countValue = 25;
      if (countValue <= 1) return [0.5];
      return Array.from({ length: countValue }, (_, i) => i / (countValue - 1));
    };
  }, []);

  // Add this near your other UI elements
  // const handleSettingsPopover = (
  //   <Popover
  //     buttonContent={
  //       <div className="cursor-pointer p-[10px] rounded-[5px] hover:bg-[#f3f4f5]">
  //         <Cog6ToothIcon className="h-[20px] text-[#6f717c]" />
  //       </div>
  //     }
  //     popoverContent={
  //       <div className="p-4 w-[250px]">
  //         <h3 className="text-sm font-semibold mb-3">Handle Settings</h3>
  //         <div className="space-y-3">
  //           <div>
  //             <label className="block text-xs text-gray-600 mb-1">
  //               Left Handles
  //             </label>
  //             <input
  //               type="number"
  //               min="1"
  //               max="20"
  //               value={leftHandles}
  //               onChange={(e) => {
  //                 const value = parseInt(e.target.value);
  //                 if (!isNaN(value) && value >= 1 && value <= 20) {
  //                   updateHandleCounts('leftHandles', value);
  //                 }
  //               }}
  //               onKeyDown={(e) => {
  //                 if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
  //                   e.preventDefault();
  //                   const currentValue = parseInt(e.currentTarget.value) || 0;
  //                   const newValue = e.key === 'ArrowUp'
  //                     ? Math.min(20, currentValue + 1)
  //                     : Math.max(1, currentValue - 1);
  //                   updateHandleCounts('leftHandles', newValue);
  //                 }
  //               }}
  //               onWheel={(e) => e.currentTarget.blur()}
  //               className="w-full px-2 py-1 text-sm border rounded"
  //             />
  //           </div>
  //           <div>
  //             <label className="block text-xs text-gray-600 mb-1">
  //               Right Handles
  //             </label>
  //             <input
  //               type="number"
  //               min="1"
  //               max="20"
  //               value={rightHandles}
  //               onChange={(e) => {
  //                 const value = parseInt(e.target.value);
  //                 if (!isNaN(value) && value >= 1 && value <= 20) {
  //                   updateHandleCounts('rightHandles', value);
  //                 }
  //               }}
  //               onKeyDown={(e) => {
  //                 // Prevent the default up/down arrow behavior
  //                 if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
  //                   e.preventDefault();
  //                   const currentValue = parseInt(e.currentTarget.value) || 0;
  //                   const newValue = e.key === 'ArrowUp'
  //                     ? Math.min(20, currentValue + 1)
  //                     : Math.max(1, currentValue - 1);
  //                   updateHandleCounts('rightHandles', newValue);
  //                 }
  //               }}
  //               // Prevent mousewheel from changing the value
  //               onWheel={(e) => e.currentTarget.blur()}
  //               className="w-full px-2 py-1 text-sm border rounded"
  //             />
  //           </div>
  //           <div>
  //             <label className="block text-xs text-gray-600 mb-1">
  //               Top Handles
  //             </label>
  //             <input
  //               type="number"
  //               min="1"
  //               max="20"
  //               value={topHandles}
  //               onChange={(e) => {
  //                 const value = parseInt(e.target.value);
  //                 if (!isNaN(value) && value >= 1 && value <= 20) {
  //                   updateHandleCounts('topHandles', value);
  //                 }
  //               }}
  //               onKeyDown={(e) => {
  //                 if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
  //                   e.preventDefault();
  //                   const currentValue = parseInt(e.currentTarget.value) || 0;
  //                   const newValue = e.key === 'ArrowUp'
  //                     ? Math.min(20, currentValue + 1)
  //                     : Math.max(1, currentValue - 1);
  //                   updateHandleCounts('topHandles', newValue);
  //                 }
  //               }}
  //               onWheel={(e) => e.currentTarget.blur()}
  //               className="w-full px-2 py-1 text-sm border rounded"
  //             />
  //           </div>
  //           <div>
  //             <label className="block text-xs text-gray-600 mb-1">
  //               Bottom Handles
  //             </label>
  //             <input
  //               type="number"
  //               min="1"
  //               max="20"
  //               value={bottomHandles}
  //               onChange={(e) => {
  //                 const value = parseInt(e.target.value);
  //                 if (!isNaN(value) && value >= 1 && value <= 20) {
  //                   updateHandleCounts('bottomHandles', value);
  //                 }
  //               }}
  //               onKeyDown={(e) => {
  //                 if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
  //                   e.preventDefault();
  //                   const currentValue = parseInt(e.currentTarget.value) || 0;
  //                   const newValue = e.key === 'ArrowUp'
  //                     ? Math.min(20, currentValue + 1)
  //                     : Math.max(1, currentValue - 1);
  //                   updateHandleCounts('bottomHandles', newValue);
  //                 }
  //               }}
  //               onWheel={(e) => e.currentTarget.blur()}
  //               className="w-full px-2 py-1 text-sm border rounded"
  //             />
  //           </div>
  //         </div>
  //       </div>
  //     }
  //     classes="w-[250px]"
  //   />
  // );

  return (
    <div
      onMouseEnter={handleNodeMouseEnter}
      onMouseLeave={handleNodeMouseLeave}
      style={{ zIndex: Number(style?.zIndex ?? 0) + 1 }}
      className="relative px-4 py-2 shadow-xl rounded-xl bg-white h-[100%]"
    >
      <div className="text-center">
        <img
          src={data?.icon_url}
          alt="icon"
          className="h-auto w-[70%] mx-auto mb-3"
        />
        <div
          className="font-semibold cursor-text h-full"
          ref={nodeRef}
          style={{
            fontSize: `${fontSize}px`,
            overflowWrap: "break-word",
            whiteSpace: "normal",
          }}
        >
          {isEditText ? (
            <textarea
              ref={textareaRef}
              autoFocus
              value={nodeTitle || data?.label}
              onChange={onChangeNodeTitle}
              onKeyDown={handleKeyDown}
              className="p-0 pl-1 border-0 bg-white shadow-sm text-xs font-semibold text-center resize-none h-full w-full"
              style={{ boxShadow: "none", minHeight: 40 }}
              draggable={false}
            />
          ) : (
            <span onClick={onEditNodeText}>{data?.label}</span>
          )}
        </div>
      </div>

      {/* Top Handles */}
      {getPositions(topHandles).map((position) => (
        <Handle
          key={`top-target-${position}`}
          type="target"
          className="z-50"
          position={Position.Top}
          id={`top-target-${position}`}
          style={{ left: `${position * 100}%` }}
        />
      ))}
      {getPositions(topHandles).map((position) => (
        <Handle
          key={`top-source-${position}`}
          type="source"
          className="z-50"
          position={Position.Top}
          id={`top-source-${position}`}
          style={{ left: `${position * 100}%` }}
        />
      ))}

      {/* Bottom Handles */}
      {getPositions(bottomHandles).map((position) => (
        <Handle
          key={`bottom-target-${position}`}
          type="target"
          className="z-50"
          position={Position.Bottom}
          id={`bottom-target-${position}`}
          style={{ left: `${position * 100}%` }}
        />
      ))}
      {getPositions(bottomHandles).map((position) => (
        <Handle
          key={`bottom-source-${position}`}
          type="source"
          className="z-50"
          position={Position.Bottom}
          id={`bottom-source-${position}`}
          style={{ left: `${position * 100}%` }}
        />
      ))}

      {/* Left Handles */}
      {getPositions(leftHandles).map((position) => (
        <Handle
          key={`left-target-${position}`}
          type="target"
          className="z-50"
          position={Position.Left}
          id={`left-target-${position}`}
          style={{ top: `${position * 100}%` }}
        />
      ))}
      {getPositions(leftHandles).map((position) => (
        <Handle
          key={`left-source-${position}`}
          type="source"
          className="z-50"
          position={Position.Left}
          id={`left-source-${position}`}
          style={{ top: `${position * 100}%` }}
        />
      ))}

      {/* Right Handles */}
      {getPositions(rightHandles).map((position) => (
        <Handle
          key={`right-target-${position}`}
          type="target"
          className="z-50"
          position={Position.Right}
          id={`right-target-${position}`}
          style={{ top: `${position * 100}%` }}
        />
      ))}
      {getPositions(rightHandles).map((position) => (
        <Handle
          key={`right-source-${position}`}
          type="source"
          className="z-50"
          position={Position.Right}
          id={`right-source-${position}`}
          style={{ top: `${position * 100}%` }}
        />
      ))}

      <NodeResizer
        lineStyle={lineStyle}
        minHeight={minHeight}
        minWidth={minWidth}
        isVisible={true} // Always show the resizer handles
        handleStyle={{
          width: "12px", // Resizer handle size for easy grabbing
          height: "12px",
          borderRadius: "50%",
          backgroundColor: "transparent",
          border: 0,
        }}
      />

      <NodeToolbar className="nodrag nowheel absolute top-[10px] left-0">
        <div className="bg-white p-[4px] mb-[10px] rounded-[7px] shadow-[0_0_15px_5px_rgba(0,0,0,0.1)] border border-[#e0e0e0] flex items-center justify-center">
          {/* {handleSettingsPopover} */}

          <div className="p-[0.5px]">
            <Popover
              buttonContent={
                <div className={`${iconWrapper}`}>
                  <SquaresPlusIcon className={`${iconStyle}`} />
                </div>
              }
              popoverContent={
                <div className="relative">
                  <NodeIconsDropdown
                    onChangeNodeIcon={onChangeNodeIcon}
                    nodeIcon={data?.icon_url}
                  />
                  <div
                    className={`w-0 h-0 ${
                      (toolbarPosition === "bottom" &&
                        "bottom-[-19px] left-[115px] rotate-180") ||
                      (toolbarPosition === "top" &&
                        "bottom-[-19px] left-[115px] rotate-180") ||
                      (toolbarPosition === "left" &&
                        "top-[50px] left-[-18px] rotate-[35deg]") ||
                      (toolbarPosition === "right" &&
                        "top-[50px] right-[-22px] rotate-90") ||
                      "bottom-[-19px] left-[115px] rotate-180"
                    } border-l-[12px] border-l-transparent border-r-[12px] border-r-transparent border-b-[17px] border-b-white absolute`}
                  />
                </div>
              }
              classes={`w-[400px] ${
                (toolbarPosition === "bottom" &&
                  "bottom-[45px] left-[-110px]") ||
                (toolbarPosition === "top" && "bottom-[45px] left-[-110px]") ||
                (toolbarPosition === "left" && "left-[145px] top-[-45px]") ||
                (toolbarPosition === "right" &&
                  "left-[-425px] bottom-[-140px]") ||
                "bottom-[40px] left-[-110px]"
              }`}
            />
          </div>

          {nodesList?.length > 1 && (
            <div className="flex items-center justify-center gap-2">
              {Number(nodeObject?.style?.zIndex) < findMaxZIndex(nodesList) && (
                <>
                  <div
                    className={`${iconWrapper} order-3`}
                    onClick={() => bringNodeToFront(id, true)}
                    data-tooltip-id="move-absolute-top-node-tooltip"
                  >
                    <ChevronDoubleUpIcon className={`${iconStyle}`} />
                  </div>
                </>
              )}

              {Number(nodeObject?.style?.zIndex) > findMinZIndex(nodesList) && (
                <>
                  <div
                    className={`${iconWrapper} order-4`}
                    onClick={() => sendNodeToBack(id, true)}
                    data-tooltip-id="move-absolute-bottom-node-tooltip"
                  >
                    <ChevronDoubleDownIcon className={`${iconStyle}`} />
                  </div>
                </>
              )}
            </div>
          )}

          <div
            onClick={onDelete}
            className={`${iconWrapper}`}
            data-tooltip-id="delete-node-tooltip"
          >
            <TrashIcon className={`${iconStyle}`} />
          </div>

          <div
            onClick={onOpenNodeEditSetting}
            className={`${iconWrapper}`}
            data-tooltip-id="edit-node-tooltip"
          >
            <PencilIcon className={`${iconStyle}`} />
          </div>

          {hasParent && (
            <div
              className={`${iconWrapper}`}
              data-tooltip-id="detach-node-tooltip"
            >
              <ArrowTopRightOnSquareIcon
                onClick={onDetach}
                className={`${iconStyle}`}
              />
            </div>
          )}
        </div>
      </NodeToolbar>

      <Tooltip className="z-50" id="move-up-node-tooltip" place="top">
        Move Up
      </Tooltip>

      <Tooltip className="z-50" id="move-absolute-top-node-tooltip" place="top">
        Move to Absolute Top
      </Tooltip>

      <Tooltip className="z-50" id="move-down-node-tooltip" place="top">
        Move Down
      </Tooltip>

      <Tooltip
        className="z-50"
        id="move-absolute-bottom-node-tooltip"
        place="top"
      >
        Move to Absolute Bottom
      </Tooltip>

      <Tooltip className="z-50" id="delete-node-tooltip" place="top">
        Delete
      </Tooltip>

      <Tooltip className="z-50" id="edit-node-tooltip" place="top">
        Edit Setting
      </Tooltip>

      <Tooltip className="z-50" id="detach-node-tooltip" place="top">
        Detach
      </Tooltip>

      <Tooltip className="z-50" id="move-up-down-tooltip" place="top">
        Move Up/Down
      </Tooltip>
    </div>
  );
});

type IsEqualCompareObj = {
  minWidth: number;
  minHeight: number;
  hasChildNodes: boolean;
};

function isEqual(prev: IsEqualCompareObj, next: IsEqualCompareObj): boolean {
  return (
    prev.minWidth === next.minWidth &&
    prev.minHeight === next.minHeight &&
    prev.hasChildNodes === next.hasChildNodes
  );
}

export default SimpleNode;
