import { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useFloating, offset, flip, shift } from '@floating-ui/react-dom';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { classNames } from 'src/utils/utilities';

export type SimpleItem = {
  id: number;
  option: string;
};

export type CategoryItem = {
  id: number;
  category: string;
  options: string[];
};

export type Item = SimpleItem | CategoryItem;

export type SelectCallbackProps = {
  id: Item['id'];
  category: string | null;
  option: string | null;
};

function checkIsCategory(item: Item): item is CategoryItem {
  return 'options' in item;
}

function findCategoryOption(items: Item[], id: number, optionIndex: number) {
  const item = items.find(({ id: categoryId }) => categoryId === id);

  if (!item || !checkIsCategory(item)) return null;

  return item.options[optionIndex];
}

export default function CommandsDropdown({
  show,
  items,
  onSelect,
  children,
}: {
  show: boolean;
  items: Item[];
  onSelect: (props: SelectCallbackProps | null, element: HTMLElement) => void;
  children: (setReference: (el: HTMLElement | null) => void) => React.ReactNode;
}) {
  const [openCategory, setOpenCategory] = useState<number | null>(null);

  const onSelectRef = useRef(onSelect);
  const categoryRefs = useRef<{ [key: number]: HTMLDivElement | HTMLButtonElement | null }>({});
  const optionRefs = useRef<{ [key: number]: { [key: number]: HTMLButtonElement | null } }>({});

  const paddingBottom = useMemo(() => {
    const totalItems = items.length;

    return items.reduce((acc, item, index) => {
      const remainingItems = totalItems - (index + 1);
      const padding = checkIsCategory(item) ? (item.options.length - remainingItems - 1) * 48 : 0;

      return padding > acc ? padding : acc;
    }, 0);
  }, [items]);

  const { refs, floatingStyles, placement } = useFloating({
    placement: 'bottom-start',
    middleware: [
      offset({ mainAxis: 4, crossAxis: 8 }),
      flip({
        fallbackPlacements: ['top-start'],
        fallbackStrategy: 'bestFit',
        mainAxis: true,
        crossAxis: false,
        padding: {
          bottom: paddingBottom,
        },
      }),
      shift({
        padding: 8,
      }),
    ],
  });

  // Function to determine the placement of nested dropdowns
  const getNestedDropdownPlacement = (mainPlacement: string) => {
    return mainPlacement.startsWith('top') ? 'bottom-start' : 'top-start';
  };

  useEffect(() => {
    onSelectRef.current = onSelect;
  }, [onSelect]);

  useEffect(() => {
    if (!show) return;

    const handleClickOutside = (event: MouseEvent) => {
      const {
        floating: { current: floating },
        reference: { current: reference },
      } = refs;

      if (floating && !floating.contains(event.target as Node)) {
        setOpenCategory(null);
        onSelectRef.current(null, reference as HTMLElement);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refs, show]);

  useEffect(() => {
    if (show) {
      const firstCategory = categoryRefs.current[0];

      if (firstCategory) {
        firstCategory.focus();
      }
    }
  }, [show]);

  const handleItemKeyDown = useCallback(
    (event: React.KeyboardEvent, itemId: number) => {
      const { key } = event;
      const item = items.find(({ id }) => id === itemId);

      if (!item) return;

      const isCategory = checkIsCategory(item);

      if (isCategory) {
        if (key === 'Enter' || key === 'ArrowRight') {
          event.preventDefault();
          setOpenCategory(itemId);

          setTimeout(() => {
            const firstOption = optionRefs.current[itemId]?.[0];

            if (firstOption) {
              firstOption.focus();
            }
          }, 0);
        }

        if (key === 'ArrowLeft') {
          setOpenCategory(null);
        }
      }

      if (key === 'Escape') {
        event.preventDefault();
        setOpenCategory(null);
        onSelectRef.current(null, refs.reference.current as HTMLElement);
      }

      if (key === 'ArrowDown') {
        event.preventDefault();
        const nextCategory = categoryRefs.current[itemId + 1];

        if (nextCategory) {
          nextCategory.focus();
        }
      }

      if (key === 'ArrowUp') {
        event.preventDefault();
        const prevCategory = categoryRefs.current[itemId - 1];

        if (prevCategory) {
          prevCategory.focus();
        }
      }
    },
    [items, refs.reference]
  );

  const handleOptionKeyDown = useCallback(
    (event: React.KeyboardEvent, itemId: number, optionIndex: number) => {
      const { key } = event;

      if (key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();

        const option = findCategoryOption(items, itemId, optionIndex);

        if (option) {
          onSelectRef.current(
            {
              id: itemId,
              category: null,
              option,
            },
            refs.reference.current as HTMLElement
          );
          setOpenCategory(null);
        }
      }

      if (key === 'Escape') {
        event.preventDefault();
        event.stopPropagation();

        setOpenCategory(null);

        const category = categoryRefs.current[itemId];

        if (category) {
          category.focus();
        }
      }

      if (key === 'ArrowLeft') {
        event.preventDefault();
        event.stopPropagation();

        setOpenCategory(null);

        const category = categoryRefs.current[itemId];

        if (category) {
          category.focus();
        }
      }

      if (key === 'ArrowDown') {
        event.preventDefault();
        event.stopPropagation();

        const nextOption = optionRefs.current[itemId]?.[optionIndex + 1];

        if (nextOption) {
          nextOption.focus();
        }
      }

      if (key === 'ArrowUp') {
        event.preventDefault();
        event.stopPropagation();

        const prevOption = optionRefs.current[itemId]?.[optionIndex - 1];

        if (prevOption) {
          prevOption.focus();
        }
      }
    },
    [refs, items]
  );

  return (
    <>
      {children(refs.setReference)}
      {show && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          className='absolute z-10 top-0 left-4 min-w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
        >
          {items.map((item) => {
            const isCategory = checkIsCategory(item);

            if (isCategory) {
              const { id: categoryId, category, options } = item;

              const isOpen = openCategory === categoryId;
              const nestedPlacement = getNestedDropdownPlacement(placement);

              return (
                <div
                  key={categoryId}
                  className='relative p-1'
                >
                  <div
                    ref={(el) => (categoryRefs.current[categoryId] = el)}
                    className={classNames(
                      'relative group px-4 py-3 rounded-md text-gray-700 transition-colors',
                      'flex items-center justify-between gap-x-2 text-xs',
                      isOpen && '!text-customDarkBlue bg-gray-100',
                      'focus:!text-customDarkBlue focus:bg-gray-100'
                    )}
                    role='menuitem'
                    aria-haspopup='true'
                    aria-expanded={isOpen}
                    tabIndex={0}
                    onMouseEnter={() => setOpenCategory(categoryId)}
                    onKeyDown={(event) => handleItemKeyDown(event, categoryId)}
                  >
                    {category}
                    <ChevronRightIcon
                      className={classNames(
                        'h-4 w-4 text-gray-400 transition-colors',
                        isOpen && '!text-customDarkBlue'
                      )}
                    />
                    <div
                      className={classNames(
                        'absolute',
                        nestedPlacement === 'top-start' ? 'top-1' : 'bottom-1',
                        'left-full -translate-x-2 z-10 min-w-48 p-1 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                        !isOpen && 'invisible'
                      )}
                      role='menu'
                    >
                      {options.map((option, optionIndex) => (
                        <button
                          key={option}
                          ref={(el) => {
                            if (!optionRefs.current[categoryId]) {
                              optionRefs.current[categoryId] = {};
                            }
                            optionRefs.current[categoryId][optionIndex] = el;
                          }}
                          type='button'
                          className={classNames(
                            'w-full text-gray-700 transition-colors',
                            'group flex items-center px-4 py-3 text-xs rounded-md cursor-pointer',
                            'hover:text-customDarkBlue hover:bg-gray-100',
                            'focus:text-customDarkBlue focus:bg-gray-100',
                            !isOpen && 'invisible'
                          )}
                          onKeyDown={(event) => handleOptionKeyDown(event, categoryId, optionIndex)}
                          onClick={() =>
                            onSelect(
                              {
                                id: categoryId,
                                category,
                                option,
                              },
                              refs.reference.current as HTMLElement
                            )
                          }
                          role='menuitem'
                        >
                          {option}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              );
            }

            const { id, option } = item;

            return (
              <button
                key={id}
                ref={(el) => (categoryRefs.current[id] = el)}
                type='button'
                className={classNames(
                  'w-full text-gray-700 transition-colors',
                  'group flex items-center px-4 py-3 text-xs rounded-md cursor-pointer',
                  'hover:text-customDarkBlue hover:bg-gray-100',
                  'focus:text-customDarkBlue focus:bg-gray-100'
                )}
                onKeyDown={(event) => handleItemKeyDown(event, id)}
                onClick={() =>
                  onSelect(
                    {
                      id,
                      category: null,
                      option,
                    },
                    refs.reference.current as HTMLElement
                  )
                }
                role='menuitem'
              >
                {option}
              </button>
            );
          })}
        </div>
      )}
    </>
  );
}
