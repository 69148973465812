import React from "react";
import CustomButton from "../customButton";
import { useAccessToken } from "../../contexts/AccessTokenContext";
import { useAppDispatch } from "../../hooks";
import {
  setShowPricingModal,
  setShowCloseModalOption,
} from "../../redux/profile/profileSlice";
import { calculateReversedPercentage } from "../../utils/utilities";

const SubscriptionBox = () => {
  const { userTokenInfo } = useAccessToken();
  const dispatch = useAppDispatch();

  const accountStatus = userTokenInfo?.app_meta?.account_status;

  const onOpenPricingModal = () => {
    dispatch(setShowPricingModal(true));
    dispatch(setShowCloseModalOption(true));
  };

  return (
    <div className="bg-[#F7F7F8] rounded-[10px] px-[16px] py-[12px] shadow-sm">
      <div className="flex items-center justify-between text-xs text-[#14151A] mb-[10px]">
        <div className="font-semibold">Free Trial</div>
        <div>{accountStatus?.days_remaining} days left</div>
      </div>

      <div className="w-full bg-gray-200 rounded-full h-[8px] mb-[16px] dark:bg-gray-700">
        <div
          className="bg-[#26BD6C] h-[8px] rounded-full"
          style={{
            width: `${calculateReversedPercentage(accountStatus?.days_remaining)}%`,
          }}
        />
      </div>

      <CustomButton
        buttonType="secondary"
        buttonSize="sm"
        text="Upgrade"
        onClickBtn={onOpenPricingModal}
        type={"button"}
        btnStyle="w-full"
      />
    </div>
  );
};

export default React.memo(SubscriptionBox);
