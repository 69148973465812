import { useEffect } from 'react';
import { useUserTour } from 'src/contexts/userTour/UserTour.context';
import type { StepHandlers, TourHandlesMap, UserJourneyConfig } from 'src/type';

export function useTourHandlers(targetJourney: UserJourneyConfig, value: Record<string, StepHandlers>) {
  const { journey, addStepHandler, removeStepHandler } = useUserTour();

  useEffect(() => {
    if (targetJourney !== journey) return;

    Object.entries(value).forEach(([target, handler]) => {
      addStepHandler(target, handler);
    });

    return () => {
      Object.keys(value).forEach((target) => {
        removeStepHandler(target);
      });
    };
  }, [value, targetJourney, journey, addStepHandler, removeStepHandler]);
}

export function useRegisterTourHandle<T extends keyof TourHandlesMap>(componentName: T, handle: TourHandlesMap[T]) {
  const { journey, addHandle, removeHandle } = useUserTour();

  useEffect(() => {
    if (!journey) return;

    addHandle(componentName, handle);

    return () => {
      removeHandle(componentName);
    };
  }, [journey, componentName, handle, addHandle, removeHandle]);
}
