import { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { DocumentTextIcon } from '@heroicons/react/24/outline';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../hooks';
import Discussions from '../Comments';
import HTMLContentModal from './HTMLContentModal';
import ComplexityStatus from '../../components/complexityStatus';
import { formatLatestDate, getFirstPathFromUrl } from '../../utils/utilities';
import ShareButton from '../shareButton';

const tabsList = [
  { id: 1, title: 'Details' },
  { id: 2, title: 'Discussion' },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

interface AIChatTempDetailProps {
  gettingProjectTempDetail: Function;
  tempType?: string;
}

export default function AIChatTempDetail({ gettingProjectTempDetail, tempType }: AIChatTempDetailProps) {
  const [selectedTab, setSelectedTab] = useState(1);
  const [isOpenHTML, setIsOpenHTML] = useState(false);
  const [selectedDocument] = useState('');
  const [documentData, setDocumentData] = useState<any>(null);

  const { user } = useAuth0();
  const navigate = useNavigate();

  const { profileData } = useAppSelector<any>((state: any) => state.profile);
  const { templateByIdRes, getPublicTemplateByIdRes } = useAppSelector((state) => state.templates);
  const firstPath = getFirstPathFromUrl(window.location.pathname);

  useEffect(() => {
    gettingProjectTempDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstPath]);

  useEffect(() => {
    if (firstPath === 'template' || tempType === 'my') {
      setDocumentData(templateByIdRes);
    }

    if (firstPath === 'community-template' || tempType === 'community') {
      setDocumentData(getPublicTemplateByIdRes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateByIdRes, getPublicTemplateByIdRes]);

  const isPrivate = documentData?.project_template?.privacy === true;

  return (
    <div className='w-full'>
      <div className='grid grid-cols-custom h-[calc(100vh_-_114px)] overflow-y-auto'>
        <div className=' col-span-3 border-r border-zinc-200 pr-7 pl-3 pt-6'>
          {firstPath === 'community-template' ? (
            <nav
              className='flex space-x-4 mb-6'
              aria-label='Tabs'
            >
              {tabsList.map((tab: any) => (
                <span
                  key={tab.id}
                  onClick={() => setSelectedTab(tab.id)}
                  className={classNames(
                    tab.id === selectedTab
                      ? 'text-blue-500 border-blue-500'
                      : 'border-transparent text-customLightBlue hover:border-gray-300 hover:text-gray-700',
                    'whitespace-nowrap border-b-2 py-2 px-2 text-xs font-medium cursor-pointer'
                  )}
                >
                  {tab.title}
                </span>
              ))}
            </nav>
          ) : (
            <h3 className={classNames('mb-3 border-transparent text-lg', 'whitespace-nowrap border-b-2 text-gray-500')}>
              Details
            </h3>
          )}

          {selectedTab === 1 && (
            <>
              <div className='bg-blue-50 border border-y border-blue-300 mt-2 mb-6 p-7 rounded-xl'>
                <h3 className='text-xs font-semibold text-customDarkBlue mb-4'>Description</h3>
                <p className='text-xs'>{documentData?.project_template?.description}</p>
              </div>

              <div className='bg-white mt-2 py-4 rounded-xl'>
                <>
                  <h3 className='text-lg text-gray-500 mb-5'>Documents</h3>
                  {documentData?.document_templates?.length > 0 &&
                    documentData?.document_templates.map((item: any) => (
                      <div
                        key={item.id}
                        onClick={() =>
                          navigate(
                            `/${getFirstPathFromUrl(window.location.pathname)}/${item.project_template_id}/document/${item.id}`
                          )
                        }
                        className='group w-fit text-sm flex items-center mb-4 cursor-pointer transition-colors hover:text-primaryLight'
                      >
                        <DocumentTextIcon className='mr-2 h-5 text-zinc-500 transition-colors group-hover:text-primaryLight' />
                        <span>{item.title}</span>
                      </div>
                    ))}
                </>
              </div>
            </>
          )}
          {firstPath === 'community-template' && <>{selectedTab === 2 && <Discussions />}</>}
        </div>
        <div className='col-span-1 px-6 pt-6'>
          <div className='mb-7'>
            <div className='text-gray-400 mb-2 text-xs uppercase'>Publisher</div>

            <div className='mb-2 text-xs'>
              <div
                className='flex items-center'
                data-tooltip-id='user-name'
              >
                <div className='mr-3'>
                  <img
                    className='h-8 w-8 rounded-full bg-gray-50'
                    src={user?.picture}
                    alt={profileData?.first_name}
                  />
                </div>
                <div>
                  {`${profileData?.first_name || profileData?.last_name ? `${profileData?.first_name} ${profileData?.last_name}` : profileData?.email}`}
                </div>
              </div>

              <Tooltip
                id='user-name'
                place='top'
              >
                {`${profileData?.first_name || profileData?.last_name ? `${profileData?.first_name} ${profileData?.last_name}` : profileData?.email}`}
              </Tooltip>
            </div>
          </div>

          <div className='mb-7'>
            <div className='text-gray-400 mb-2 text-xs uppercase'>Date Published</div>
            <div className='flex items-center mb-2 text-xs'>
              {formatLatestDate(documentData?.project_template?.created_at)}
            </div>
          </div>

          <div className='mb-7'>
            <div className='text-gray-400 mb-2 text-xs uppercase'>Version</div>
            <div className='flex items-center mb-2 text-xs'>{documentData?.project_template?.version}</div>
          </div>

          <div className='mb-7'>
            <div className='text-gray-400 mb-2 text-xs uppercase'>Last Update</div>
            <div className='flex items-center mb-2 text-xs'>
              {formatLatestDate(documentData?.project_template?.updated_at)}
            </div>
          </div>

          <div className='mb-7'>
            <div className='text-gray-400 mb-2 text-xs uppercase'>Category</div>
            <div className='flex items-center mb-2 text-xs'>
              <span
                className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset justify-center bg-blue-50 text-blue-700 ring-blue-600/20`}
              >
                {documentData?.project_template?.category}
              </span>
            </div>
          </div>

          <div className='mb-7'>
            <div className='text-gray-400 mb-2 text-xs uppercase'>Complexity</div>
            <div className='flex items-center mb-2 text-xs'>
              <ComplexityStatus status={documentData?.project_template?.complexity} />
            </div>
          </div>

          {!isPrivate && (
            <div>
              <div className='text-gray-400 mb-2 text-xs uppercase'>Share</div>
              <div className='flex items-center'>
                {/* <div className='cursor-pointer mr-2 hover:scale-110'>
                <img
                  className='h-[15px]'
                  src={twitterIcon}
                  alt='Twitter Logo'
                />
              </div> */}
                <ShareButton.LinkedIn text={documentData?.project_template?.title || 'Template'} />
                {/* <div className='cursor-pointer mr-2 hover:scale-110'>
                <LinkIcon className='h-[15px]' />
              </div> */}
              </div>
            </div>
          )}
        </div>
      </div>

      <HTMLContentModal
        openModal={isOpenHTML}
        onClosingModal={() => setIsOpenHTML(false)}
        documentData={selectedDocument}
      />
    </div>
  );
}
