interface RadioGroupProps {
  onChangeRadio: Function;
  value: string;
  radioList: any[];
}

export default function RadioGroup({
  onChangeRadio,
  value,
  radioList,
}: RadioGroupProps) {
  return (
    <div className="space-y-5 sm:mt-0">
      {radioList.map(({ id, value: itemValue, title }) => (
        <div key={id} className="relative flex items-start">
          <label className="flex w-full cursor-pointer">
            <div className="absolute flex h-[20px] w-[16px] items-center">
              <input
                name="privacy"
                type="radio"
                checked={itemValue === value}
                onChange={() => onChangeRadio(itemValue)}
                className="h-4 w-4 border-gray-300 text-blue-500 focus:ring-blue-500"
              />
            </div>
            <div className="pl-7 text-xs leading-6">
              <span className="font-medium text-gray-900">
                {title}
              </span>
              <p className="text-gray-500">
                You are the only one able to access this project
              </p>
            </div>
          </label>
        </div>
      ))}
    </div>
  );
}
