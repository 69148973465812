import { type, wait, waitForElement } from 'src/utils/userTourUtils';
import { getSpecialChatAssistant } from 'src/utils/special-chat-assistants';
import { generateActions } from 'src/utils/generate-actions';
import type { BeforeTourHandler, TourHandlers } from 'src/type';
import type { Patch } from 'src/pages/flowchart/flow/utils';

const EXAMPLE_DIAGRAM = {
  title: 'Example Diagram',
  type: 'Network',
};

export const diagramTourHandlers: TourHandlers = {
  'step-diagram-create-btn': {
    async setup(context) {
      // open left sidebar
      await wait(300);
      context.getHandle('ProjectsDetails').openLeftSidebar();
      await waitForElement('.step-diagram-create-btn');
    },
    async complete(context) {
      // open new diagram modal
      context.getHandle('Diagrams').openNewDiagramModal();
      await waitForElement('.new-diagram-modal.ready');
    },
  },
  'step-diagram-save-btn': {
    setup(context) {
      // fill title
      const diagramFormHandle = context.getHandle('DiagramForm');
      type('#title', EXAMPLE_DIAGRAM.title).then(() => {
        diagramFormHandle.setTitle(EXAMPLE_DIAGRAM.title);
      });
      // fill diagram type
      diagramFormHandle.setType(EXAMPLE_DIAGRAM.type);
    },
    async complete(context) {
      const diagramId = await context.getHandle('Diagrams').createDiagram(EXAMPLE_DIAGRAM);

      if (diagramId) {
        context.setParam('diagramId', diagramId);
      } else throw new Error('Failed to create diagram');
    },
  },
  'step-diagram-search-bar': {
    async setup() {
      // wait for search bar to appear
      await waitForElement('.step-diagram-search-bar');
    },
    async complete(context) {
      // fill search input
      await type('#search', 'static');
      context.getHandle('DiagramSidebar').setSearchValue('static');
      await wait(500);
    },
  },
  'step-diagram-node': {
    async setup(context) {
      // wait for node to appear
      const element = await waitForElement('.diagram-node');
      element.classList.add('step-diagram-node');

      context.setParam('nodeId', Number(element.id));
    },
    async complete(context) {
      // insert node into diagram
      const flowAppHandle = context.getHandle('FlowApp');
      const flowContainer = document.querySelector('.react-flow');

      if (!flowContainer) {
        throw new Error('Flow container not found');
      }

      const { width, height } = flowContainer.getBoundingClientRect();
      const { x: offsetX, y: offsetY, zoom } = flowAppHandle.getFlowchartViewport();

      const nodeWidth = 120;
      const centerPosition = {
        x: (width / 2 - offsetX) / zoom - nodeWidth / 2,
        y: (height / 2 - offsetY) / zoom,
      };

      const nodeId = context.getParam<number>('nodeId');
      const nodeData = nodeId ? context.getHandle('DiagramSidebar').findNodeData(nodeId) : null;

      if (!nodeData) {
        throw new Error('Node data not found');
      }

      const node = {
        id: `node-${nodeId}`,
        type: 'node',
        position: centerPosition,
        selected: true,
        data: {
          label: nodeData.name,
          icon_url: nodeData.icon,
          border_style: '',
          color: '',
          background: '',
        },
        style: {
          zIndex: 1000,
        },
        width: nodeWidth,
      };

      const patch: Patch = {
        op: 'add',
        target: 'node',
        value: node,
      };

      flowAppHandle.applyPatches([patch]);
      await wait(500);
    },
  },
  'step-edit-ai-btn': {
    complete(context) {
      // open edit chat
      context.getHandle('DiagramActions').openEditWithAi();
    },
  },
  'chat-send-button': {
    async setup(context) {
      // wait for chat send button to appear
      const message = 'I need a server, database and a firewall.';

      await waitForElement('#message');
      await type('#message', message);
      context.getHandle('ChatGPTInput').setMessage(message);
    },
    complete(context) {
      // send message
      context.getHandle('ChatGPTInput').sendMessage();
    },
  },
  'diagram-patches-button': {
    async setup() {
      // wait for message to be fully generated
      await waitForElement('.assistant-message-ready', { timeout: 40000 });
    },
    async complete(context) {
      // update diagram via patches
      context.getHandle('PatchButton').applyPatches();
      context.getHandle('ProjectsDetails').closeRightSidebar();
      await wait(800);
    },
  },
  'step-generate-artifact-btn': {
    complete(context) {
      // open generate artifact dropdown
      const btn = document.querySelector('.step-generate-artifact-btn');

      if (btn instanceof HTMLButtonElement) {
        btn.click();
      } else throw new Error('Generate artifact button not found');
    },
  },
  'step-generate-artifact-dropdown': {
    async setup() {
      await waitForElement('.step-generate-artifact-dropdown.ready');
    },
    async complete(context) {
      // open summary document modal
      const item = generateActions.diagram.summary;
      context.getHandle('ProjectsDetails').openDocumentFromDiagramModal(item);
    },
    beforeNext(context) {
      context.getHandle('ItemWithDropdown').closeDropdown();
    },
  },
  'step-generate-modal-btn': {
    async setup(context) {
      // wait for modal to be ready
      await waitForElement('.generate-document-modal.ready');

      // fill title
      const title = 'Example Diagram Summary';

      type('#title', title).then(() => {
        context.getHandle('DocumentFromDiagramForm').setTitle(title);
      });
    },
    async complete(context) {
      // create summary document from diagram
      context.setLoading(true);

      await context.getHandle('ProjectsDetails').generateDocumentFromDiagram('Example Diagram Summary');

      context.setLoading(false);
    },
  },
  'ck-content': {
    async setup() {
      await waitForElement('.ck-content');
      await wait(400);
    },
    async beforeNext(_, navigator) {
        // navigate back to diagrams
        const projectId = navigator.getParam<string>('id');
        const diagramId = navigator.getParam<string>('diagramId');

        if (!projectId || !diagramId) {
            throw new Error('Project id or diagram id not found');
        }

        navigator.navigate(`projects/${projectId}?active=diagrams&id=${diagramId}`);
        await waitForElement('.step-validate-btn');
        await wait(100);
    }
  },
  'step-validate-btn': {
    complete() {
      // open validation dropdown
      const btn = document.querySelector('.step-validate-btn');

      if (btn instanceof HTMLButtonElement) {
        btn.click();
      } else throw new Error('Validate button not found');
    },
  },
  'step-validation-dropdown': {
    async setup() {
      await waitForElement('.step-validation-dropdown.ready');
      await wait(100);
    },
    async complete(context) {
      // open validation chat
      const conversationContext = getSpecialChatAssistant('diagram:limitations');

      if (!conversationContext) {
        throw new Error('Conversation context not found for diagram:limitations');
      }

      await context.getHandle('DiagramActions').openSpecialChat(conversationContext);
    },
    async beforeNext(context) {
      // close validation dropdown
      context.getHandle('ItemWithDropdown').closeDropdown();
    },
  },
};

export const beforeDiagramTour: BeforeTourHandler = (navigator) => {
  const projectId = navigator.getParam<string>('id');

  if (!projectId) {
    throw new Error('Project id param is not set');
  }

  navigator.navigate({ pathname: `projects/${projectId}`, search: 'active=diagrams' });
};
