import { CheckIcon, DocumentTextIcon, PlusCircleIcon, CubeIcon } from '@heroicons/react/20/solid';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { ComponentPropsWithoutRef, FC, Fragment, PropsWithChildren, ReactNode } from 'react';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from '@headlessui/react';

import type { AnchorPropsWithSelection } from '@headlessui/react/dist/internal/floating';
import { classNames } from '../../utils/utilities';
import { DROPDOWN_TRANSITION_CLASSES } from 'src/config';
import { noop } from 'lodash';

export type Option = {
  id: string;
  title: string;
  icon?: FC<ComponentPropsWithoutRef<'svg'>>;
};

type FooterOption = {
  id: string | number;
  title: string;
  icon?: FC<ComponentPropsWithoutRef<'svg'>>;
  onClick: () => void;
};

type BaseProps = {
  optionsList: Option[];
  onAddingDocument?: any;
  btnText?: string;
  title?: string;
  onSelectAssistant?: Function;
  className?: string;
  buttonClassName?: string;
  dropdownClassName?: string;
  anchor?: AnchorPropsWithSelection;
  dropdownPosition?: 'left' | 'right';
  isNested?: boolean;
  withIcon?: boolean;
  mainIcon?: FC<ComponentPropsWithoutRef<'svg'>>;
  children?: ReactNode;
  type?: string;
  footerOptions?: FooterOption[];
};

export type SingleOptionProps<O> = {
  multiple?: false;
  selected?: O | null;
  setSelected?: (option: O) => void;
  fromPage?: string;
};

export type MultipleOptionsProps<O> = {
  multiple: true;
  selected: O[];
  setSelected?: (options: O[]) => void;
  fromPage?: string;
};

export type DropdownSelectionOptionProps<O extends Option> = BaseProps &
  (SingleOptionProps<O> | MultipleOptionsProps<O>);

const MAX_OPTIONS_BEFORE_SCROLL = 6;

export default function DropdownSelectionOption<O extends Option>({
  optionsList,
  multiple = false,
  selected,
  setSelected,
  onAddingDocument,
  btnText,
  title,
  onSelectAssistant,
  anchor,
  dropdownPosition = 'right',
  isNested = false,
  className,
  buttonClassName,
  dropdownClassName,
  withIcon = true,
  mainIcon,
  children,
  fromPage,
  type,
  footerOptions = [],
}: PropsWithChildren<DropdownSelectionOptionProps<O>>) {
  const fullFooterOptions = onAddingDocument
    ? [
        {
          id: 'add-document',
          title: btnText,
          onClick: onAddingDocument,
          icon: PlusCircleIcon,
        },
        ...footerOptions,
      ]
    : footerOptions;

  return (
    <Listbox
      multiple={multiple}
      value={selected}
      onChange={setSelected || noop}
    >
      {({ open }) => (
        <>
          <div className={classNames('relative', className)}>
            <ListboxButton
              className={classNames(
                'relative bg-transparent hover:bg-transparent cursor-pointer rounded-md text-left text-customLightBlue  sm:text-xs',
                buttonClassName
              )}
            >
              {children || (
                <div className='flex items-center justify-between'>
                  {(() => {
                    const Icon = mainIcon || (Array.isArray(selected) ? null : selected?.icon);

                    if (!Icon) {
                      return null;
                    }

                    return <Icon className={`h-4 w-4 ${fromPage === 'diagram' ? 'text-zinc-800' : 'mr-2'}`} />;
                  })()}
                  <span className={`block truncate line-clamp-1`}>
                    {fromPage === 'diagram' ? (
                      <span className='flex text-gray-600 ml-2 text-[14px] font-normal'>Data</span>
                    ) : (
                      <>
                        {(() => {
                          if (Array.isArray(selected)) {
                            if (selected.length === 0) return title;

                            return selected.length === 1 ? selected[0].title : `${selected.length} doc(s) selected`;
                          }

                          return selected?.title || title;
                        })() || 'Loading...'}
                      </>
                    )}
                  </span>
                  <ChevronDownIcon className='h-4 ml-2 stroke-gray-400' />
                </div>
              )}
            </ListboxButton>

            <Transition
              show={open}
              as={Fragment}
              {...DROPDOWN_TRANSITION_CLASSES}
            >
              <ListboxOptions
                anchor={anchor}
                className={classNames(
                  'absolute w-[350px] z-10 mt-2 rounded-md bg-white text-base sm:text-xs h-auto',
                  'shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                  'divide-y divide-gray-300',
                  dropdownPosition === 'left' ? 'right-0' : 'left-0',
                  dropdownClassName
                )}
              >
                {!isNested && title && (
                  <div className='px-5 py-3'>
                    <span className='text-sm text-customDarkBlue font-semibold'>{title}</span>
                  </div>
                )}
                <div
                  className='p-1 overflow-auto'
                  style={{ maxHeight: MAX_OPTIONS_BEFORE_SCROLL * 32 + 8 }}
                >
                  {optionsList?.map((item) => {
                    const isSelected = Array.isArray(selected)
                      ? selected.some(({ id }) => id === item.id)
                      : selected?.id === item.id;

                    const Icon = item.icon || (type === 'diagram' ? CubeIcon : DocumentTextIcon);

                    return (
                      <ListboxOption
                        onClick={onSelectAssistant ? () => onSelectAssistant(item) : () => console.log('....')}
                        key={item.id}
                        className={() =>
                          classNames(
                            isSelected ? 'text-customDarkBlue' : 'text-customDarkBlue',
                            'relative cursor-pointer select-none py-2 px-4 rounded-md',
                            'transition-colors hover:bg-gray-100 hover:text-customDarkBlue'
                          )
                        }
                        value={item}
                      >
                        <>
                          <span
                            className={classNames(
                              isSelected ? 'font-semibold' : 'font-normal',
                              'pl-4 truncate flex items-center'
                            )}
                          >
                            {withIcon && (
                              <div className='w-4 mr-2'>
                                <Icon className={`h-4 w-4 ${isSelected ? 'text-customLightBlue' : 'text-zinc-400'} `} />
                              </div>
                            )}
                            <span className='line-clamp-1'>{item.title}</span>
                          </span>

                          <div
                            className={classNames(
                              'absolute top-1/2 left-2 -translate-y-1/2',
                              'flex items-center',
                              isSelected ? 'text-blue-600' : 'text-customLightBlue',
                              multiple && 'size-4 rounded border bg-white'
                            )}
                          >
                            <CheckIcon
                              className={classNames('size-5 opacity-0 transition-opacity', isSelected && 'opacity-100')}
                            />
                          </div>
                        </>
                      </ListboxOption>
                    );
                  })}
                </div>

                {fullFooterOptions.length > 0 && (
                  <div className='p-1'>
                    {fullFooterOptions.map(({ id, title, icon: Icon = PlusCircleIcon, onClick }) => (
                      <ListboxOption
                        as='div'
                        key={id}
                        value={null}
                        className={classNames(
                          selected ? 'font-semibold' : 'font-normal',
                          'truncate flex items-center bg-white text-gray-700 py-2 px-4 rounded-md',
                          'cursor-pointer transition-colors hover:bg-gray-100 hover:text-customDarkBlue'
                        )}
                        onClick={onClick}
                      >
                        <Icon className={`h-5 w-5 mr-2 text-zinc-500`} />
                        <span>{title}</span>
                      </ListboxOption>
                    ))}
                  </div>
                )}
              </ListboxOptions>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
