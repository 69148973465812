import React from "react";
import CustomButton from "src/components/customButton";
import TextareaField from "../../../components/formFields/textareaField";
import * as yup from "yup";
import { useFormik } from "formik";

interface GenerateDiagramFormAzureProps {
  onCloseModal: () => void;
  loading: boolean;
  onSubmitData: Function;
}

const GenerateDiagramFormAzure = React.memo(function GenerateDiagramFormAzure({
  onCloseModal,
  loading,
  onSubmitData,
}: GenerateDiagramFormAzureProps) {
  const validationSchema = yup.object().shape({
    tagName: yup.string().required("Tag Name is required"),
    clientSecret: yup.string().required("Client Secret is required"),
    clientId: yup.string().required("Client ID is required"),
    tenantId: yup.string().required("Tenant ID is required"),
    subscriptionId: yup.string().required("Subscription ID is required"),
  });

  const formik = useFormik({
    initialValues: {
      tagName: "service=solutionpilot",
      clientSecret: "V_i8Q~LknkIEbbUdC2iYwYKE~45m6vwYd927Ga9c",
      clientId: "f3343d82-9d6c-408d-850e-e8910b700b07",
      tenantId: "e25854cc-0bf2-4036-b119-b357006a675b",
      subscriptionId: "78307899-a5a0-4a6d-a2d6-fa45edbab380",
    },
    validationSchema,
    onSubmit: (values) => {
      onSubmitData(values);
    },
  });

  return (
    <form className="flex h-full flex-col" onSubmit={formik.handleSubmit}>
      <div className="space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 mb-3">
        <div>
          <label className="block text-xs font-medium leading-6 text-customDarkBlue">
            Tag Name
          </label>
        </div>
        <div className="sm:col-span-2">
          <TextareaField
            id="tagName"
            {...formik.getFieldProps("tagName")}
            rows={2}
            style={{
              border: `${formik.touched.tagName && formik.errors.tagName ? "solid 1px red" : "solid 1px #cacaca"}`,
            }}
            placeholder="Enter the key value pair tag, Example: service=solutionpilot"
          />
          {formik.touched.tagName && formik.errors.tagName ? (
            <div className="text-red-600 text-xs">{formik.errors.tagName}</div>
          ) : null}
        </div>
      </div>

      <div className="space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 mb-3">
        <div>
          <label className="block text-xs font-medium leading-6 text-customDarkBlue">
            Client Secret
          </label>
        </div>
        <div className="sm:col-span-2">
          <TextareaField
            id="clientSecret"
            {...formik.getFieldProps("clientSecret")}
            rows={2}
            style={{
              border: `${formik.touched.clientSecret && formik.errors.clientSecret ? "solid 1px red" : "solid 1px #cacaca"}`,
            }}
            placeholder="Enter the client secret"
          />
          {formik.touched.clientSecret && formik.errors.clientSecret ? (
            <div className="text-red-600 text-xs">
              {formik.errors.clientSecret}
            </div>
          ) : null}
        </div>
      </div>

      <div className="space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 mb-3">
        <div>
          <label className="block text-xs font-medium leading-6 text-customDarkBlue">
            Client ID
          </label>
        </div>
        <div className="sm:col-span-2">
          <TextareaField
            id="clientId"
            {...formik.getFieldProps("clientId")}
            rows={2}
            style={{
              border: `${formik.touched.clientId && formik.errors.clientId ? "solid 1px red" : "solid 1px #cacaca"}`,
            }}
            placeholder="Enter the client ID"
          />
          {formik.touched.clientId && formik.errors.clientId ? (
            <div className="text-red-600 text-xs">{formik.errors.clientId}</div>
          ) : null}
        </div>
      </div>

      <div className="space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 mb-3">
        <div>
          <label className="block text-xs font-medium leading-6 text-customDarkBlue">
            Tenant ID
          </label>
        </div>
        <div className="sm:col-span-2">
          <TextareaField
            id="tenantId"
            {...formik.getFieldProps("tenantId")}
            rows={2}
            style={{
              border: `${formik.touched.tenantId && formik.errors.tenantId ? "solid 1px red" : "solid 1px #cacaca"}`,
            }}
            placeholder="Enter the tenant ID"
          />
          {formik.touched.tenantId && formik.errors.tenantId ? (
            <div className="text-red-600 text-xs">{formik.errors.tenantId}</div>
          ) : null}
        </div>
      </div>

      <div className="space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 mb-3">
        <div>
          <label className="block text-xs font-medium leading-6 text-customDarkBlue">
            Subscription ID
          </label>
        </div>
        <div className="sm:col-span-2">
          <TextareaField
            id="subscriptionId"
            {...formik.getFieldProps("subscriptionId")}
            rows={2}
            style={{
              border: `${formik.touched.subscriptionId && formik.errors.subscriptionId ? "solid 1px red" : "solid 1px #cacaca"}`,
            }}
            placeholder="Enter the subscription ID"
          />
          {formik.touched.subscriptionId && formik.errors.subscriptionId ? (
            <div className="text-red-600 text-xs">
              {formik.errors.subscriptionId}
            </div>
          ) : null}
        </div>
      </div>

      {/* Action buttons */}
      <div className="w-full text-right mt-5">
        <CustomButton
          text="Cancel"
          type="button"
          onClickBtn={onCloseModal}
          buttonType="secondary"
          btnStyle="mr-2"
        />

        <CustomButton
          text="Save"
          type="submit"
          onClickBtn={() => {}}
          loading={loading}
          buttonType="primary"
        />
      </div>
    </form>
  );
});

export default GenerateDiagramFormAzure;
