import { useAuth0 } from '@auth0/auth0-react';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import {
  ArrowPathIcon,
  LightBulbIcon,
  CursorArrowRaysIcon,
  DocumentChartBarIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  CubeIcon
} from '@heroicons/react/24/outline';
import { ElementType, ReactNode } from 'react';
import { useUserTour } from 'src/contexts/userTour/UserTour.context';
import { useAppDispatch } from 'src/hooks/store';
import { createProject } from 'src/redux/projects/projectsApi';
import type { UserJourneyConfig } from 'src/type';
import { EXAMPLE_PROJECT, diagramJourney, projectCreationJourney, tChartCreationJourney } from 'src/utils/user-tour';
import { classNames } from 'src/utils/utilities';

type FlyoutMenuItem = {
  name: string;
  description: string;
  icon: ElementType;
  color: string;
  journey?: UserJourneyConfig | null;
  needsProject?: boolean;
};

const items: FlyoutMenuItem[] = [
  {
    name: 'Document Editor',
    description: 'Create and edit documents with AI',
    icon: LightBulbIcon,
    journey: projectCreationJourney,
    color: 'bg-indigo-500',
  },
  {
    name: 'Diagram Editor',
    description: 'Create and edit diagrams with AI',
    icon: CubeIcon,
    journey: diagramJourney,
    color: 'bg-violet-500',
    needsProject: true,
  },
  // {
  //   name: 'Integrations',
  //   description: 'Connect with third-party tools and find out expectations',
  //   icon: SquaresPlusIcon,
  //   color: 'bg-blue-500',
  //   journey: tChartCreationJourney,
  //   needsProject: true,
  // },
  // {
  //   name: 'Engagement',
  //   description: 'Speak directly to your customers with our engagement tool',
  //   icon: CursorArrowRaysIcon,
  //   journey: null,
  //   color: 'bg-violet-500',
  // },
  // {
  //   name: 'Automations',
  //   description: 'Build strategic funnels that will convert',
  //   icon: ArrowPathIcon,
  //   journey: null,
  //   color: 'bg-teal-500',
  // },
  // {
  //   name: 'Security',
  //   description: "Your customers' data will be safe and secure",
  //   icon: FingerPrintIcon,
  //   journey: null,
  //   color: 'bg-cyan-500',
  // },
  // {
  //   name: 'Reports',
  //   description: 'Edit, manage and create newly informed decisions',
  //   icon: DocumentChartBarIcon,
  //   journey: null,
  //   color: 'bg-purple-500',
  // },
];

export default function UserTourMenu({ children }: Readonly<{ children: ReactNode }>) {
  const { loadJourney } = useUserTour();
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const creatingProject = async () => {
    try {
      const accessToken = await getAccessTokenSilently();

      if (!accessToken) {
        throw new Error('No access token');
      }

      const res = await dispatch(
        createProject({
          body: EXAMPLE_PROJECT,
          accessToken,
          skipDefaultDocument: true,
        })
      );

      return res?.payload?.data?.id;
    } catch (error) {
      console.error(error);

      return null;
    }
  };

  const handleStartJourney = async (journey: UserJourneyConfig, needsProject: boolean) => {
    if (!journey) return;

    if (needsProject) {
      const projectId = await creatingProject();

      if (!projectId) {
        return;
      }

      loadJourney(journey, { id: projectId });
    } else {
      loadJourney(journey);
    }
  };

  return (
    <Popover className='relative'>
      <PopoverButton className='w-full'>{children}</PopoverButton>
      <PopoverPanel
        transition
        className='absolute bottom-full left-0 z-10 mb-5 flex w-screen max-w-max px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in'
      >
        {({ close }) => (
          <div className='w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm/6 shadow-lg ring-1 ring-gray-900/5 lg:max-w-3xl'>
            <div className='grid grid-cols-1 gap-x-6 gap-y-1 p-4 lg:grid-cols-2'>
              {items.map(({ name, description, icon: Icon, color, journey, needsProject = false }) => (
                <div
                  key={name}
                  onClick={() => {
                    close();

                    if (journey) {
                      handleStartJourney(journey, needsProject);
                    }
                  }}
                  className='group/tour relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50 cursor-pointer'
                >
                  <div className={classNames('flex size-11 flex-none items-center justify-center rounded-lg', color)}>
                    <Icon
                      className='size-6 text-white'
                      aria-hidden='true'
                    />
                  </div>
                  <div>
                    <span className='font-semibold text-gray-900'>
                      {name}
                      <span className='absolute inset-0' />
                    </span>
                    <p className='mt-1 text-gray-600'>{description}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className='bg-gray-50 px-8 py-6'>
              <div className='flex items-center gap-x-3'>
                <h3 className='text-sm/6 font-semibold text-gray-900'>Need Help with Cloud or AI?</h3>
                <p className='rounded-full bg-indigo-600/10 px-2.5 py-1.5 text-xs font-semibold text-indigo-600'>Contact Us</p>
              </div>
              <p className='mt-2 text-sm/6 text-gray-600'>We can help with any cloud design, Ai implementation, software development, or consulting.</p>
            </div>
          </div>
        )}
      </PopoverPanel>
    </Popover>
  );
}
