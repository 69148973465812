import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ChevronLeftIcon, Cog6ToothIcon as CogIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useAuth0 } from '@auth0/auth0-react';
import ChatGPT from './chatGPT';
import { getAllConversations, createConversation, deleteConversations } from '../../../../redux/chatGPT/chatGPTApi';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { classNames } from '../../../../utils/utilities';
import WrapperLoader from 'src/components/wrapperLoader';
import CompleteEmptyPage from '../../../../components/completeEmptyPage';
import ChatAIModal from '../chatAImodal';
import ConversationBox from './conversationBox';
import RoundButton from '../../../../components/roundButton';
import ConfirmationModal from '../../../../components/confirmationModal';
import DropdownSelectionOption from '../../../../components/dropdownSelectionOption';

import aiSVG from '../../../../assets/empty_state/0-designs-ai-chat-empty-state.svg';
import CustomDrawer from 'src/components/customDrawer';
import { getUserAiAssistant, updateUserAiAssistant } from 'src/redux/userAiAssistants/userAiAssistantsApi';
import CreationForm from 'src/pages/aiAssistants/CreationForm';
import type { SelectedConversation } from 'src/type';
import ItemWithDropdown from 'src/components/itemWithDropdown';
import { noop } from 'lodash';
import { useParams } from 'react-router-dom';
import { useRefValue } from 'src/hooks/useRefValue';
import CustomButton from 'src/components/customButton';

interface ChatGPTProps {
  addItemToTextEditor: Function;
  selectedConversation: SelectedConversation | null;
  setSelectedConversation: Dispatch<SetStateAction<SelectedConversation | null>>;
}

function ChatUpdateModal({
  aiAssistantId,
  open,
  onClose,
}: Readonly<{ aiAssistantId: string; open: boolean; onClose: VoidFunction }>) {
  const [fetchLoader, setFetchLoader] = useState(false);
  const [updateLoader, setUpdateLoader] = useState(false);

  const selectedAiAssistant = useAppSelector((state) => state.userAiAssistants.getUserAiAssistantRes);
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchData = async () => {
      setFetchLoader(true);

      try {
        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
          throw new Error('No access token');
        }

        await dispatch(
          getUserAiAssistant({
            accessToken,
            resource_id: aiAssistantId,
          })
        );
      } catch (error) {
        console.error('Error getting AI Assistant:', error);
      } finally {
        setFetchLoader(false);
      }
    };

    if (selectedAiAssistant) {
      setFetchLoader(false);
    } else if (open && !fetchLoader) {
      fetchData();
    }
  }, [selectedAiAssistant, fetchLoader, aiAssistantId, getAccessTokenSilently, dispatch, open]);

  const updatingAIAssistant = async (body: any) => {
    if (!selectedAiAssistant) {
      return;
    }

    setUpdateLoader(true);

    try {
      const accessToken = await getAccessTokenSilently();

      if (!accessToken) {
        throw new Error('No access token');
      }

      await dispatch(
        updateUserAiAssistant({
          body,
          accessToken,
          resource_id: aiAssistantId,
        })
      );

      onClose();
    } catch (error) {
      console.error('Error updating AI Assistant:', error);
    } finally {
      setUpdateLoader(false);
    }
  };

  return (
    <CustomDrawer.AiAssistant
      open={open}
      onCloseModal={onClose}
      headerClassName='bg-gradient-primary'
      isEditMode
    >
      <WrapperLoader loading={fetchLoader}>
        <CreationForm
          onCloseModal={onClose}
          editData={selectedAiAssistant}
          onSubmitForm={updatingAIAssistant}
          loader={updateLoader}
          editMode
        />
      </WrapperLoader>
    </CustomDrawer.AiAssistant>
  );
}

export default function ChatGPTSideBar({
  addItemToTextEditor,
  selectedConversation,
  setSelectedConversation,
}: ChatGPTProps) {
  const [allConversationsLoader, setAllConversationsLoader] = useState(false);
  const [submittingConversationType, setSubmittingConversationType] = useState<string | null>(null);
  const [aiChatsModal, setAiChatsModal] = useState(false);

  const [deleteLoader, setDeleteLoader] = useState(false);
  const [selectedData, setSelectedData] = useState<any>(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [conversationsList, setConversationsList] = useState<any[]>([]);

  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { getAllConversationsRes } = useAppSelector((state) => state.chatGPT);
  const { currentAiAssistantTab } = useAppSelector((state) => state.userAiAssistants);
  const { id: projectOrTemplateId } = useParams<{ id: string }>();

  const prevProjectId = useRefValue(getAllConversationsRes?.[0]?.project_id);

  useEffect(() => {
    const list = [...(getAllConversationsRes || [])];
    if (list?.length > 0) {
      list.reverse();
    }

    setConversationsList(list);
  }, [getAllConversationsRes]);

  useEffect(() => {
    const fetchData = async () => {
      setAllConversationsLoader(true);

      if (prevProjectId.current !== projectOrTemplateId) {
        setConversationsList([]);
      }

      try {
        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
          throw new Error('No access token');
        }

        await dispatch(
          getAllConversations({
            accessToken,
            project_id: projectOrTemplateId,
          })
        );
      } catch (error) {
        console.error('Error getting access token:', error);
      } finally {
        setAllConversationsLoader(false);
      }
    };

    fetchData();
  }, [projectOrTemplateId, prevProjectId, dispatch, getAccessTokenSilently]);

  const onOpenAIChatModal = () => {
    setAiChatsModal(true);
  };

  const onCloseAIChatModal = () => {
    setAiChatsModal(false);
  };

  const creatingConversation = (conversation_configuration_id: string, body: any = {}) => {
    const fetchData = async () => {
      setSubmittingConversationType(body?.conversation_type || 'chat');

      try {
        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
          throw new Error('No access token');
        }

        const res = await dispatch(
          createConversation({
            body,
            accessToken,
            project_id: projectOrTemplateId,
            conversation_configuration_id,
            agent_name: '',
            template_type: (currentAiAssistantTab === 1 && 'community') || (currentAiAssistantTab === 2 && 'private'),
          })
        );

        onSelectedConversation(res?.payload?.data);
        onCloseAIChatModal();
      } catch (error) {
        console.error('Error getting access token:', error);
      } finally {
        setSubmittingConversationType(null);
      }
    };
    fetchData();
  };

  const deletingConversation = () => {
    const fetchData = async () => {
      setDeleteLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            deleteConversations({
              accessToken,
              project_id: selectedData?.project_id,
              conversation_id: selectedData?.id,
            })
          );

          setDeleteLoader(false);
          closeConfirmationModal();
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        setDeleteLoader(false);
        closeConfirmationModal();
      }
    };
    fetchData();
  };

  const deleteConfirmationModal = (e: any, data: any) => {
    e.stopPropagation();

    setSelectedData(data);
    setDeleteConfirmation(true);
  };

  const closeConfirmationModal = () => {
    setDeleteConfirmation(false);
    setSelectedData(null);
  };

  const onSelectedConversation = (data: SelectedConversation | null) => {
    setSelectedConversation((curr) => {
      if (!curr) return data;

      if (!data) return null;

      // Preserve the selected document fragment when switching AI assistants
      // This allows users to ask different AI assistants about the same selected content
      if (curr.messageContext?.entityId === 'html') {
        return {
          ...curr,
          ...data,
        };
      }

      return data;
    });
  };

  const actionsList = [
    {
      id: 1,
      name: 'Quick Chat',
      onActionClick: () => creatingConversation('', { title: 'Quick Chat', conversation_type: 'quick-chat' }),
    },
    {
      id: 2,
      name: 'Custom Chat',
      onActionClick: onOpenAIChatModal,
    },
  ];

  const dropdownActionsList = [
    {
      id: 1,
      title: 'New Quick Chat',
      onClick: () => creatingConversation('', { title: 'Quick Chat', conversation_type: 'quick-chat' }),
    },
    {
      id: 2,
      title: 'New Custom Chat',
      onClick: onOpenAIChatModal,
    },
  ];

  if ((!conversationsList || conversationsList.length === 0) && !selectedConversation) {
    return (
      <>
        <div
          className='flex items-center justify-center home-empty'
          style={{ height: 'calc(100vh - 165px)' }}
        >
          <CompleteEmptyPage
            title="You don't have any AI chats"
            subTitle='Get started by creating a new chat'
            icon={aiSVG}
            imageHeight='200px'
          >
            <div className='mx-4 mt-6 flex gap-4 items-center justify-center w-full'>
              <CustomButton
                text='Quick Chat'
                type='button'
                onClickBtn={() => creatingConversation('', { title: 'Quick Chat', conversation_type: 'quick-chat' })}
                beforeIcon={
                  <PlusIcon
                    className='-ml-0.5 mr-1.5 h-5 w-5'
                    aria-hidden='true'
                  />
                }
                buttonType='primary'
                loading={submittingConversationType === 'quick-chat'}
              />
              <CustomButton
                text='Custom'
                type='button'
                onClickBtn={onOpenAIChatModal}
                beforeIcon={
                  <PlusIcon
                    className='-ml-0.5 mr-1.5 h-5 w-5'
                    aria-hidden='true'
                  />
                }
                buttonType='secondary'
                loading={submittingConversationType === 'chat'}
              />
            </div>
          </CompleteEmptyPage>
        </div>

        {aiChatsModal && (
          <ChatAIModal
            onCloseModal={onCloseAIChatModal}
            openModal={aiChatsModal}
            onUseTemplate={creatingConversation}
          />
        )}
      </>
    );
  }

  if (selectedConversation) {
    return (
      <>
        <div>
          <div className='px-3 h-toolbar border-b border-zinc-100 flex items-center justify-between'>
            <div className='flex items-center'>
              <button
                type='button'
                onClick={() => onSelectedConversation(null)}
                className={classNames(
                  `p-2 mr-2 rounded cursor-pointer bg-transparent text-customDarkBlue`,
                  'flex items-center justify-center transition-colors hover:bg-zinc-100 hover:text-customDarkBlue'
                )}
              >
                <ChevronLeftIcon className='h-4' />
              </button>

              <span>
                <DropdownSelectionOption
                  optionsList={conversationsList}
                  selected={selectedConversation}
                  onSelectAssistant={onSelectedConversation}
                  buttonClassName='px-2 py-1.5 !text-base !text-customDarkBlue transition-colors hover:bg-zinc-100'
                  dropdownClassName='-ml-8'
                  footerOptions={dropdownActionsList}
                />
              </span>
            </div>
            {!selectedConversation?.agent_name && (
              <button
                type='button'
                onClick={() => setUpdateModalOpen(true)}
                className={classNames(
                  `p-2 ml-2 rounded cursor-pointer bg-transparent text-customDarkBlue`,
                  'flex items-center justify-center transition-colors hover:bg-zinc-100'
                )}
              >
                <CogIcon className='size-4' />
              </button>
            )}
          </div>
          <ChatGPT
            addItemToTextEditor={addItemToTextEditor}
            selectedConversation={selectedConversation}
            setSelectedConversation={setSelectedConversation}
          />
        </div>

        {selectedConversation?.conversation_configuration_id && (
          <ChatUpdateModal
            aiAssistantId={selectedConversation.conversation_configuration_id}
            open={updateModalOpen}
            onClose={() => setUpdateModalOpen(false)}
          />
        )}
      </>
    );
  }

  return (
    <>
      <WrapperLoader loading={allConversationsLoader}>
        <div className='p-0 px-3'>
          <div className='mb-3'>
            <h3 className='h-toolbar text-md mb-2 border-b border-zinc-100 flex items-center'>Ai Chats</h3>
            <ItemWithDropdown
              actionsData={actionsList}
              className='relative'
            >
              <div
                className={classNames(
                  'w-full p-2 border border-zinc-200 border-dashed rounded-md text-center cursor-pointer hover:border-blue-400',
                  'flex justify-center items-center'
                )}
              >
                <RoundButton
                  asSpan
                  onClickBtn={noop}
                />
              </div>
            </ItemWithDropdown>
          </div>

          <div
            className='overflow-y-hidden hover:overflow-y-auto transition-all'
            style={{ height: 'calc(100vh - 163px)' }}
          >
            <ul className='mt-3 grid grid-cols-1'>
              {conversationsList?.length > 0
                ? conversationsList.map((data: any, index: number) => (
                    <ConversationBox
                      key={index}
                      data={data}
                      onDelete={(e: any) => deleteConfirmationModal(e, data)}
                      deleteLoader={false}
                      selectedData={selectedData}
                      onSelectConversation={() => onSelectedConversation(data)}
                      selectedConversation={selectedConversation}
                    />
                  ))
                : ''}
            </ul>
          </div>
        </div>
      </WrapperLoader>

      {aiChatsModal && (
        <ChatAIModal
          onCloseModal={onCloseAIChatModal}
          openModal={aiChatsModal}
          onUseTemplate={creatingConversation}
        />
      )}

      <ConfirmationModal
        open={deleteConfirmation}
        closingModal={() => closeConfirmationModal()}
        onDeleting={deletingConversation}
        deleteLoader={deleteLoader}
      />
    </>
  );
}
