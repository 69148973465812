import './texteditor.css';

import { useContext, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';

import AiSuggestionsPopover from 'src/components/documentPopover/aiSuggestions';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import LoaderPage from '../../../components/loaderPage';
import { getDocumentFavoriteComponents } from '../../../redux/documentComponents/documentComponentsApi';
import { getRawIconObjectByName } from '../../../utils/documentComponentsIcons';
import { useAiSuggestionsFetcher } from './useAiSuggestions';
import { useAuth0 } from '@auth0/auth0-react';
import { DetailsContext } from 'src/contexts/details/context';
import { SelectionPopover, useSelection } from 'src/components/documentPopover/selection';
import { useWatch } from 'src/hooks/useWatch';

const MyReactEditor = ({ onReady, onUnmount, onChange, openSpecialChat }) => {
  const [documentComponentsList, setDocumentComponentsList] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { collapseSidebar } = useAppSelector((state) => state.profile);
  const { getDocumentFavoriteComponentsRes } = useAppSelector((state) => state.documentComponents);

  const fetchSuggestions = useAiSuggestionsFetcher();
  const [editor, setEditor] = useState(null);
  const [suggestionsPlugin, setSuggestionsPlugin] = useState(null);

  const { selectedDocument, setEditorRef } = useContext(DetailsContext);
  const aiSuggestionsEnabled = !!selectedDocument?.ai_suggestions;

  const [selection, setSelection] = useState(null);
  const [isSelectionPending, setIsSelectionPending] = useState(false);
  const { setContainerRef, containerRef } = useSelection(({ selection, isPending }) => {
    setIsSelectionPending(isPending);

    if (!isPending || !selection) {
      setSelection(selection);
    }
  });

  useEffect(() => {
    if (!suggestionsPlugin) return;

    if (aiSuggestionsEnabled) {
      suggestionsPlugin.enable();
    } else {
      suggestionsPlugin.disable();
    }
  }, [suggestionsPlugin, aiSuggestionsEnabled]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(getDocumentFavoriteComponents({ accessToken }));
        }
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getDocumentFavoriteComponentsRes?.length > 0) {
      const newList = [];

      getDocumentFavoriteComponentsRes.forEach((data) => {
        const iconObject = getRawIconObjectByName(data?.icon);

        newList.push({
          title: data?.title,
          icon: iconObject?.icon,
          data: () => data?.content,
          description: data?.short_description,
        });
      });

      setDocumentComponentsList(newList);
      setIsLoading(false);
    }

    if (getDocumentFavoriteComponentsRes === null) {
      setIsLoading(false);
    }
  }, [getDocumentFavoriteComponentsRes]);

  useEffect(() => {
    if (!editor) return;

    const listener = () => {
      const project = document.getElementById('project-container');
      const toolkit = document.getElementsByClassName('ck-editor__top');
      if (toolkit[0] && project) {
        toolkit[0].style.width = `${project.clientWidth - 2}px`;

        if (collapseSidebar) {
          toolkit[0].style.left = `53px`;
        } else {
          toolkit[0].style.left = `257px`;
        }
      }
    };

    editor.model.on('change:data', listener);

    return () => {
      editor.model.off('change:data', listener);
    };
  }, [collapseSidebar, editor, isLoading]);

  useWatch({
    triggers: [editor],
    dependencies: { setContainerRef, setEditorRef, onReady, onUnmount },
    callback: ({ setContainerRef, setEditorRef, onReady, onUnmount }) => {
      const ckEditor = editor || window.ckEditor;
      const editableElement = ckEditor?.ui.view.editable.element;

      if (!ckEditor) return;

      setEditorRef(ckEditor);
      setContainerRef(editableElement);
      onReady(ckEditor);

      const inlineSuggestions = ckEditor.plugins.get('InlineSuggestions');

      if (inlineSuggestions) {
        setSuggestionsPlugin(inlineSuggestions);
      }

      return () => {
        setEditorRef(null);
        setContainerRef(null);
        onUnmount();
      };
    },
  });

  return (
    <div className='relative'>
      {isLoading ? (
        <LoaderPage page='design-detail' />
      ) : (
        <>
          <Editor.ReactEditor
            editor={Editor}
            onReady={(editor) => {
              setEditor(editor);
              window.ckEditor = editor;
            }}
            onChange={onChange}
            config={{
              placeholder: 'Type here...',
              template: {
                definitions: documentComponentsList,
              },
              inlineSuggestions: {
                fetcher: fetchSuggestions,
              },
              isReadOnly: false,
            }}
          />
          {aiSuggestionsEnabled && suggestionsPlugin && editor && (
            <AiSuggestionsPopover
              suggestionsPlugin={suggestionsPlugin}
              editor={editor}
            />
          )}
          {!isSelectionPending && (
            <SelectionPopover
              selection={selection}
              containerRef={containerRef}
              openSpecialChat={openSpecialChat}
            />
          )}
        </>
      )}
    </div>
  );
};

export default MyReactEditor;
