import {
  useCallback,
  useEffect,
  useRef,
  useState,
  ChangeEvent,
  FormEvent,
  KeyboardEvent,
  ComponentProps,
  MouseEvent as SyntheticMouseEvent,
} from 'react';
import { autoUpdate, flip, shift, useFloating } from '@floating-ui/react-dom';
import CheckIcon from '@heroicons/react/20/solid/CheckIcon';
import { StopCircleIcon, ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/solid';
import PaperAirplaneIcon from '@heroicons/react/20/solid/PaperAirplaneIcon';
import { GradientProgressLoader } from '../loader';
import { classNames } from 'src/utils/utilities';
import DocumentPopover from './layout';
import { Kbd } from './layout';
import type {
  ElementLifecycleData,
  InlineSuggestionsInterface,
  SerializedSuggestionsState,
} from 'ckeditor5/types/inlineSuggestions';
import type Editor from 'ckeditor5/build/ckeditor';
import { platform } from 'src/utils/platform';
import CommandsDropdown, { type SelectCallbackProps, type SimpleItem } from '../commandsDropdown';
import { useDebouncedCallback } from 'use-debounce';

type Status = 'idle' | 'pending' | 'success';

const COMMANDS_DROPDOWN_ITEMS: SimpleItem[] = [
  {
    id: 0,
    option: 'Improve writing',
  },
  {
    id: 1,
    option: 'Make shorter',
  },
  {
    id: 2,
    option: 'Make longer',
  },
  {
    id: 3,
    option: 'Simplify language',
  },
];

function PopoverHeader({
  editor,
  isNavigationEnabled,
  suggestions,
  currentSuggestionIndex,
}: Readonly<{ editor: Editor; isNavigationEnabled: boolean; suggestions: string[]; currentSuggestionIndex: number }>) {
  const handleAccept = useCallback(() => {
    editor.focus();
    editor.execute('suggestions:accept');
  }, [editor]);

  return (
    <DocumentPopover.Header title={`Suggestion ${currentSuggestionIndex + 1}/${suggestions.length}`}>
      <DocumentPopover.Button
        onClick={handleAccept}
        className='bg-white text-sm text-gray-800'
      >
        Accept
      </DocumentPopover.Button>
      <div className='group flex items-center bg-white text-gray-700'>
        <DocumentPopover.Button
          disabled={!isNavigationEnabled}
          onClick={() => editor.execute('suggestions:prev')}
          className='rounded-r-none group-hover:border-r-0 hover:!border-r-[1px]'
        >
          <ArrowLeftIcon className='h-5' />
        </DocumentPopover.Button>
        <DocumentPopover.Button
          disabled={!isNavigationEnabled}
          onClick={() => editor.execute('suggestions:next')}
          className='rounded-l-none border-l-0 hover:border-l-[1px]'
        >
          <ArrowRightIcon className='h-5' />
        </DocumentPopover.Button>
      </div>
    </DocumentPopover.Header>
  );
}

function InstructionsInput({
  suggestionsPlugin,
  status,
  setStatus,
  instruction,
  setInstruction,
}: Readonly<{
  suggestionsPlugin: InlineSuggestionsInterface;
  status: Status;
  setStatus: (newStatus: Status) => void;
  instruction: string;
  setInstruction: (newInstruction: string) => void;
}>) {
  const submittedInstructionRef = useRef('');
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleChange = ({ currentTarget: { value } }: ChangeEvent<HTMLInputElement>) => {
    setInstruction(value);

    if (status === 'pending') {
      suggestionsPlugin.cancelPendingFetch();
    }

    if (status === 'pending' || (status === 'success' && value !== submittedInstructionRef.current)) {
      setStatus('idle');
    }
  };

  const handleSubmit = async (e: FormEvent | null, text = instruction) => {
    e?.preventDefault();

    if (text.trim() === '' || status !== 'idle') return;

    submittedInstructionRef.current = text;
    setStatus('pending');

    const { ok } = await suggestionsPlugin.refetch({ instructions: [text], immediate: true });

    if (ok) {
      setStatus('success');
    } else {
      setStatus('idle');
    }
  };

  const handleStopSubmission = (e: SyntheticMouseEvent<HTMLButtonElement>) => {
    e.preventDefault(); // prevent form submission

    suggestionsPlugin.cancelPendingFetch();
    setStatus('idle');
  };

  const handleConfirmOption = (data: SelectCallbackProps | null) => {
    if (!data?.option) {
      setInstruction('');
    } else {
      setInstruction(data.option);
      handleSubmit(null, data.option)
    }

    inputRef.current?.focus();
  };

  const buttonProps = ((): ComponentProps<'button'> => {
    switch (status) {
      case 'idle':
        return {
          type: 'submit',
          disabled: instruction.trim() === '',
          className: classNames('group'),
          children: <PaperAirplaneIcon className='w-5 transition-colors text-gray-400 group-hover:text-blue-600' />,
        };
      case 'pending':
        return {
          type: 'button',
          disabled: false,
          children: <StopCircleIcon className='size-5' />,
          onClick: handleStopSubmission,
        };
      case 'success':
        return {
          type: 'button',
          disabled: true,
          children: <CheckIcon className='w-5 text-green-600' />,
        };
    }
  })();

  return (
    <CommandsDropdown
      show={instruction === '/'}
      items={COMMANDS_DROPDOWN_ITEMS}
      onSelect={handleConfirmOption}
    >
      {(setReference) => (
        <form
          ref={setReference}
          onSubmit={handleSubmit}
          className='relative rounded-md ring-inset ring-gray-300 ring-1 shadow-sm'
          autoComplete='off'
        >
          <input
            ref={inputRef}
            name='aiInstruction'
            placeholder='Suggest changes or type / for commands'
            className={classNames(
              'block w-full py-[0.375rem] pl-3 pr-[52px] border-0 rounded-md',
              'text-sm/[1.5rem] text-gray-900 ring-inset ring-gray-300 ring-1 shadow-sm',
              'disabled:text-gray-500 disabled:cursor-not-allowed'
            )}
            value={instruction}
            onChange={handleChange}
            autoComplete='off'
            role='combobox'
            aria-controls='instructions'
            aria-expanded={instruction === '/'}
            aria-autocomplete='list'
            disabled={status === 'pending'}
          />

          <button
            type={buttonProps.type}
            disabled={buttonProps.disabled}
            tabIndex={-1}
            className={classNames(
              'absolute top-0 bottom-0 right-0',
              'w-10 rounded-r-md border-[1px] border-transparent',
              'flex items-center justify-center',
              'disabled:cursor-default disabled:pointer-events-none',
              buttonProps.className
            )}
            onClick={buttonProps.onClick}
          >
            {buttonProps.children}
          </button>
        </form>
      )}
    </CommandsDropdown>
  );
}

export default function AiSuggestionsPopover({
  className = '',
  suggestionsPlugin,
  editor,
  ...props
}: ComponentProps<'div'> & { suggestionsPlugin: InlineSuggestionsInterface; editor: Editor }) {
  const referenceDOMRect = useRef<DOMRect | null>(null);

  const [isLocked, setIsLocked] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [state, setState] = useState<SerializedSuggestionsState | null>(null);

  const [status, setStatus] = useState<'idle' | 'pending' | 'success'>('idle');
  const [instruction, setInstruction] = useState('');

  const currentSuggestionIndex = state?.currentSuggestion ? state.suggestions.indexOf(state.currentSuggestion) : 0;

  const { refs, floatingStyles } = useFloating({
    placement: 'top-start',
    whileElementsMounted: autoUpdate,
    middleware: [shift({ padding: { right: 42 } }), flip()],
  });

  const showPopover = state && state.suggestions.length > 0 && state.currentSuggestion && (isHovered || isLocked);

  const lockIn = useCallback(() => setIsLocked(true), []);
  const lockOut = useCallback(() => setIsLocked(false), []);

  const handleTabPress = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      editor.focus();
    }
  };

  const handleMouseMove = useCallback(
    (event: SyntheticMouseEvent | MouseEvent): void => {
      const {
        floating: { current: floating },
        reference: { current: reference },
      } = refs;

      if (floating?.matches(':hover')) {
        return setIsHovered(true);
      }

      if (!(reference instanceof HTMLElement)) {
        return setIsHovered(false);
      }

      const { left, right, top, bottom } = reference.getBoundingClientRect();

      const extendedLeft = left - 10;
      const extendedRight = right + 10;
      const extendedTop = top - 10;
      const extendedBottom = bottom + 10;

      const { clientX, clientY } = event;

      const isInside =
        clientX >= extendedLeft && clientX <= extendedRight && clientY >= extendedTop && clientY <= extendedBottom;

      setIsHovered(isInside);
    },
    [refs]
  );

  const debouncedHandleMouseMove = useDebouncedCallback(handleMouseMove, 100);

  const handleRef = (node: HTMLDivElement | null) => {
    const {
      floating: { current: currentNode },
    } = refs;

    if (currentNode === node) return;

    refs.setFloating(node);

    if (currentNode) {
      currentNode.removeEventListener('focusin', lockIn);
      currentNode.removeEventListener('focusout', lockOut);
    }

    if (node) {
      node.addEventListener('focusin', lockIn);
      node.addEventListener('focusout', lockOut);
    }
  };

  useEffect(() => {
    const listener = (_evt: any, { type, element }: ElementLifecycleData) => {
      if (type === 'insertion' && element instanceof HTMLElement) {
        refs.setReference(element);
        referenceDOMRect.current = element.getBoundingClientRect();
        document.addEventListener('mousemove', debouncedHandleMouseMove);
      } else if (type === 'removal') {
        refs.setReference(null);
        referenceDOMRect.current = null;
        document.removeEventListener('mousemove', debouncedHandleMouseMove);

        setInstruction('');
        setStatus('idle');
      }
    };

    suggestionsPlugin.on('suggestions:domLifecycle', listener);

    return () => {
      suggestionsPlugin.off('suggestions:domLifecycle', listener);
    };
  }, [debouncedHandleMouseMove, refs, suggestionsPlugin]);

  useEffect(() => {
    const listener = (_evt: any, state: SerializedSuggestionsState) => {
      setState(state);
    };

    setState(suggestionsPlugin.getState());
    suggestionsPlugin.on('suggestions:stateChange', listener);

    return () => {
      suggestionsPlugin.off('suggestions:stateChange', listener);
    };
  }, [suggestionsPlugin]);

  useEffect(() => {
    if (!showPopover) {
      setIsHovered(false);
      setIsLocked(false);

      setInstruction((curr) => {
        if (curr === '/') {
          return '';
        }

        return curr;
      });
    }
  }, [showPopover]);

  if (!showPopover) return null;

  return (
    <DocumentPopover
      ref={handleRef}
      className={classNames('max-w-[28rem] w-full flex flex-col rounded-lg border-[1px] z-50 shadow-lg', className)}
      style={floatingStyles}
      onMouseEnter={handleMouseMove}
      onMouseLeave={handleMouseMove}
      onKeyDown={handleTabPress}
      {...props}
    >
      <PopoverHeader
        editor={editor}
        isNavigationEnabled={state.isNavigationEnabled}
        suggestions={state.suggestions}
        currentSuggestionIndex={currentSuggestionIndex}
      />
      <DocumentPopover.Body className='relative'>
        <p className='w-full text-sm text-gray-500 transition-colors duration-150 ease-in-out'>
          {state.suggestions[currentSuggestionIndex]}
        </p>
        <InstructionsInput
          suggestionsPlugin={suggestionsPlugin}
          status={status}
          setStatus={setStatus}
          instruction={instruction}
          setInstruction={setInstruction}
        />
        {status === 'pending' && <GradientProgressLoader className='absolute bottom-0 translate-y-1/2 inset-x-0' />}
      </DocumentPopover.Body>
      <DocumentPopover.Footer className='basis-full'>
        <Kbd>{platform.isMac ? 'Cmd' : 'Ctrl'}</Kbd> <Kbd>i</Kbd> to accept,
        <Kbd>Esc</Kbd> to reject
      </DocumentPopover.Footer>
    </DocumentPopover>
  );
}
