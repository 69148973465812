export const azure_nodes = [
  {
    name: "Web",
    categoryIcon: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/web/app-service-plans.svg",
    items: [
      {
        name: "api-center",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/web/api-center.svg",
        type: "Microsoft.ApiCenter/services"
      },
      {
        name: "api-connections",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/web/api-connections.svg",
        type: "Microsoft.Web/connections"
      },
      {
        name: "api-management-services",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/web/api-management-services.svg",
        type: "Microsoft.ApiManagement/service"
      },
      {
        name: "app-service-certificates",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/web/app-service-certificates.svg",
        type: "Microsoft.CertificateRegistration/certificateOrders"
      },
      {
        name: "app-service-domains",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/web/app-service-domains.svg",
        type: "Microsoft.DomainRegistration/domains"
      },
      {
        name: "app-service-environments",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/web/app-service-environments.svg",
        type: "Microsoft.Web/hostingEnvironments"
      },
      {
        name: "app-service-plans",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/web/app-service-plans.svg",
        type: "Microsoft.Web/serverfarms"
      },
      {
        name: "app-services",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/web/app-services.svg",
        type: "Microsoft.Web/sites"
      },
      {
        name: "function-apps",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/web/function-apps.svg",
        type: "Microsoft.Web/sites"
      },
      {
        name: "azure-media-service",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/web/azure-media-service.svg",
        type: "Microsoft.Media/mediaservices"
      },
      {
        name: "azure-spring-apps",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/web/azure-spring-apps.svg",
        type: "Microsoft.AppPlatform/Spring"
      },
      {
        name: "cognitive-search",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/web/cognitive-search.svg",
        type: "Microsoft.Search/searchServices"
      },
      {
        name: "cognitive-services",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/web/cognitive-services.svg",
        type: "Microsoft.CognitiveServices/accounts"
      },
      {
        name: "front-door-and-cdn-profiles",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/web/front-door-and-cdn-profiles.svg",
        type: "Microsoft.Cdn/profiles"
      },
      {
        name: "notification-hub-namespaces",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/web/notification-hub-namespaces.svg",
        type: "Microsoft.NotificationHubs/namespaces"
      },
      {
        name: "power-platform",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/web/power-platform.svg",
        type: "Microsoft.PowerPlatform/enterprisePolicies"
      },
      {
        name: "signalr",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/web/signalr.svg",
        type: "Microsoft.SignalRService/SignalR"
      },
      {
        name: "static-apps",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/web/static-apps.svg",
        type: "Microsoft.Web/staticSites"
      }
    ],
  },
  {
    name: "Containers",
    categoryIcon: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/containers/kubernetes-services.svg",
    items: [
      {
        name: "kubernetes-services",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/containers/kubernetes-services.svg",
        type: "Microsoft.ContainerService/managedClusters"
      },
      {
        name: "container-instances",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/containers/container-instances.svg",
        type: "Microsoft.ContainerInstance/containerGroups"
      },
      {
        name: "container-apps-environments",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/container-apps-environments.svg",
        type: "Microsoft.App/managedEnvironments"
      },
      {
        name: "worker-container-app",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/containers/worker-container-app.svg",
        type: "Microsoft.App/containerApps"
      },
      {
        name: "container-registries",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/containers/container-registries.svg",
        type: "Microsoft.ContainerRegistry/registries"
      },
      {
        name: "azure-red-hat-openshift",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/containers/azure-red-hat-openshift.svg",
        type: "Microsoft.RedHatOpenShift/openShiftClusters"
      },
      {
        name: "batch-accounts",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/containers/batch-accounts.svg",
        type: "Microsoft.Batch/batchAccounts"
      },
      {
        name: "service-fabric-clusters",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/containers/service-fabric-clusters.svg",
        type: "Microsoft.ServiceFabric/clusters"
      }
    ],
  },
  {
    name: "Compute",
    categoryIcon: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/compute/virtual-machine.svg",
    items: [
      {
        name: "virtual-machine",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/compute/virtual-machine.svg",
        type: "Microsoft.Compute/virtualMachines"
      },
      {
        name: "vm-scale-sets",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/compute/vm-scale-sets.svg",
        type: "Microsoft.Compute/virtualMachineScaleSets"
      },
      {
        name: "automanaged-vm",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/compute/automanaged-vm.svg",
        type: "Microsoft.Compute/virtualMachines"
      },
      {
        name: "availability-sets",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/compute/availability-sets.svg",
        type: "Microsoft.Compute/availabilitySets"
      },
      {
        name: "azure-compute-galleries",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/compute/azure-compute-galleries.svg",
        type: "Microsoft.Compute/galleries"
      },
      {
        name: "batch-accounts",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/compute/batch-accounts.svg",
          type: "Microsoft.Batch/batchAccounts"
      },
      {
        name: "disk-encryption-sets",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/compute/disk-encryption-sets.svg",
        type: "Microsoft.Compute/diskEncryptionSets"
      },
      {
        name: "disks-snapshots",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/compute/disks-snapshots.svg",
        type: "Microsoft.Compute/snapshots"
      },
      {
        name: "disks",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/compute/disks.svg",
        type: "Microsoft.Compute/disks"
      },
      {
        name: "host-groups",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/compute/host-groups.svg",
        type: "Microsoft.Compute/hostGroups"
      },
      {
        name: "host-pools",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/compute/host-pools.svg",
        type: "Microsoft.DesktopVirtualization/hostPools"
      },
      {
        name: "hosts",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/compute/hosts.svg",
        type: "Microsoft.Compute/hosts"
      },
      {
        name: "image-definitions",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/compute/image-definitions.svg",
        type: "Microsoft.Compute/images"
      },
      {
        name: "image-templates",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/compute/image-templates.svg",
        type: "Microsoft.VirtualMachineImages/imageTemplates"
      },
      {
        name: "image-versions",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/compute/image-versions.svg",
        type: "Microsoft.Compute/images"
      },
      {
        name: "images",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/compute/images.svg",
        type: "Microsoft.Compute/images"
      },
      {
        name: "maintenance-configuration",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/compute/maintenance-configuration.svg",
        type: "Microsoft.Maintenance/configurations"
      },
      {
        name: "managed-service-fabric",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/compute/managed-service-fabric.svg",
        type: "Microsoft.ServiceFabric/managedClusters"
      },
      {
        name: "mesh-applications",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/compute/mesh-applications.svg",
        type: "Microsoft.ServiceFabricMesh/applications"
      },
      {
        name: "metrics-advisor",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/compute/metrics-advisor.svg",
        type: "Microsoft.CognitiveServices/accounts"
      },
      {
        name: "restore-points-collections",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/compute/restore-points-collections.svg",
        type: "Microsoft.Compute/restorePointCollections"
      },
      {
        name: "restore-points",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/compute/restore-points.svg",
        type: "Microsoft.Compute/restorePoints"
      },
      {
        name: "service-fabric-clusters",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/compute/service-fabric-clusters.svg",
        type: "Microsoft.ServiceFabric/clusters"
      },
      {
        name: "shared-image-galleries",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/compute/shared-image-galleries.svg",
        type: "Microsoft.Compute/galleries"
      },
      {
        name: "workspaces",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/compute/workspaces.svg",
        type: "Microsoft.DesktopVirtualization/workspaces"
      }
    ],
  },
  {
    name: "Networking",
    categoryIcon: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/virtual-networks.svg",
    items: [
      {
        name: "virtual-networks",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/virtual-networks.svg",
        type: "Microsoft.Network/virtualNetworks"
      },
      {
        name: "subnet",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/subnet.svg",
        type: "Microsoft.Network/virtualNetworks/subnets"
      },
      {
        name: "firewalls",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/firewalls.svg",
        type: "Microsoft.Network/azureFirewalls"
      },
      {
        name: "azure-firewall-manager",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/azure-firewall-manager.svg",
        type: "Microsoft.Network/firewallPolicies"
      },
      {
        name: "azure-firewall-policy",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/azure-firewall-policy.svg",
        type: "Microsoft.Network/firewallPolicies"
      },
      {
        name: "front-door-and-cdn-profiles",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/front-door-and-cdn-profiles.svg",
        type: "Microsoft.Cdn/profiles"
      },
      {
        name: "cdn-profiles",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/cdn-profiles.svg",
        type: "Microsoft.Cdn/profiles"
      },
      {
        name: "application-gateways",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/application-gateways.svg",
        type: "Microsoft.Network/applicationGateways"
      },
      {
        name: "application-gateway-containers",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/application-gateway-containers.svg",
        type: "Microsoft.Network/applicationGateways"
      },
      {
        name: "load-balancers",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/load-balancers.svg",
        type: "Microsoft.Network/loadBalancers"
      },
      {
        name: "load-balancer-hub",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/load-balancer-hub.svg",
        type: "Microsoft.Network/loadBalancers"
      },
      {
        name: "dns-zones",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/dns-zones.svg",
        type: "Microsoft.Network/privateDnsZones"
      },
      {
        name: "dns-multistack",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/dns-multistack.svg",
        type: "Microsoft.Network/dnsZones"
      },
      {
        name: "dns-security-policy",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/dns-security-policy.svg",
        type: "Microsoft.Network/dnsZones"
      },
      {
        name: "traffic-manager-profiles",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/traffic-manager-profiles.svg",
        type: "Microsoft.Network/trafficManagerProfiles"
      },
      {
        name: "virtual-network-gateways",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/virtual-network-gateways.svg",
        type: "Microsoft.Network/virtualNetworkGateways"
      },
      {
        name: "virtual-router",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/virtual-router.svg",
        type: "Microsoft.Network/virtualRouters"
      },
      {
        name: "virtual-wan-hub",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/virtual-wan-hub.svg",
        type: "Microsoft.Network/virtualWans"
      },
      {
        name: "virtual-wans",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/virtual-wans.svg",
        type: "Microsoft.Network/virtualWans"
      },
      {
        name: "expressroute-circuits",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/expressroute-circuits.svg",
        type: "Microsoft.Network/expressRouteCircuits"
      },
      {
        name: "local-network-gateways",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/local-network-gateways.svg",
        type: "Microsoft.Network/localNetworkGateways"
      },
      {
        name: "bastions",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/bastions.svg",
        type: "Microsoft.Network/bastionHosts"
      },
      {
        name: "connected-cache",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/connected-cache.svg",
        type: "Microsoft.Network/connectedCaches"
      },
      {
        name: "connections",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/connections.svg",
        type: "Microsoft.Network/connections"
      },
      {
        name: "ddos-protection-plans",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/ddos-protection-plans.svg",
        type: "Microsoft.Network/ddosProtectionPlans"
      },
      {
        name: "ip-address-manager",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/ip-address-manager.svg",
        type: "Microsoft.Network/publicIPAddresses"
      },
      {
        name: "ip-groups",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/ip-groups.svg",
        type: "Microsoft.Network/ipGroups"
      },
      {
        name: "azure-communications-gateway",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/azure-communications-gateway.svg",
        type: "Microsoft.Network/azureCommunicationGateways"
      },
      {
        name: "nat",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/nat.svg",
        type: "Microsoft.Network/natGateways"
      },
      {
        name: "network-interfaces",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/network-interfaces.svg",
        type: "Microsoft.Network/networkInterfaces"
      },
      {
        name: "network-security-groups",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/network-security-groups.svg",
        type: "Microsoft.Network/networkSecurityGroups"
      },
      {
        name: "network-watcher",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/network-watcher.svg",
        type: "Microsoft.Network/networkWatchers"
      },
      {
        name: "on-premises-data-gateways",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/on-premises-data-gateways.svg",
        type: "Microsoft.Network/onPremisesDataGateways"
      },
      {
        name: "private-link-service",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/private-link-service.svg",
        type: "Microsoft.Network/privateLinkServices"
      },
      {
        name: "private-link-services",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/private-link-services.svg",
        type: "Microsoft.Network/privateLinkServices"
      },
      {
        name: "private-link",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/private-link.svg",
        type: "Microsoft.Network/privateLinkServices"
      },
      {
        name: "proximity-placement-groups",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/proximity-placement-groups.svg",
        type: "Microsoft.Compute/proximityPlacementGroups"
      },
      {
        name: "private-endpoints",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/private-endpoints.svg",
        type: "Microsoft.Network/privateEndpoints"
      },
      {
        name: "public-ip-addresses",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/public-ip-addresses.svg",
        type: "Microsoft.Network/publicIPAddresses"
      },
      {
        name: "public-ip-prefixes",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/public-ip-prefixes.svg",
        type: "Microsoft.Network/publicIPPrefixes"
      },
      {
        name: "resource-management-private-link",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/resource-management-private-link.svg",
        type: "Microsoft.Network/privateLinkServices"
      },
      {
        name: "route-filters",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/route-filters.svg",
        type: "Microsoft.Network/routeFilters"
      },
      {
        name: "route-tables",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/route-tables.svg",
        type: "Microsoft.Network/routeTables"
      },
      {
        name: "service-endpoint-policies",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/service-endpoint-policies.svg",
        type: "Microsoft.Network/serviceEndpointPolicies"
      },
      {
        name: "spot-vm",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/spot-vm.svg",
        type: "Microsoft.Compute/virtualMachines"
      },
      {
        name: "spot-vmss",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/spot-vmss.svg",
        type: "Microsoft.Compute/virtualMachineScaleSets"
      },
      {
        name: "web-application-firewall-policies(waf)",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/web-application-firewall-policies(waf).svg",
        type: "Microsoft.Network/applicationGatewayWebApplicationFirewallPolicies"
      },
      {
        name: "atm-multistack",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/networking/atm-multistack.svg",
        type: "Microsoft.Network/virtualNetworks"
      }
    ]
  },
  {
    name: "Databases",
    categoryIcon: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/databases/sql-server.svg",
    items: [
      {
        name: "azure-cosmos-db",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/databases/azure-cosmos-db.svg",
        type: "Microsoft.DocumentDB/databaseAccounts"
      },
      {
        name: "azure-data-explorer-clusters",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/databases/azure-data-explorer-clusters.svg",
        type: "Microsoft.Kusto/clusters"
      },
      {
        name: "azure-database-mariadb-server",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/databases/azure-database-mariadb-server.svg",
        type: "Microsoft.DBforMariaDB/servers"
      },
      {
        name: "azure-database-migration-services",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/databases/azure-database-migration-services.svg",
        type: "Microsoft.DataMigration/services"
      },
      {
        name: "azure-database-mysql-server",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/databases/azure-database-mysql-server.svg",
        type: "Microsoft.DBforMySQL/servers"
      },
      {
        name: "azure-database-postgresql-server-group",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/databases/azure-database-postgresql-server-group.svg",
        type: "Microsoft.DBforPostgreSQL/serverGroups"
      },
      {
        name: "azure-database-postgresql-server",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/databases/azure-database-postgresql-server.svg",
        type: "Microsoft.DBforPostgreSQL/flexibleServers"
      },
      {
        name: "azure-purview-accounts",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/databases/azure-purview-accounts.svg",
        type: "Microsoft.Purview/accounts"
      },
      {
        name: "azure-sql-edge",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/databases/azure-sql-edge.svg",
        type: "Microsoft.Sql/servers"
      },
      {
        name: "azure-sql-server-stretch-databases",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/databases/azure-sql-server-stretch-databases.svg",
        type: "Microsoft.Sql/servers/databases"
      },
      {
        name: "azure-sql-vm",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/databases/azure-sql-vm.svg",
        type: "Microsoft.SqlVirtualMachine/sqlVirtualMachines"
      },
      {
        name: "azure-sql",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/databases/azure-sql.svg",
        type: "Microsoft.Sql/servers"
      },
      {
        name: "azure-synapse-analytics",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/databases/azure-synapse-analytics.svg",
        type: "Microsoft.Synapse/workspaces"
      },
      {
        name: "cache-redis",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/databases/cache-redis.svg",
        type: "Microsoft.Cache/Redis"
      },
      {
        name: "data-factories",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/databases/data-factories.svg",
        type: "Microsoft.DataFactory/factories"
      },
      {
        name: "elastic-job-agents",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/databases/elastic-job-agents.svg",
        type: "Microsoft.Sql/servers/elasticJobAgents"
      },
      {
        name: "instance-pools",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/databases/instance-pools.svg",
        type: "Microsoft.Sql/instancePools"
      },
      {
        name: "managed-database",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/databases/managed-database.svg",
        type: "Microsoft.Sql/managedInstances/databases"
      },
      {
        name: "oracle-database",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/databases/oracle-database.svg",
        type: "Microsoft.Oracle/servers"
      },
      {
        name: "sql-data-warehouses",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/databases/sql-data-warehouses.svg",
        type: "Microsoft.Sql/servers/databases"
      },
      {
        name: "sql-database",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/databases/sql-database.svg",
        type: "Microsoft.Sql/servers/databases"
      },
      {
        name: "sql-elastic-pools",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/databases/sql-elastic-pools.svg",
        type: "Microsoft.Sql/servers/elasticPools"
      },
      {
        name: "sql-managed-instance",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/databases/sql-managed-instance.svg",
        type: "Microsoft.Sql/managedInstances"
      },
      {
        name: "sql-server-registries",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/databases/sql-server-registries.svg",
        type: "Microsoft.Sql/servers"
      },
      {
        name: "sql-server",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/databases/sql-server.svg",
        type: "Microsoft.Sql/servers"
      },
      {
        name: "ssis-lift-and-shift-ir",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/databases/ssis-lift-and-shift-ir.svg",
        type: "Microsoft.DataFactory/factories/integrationRuntimes"
      },
      {
        name: "virtual-clusters",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/databases/virtual-clusters.svg",
        type: "Microsoft.Sql/virtualClusters"
      }
    ]
  }, // here
  {
    name: "Storage",
    categoryIcon: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/storage/storage-accounts.svg",
    items: [
      {
        name: "azure-databox-gateway",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/storage/azure-databox-gateway.svg",
        type: "Microsoft.DataBox/gateways"
      },
      {
        name: "azure-fileshares",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/storage/azure-fileshares.svg",
        type: "Microsoft.Storage/storageAccounts/fileServices"
      },
      {
        name: "azure-hcp-cache",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/storage/azure-hcp-cache.svg",
        type: "Microsoft.HCP/cache"
      },
      {
        name: "azure-netapp-files",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/storage/azure-netapp-files.svg",
        type: "Microsoft.NetApp/netAppAccounts"
      },
      {
        name: "azure-stack-edge",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/storage/azure-stack-edge.svg",
        type: "Microsoft.DataBoxEdge/dataBoxEdgeDevices"
      },
      {
        name: "data-box",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/storage/data-box.svg",
        type: "Microsoft.DataBox/dataBoxes"
      },
      {
        name: "data-lake-storage-gen1",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/storage/data-lake-storage-gen1.svg",
        type: "Microsoft.DataLakeStore/accounts"
      },
      {
        name: "data-share-invitations",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/storage/data-share-invitations.svg",
        type: "Microsoft.DataShare/invitations"
      },
      {
        name: "data-shares",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/storage/data-shares.svg",
        type: "Microsoft.DataShare/accounts"
      },
      {
        name: "import-export-jobs",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/storage/import-export-jobs.svg",
        type: "Microsoft.ImportExport/jobs"
      },
      {
        name: "recovery-services-vaults",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/storage/recovery-services-vaults.svg",
        type: "Microsoft.RecoveryServices/vaults"
      },
      {
        name: "storage-accounts",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/storage/storage-accounts.svg",
        type: "Microsoft.Storage/storageAccounts"
      },
      {
        name: "storage-explorer",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/storage/storage-explorer.svg",
        type: "Microsoft.StorageExplorer/explorers"
      },
      {
        name: "storage-sync-services",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/storage/storage-sync-services.svg",
        type: "Microsoft.StorageSync/storageSyncServices"
      },
      {
        name: "storsimple-data-managers",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/storage/storsimple-data-managers.svg",
        type: "Microsoft.StorSimple/managers"
      },
      {
        name: "storsimple-device-managers",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/storage/storsimple-device-managers.svg",
        type: "Microsoft.StorSimple/managers"
      }
    ]
  },
  {
    name: "Identity",
    categoryIcon: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/identity/entra-domain-services.svg",
    items: [
      {
        name: "active-directory-connect-health",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/identity/active-directory-connect-health.svg",
        type: "Microsoft.AAD/connectHealth"
      },
      {
        name: "administrative-units",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/identity/administrative-units.svg",
        type: "Microsoft.AAD/administrativeUnits"
      },
      {
        name: "api-proxy",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/identity/api-proxy.svg",
        type: "Microsoft.AAD/apiProxies"
      },
      {
        name: "app-registrations",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/identity/app-registrations.svg",
        type: "Microsoft.AAD/appRegistrations"
      },
      {
        name: "azure-ad-b2c",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/identity/azure-ad-b2c.svg",
        type: "Microsoft.AAD/b2cDirectories"
      },
      {
        name: "azure-information-protection",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/identity/azure-information-protection.svg",
        type: "Microsoft.InformationProtection/policies"
      },
      {
        name: "enterprise-applications",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/identity/enterprise-applications.svg",
        type: "Microsoft.AAD/enterpriseApplications"
      },
      {
        name: "entra-connect",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/identity/entra-connect.svg",
        type: "Microsoft.Entra/connectors"
      },
      {
        name: "entra-domain-services",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/identity/entra-domain-services.svg",
        type: "Microsoft.Entra/domainServices"
      },
      {
        name: "entra-global-secure-access",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/identity/entra-global-secure-access.svg",
        type: "Microsoft.Entra/globalSecureAccess"
      },
      {
        name: "entra-id-protection",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/identity/entra-id-protection.svg",
        type: "Microsoft.Entra/idProtection"
      },
      {
        name: "entra-identity-custom-roles",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/identity/entra-identity-custom-roles.svg",
        type: "Microsoft.Entra/customRoles"
      },
      {
        name: "entra-identity-licenses",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/identity/entra-identity-licenses.svg",
        type: "Microsoft.Entra/identityLicenses"
      },
      {
        name: "entra-identity-roles-and-administrators",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/identity/entra-identity-roles-and-administrators.svg",
        type: "Microsoft.Entra/rolesAndAdministrators"
      },
      {
        name: "entra-internet-access",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/identity/entra-internet-access.svg",
        type: "Microsoft.Entra/internetAccess"
      },
      {
        name: "entra-managed-identities",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/identity/entra-managed-identities.svg",
        type: "Microsoft.ManagedIdentity/userAssignedIdentities"
      },
      {
        name: "entra-private-access",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/identity/entra-private-access.svg",
        type: "Microsoft.Entra/privateAccess"
      },
      {
        name: "entra-privleged-identity-management",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/identity/entra-privleged-identity-management.svg",
        type: "Microsoft.Entra/privilegedIdentityManagement"
      },
      {
        name: "entra-verified-id",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/identity/entra-verified-id.svg",
        type: "Microsoft.Entra/verifiedId"
      },
      {
        name: "external-identities",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/identity/external-identities.svg",
        type: "Microsoft.AAD/externalIdentities"
      },
      {
        name: "groups",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/identity/groups.svg",
        type: "Microsoft.AAD/groups"
      },
      {
        name: "identity-governance",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/identity/identity-governance.svg",
        type: "Microsoft.AAD/identityGovernance"
      },
      {
        name: "managed-identities",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/identity/managed-identities.svg",
        type: "Microsoft.ManagedIdentity/userAssignedIdentities"
      },
      {
        name: "multi-factor-authentication",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/identity/multi-factor-authentication.svg",
        type: "Microsoft.AAD/multiFactorAuth"
      },
      {
        name: "security",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/identity/security.svg",
        type: "Microsoft.AAD/security"
      },
      {
        name: "tenant-properties",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/identity/tenant-properties.svg",
        type: "Microsoft.AAD/tenantProperties"
      },
      {
        name: "user-settings",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/identity/user-settings.svg",
        type: "Microsoft.AAD/userSettings"
      },
      {
        name: "users",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/identity/users.svg",
        type: "Microsoft.AAD/users"
      },
      {
        name: "verifiable-credentials",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/identity/verifiable-credentials.svg",
        type: "Microsoft.VerifiableCredentials/credentials"
      },
      {
        name: "verification-as-a-service",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/identity/verification-as-a-service.svg",
        type: "Microsoft.Verification/verificationServices"
      }
    ]
  },
  {
    name: "AI Services",
    categoryIcon: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/ai_services/ai-studio.svg",
    items: [
      {
        name: "ai-studio",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/ai_services/ai-studio.svg",
        type: "Microsoft.MachineLearningServices/workspaces"
      },
      {
        name: "azure-openai",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/ai_services/azure-openai.svg",
        type: "Microsoft.CognitiveServices/accounts"
      },
      {
        name: "batch-ai",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/ai_services/batch-ai.svg",
        type: "Microsoft.BatchAI/workspaces"
      },
      {
        name: "bonsai",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/ai_services/bonsai.svg",
        type: "Microsoft.Bonsai/accounts"
      },
      {
        name: "bot-services",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/ai_services/bot-services.svg",
        type: "Microsoft.BotService/botServices"
      },
      {
        name: "cognitive-search",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/ai_services/cognitive-search.svg",
        type: "Microsoft.Search/searchServices"
      },
      {
        name: "cognitive-services-decisions",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/ai_services/cognitive-services-decisions.svg",
        type: "Microsoft.CognitiveServices/accounts"
      },
      {
        name: "cognitive-services",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/ai_services/cognitive-services.svg",
        type: "Microsoft.CognitiveServices/accounts"
      },
      {
        name: "computer-vision",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/ai_services/computer-vision.svg",
        type: "Microsoft.CognitiveServices/accounts"
      },
      {
        name: "content-moderators",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/ai_services/content-moderators.svg",
        type: "Microsoft.CognitiveServices/accounts"
      },
      {
        name: "content-safety",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/ai_services/content-safety.svg",
        type: "Microsoft.CognitiveServices/accounts"
      },
      {
        name: "custom-vision",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/ai_services/custom-vision.svg",
        type: "Microsoft.CognitiveServices/accounts"
      },
      {
        name: "face-apis",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/ai_services/face-apis.svg",
        type: "Microsoft.CognitiveServices/accounts"
      },
      {
        name: "form-recognizers",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/ai_services/form-recognizers.svg",
        type: "Microsoft.CognitiveServices/accounts"
      },
      {
        name: "genomics-accounts",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/ai_services/genomics-accounts.svg",
        type: "Microsoft.Genomics/accounts"
      },
      {
        name: "genomics",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/ai_services/genomics.svg",
        type: "Microsoft.Genomics/accounts"
      },
      {
        name: "immersive-readers",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/ai_services/immersive-readers.svg",
        type: "Microsoft.CognitiveServices/accounts"
      },
      {
        name: "language-understanding",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/ai_services/language-understanding.svg",
        type: "Microsoft.CognitiveServices/accounts"
      },
      {
        name: "language",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/ai_services/language.svg",
        type: "Microsoft.CognitiveServices/accounts"
      },
      {
        name: "metrics-advisor",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/ai_services/metrics-advisor.svg",
        type: "Microsoft.CognitiveServices/accounts"
      },
      {
        name: "personalizers",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/ai_services/personalizers.svg",
        type: "Microsoft.CognitiveServices/accounts"
      },
      {
        name: "azure-experimentation-studio",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/ai_services/azure-experimentation-studio.svg",
        type: "Microsoft.MachineLearningServices/workspaces"
      },
      {
        name: "qna-makers",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/ai_services/qna-makers.svg",
        type: "Microsoft.CognitiveServices/accounts"
      },
      {
        name: "serverless-search",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/ai_services/serverless-search.svg",
        type: "Microsoft.Search/searchServices"
      },
      {
        name: "speech-services",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/ai_services/speech-services.svg",
        type: "Microsoft.CognitiveServices/accounts"
      },
      {
        name: "translator-text",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/ai_services/translator-text.svg",
        type: "Microsoft.CognitiveServices/accounts"
      },
      {
        name: "azure-applied-ai-services",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/ai_services/azure-applied-ai-services.svg",
        type: "Microsoft.CognitiveServices/accounts"
      },
      {
        name: "azure-object-understanding",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/ai_services/azure-object-understanding.svg",
        type: "Microsoft.CognitiveServices/accounts"
      }
    ]
  },
  {
    name: "Machine Learning Services",
    categoryIcon: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/machine_learning_services/machine-learning.svg",
    items: [
      {
        name: "machine-learning-studio-(classic)-web-services",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/machine_learning_services/machine-learning-studio-(classic)-web-services.svg",
        type: "Microsoft.MachineLearning/webServices"
      },
      {
        name: "machine-learning-studio-web-service-plans",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/machine_learning_services/machine-learning-studio-web-service-plans.svg",
        type: "Microsoft.MachineLearning/webServicePlans"
      },
      {
        name: "machine-learning-studio-workspaces",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/machine_learning_services/machine-learning-studio-workspaces.svg",
        type: "Microsoft.MachineLearningServices/workspaces"
      },
      {
        name: "machine-learning",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/machine_learning_services/machine-learning.svg",
        type: "Microsoft.MachineLearningServices/workspaces"
      }
    ]
  },
  {
    name: "Analytics",
    categoryIcon: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/analytics/log-analytics-workspaces.svg",
    items: [
      {
        name: "analysis-services",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/analytics/analysis-services.svg",
        type: "Microsoft.AnalysisServices/servers"
      },
      {
        name: "azure-data-explorer-clusters",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/analytics/azure-data-explorer-clusters.svg",
        type: "Microsoft.Kusto/clusters"
      },
      {
        name: "azure-databricks",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/analytics/azure-databricks.svg",
        type: "Microsoft.Databricks/workspaces"
      },
      {
        name: "azure-synapse-analytics",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/analytics/azure-synapse-analytics.svg",
        type: "Microsoft.Synapse/workspaces"
      },
      {
        name: "azure-workbooks",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/analytics/azure-workbooks.svg",
        type: "Microsoft.Insights/workbooks"
      },
      {
        name: "data-factories",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/analytics/data-factories.svg",
        type: "Microsoft.DataFactory/factories"
      },
      {
        name: "data-lake-analytics",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/analytics/data-lake-analytics.svg",
        type: "Microsoft.DataLakeAnalytics/accounts"
      },
      {
        name: "data-lake-store-gen1",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/analytics/data-lake-store-gen1.svg",
        type: "Microsoft.DataLakeStore/accounts"
      },
      {
        name: "endpoint-analytics",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/analytics/endpoint-analytics.svg",
        type: "Microsoft.AnalysisServices/servers"
      },
      {
        name: "event-hub-clusters",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/analytics/event-hub-clusters.svg",
        type: "Microsoft.EventHub/clusters"
      },
      {
        name: "event-hubs",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/analytics/event-hubs.svg",
        type: "Microsoft.EventHub/namespaces"
      },
      {
        name: "hd-insight-clusters",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/analytics/hd-insight-clusters.svg",
        type: "Microsoft.HDInsight/clusters"
      },
      {
        name: "log-analytics-workspaces",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/analytics/log-analytics-workspaces.svg",
        type: "Microsoft.OperationalInsights/workspaces"
      },
      {
        name: "power-bi-embedded",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/analytics/power-bi-embedded.svg",
        type: "Microsoft.PowerBIDedicated/capacities"
      },
      {
        name: "power-platform",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/analytics/power-platform.svg",
        type: "Microsoft.PowerPlatform/enterprisePolicies"
      },
      {
        name: "private-link-services",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/analytics/private-link-services.svg",
        type: "Microsoft.Network/privateLinkServices"
      },
      {
        name: "stream-analytics-jobs",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/analytics/stream-analytics-jobs.svg",
        type: "Microsoft.StreamAnalytics/streamingJobs"
      }
    ]
  },
  {
    name: "Azure DevOps",
    categoryIcon: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/azure_devops/azure-devops.svg",
    items: [
      {
        name: "api-connections",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/azure_devops/api-connections.svg",
        type: "Microsoft.Web/connections"
      },
      {
        name: "api-management-services",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/azure_devops/api-management-services.svg",
        type: "Microsoft.ApiManagement/service"
      },
      {
        name: "azure-devops",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/azure_devops/azure-devops.svg",
        type: "Microsoft.DevOps/organizations"
      },
      {
        name: "change-analysis",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/azure_devops/change-analysis.svg",
        type: "Microsoft.ChangeAnalysis/changeAnalysis"
      },
      {
        name: "cloudtest",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/azure_devops/cloudtest.svg",
        type: "Microsoft.CloudTest/testPlans"
      },
      {
        name: "code-optimization",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/azure_devops/code-optimization.svg",
        type: "Microsoft.CodeOptimization/optimization"
      },
      {
        name: "devops-starter",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/azure_devops/devops-starter.svg",
        type: "Microsoft.DevOps/starter"
      },
      {
        name: "devtest-labs",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/azure_devops/devtest-labs.svg",
        type: "Microsoft.DevTestLab/labs"
      },
      {
        name: "lab-accounts",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/azure_devops/lab-accounts.svg",
        type: "Microsoft.LabServices/labAccounts"
      },
      {
        name: "lab-services",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/azure_devops/lab-services.svg",
        type: "Microsoft.LabServices/labs"
      },
      {
        name: "load-testing",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/azure_devops/load-testing.svg",
        type: "Microsoft.LoadTest/loadTests"
      }
    ]
  },
  {
    name: "General",
    categoryIcon: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/backlog.svg",
    items: [
      {
        name: "all-resources",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/all-resources.svg",
        type: "Microsoft.Resources/resources"
      },
      {
        name: "backlog",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/backlog.svg",
        type: "Microsoft.DevOps/backlog"
      },
      {
        name: "biz-talk",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/biz-talk.svg",
        type: "Microsoft.BizTalk/services"
      },
      {
        name: "blob-block",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/blob-block.svg",
        type: "Microsoft.Storage/storageAccounts"
      },
      {
        name: "blob-page",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/blob-page.svg",
        type: "Microsoft.Storage/storageAccounts"
      },
      {
        name: "branch",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/branch.svg",
        type: "Microsoft.DevOps/branches"
      },
      {
        name: "browser",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/browser.svg",
        type: "Microsoft.Web/browsers"
      },
      {
        name: "bug",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/bug.svg",
        type: "Microsoft.DevOps/bugs"
      },
      {
        name: "builds",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/builds.svg",
        type: "Microsoft.DevOps/builds"
      },
      {
        name: "cache",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/cache.svg",
        type: "Microsoft.Cache/Redis"
      },
      {
        name: "code",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/code.svg",
        type: "Microsoft.DevOps/code"
      },
      {
        name: "commit",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/commit.svg",
        type: "Microsoft.DevOps/commits"
      },
      {
        name: "controls-horizontal",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/controls-horizontal.svg",
        type: "Microsoft.DevOps/controls"
      },
      {
        name: "controls",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/controls.svg",
        type: "Microsoft.DevOps/controls"
      },
      {
        name: "cost-alerts",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/cost-alerts.svg",
        type: "Microsoft.CostManagement/alerts"
      },
      {
        name: "cost-analysis",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/cost-analysis.svg",
        type: "Microsoft.CostManagement/analysis"
      },
      {
        name: "cost-budgets",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/cost-budgets.svg",
        type: "Microsoft.CostManagement/budgets"
      },
      {
        name: "cost-management-and-billing",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/cost-management-and-billing.svg",
        type: "Microsoft.Billing/billingAccounts"
      },
      {
        name: "cost-management",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/cost-management.svg",
        type: "Microsoft.CostManagement/management"
      },
      {
        name: "counter",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/counter.svg",
        type: "Microsoft.DevOps/counters"
      },
      {
        name: "cubes",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/cubes.svg",
        type: "Microsoft.AnalysisServices/cubes"
      },
      {
        name: "dashboard",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/dashboard.svg",
        type: "Microsoft.Portal/dashboards"
      },
      {
        name: "dev-console",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/dev-console.svg",
        type: "Microsoft.DevOps/console"
      },
      {
        name: "download",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/download.svg",
        type: "Microsoft.Storage/downloads"
      },
      {
        name: "error",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/error.svg",
        type: "Microsoft.DevOps/errors"
      },
      {
        name: "extensions",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/extensions.svg",
        type: "Microsoft.DevOps/extensions"
      },
      {
        name: "feature-previews",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/feature-previews.svg",
        type: "Microsoft.DevOps/features"
      },
      {
        name: "file",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/file.svg",
        type: "Microsoft.Storage/files"
      },
      {
        name: "files",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/files.svg",
        type: "Microsoft.Storage/files"
      },
      {
        name: "folder-blank",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/folder-blank.svg",
        type: "Microsoft.Storage/folders"
      },
      {
        name: "folder-website",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/folder-website.svg",
        type: "Microsoft.Web/folders"
      },
      {
        name: "free-services",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/free-services.svg",
        type: "Microsoft.Resources/freeServices"
      },
      {
        name: "ftp",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/ftp.svg",
        type: "Microsoft.Web/ftp"
      },
      {
        name: "gear",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/gear.svg",
        type: "Microsoft.DevOps/gear"
      },
      {
        name: "globe-error",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/globe-error.svg",
        type: "Microsoft.DevOps/globeErrors"
      },
      {
        name: "globe-success",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/globe-success.svg",
        type: "Microsoft.DevOps/globeSuccess"
      },
      {
        name: "globe-warning",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/globe-warning.svg",
        type: "Microsoft.DevOps/globeWarnings"
      },
      {
        name: "guide",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/guide.svg",
        type: "Microsoft.DevOps/guides"
      },
      {
        name: "heart",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/heart.svg",
        type: "Microsoft.DevOps/hearts"
      },
      {
        name: "help-and-support",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/help-and-support.svg",
        type: "Microsoft.Support/help"
      },
      {
        name: "image",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/image.svg",
        type: "Microsoft.Compute/images"
      },
      {
        name: "information",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/information.svg",
        type: "Microsoft.DevOps/information"
      },
      {
        name: "input-output",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/input-output.svg",
        type: "Microsoft.DevOps/io"
      },
      {
        name: "journey-hub",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/journey-hub.svg",
        type: "Microsoft.DevOps/journeyHub"
      },
      {
        name: "launch-portal",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/launch-portal.svg",
        type: "Microsoft.Portal/launch"
      },
      {
        name: "learn",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/learn.svg",
        type: "Microsoft.Learn/learningPaths"
      },
      {
        name: "load-test",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/load-test.svg",
        type: "Microsoft.LoadTest/loadTests"
      },
      {
        name: "location",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/location.svg",
        type: "Microsoft.DevOps/locations"
      },
      {
        name: "log-streaming",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/log-streaming.svg",
        type: "Microsoft.Insights/logProfiles"
      },
      {
        name: "management-portal",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/management-portal.svg",
        type: "Microsoft.Portal/management"
      },
      {
        name: "marketplace-management",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/marketplace-management.svg",
        type: "Microsoft.Marketplace/management"
      },
      {
        name: "marketplace",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/marketplace.svg",
        type: "Microsoft.Marketplace/offers"
      },
      {
        name: "media-file",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/media-file.svg",
        type: "Microsoft.Media/mediaFiles"
      },
      {
        name: "media",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/media.svg",
        type: "Microsoft.Media/mediaServices"
      },
      {
        name: "mobile-engagement",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/mobile-engagement.svg",
        type: "Microsoft.MobileEngagement/applications"
      },
      {
        name: "mobile",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/mobile.svg",
        type: "Microsoft.MobileEngagement/applications"
      },
      {
        name: "module",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/module.svg",
        type: "Microsoft.DevOps/modules"
      },
      {
        name: "power-up",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/power-up.svg",
        type: "Microsoft.PowerPlatform/powerUps"
      },
      {
        name: "power",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/power.svg",
        type: "Microsoft.PowerPlatform/power"
      },
      {
        name: "powershell",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/powershell.svg",
        type: "Microsoft.Automation/automationAccounts"
      },
      {
        name: "preview-features",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/preview-features.svg",
        type: "Microsoft.DevOps/features"
      },
      {
        name: "process-explorer",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/process-explorer.svg",
        type: "Microsoft.DevOps/processes"
      },
      {
        name: "production-ready-database",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/production-ready-database.svg",
        type: "Microsoft.Sql/servers/databases"
      },
      {
        name: "quickstart-center",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/quickstart-center.svg",
        type: "Microsoft.Portal/quickstart"
      },
      {
        name: "recent",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/recent.svg",
        type: "Microsoft.DevOps/recent"
      },
      {
        name: "region-management",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/region-management.svg",
        type: "Microsoft.DevOps/regions"
      },
      {
        name: "reservations",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/reservations.svg",
        type: "Microsoft.Capacity/reservationOrders"
      },
      {
        name: "resource-explorer",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/resource-explorer.svg",
        type: "Microsoft.Resources/resources"
      },
      {
        name: "resource-groups",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/resource-groups.svg",
        type: "Microsoft.Resources/resourceGroups"
      },
      {
        name: "resource-linked",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/resource-linked.svg",
        type: "Microsoft.Resources/resources"
      },
      {
        name: "scheduler",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/scheduler.svg",
        type: "Microsoft.Scheduler/jobCollections"
      },
      {
        name: "search-grid",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/search-grid.svg",
        type: "Microsoft.Search/searchServices"
      },
      {
        name: "search",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/search.svg",
        type: "Microsoft.Search/searchServices"
      },
      {
        name: "server-farm",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/server-farm.svg",
        type: "Microsoft.Web/serverfarms"
      },
      {
        name: "service-health",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/service-health.svg",
        type: "Microsoft.ResourceHealth/availabilityStatuses"
      },
      {
        name: "ssd",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/ssd.svg",
        type: "Microsoft.Compute/disks"
      },
      {
        name: "storage-azure-files",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/storage-azure-files.svg",
        type: "Microsoft.Storage/storageAccounts"
      },
      {
        name: "storage-container",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/storage-container.svg",
        type: "Microsoft.Storage/storageAccounts"
      },
      {
        name: "storage-queue",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/storage-queue.svg",
        type: "Microsoft.Storage/storageAccounts"
      },
      {
        name: "subscriptions",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/subscriptions.svg",
        type: "Microsoft.Resources/subscriptions"
      },
      {
        name: "table",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/table.svg",
        type: "Microsoft.Storage/storageAccounts"
      },
      {
        name: "tag",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/tag.svg",
        type: "Microsoft.Resources/tags"
      },
      {
        name: "tags",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/tags.svg",
        type: "Microsoft.Resources/tags"
      },
      {
        name: "templates",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/templates.svg",
        type: "Microsoft.Resources/deployments"
      },
      {
        name: "tfs-vc-repository",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/tfs-vc-repository.svg",
        type: "Microsoft.DevOps/repositories"
      },
      {
        name: "toolbox",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/toolbox.svg",
        type: "Microsoft.DevOps/toolboxes"
      },
      {
        name: "troubleshoot",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/troubleshoot.svg",
        type: "Microsoft.Support/troubleshoot"
      },
      {
        name: "versions",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/versions.svg",
        type: "Microsoft.DevOps/versions"
      },
      {
        name: "web-slots",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/web-slots.svg",
        type: "Microsoft.Web/sites/slots"
      },
      {
        name: "web-test",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/web-test.svg",
        type: "Microsoft.Insights/webTests"
      },
      {
        name: "website-power",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/website-power.svg",
        type: "Microsoft.Web/sites"
      },
      {
        name: "website-staging",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/website-staging.svg",
        type: "Microsoft.Web/sites/slots"
      },
      {
        name: "workbooks",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/workbooks.svg",
        type: "Microsoft.Insights/workbooks"
      },
      {
        name: "workflow",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/workflow.svg",
        type: "Microsoft.Logic/workflows"
      }
    ]
  },
  {
    name: "Intune",
    categoryIcon: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/intune/intune.svg",
    items: [
      {
        name: "client-apps",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/intune/client-apps.svg",
        type: "Microsoft.Intune/clientApps"
      },
      {
        name: "device-compliance",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/intune/device-compliance.svg",
        type: "Microsoft.Intune/deviceCompliance"
      },
      {
        name: "device-configuration",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/intune/device-configuration.svg",
        type: "Microsoft.Intune/deviceConfiguration"
      },
      {
        name: "device-enrollment",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/intune/device-enrollment.svg",
        type: "Microsoft.Intune/deviceEnrollment"
      },
      {
        name: "device-security-apple",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/intune/device-security-apple.svg",
        type: "Microsoft.Intune/deviceSecurity"
      },
      {
        name: "device-security-google",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/intune/device-security-google.svg",
        type: "Microsoft.Intune/deviceSecurity"
      },
      {
        name: "device-security-windows",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/intune/device-security-windows.svg",
        type: "Microsoft.Intune/deviceSecurity"
      },
      {
        name: "devices",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/intune/devices.svg",
        type: "Microsoft.Intune/devices"
      },
      {
        name: "ebooks",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/intune/ebooks.svg",
        type: "Microsoft.Intune/ebooks"
      },
      {
        name: "entra-identity-roles-and-administrators",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/intune/entra-identity-roles-and-administrators.svg",
        type: "Microsoft.Entra/roles"
      },
      {
        name: "exchange-access",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/intune/exchange-access.svg",
        type: "Microsoft.Intune/exchangeAccess"
      },
      {
        name: "intune-app-protection",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/intune/intune-app-protection.svg",
        type: "Microsoft.Intune/appProtection"
      },
      {
        name: "intune-for-education",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/intune/intune-for-education.svg",
        type: "Microsoft.Intune/education"
      },
      {
        name: "intune",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/intune/intune.svg",
        type: "Microsoft.Intune/intune"
      },
      {
        name: "mindaro",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/intune/mindaro.svg",
        type: "Microsoft.Mindaro/services"
      },
      {
        name: "security-baselines",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/intune/security-baselines.svg",
        type: "Microsoft.Intune/securityBaselines"
      },
      {
        name: "software-updates",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/intune/software-updates.svg",
        type: "Microsoft.Intune/softwareUpdates"
      },
      {
        name: "tenant-status",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/intune/tenant-status.svg",
        type: "Microsoft.Intune/tenantStatus"
      }
    ]
  },
  {
    name: "Management and Governance",
    categoryIcon: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/management_and_governance/management-groups.svg",
    items: [
      {
        name: "management-groups",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/management_and_governance/management-groups.svg",
        type: "Microsoft.Management/managementGroups"
      },
      {
        name: "activity-log",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/management_and_governance/activity-log.svg",
        type: "Microsoft.Insights/eventTypes"
      },
      {
        name: "advisor",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/management_and_governance/advisor.svg",
        type: "Microsoft.Advisor/recommendations"
      },
      {
        name: "alerts",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/management_and_governance/alerts.svg",
        type: "Microsoft.Insights/alerts"
      },
      {
        name: "arc-machines",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/management_and_governance/arc-machines.svg",
        type: "Microsoft.HybridCompute/machines"
      },
      {
        name: "automation-accounts",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/management_and_governance/automation-accounts.svg",
        type: "Microsoft.Automation/automationAccounts"
      },
      {
        name: "azure-arc",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/management_and_governance/azure-arc.svg",
        type: "Microsoft.HybridCompute/machines"
      },
      {
        name: "azure-lighthouse",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/management_and_governance/azure-lighthouse.svg",
        type: "Microsoft.ManagedServices/registrationDefinitions"
      },
      {
        name: "blueprints",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/management_and_governance/blueprints.svg",
        type: "Microsoft.Blueprint/blueprints"
      },
      {
        name: "compliance",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/management_and_governance/compliance.svg",
        type: "Microsoft.PolicyInsights/complianceResults"
      },
      {
        name: "cost-management-and-billing",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/management_and_governance/cost-management-and-billing.svg",
        type: "Microsoft.Billing/billingAccounts"
      },
      {
        name: "customer-lockbox-for-microsoft-azure",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/management_and_governance/customer-lockbox-for-microsoft-azure.svg",
        type: "Microsoft.CustomerLockbox/requests"
      },
      {
        name: "diagnostics-settings",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/management_and_governance/diagnostics-settings.svg",
        type: "Microsoft.Insights/diagnosticSettings"
      },
      {
        name: "education",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/management_and_governance/education.svg",
        type: "Microsoft.Education/schools"
      },
      {
        name: "intune-trends",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/management_and_governance/intune-trends.svg",
        type: "Microsoft.Intune/trends"
      },
      {
        name: "log-analytics-workspaces",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/management_and_governance/log-analytics-workspaces.svg",
        type: "Microsoft.OperationalInsights/workspaces"
      },
      {
        name: "machinesazurearc",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/management_and_governance/machinesazurearc.svg",
        type: "Microsoft.HybridCompute/machines"
      },
      {
        name: "managed-applications-center",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/management_and_governance/managed-applications-center.svg",
        type: "Microsoft.Solutions/applications"
      },
      {
        name: "managed-desktop",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/management_and_governance/managed-desktop.svg",
        type: "Microsoft.ManagedDesktop/managedDesktops"
      },
      {
        name: "metrics",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/management_and_governance/metrics.svg",
        type: "Microsoft.Insights/metrics"
      },
      {
        name: "monitor",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/management_and_governance/monitor.svg",
        type: "Microsoft.Insights/monitors"
      },
      {
        name: "my-customers",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/management_and_governance/my-customers.svg",
        type: "Microsoft.CustomerInsights/hubs"
      },
      {
        name: "policy",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/management_and_governance/policy.svg",
        type: "Microsoft.Authorization/policyDefinitions"
      },
      {
        name: "recovery-services-vaults",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/management_and_governance/recovery-services-vaults.svg",
        type: "Microsoft.RecoveryServices/vaults"
      },
      {
        name: "resource-graph-explorer",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/management_and_governance/resource-graph-explorer.svg",
        type: "Microsoft.ResourceGraph/resources"
      },
      {
        name: "resources-provider",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/management_and_governance/resources-provider.svg",
        type: "Microsoft.Resources/resources"
      },
      {
        name: "scheduler-job-collections",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/management_and_governance/scheduler-job-collections.svg",
        type: "Microsoft.Scheduler/jobCollections"
      },
      {
        name: "service-catalog-mad",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/management_and_governance/service-catalog-mad.svg",
        type: "Microsoft.ServiceCatalog/serviceCatalogs"
      },
      {
        name: "service-providers",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/management_and_governance/service-providers.svg",
        type: "Microsoft.ServiceProvider/serviceProviders"
      },
      {
        name: "solutions",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/management_and_governance/solutions.svg",
        type: "Microsoft.Solutions/applications"
      },
      {
        name: "universal-print",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/management_and_governance/universal-print.svg",
        type: "Microsoft.UniversalPrint/printers"
      },
      {
        name: "user-privacy",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/management_and_governance/user-privacy.svg",
        type: "Microsoft.Privacy/privacySettings"
      }
    ]
  },
  {
    name: "Migration",
    categoryIcon: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/migration/azure-migrate.svg",
    items: [
      {
        name: "azure-database-migration-services",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/migration/azure-database-migration-services.svg",
        type: "Microsoft.DataMigration/services"
      },
      {
        name: "azure-databox-gateway",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/migration/azure-databox-gateway.svg",
        type: "Microsoft.DataBoxEdge/dataBoxEdgeDevices"
      },
      {
        name: "azure-migrate",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/migration/azure-migrate.svg",
        type: "Microsoft.Migrate/migrateProjects"
      },
      {
        name: "azure-stack-edge",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/migration/azure-stack-edge.svg",
        type: "Microsoft.DataBoxEdge/dataBoxEdgeDevices"
      },
      {
        name: "cost-management-and-billing",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/migration/cost-management-and-billing.svg",
        type: "Microsoft.Billing/billingAccounts"
      },
      {
        name: "data-box",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/migration/data-box.svg",
        type: "Microsoft.DataBox/jobs"
      },
      {
        name: "recovery-services-vaults",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/migration/recovery-services-vaults.svg",
        type: "Microsoft.RecoveryServices/vaults"
      }
    ]
  },
  {
    name: "Mobile",
    categoryIcon: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/mobile/app-services.svg",
    items: [
      {
        name: "app-services",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/mobile/app-services.svg",
        type: "Microsoft.Web/sites"
      },
      {
        name: "notification-hubs",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/mobile/notification-hubs.svg",
        type: "Microsoft.NotificationHubs/namespaces"
      },
      {
        name: "power-platform",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/mobile/power-platform.svg",
        type: "Microsoft.PowerPlatform/enterprisePolicies"
      }
    ]
  },
  {
    name: "Monitor",
    categoryIcon: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/monitor/log-analytics-workspaces.svg",
    items: [
      {
        name: "activity-log",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/monitor/activity-log.svg",
        type: "Microsoft.Insights/eventTypes"
      },
      {
        name: "application-insights",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/monitor/application-insights.svg",
        type: "Microsoft.Insights/components"
      },
      {
        name: "auto-scale",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/monitor/auto-scale.svg",
        type: "Microsoft.Insights/autoscaleSettings"
      },
      {
        name: "azure-monitors-for-sap-solutions",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/monitor/azure-monitors-for-sap-solutions.svg",
        type: "Microsoft.AzureMonitorForSAPSolutions/monitors"
      },
      {
        name: "azure-workbooks",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/monitor/azure-workbooks.svg",
        type: "Microsoft.Insights/workbooks"
      },
      {
        name: "change-analysis",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/monitor/change-analysis.svg",
        type: "Microsoft.ChangeAnalysis/changeAnalysis"
      },
      {
        name: "diagnostics-settings",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/monitor/diagnostics-settings.svg",
        type: "Microsoft.Insights/diagnosticSettings"
      },
      {
        name: "log-analytics-workspaces",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/monitor/log-analytics-workspaces.svg",
        type: "Microsoft.OperationalInsights/workspaces"
      },
      {
        name: "metrics",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/monitor/metrics.svg",
        type: "Microsoft.Insights/metrics"
      },
      {
        name: "monitor",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/monitor/monitor.svg",
        type: "Microsoft.Insights/monitors"
      },
      {
        name: "network-watcher",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/monitor/network-watcher.svg",
        type: "Microsoft.Network/networkWatchers"
      }
    ]
  },
  {
    name: "Other",
    categoryIcon: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/backlog.svg",
    items: [
      {
        name: "aks-automatic",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/aks-automatic.svg",
        type: "Microsoft.ContainerService/managedClusters"
      },
      {
        name: "aks-istio",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/aks-istio.svg",
        type: "Microsoft.ContainerService/managedClusters"
      },
      {
        name: "app-compliance-automation",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/app-compliance-automation.svg",
        type: "Microsoft.AppComplianceAutomation/automation"
      },
      {
        name: "app-registrations",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/app-registrations.svg",
        type: "Microsoft.AppRegistrations/registrations"
      },
      {
        name: "app-space-component",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/app-space-component.svg",
        type: "Microsoft.AppSpace/components"
      },
      {
        name: "aquila",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/aquila.svg",
        type: "Microsoft.Aquila/services"
      },
      {
        name: "arc-data-services",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/arc-data-services.svg",
        type: "Microsoft.AzureArcData/dataControllers"
      },
      {
        name: "arc-kubernetes",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/arc-kubernetes.svg",
        type: "Microsoft.Kubernetes/connectedClusters"
      },
      {
        name: "arc-sql-managed-instance",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/arc-sql-managed-instance.svg",
        type: "Microsoft.AzureArcData/sqlManagedInstances"
      },
      {
        name: "arc-sql-server",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/arc-sql-server.svg",
        type: "Microsoft.AzureArcData/sqlServers"
      },
      {
        name: "avs-vm",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/avs-vm.svg",
        type: "Microsoft.AVS/privateClouds"
      },
      {
        name: "azure-a",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/azure-a.svg",
        type: "Microsoft.AzureA/services"
      },
      {
        name: "azure-backup-center",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/azure-backup-center.svg",
        type: "Microsoft.RecoveryServices/backupVaults"
      },
      {
        name: "azure-center-for-sap",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/azure-center-for-sap.svg",
        type: "Microsoft.AzureCenterForSAP/solutions"
      },
      {
        name: "azure-chaos-studio",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/azure-chaos-studio.svg",
        type: "Microsoft.Chaos/experiments"
      },
      {
        name: "azure-cloud-shell",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/azure-cloud-shell.svg",
        type: "Microsoft.CloudShell/cloudShells"
      },
      {
        name: "azure-communication-services",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/azure-communication-services.svg",
        type: "Microsoft.Communication/communicationServices"
      },
      {
        name: "azure-compute-galleries",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/azure-compute-galleries.svg",
        type: "Microsoft.Compute/galleries"
      },
      {
        name: "azure-deployment-environments",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/azure-deployment-environments.svg",
        type: "Microsoft.DeploymentEnvironments/environments"
      },
      {
        name: "azure-dev-tunnels",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/azure-dev-tunnels.svg",
        type: "Microsoft.DevTunnels/tunnels"
      },
      {
        name: "azure-edge-hardware-center",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/azure-edge-hardware-center.svg",
        type: "Microsoft.EdgeHardwareCenter/hardware"
      },
      {
        name: "azure-hpc-workbenches",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/azure-hpc-workbenches.svg",
        type: "Microsoft.HPC/workbenches"
      },
      {
        name: "azure-load-testing",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/azure-load-testing.svg",
        type: "Microsoft.LoadTest/loadTests"
      },
      {
        name: "azure-managed-grafana",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/azure-managed-grafana.svg",
        type: "Microsoft.Dashboard/grafana"
      },
      {
        name: "azure-monitor-dashboard",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/azure-monitor-dashboard.svg",
        type: "Microsoft.Insights/dashboards"
      },
      {
        name: "azure-monitor-pipeline",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/azure-monitor-pipeline.svg",
        type: "Microsoft.Insights/pipelines"
      },
      {
        name: "azure-network-function-manager-functions",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/azure-network-function-manager-functions.svg",
        type: "Microsoft.NetworkFunctionManager/functions"
      },
      {
        name: "azure-network-function-manager",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/azure-network-function-manager.svg",
        type: "Microsoft.NetworkFunctionManager/managers"
      },
      {
        name: "azure-orbital",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/azure-orbital.svg",
        type: "Microsoft.Orbital/groundStations"
      },
      {
        name: "azure-quotas",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/azure-quotas.svg",
        type: "Microsoft.Quota/quotas"
      },
      {
        name: "azure-sphere",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/azure-sphere.svg",
        type: "Microsoft.Sphere/catalogs"
      },
      {
        name: "azure-storage-mover",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/azure-storage-mover.svg",
        type: "Microsoft.StorageMover/storageMovers"
      },
      {
        name: "azure-support-center-blue",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/azure-support-center-blue.svg",
        type: "Microsoft.Support/supportTickets"
      },
      {
        name: "azure-sustainability",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/azure-sustainability.svg",
        type: "Microsoft.Sustainability/sustainability"
      },
      {
        name: "azure-video-indexer",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/azure-video-indexer.svg",
        type: "Microsoft.VideoIndexer/accounts"
      },
      {
        name: "azure-virtual-desktop",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/azure-virtual-desktop.svg",
        type: "Microsoft.DesktopVirtualization/workspaces"
      },
      {
        name: "azure-vmware-solution",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/azure-vmware-solution.svg",
        type: "Microsoft.AVS/privateClouds"
      },
      {
        name: "azureattestation",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/azureattestation.svg",
        type: "Microsoft.AzureAttestation/attestationProviders"
      },
      {
        name: "azurite",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/azurite.svg",
        type: "Microsoft.Azurite/services"
      },
      {
        name: "backup-vault",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/backup-vault.svg",
        type: "Microsoft.RecoveryServices/vaults"
      },
      {
        name: "bare-metal-infrastructure",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/bare-metal-infrastructure.svg",
        type: "Microsoft.BareMetal/infrastructure"
      },
      {
        name: "business-process-tracking",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/business-process-tracking.svg",
        type: "Microsoft.BusinessProcessTracking/tracking"
      },
      {
        name: "capacity-reservation-groups",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/capacity-reservation-groups.svg",
        type: "Microsoft.Capacity/reservationGroups"
      },
      {
        name: "central-service-instance-for-sap",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/central-service-instance-for-sap.svg",
        type: "Microsoft.SAP/centralInstances"
      },
      {
        name: "ceres",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/ceres.svg",
        type: "Microsoft.Ceres/services"
      },
      {
        name: "cloud-services-(extended-support)",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/cloud-services-(extended-support).svg",
        type: "Microsoft.Compute/cloudServices"
      },
      {
        name: "community-images",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/community-images.svg",
        type: "Microsoft.Compute/communityGalleries"
      },
      {
        name: "compliance-center",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/compliance-center.svg",
        type: "Microsoft.ComplianceCenter/compliance"
      },
      {
        name: "compute-fleet",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/compute-fleet.svg",
        type: "Microsoft.Compute/fleets"
      },
      {
        name: "confidential-ledgers",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/confidential-ledgers.svg",
        type: "Microsoft.ConfidentialLedger/ledgers"
      },
      {
        name: "connected-vehicle-platform",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/connected-vehicle-platform.svg",
        type: "Microsoft.ConnectedVehicle/platforms"
      },
      {
        name: "cost-export",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/cost-export.svg",
        type: "Microsoft.CostManagement/exports"
      },
      {
        name: "custom-ip-prefix",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/custom-ip-prefix.svg",
        type: "Microsoft.Network/customIPPrefixes"
      },
      {
        name: "dashboard-hub",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/dashboard-hub.svg",
        type: "Microsoft.Portal/dashboards"
      },
      {
        name: "data-collection-rules",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/data-collection-rules.svg",
        type: "Microsoft.Insights/dataCollectionRules"
      },
      {
        name: "database-instance-for-sap",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/database-instance-for-sap.svg",
        type: "Microsoft.SAP/databaseInstances"
      },
      {
        name: "dedicated-hsm",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/dedicated-hsm.svg",
        type: "Microsoft.HardwareSecurityModules/dedicatedHSMs"
      },
      {
        name: "defender-cm-local-manager",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/defender-cm-local-manager.svg",
        type: "Microsoft.Defender/cmLocalManagers"
      },
      {
        name: "defender-dcs-controller",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/defender-dcs-controller.svg",
        type: "Microsoft.Defender/dcsControllers"
      },
      {
        name: "defender-distributer-control-system",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/defender-distributer-control-system.svg",
        type: "Microsoft.Defender/distributerControlSystems"
      },
      {
        name: "defender-engineering-station",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/defender-engineering-station.svg",
        type: "Microsoft.Defender/engineeringStations"
      },
      {
        name: "defender-external-management",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/defender-external-management.svg",
        type: "Microsoft.Defender/externalManagement"
      },
      {
        name: "defender-freezer-monitor",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/defender-freezer-monitor.svg",
        type: "Microsoft.Defender/freezerMonitors"
      },
      {
        name: "defender-historian",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/defender-historian.svg",
        type: "Microsoft.Defender/historians"
      },
      {
        name: "defender-hmi",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/defender-hmi.svg",
        type: "Microsoft.Defender/hmis"
      },
      {
        name: "defender-industrial-packaging-system",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/defender-industrial-packaging-system.svg",
        type: "Microsoft.Defender/industrialPackagingSystems"
      },
      {
        name: "defender-industrial-printer",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/defender-industrial-printer.svg",
        type: "Microsoft.Defender/industrialPrinters"
      },
      {
        name: "defender-industrial-robot",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/defender-industrial-robot.svg",
        type: "Microsoft.Defender/industrialRobots"
      },
      {
        name: "defender-industrial-scale-system",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/defender-industrial-scale-system.svg",
        type: "Microsoft.Defender/industrialScaleSystems"
      },
      {
        name: "defender-marquee",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/defender-marquee.svg",
        type: "Microsoft.Defender/marquees"
      },
      {
        name: "defender-meter",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/defender-meter.svg",
        type: "Microsoft.Defender/meters"
      },
      {
        name: "defender-plc",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/defender-plc.svg",
        type: "Microsoft.Defender/plcs"
      },
      {
        name: "defender-pneumatic-device",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/defender-pneumatic-device.svg",
        type: "Microsoft.Defender/pneumaticDevices"
      },
      {
        name: "defender-programable-board",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/defender-programable-board.svg",
        type: "Microsoft.Defender/programableBoards"
      },
      {
        name: "defender-relay",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/defender-relay.svg",
        type: "Microsoft.Defender/relays"
      },
      {
        name: "defender-robot-controller",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/defender-robot-controller.svg",
        type: "Microsoft.Defender/robotControllers"
      },
      {
        name: "defender-rtu",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/defender-rtu.svg",
        type: "Microsoft.Defender/rtus"
      },
      {
        name: "defender-sensor",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/defender-sensor.svg",
        type: "Microsoft.Defender/sensors"
      },
      {
        name: "defender-slot",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/defender-slot.svg",
        type: "Microsoft.Defender/slots"
      },
      {
        name: "defender-web-guiding-system",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/other/defender-web-guiding-system.svg",
        type: "Microsoft.Defender/webGuidingSystems"
      }
    ]
  },
  {
    name: "Security",
    categoryIcon: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/security/azure-sentinel.svg",
    items: [
      {
        name: "application-security-groups",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/security/application-security-groups.svg",
        type: "Microsoft.Network/applicationSecurityGroups"
      },
      {
        name: "azure-information-protection",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/security/azure-information-protection.svg",
        type: "Microsoft.InformationProtection/policies"
      },
      {
        name: "azure-sentinel",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/security/azure-sentinel.svg",
        type: "Microsoft.OperationalInsights/workspaces"
      },
      {
        name: "conditional-access",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/security/conditional-access.svg",
        type: "Microsoft.AAD/conditionalAccessPolicies"
      },
      {
        name: "detonation",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/security/detonation.svg",
        type: "Microsoft.Detonation/detonationServices"
      },
      {
        name: "entra-identity-risky-signins",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/security/entra-identity-risky-signins.svg",
        type: "Microsoft.Entra/identityRiskySignins"
      },
      {
        name: "entra-identity-risky-users",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/security/entra-identity-risky-users.svg",
        type: "Microsoft.Entra/identityRiskyUsers"
      },
      {
        name: "extendedsecurityupdates",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/security/extendedsecurityupdates.svg",
        type: "Microsoft.ESU/updates"
      },
      {
        name: "identity-secure-score",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/security/identity-secure-score.svg",
        type: "Microsoft.Identity/secureScores"
      },
      {
        name: "key-vaults",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/security/key-vaults.svg",
        type: "Microsoft.KeyVault/vaults"
      },
      {
        name: "microsoft-defender-easm",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/security/microsoft-defender-easm.svg",
        type: "Microsoft.Defender/easm"
      },
      {
        name: "microsoft-defender-for-cloud",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/security/microsoft-defender-for-cloud.svg",
        type: "Microsoft.Security/defenderForCloud"
      },
      {
        name: "microsoft-defender-for-iot",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/security/microsoft-defender-for-iot.svg",
        type: "Microsoft.IoTDefender/defenderForIoT"
      },
      {
        name: "multifactor-authentication",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/security/multifactor-authentication.svg",
        type: "Microsoft.AAD/multiFactorAuth"
      },
      {
        name: "user-settings",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/security/user-settings.svg",
        type: "Microsoft.AAD/userSettings"
      }
    ]
  },
  {
    name: "Azure Ecosystem",
    categoryIcon: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/azure_ecosystem/applens.svg",
    items: [
      {
        name: "applens",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/azure_ecosystem/applens.svg",
        type: "Microsoft.AppLens/lenses"
      },
      {
        name: "azure-hybrid-center",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/azure_ecosystem/azure-hybrid-center.svg",
        type: "Microsoft.HybridCenter/centers"
      },
      {
        name: "collaborative-service",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/azure_ecosystem/collaborative-service.svg",
        type: "Microsoft.Collaborative/services"
      }
    ]
  },
  {
    name: "IoT",
    categoryIcon: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/iot/iot-hub.svg",
    items: [
      {
        name: "azure-cosmos-db",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/iot/azure-cosmos-db.svg",
        type: "Microsoft.DocumentDB/databaseAccounts"
      },
      {
        name: "azure-databox-gateway",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/iot/azure-databox-gateway.svg",
        type: "Microsoft.DataBox/gateways"
      },
      {
        name: "azure-iot-operations",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/iot/azure-iot-operations.svg",
        type: "Microsoft.IoTOperations/operations"
      },
      {
        name: "azure-maps-accounts",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/iot/azure-maps-accounts.svg",
        type: "Microsoft.Maps/accounts"
      },
      {
        name: "azure-stack-hci-sizer",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/iot/azure-stack-hci-sizer.svg",
        type: "Microsoft.HCI/sizers"
      },
      {
        name: "azure-stack",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/iot/azure-stack.svg",
        type: "Microsoft.AzureStack/azureStacks"
      },
      {
        name: "device-provisioning-services",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/iot/device-provisioning-services.svg",
        type: "Microsoft.Devices/provisioningServices"
      },
      {
        name: "digital-twins",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/iot/digital-twins.svg",
        type: "Microsoft.DigitalTwins/digitalTwinsInstances"
      },
      {
        name: "event-grid-subscriptions",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/iot/event-grid-subscriptions.svg",
        type: "Microsoft.EventGrid/eventSubscriptions"
      },
      {
        name: "event-hub-clusters",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/iot/event-hub-clusters.svg",
        type: "Microsoft.EventHub/clusters"
      },
      {
        name: "event-hubs",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/iot/event-hubs.svg",
        type: "Microsoft.EventHub/namespaces"
      },
      {
        name: "industrial-iot",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/iot/industrial-iot.svg",
        type: "Microsoft.IndustrialIoT/industrialIoTServices"
      },
      {
        name: "iot-central-applications",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/iot/iot-central-applications.svg",
        type: "Microsoft.IoTCentral/applications"
      },
      {
        name: "iot-edge",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/iot/iot-edge.svg",
        type: "Microsoft.Devices/IotHubs"
      },
      {
        name: "iot-hub",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/iot/iot-hub.svg",
        type: "Microsoft.Devices/IotHubs"
      },
      {
        name: "logic-apps",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/iot/logic-apps.svg",
        type: "Microsoft.Logic/workflows"
      },
      {
        name: "machine-learning-studio-(classic)-web-services",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/iot/machine-learning-studio-(classic)-web-services.svg",
        type: "Microsoft.MachineLearning/webServices"
      },
      {
        name: "machine-learning-studio-web-service-plans",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/iot/machine-learning-studio-web-service-plans.svg",
        type: "Microsoft.MachineLearning/webServicePlans"
      },
      {
        name: "machine-learning-studio-workspaces",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/iot/machine-learning-studio-workspaces.svg",
        type: "Microsoft.MachineLearning/workspaces"
      },
      {
        name: "notification-hub-namespaces",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/iot/notification-hub-namespaces.svg",
        type: "Microsoft.NotificationHubs/namespaces"
      },
      {
        name: "notification-hubs",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/iot/notification-hubs.svg",
        type: "Microsoft.NotificationHubs/namespaces"
      },
      {
        name: "stack-hci-premium",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/iot/stack-hci-premium.svg",
        type: "Microsoft.HCI/premium"
      },
      {
        name: "stream-analytics-jobs",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/iot/stream-analytics-jobs.svg",
        type: "Microsoft.StreamAnalytics/streamingJobs"
      },
      {
        name: "time-series-data-sets",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/iot/time-series-data-sets.svg",
        type: "Microsoft.TimeSeriesInsights/environments"
      },
      {
        name: "time-series-insights-access-policies",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/iot/time-series-insights-access-policies.svg",
        type: "Microsoft.TimeSeriesInsights/accessPolicies"
      },
      {
        name: "time-series-insights-environments",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/iot/time-series-insights-environments.svg",
        type: "Microsoft.TimeSeriesInsights/environments"
      },
      {
        name: "time-series-insights-event-sources",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/iot/time-series-insights-event-sources.svg",
        type: "Microsoft.TimeSeriesInsights/eventSources"
      },
      {
        name: "windows10-core-services",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/iot/windows10-core-services.svg",
        type: "Microsoft.Windows10/coreServices"
      }
    ]
  },
  {
    name: "Integration",
    categoryIcon: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/integration/api-connections.svg",
    items: [
      {
        name: "api-connections",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/integration/api-connections.svg",
        type: "Microsoft.Web/connections"
      },
      {
        name: "api-management-services",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/integration/api-management-services.svg",
        type: "Microsoft.ApiManagement/service"
      },
      {
        name: "app-configuration",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/integration/app-configuration.svg",
        type: "Microsoft.AppConfiguration/configurationStores"
      },
      {
        name: "azure-api-for-fhir",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/integration/azure-api-for-fhir.svg",
        type: "Microsoft.HealthcareApis/services"
      },
      {
        name: "azure-data-catalog",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/integration/azure-data-catalog.svg",
        type: "Microsoft.DataCatalog/catalogs"
      },
      {
        name: "azure-databox-gateway",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/integration/azure-databox-gateway.svg",
        type: "Microsoft.DataBox/gateways"
      },
      {
        name: "azure-service-bus",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/integration/azure-service-bus.svg",
        type: "Microsoft.ServiceBus/namespaces"
      },
      {
        name: "azure-sql-server-stretch-databases",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/integration/azure-sql-server-stretch-databases.svg",
        type: "Microsoft.Sql/servers"
      },
      {
        name: "azure-stack-edge",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/integration/azure-stack-edge.svg",
        type: "Microsoft.DataBoxEdge/dataBoxEdgeDevices"
      },
      {
        name: "data-factories",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/integration/data-factories.svg",
        type: "Microsoft.DataFactory/factories"
      },
      {
        name: "event-grid-domains",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/integration/event-grid-domains.svg",
        type: "Microsoft.EventGrid/domains"
      },
      {
        name: "event-grid-subscriptions",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/integration/event-grid-subscriptions.svg",
        type: "Microsoft.EventGrid/eventSubscriptions"
      },
      {
        name: "event-grid-topics",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/integration/event-grid-topics.svg",
        type: "Microsoft.EventGrid/topics"
      },
      {
        name: "integration-accounts",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/integration/integration-accounts.svg",
        type: "Microsoft.Logic/integrationAccounts"
      },
      {
        name: "integration-environments",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/integration/integration-environments.svg",
        type: "Microsoft.Integration/environments"
      },
      {
        name: "integration-service-environments",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/integration/integration-service-environments.svg",
        type: "Microsoft.Integration/serviceEnvironments"
      },
      {
        name: "logic-apps-custom-connector",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/integration/logic-apps-custom-connector.svg",
        type: "Microsoft.Logic/customConnectors"
      },
      {
        name: "logic-apps",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/integration/logic-apps.svg",
        type: "Microsoft.Logic/workflows"
      },
      {
        name: "partner-namespace",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/integration/partner-namespace.svg",
        type: "Microsoft.EventGrid/partnerNamespaces"
      },
      {
        name: "partner-registration",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/integration/partner-registration.svg",
        type: "Microsoft.EventGrid/partnerRegistrations"
      },
      {
        name: "partner-topic",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/integration/partner-topic.svg",
        type: "Microsoft.EventGrid/partnerTopics"
      },
      {
        name: "power-platform",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/integration/power-platform.svg",
        type: "Microsoft.PowerPlatform/enterprisePolicies"
      },
      {
        name: "relays",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/integration/relays.svg",
        type: "Microsoft.Relay/namespaces"
      },
      {
        name: "sendgrid-accounts",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/integration/sendgrid-accounts.svg",
        type: "Microsoft.SendGrid/accounts"
      },
      {
        name: "software-as-a-service",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/integration/software-as-a-service.svg",
        type: "Microsoft.SaaS/services"
      },
      {
        name: "sql-data-warehouses",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/integration/sql-data-warehouses.svg",
        type: "Microsoft.Sql/servers"
      },
      {
        name: "storsimple-device-managers",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/integration/storsimple-device-managers.svg",
        type: "Microsoft.StorSimple/managers"
      },
      {
        name: "system-topic",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/integration/system-topic.svg",
        type: "Microsoft.EventGrid/systemTopics"
      }
    ]
  },
  {
    name: "Azure Stack",
    categoryIcon: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/azure_stack/plans.svg",
    items: [
      {
        name: "capacity",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/azure_stack/capacity.svg",
        type: "Microsoft.Capacity/reservationOrders"
      },
      {
        name: "infrastructure-backup",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/azure_stack/infrastructure-backup.svg",
        type: "Microsoft.AzureStack/infrastructureBackups"
      },
      {
        name: "multi-tenancy",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/azure_stack/multi-tenancy.svg",
        type: "Microsoft.AzureStack/multiTenancy"
      },
      {
        name: "offers",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/azure_stack/offers.svg",
        type: "Microsoft.AzureStack/offers"
      },
      {
        name: "plans",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/azure_stack/plans.svg",
        type: "Microsoft.AzureStack/plans"
      },
      {
        name: "updates",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/azure_stack/updates.svg",
        type: "Microsoft.AzureStack/updates"
      },
      {
        name: "user-subscriptions",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/azure_stack/user-subscriptions.svg",
        type: "Microsoft.AzureStack/userSubscriptions"
      }
    ]
  },
  {
    name: "Blockchain",
    categoryIcon: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/blockchain/blockchain-applications.svg",
    items: [
      {
        name: "abs-member",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/blockchain/abs-member.svg",
        type: "Microsoft.Blockchain/blockchainMembers"
      },
      {
        name: "azure-blockchain-service",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/blockchain/azure-blockchain-service.svg",
        type: "Microsoft.Blockchain/blockchainServices"
      },
      {
        name: "azure-token-service",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/blockchain/azure-token-service.svg",
        type: "Microsoft.Blockchain/tokenServices"
      },
      {
        name: "blockchain-applications",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/blockchain/blockchain-applications.svg",
        type: "Microsoft.Blockchain/blockchainApplications"
      },
      {
        name: "consortium",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/blockchain/consortium.svg",
        type: "Microsoft.Blockchain/consortiums"
      },
      {
        name: "outbound-connection",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/blockchain/outbound-connection.svg",
        type: "Microsoft.Blockchain/outboundConnections"
      }
    ]
  },
  {
    name: "Hybrid and Multicloud",
    categoryIcon: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/hybrid_and_multicloud/azure-operator-5g-core.svg",
    items: [
      {
        name: "azure-operator-5g-core",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/hybrid_and_multicloud/azure-operator-5g-core.svg",
        type: "Microsoft.Operator/5gCores"
      },
      {
        name: "azure-operator-insights",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/hybrid_and_multicloud/azure-operator-insights.svg",
        type: "Microsoft.Operator/insights"
      },
      {
        name: "azure-operator-nexus",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/hybrid_and_multicloud/azure-operator-nexus.svg",
        type: "Microsoft.Operator/nexus"
      },
      {
        name: "azure-operator-service-manager",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/hybrid_and_multicloud/azure-operator-service-manager.svg",
        type: "Microsoft.Operator/serviceManagers"
      },
      {
        name: "azure-programmable-connectivity",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/hybrid_and_multicloud/azure-programmable-connectivity.svg",
        type: "Microsoft.Operator/programmableConnectivity"
      }
    ]
  }
];

// This file has only Microsoft Azure Categories
// ./microsoft_azure_categories.js

export const azure_edges_icons = [
  {
    name: "Identity",
    categoryIcon: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/backlog.svg",
    items: [
      {
        name: "managed-identities",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/backlog.svg",
        type: "Microsoft.ManagedIdentity/userAssignedIdentities"
      },
      {
        name: "entra-managed-identities",
        url: "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/backlog.svg",
        type: "Microsoft.ManagedIdentity/userAssignedIdentities"
      }
    ]
  }
];
