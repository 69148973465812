import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppDispatch } from '../../../../hooks';
import { getTbarsList, createTbar } from '../../../../redux/tbars/tbarsApi';
import CustomDrawer from '../../../../components/customDrawer';
import TBarForm from '../../../T-bar/TBarForm';
import CompleteEmptyPage from '../../../../components/completeEmptyPage';
import { getLastIdFromUrl } from '../../../../utils/utilities';

import tChartSVG from '../../../../assets/empty_state/0-decisions-t-chart-empty-state.svg';
import { useUserTour } from 'src/contexts/userTour/UserTour.context';
import { useTourHandlers } from 'src/hooks/useUserTour';
import { EXAMPLE_TCHART, tChartCreationJourney } from 'src/utils/user-tour';
import { waitForElement, waitUntilElementRemoved } from 'src/utils/userTourUtils';

const TChartEmpty = () => {
  const [open, setOpen] = useState(false);
  const [formLoader, setFormLoader] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { markUserTourAsReady, paramsRef: tourParamsRef, setTourLoading } = useUserTour();

  const onCloseModal = () => {
    setOpen(false);
  };

  const onOpenCreateModal = () => {
    setOpen(true);
  };

  const creatingTbar = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      setTourLoading(true);

      try {
        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
          throw new Error('No access token');
        }

        const res = await dispatch(
          createTbar({
            body,
            accessToken,
            project_id: getLastIdFromUrl(window.location.pathname),
          })
        );

        if (res?.meta?.requestStatus === 'rejected') {
          throw new Error('Failed to create T-Chart');
        }

        onCloseModal();

        dispatch(
          getTbarsList({
            accessToken,
            project_id: getLastIdFromUrl(window.location.pathname),
          })
        );

        return res?.payload?.data?.tbar_analysis?.id;
      } catch (error) {
        console.error('Error getting access token:', error);
      } finally {
        setTourLoading(false);
        setFormLoader(false);
      }
    };

    return fetchData();
  };

  const onSubmitTbar = async (body: any) => {
    creatingTbar(body);
  };

  useEffect(() => {
    markUserTourAsReady();
  }, [markUserTourAsReady]);

  useTourHandlers(tChartCreationJourney, {
    'step-tchart-create-btn': {
      setup: async () => {
        if (!open) return;

        onCloseModal();

        await waitUntilElementRemoved('.new-tchart-drawer');
      },
      complete: async () => {
        onOpenCreateModal();

        await waitForElement('.new-tchart-drawer.ready');
      },
    },
    'step-tchart-save-btn': {
      setup: async () => {
        if (open) return;

        onOpenCreateModal();

        await waitForElement('.new-tchart-drawer.ready');
      },
      complete: async () => {
        const tChartId = await creatingTbar(EXAMPLE_TCHART);

        if (!tChartId) {
          throw new Error('Failed to create T-Chart');
        }

        tourParamsRef.current.tChartId = tChartId;

        await waitForElement('.step-tchart-add-argument-btn');
      },
    }
  });

  return (
    <>
      <div
        className='flex items-center justify-center'
        style={{ height: 'calc(100vh - 140px)' }}
      >
        <CompleteEmptyPage
          title="You don't have any T-Charts"
          subTitle='Get started by creating a new T-Chart'
          btnText='Create T-Chart'
          onClickBtn={onOpenCreateModal}
          icon={tChartSVG}
          imageHeight='250px'
          btnStyle='step-tchart-create-btn'
        />
      </div>

      <CustomDrawer.TChart
        open={open}
        onCloseModal={onCloseModal}
        className='new-tchart-drawer'
      >
        <TBarForm
          onCloseModal={onCloseModal}
          editData={null}
          onSubmitForm={onSubmitTbar}
          loader={formLoader}
          editMode={false}
        />
      </CustomDrawer.TChart>
    </>
  );
};

export default TChartEmpty;
