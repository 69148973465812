import type { ActionProps } from 'src/components/itemWithDropdown';

export const generateActions = {
  diagram: {
    summary: {
      agentName: 'document-generator-summary',
      callToAction: 'Summary Document',
    },
    security: {
      agentName: 'document-generator-security', 
      callToAction: 'Security Best Practices',
    },
    highAvailability: {
      agentName: 'document-generator-high-availability',
      callToAction: 'High Availability',
    },
    performance: {
      agentName: 'document-generator-performance',
      callToAction: 'Performance Options',
    },
    costOptimization: {
      agentName: 'document-generator-cost-optimization',
      callToAction: 'Cost Optimization',
    },
    recommendedOptimizations: {
      agentName: 'document-generator-optimizations',
      callToAction: 'Recommended Optimizations',
    },
  },
};

type GenerateActions = typeof generateActions;
export type GenerateItem = GenerateActions[keyof GenerateActions][keyof GenerateActions[keyof GenerateActions]];

export function getGenerateList<T extends keyof GenerateActions>(
  type: T,
  callback: (item: GenerateItem) => void
): ActionProps[] {
  const items: GenerateItem[] = Object.values(generateActions[type]);

  return items.map((item, index) => ({
    id: index + 1,
    name: item.callToAction,
    onActionClick: () => callback(item),
  }));
}