import {
  CloudIcon,
  CommandLineIcon,
  CubeIcon,
  GlobeAltIcon,
  XCircleIcon,
  ArrowPathIcon,
  ExclamationTriangleIcon,
  CheckCircleIcon,
  StopCircleIcon,
  SparklesIcon,
  CpuChipIcon,
} from '@heroicons/react/24/outline';
import { ComponentPropsWithoutRef } from 'react';
import { Category } from 'src/type';


import Azure from "../pages/flowchart/flow/assets/vendor_icons/azure.svg";
import AWS from "../pages/flowchart/flow/assets/vendor_icons/aws2.svg";
import GCP from "../pages/flowchart/flow/assets/vendor_icons/gcp.svg";

export function getLastIdFromUrl(url: string) {
  const segments = url.split('/');
  const lastSegment = segments[segments.length - 1];
  return lastSegment;
}

export function humanReadDate(created_at: string) {
  // Create a Date object
  const date = new Date(created_at);

  // Options for formatting the date
  const options: any = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  // Format the date
  const formattedDate = date.toLocaleDateString('en-US', options);

  return formattedDate;
}

function timeAgo(date: any) {
  const now: any = new Date();
  const seconds = Math.floor((now - date) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (years > 0) {
    return years === 1 ? '1 year ago' : `${years} years ago`;
  } else if (months > 0) {
    return months === 1 ? '1 month ago' : `${months} months ago`;
  } else if (days > 0) {
    return days === 1 ? '1 day ago' : `${days} days ago`;
  } else if (hours > 0) {
    return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
  } else if (minutes > 0) {
    return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
  } else {
    return seconds <= 1 ? 'just now' : `${seconds} seconds ago`;
  }
}

function formatDate(date: any) {
  const options = {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  };
  return date.toLocaleDateString('en-US', options);
}

export function timeAgoOrFormattedDate(stringDate: any) {
  const date: any = new Date(stringDate);
  const now: any = new Date();
  const seconds = Math.floor((now - date) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days <= 7) {
    // Use timeAgo for dates within a week
    return timeAgo(date);
  } else {
    // Use a specific date format for dates older than a week
    return formatDate(date);
  }
}

function cleanString(inputString: string) {
  // Remove '#' and replace newline characters with spaces
  let cleanedString = inputString.replace(/#/g, '').replace(/\n/g, ' ');

  // Remove HTML tags
  cleanedString = cleanedString.replace(/<[^>]*>/g, '');

  // Remove '&nbsp;'
  cleanedString = cleanedString.replace(/&nbsp;/g, '');

  return cleanedString.trim();
}

export function countWords(inputString: string) {
  let cleanedString = cleanString(inputString);

  if (cleanedString === '') {
    return 0;
  }

  let words = cleanedString.split(/\s+/);
  return words.length;
}

export function countCharacters(inputString: string) {
  let cleanedString = cleanString(inputString);

  return cleanedString.length;
}

export function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

export const parseQueryStringToObject = (url: string) => {
  const urlObj = new URL(url, window.location.origin);
  const urlSearchParams = urlObj.searchParams;
  return Object.fromEntries(urlSearchParams.entries());
};

export function getFirstPathFromUrl(url: string) {
  const segments = url.split('/');
  const firstSegment = segments[1];
  return firstSegment;
}

export function getPathnameInSplitArray(url: string) {
  const segments = url.split('/');
  return segments;
}

export function getQueryParams(url: string): Record<string, string> {
  // Create a URL object
  const urlObj = new URL(url);

  // Get the query string parameters
  const params = new URLSearchParams(urlObj.search);

  // Convert the parameters into an object
  const queryParams: Record<string, string> = {};
  params.forEach((value, key) => {
    queryParams[key] = value;
  });

  return queryParams;
}

export const gradientText = 'inline-block bg-gradient-to-r from-amber-400 to-amber-600 bg-clip-text text-transparent';

export const categoriesList: Array<{
  value: Category;
  title: string;
  icon: any;
}> = [
    {
      value: 'cloud-architecture',
      title: 'Cloud Architecture',
      icon: ({ className, ...props }: ComponentPropsWithoutRef<'svg'>) => (
        <CloudIcon {...props} className={classNames(className, '!text-blue-400')} />
      )
    },
    {
      value: 'azure-architecture',
      title: 'Azure Architecture',
      icon: ({ className, ...props }: ComponentPropsWithoutRef<'svg'>) => (
        <CloudIcon {...props} className={classNames(className, '!text-blue-600')} />
      )
    },
    {
      value: 'aws-architecture',
      title: 'AWS Architecture',
      icon: ({ className, ...props }: ComponentPropsWithoutRef<'svg'>) => (
        <CloudIcon {...props} className={classNames(className, '!text-orange-400')} />
      )
    },
    {
      value: 'gcp-architecture',
      title: 'GCP Architecture',
      icon: ({ className, ...props }: ComponentPropsWithoutRef<'svg'>) => (
        <CloudIcon {...props} className={classNames(className, '!text-red-400')} />
      )
    },
    {
      value: 'ai-design',
      title: 'AI Design',
      icon: ({ className, ...props }: ComponentPropsWithoutRef<'svg'>) => (
        <SparklesIcon {...props} className={classNames(className, '!text-purple-500')} />
      )
    },
    {
      value: 'software-architecture',
      title: 'Software Architecture',
      icon: ({ className, ...props }: ComponentPropsWithoutRef<'svg'>) => (
        <CommandLineIcon {...props} className={classNames(className, '!text-gray-600')} />
      )
    },
    {
      value: 'devops-cicd',
      title: 'DevOps & CI/CD',
      icon: ({ className, ...props }: ComponentPropsWithoutRef<'svg'>) => (
        <CubeIcon {...props} className={classNames(className, '!text-green-500')} />
      )
    },
    {
      value: 'business-design',
      title: 'Business Design',
      icon: ({ className, ...props }: ComponentPropsWithoutRef<'svg'>) => (
        <GlobeAltIcon {...props} className={classNames(className, '!text-indigo-500')} />
      )
    },
    {
      value: 'other',
      title: 'Other',
      icon: ({ className, ...props }: ComponentPropsWithoutRef<'svg'>) => (
        <CpuChipIcon {...props} className={classNames(className, '!text-gray-500')} />
      )
    },
  ];

export const clearableCategoriesList: Array<{
  value: Category;
  title: string;
  icon: any;
}> = [
  { 
    value: 'all', 
    title: 'Clear', 
    icon: ({ className, ...props }: ComponentPropsWithoutRef<'svg'>) => (
      <XCircleIcon {...props} className={classNames(className, '!text-gray-400')} />
    )
  }, 
  ...categoriesList
];

export function getCategoryTitle(category: Category) {
  return categoriesList.find((item) => item.value === category)?.title || '';
}

export function getCategoryValue(title: string) {
  return categoriesList.find((item) => item.title === title)?.value as Category;
}

export const statusList = [
  { value: 'not-started', title: 'Not Started', icon: ExclamationTriangleIcon },
  {
    value: 'in-progress',
    title: 'In Progress',
    icon: ({ className, ...props }: ComponentPropsWithoutRef<'svg'>) => (
      <ArrowPathIcon
        {...props}
        className={classNames(className, '!text-blue-500')}
      />
    ),
  },
  {
    value: 'concluded',
    title: 'Concluded',
    icon: ({ className, ...props }: ComponentPropsWithoutRef<'svg'>) => (
      <CheckCircleIcon
        {...props}
        className={classNames(className, '!text-green-600')}
      />
    ),
  },
  {
    value: 'canceled',
    title: 'Canceled',
    icon: ({ className, ...props }: ComponentPropsWithoutRef<'svg'>) => (
      <StopCircleIcon
        {...props}
        className={classNames(className, '!text-red-500')}
      />
    ),
  },
];

export const clearableStatusList = [{ value: 'all', title: 'Clear', icon: XCircleIcon }, ...statusList];

export function getStatusTitle(status: string) {
  return statusList.find((item) => item.value === status)?.title || '';
}

export function getStatusValue(title: string) {
  return statusList.find((item) => item.title === title)?.value || '';
}

export const initialNodes = [
  {
    id: 'react-component',
    type: 'input',
    data: { label: '1. React Component' },
    position: { x: 150, y: 25 },
  },
  {
    id: 'action-creators',
    data: { label: '2. Action Creators' },
    position: { x: 300, y: 125 },
  },
  {
    id: 'redux-store',
    data: { label: '3. Redux Store' },
    position: { x: 150, y: 225 },
  },
  {
    id: 'reducers',
    data: { label: '4. Reducers' },
    position: { x: 300, y: 325 },
  },
  {
    id: 'state',
    type: 'output',
    data: { label: '5. State' },
    position: { x: 150, y: 425 },
  },
  {
    id: 'result',
    type: 'output',
    data: { label: '6. Result' },
    position: { x: 150, y: 425 },
  },
];

export const initialEdges = [
  {
    id: 'e1',
    source: 'react-component',
    target: 'action-creators',
    animated: true,
    arrowHeadType: 'arrowclosed',
    label: 'dispatch action',
  },
  {
    id: 'e2',
    source: 'action-creators',
    target: 'redux-store',
    animated: true,
    arrowHeadType: 'arrowclosed',
    label: 'returns action',
  },
  {
    id: 'e3',
    source: 'redux-store',
    target: 'reducers',
    animated: true,
    arrowHeadType: 'arrowclosed',
    label: 'current state & action',
  },
  {
    id: 'e4',
    source: 'reducers',
    target: 'redux-store',
    animated: true,
    arrowHeadType: 'arrowclosed',
    label: 'new state',
  },
  {
    id: 'e5',
    source: 'redux-store',
    target: 'state',
    animated: true,
    arrowHeadType: 'arrowclosed',
    label: 'updates state',
  },
  {
    id: 'e6',
    source: 'state',
    target: 'react-component',
    animated: true,
    arrowHeadType: 'arrowclosed',
    label: 'passes state as props',
  },
  {
    id: 'e7',
    source: 'state',
    target: 'react-component',
    animated: true,
    arrowHeadType: 'arrowclosed',
    label: 'passes state as props',
  },
];

export function formatLatestDate(dateString: string) {
  const date = new Date(dateString);

  // Array for weekday and month names
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const monthsOfYear = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  // Extract day, month, and year
  const dayName = daysOfWeek[date.getUTCDay()];
  const monthName = monthsOfYear[date.getUTCMonth()];
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();

  // Return formatted string
  return `${dayName}, ${monthName} ${day}, ${year}`;
}

export function copyToClipboard(text: string) {
  return new Promise((resolve, reject) => {
    if (typeof navigator !== 'undefined' && navigator.clipboard) {
      navigator.clipboard.writeText(text).then(resolve).catch(reject);
    } else {
      reject(new Error('Clipboard API not supported'));
    }
  });
}

export function removeAllMediaElements(htmlContent: string): string {
  const mediaElements = ['img', 'video', 'audio', 'iframe', 'embed', 'object', 'source', 'track', 'picture'];
  const doc = new DOMParser().parseFromString(htmlContent, 'text/html');

  // Remove all media-related elements
  doc.querySelectorAll(mediaElements.join(', ')).forEach((mediaElement) => mediaElement.remove());

  return doc.body.innerHTML;
}

interface RetryConfig {
  retryInterval?: number;
  maxRetries?: number;
}

export async function retry<T>(
  callback: () => T | Promise<T>,
  { retryInterval = 100, maxRetries = 5 }: RetryConfig = {}
): Promise<T | null> {
  let attempts = 0;

  const execute = async (): Promise<T | null> => {
    try {
      const result = await callback();

      if (result === null || result === undefined) {
        throw new Error('Retry: callback returned null or undefined');
      }

      return result;
    } catch (error) {
      attempts++;

      if (attempts >= maxRetries) {
        return null;
      }

      await new Promise((resolve) => setTimeout(resolve, retryInterval));

      return execute();
    }
  };

  return execute();
}

// Check Trial Expiration

export function checkTrialExpiration(trialEndDate: number) {
  const trialEnd = trialEndDate * 1000; // Convert to milliseconds
  const currentTimestamp = Date.now(); // Get current timestamp in milliseconds

  return currentTimestamp < trialEnd;
}

export function getDaysLeftInTrial(trialEndDate: number): number {
  const trialEnd = trialEndDate * 1000; // Convert to milliseconds
  const currentTimestamp = Date.now(); // Get current timestamp in milliseconds
  const millisecondsInADay = 1000 * 60 * 60 * 24; // Number of milliseconds in a day

  const timeDifference = trialEnd - currentTimestamp;
  const daysLeft = Math.ceil(timeDifference / millisecondsInADay);

  return daysLeft > 0 ? daysLeft : 0; // Return 0 if the trial has ended
}

export function toCamelCase<T>(val: T): T {
  if (!val) return val;

  if (typeof val === 'string') {
    return val.replace(/_([a-z])/g, (g) => g[1].toUpperCase()) as T;
  }

  if (Array.isArray(val)) {
    return val.map((v) => toCamelCase(v)) as T;
  }

  if (val.constructor === Object) {
    return Object.entries(val).reduce((result: any, [key, value]) => {
      result[toCamelCase(key)] = value;

      return result;
    }, {});
  }

  return val;
}

export function parseJSONFromAi(serializedJson: string) {
  let jsonString = serializedJson;

  if (jsonString.startsWith('```json')) {
    jsonString = jsonString.slice(7);
  }

  if (jsonString.endsWith('```')) {
    jsonString = jsonString.slice(0, -3);
  }

  return JSON.parse(jsonString);
}

export function calculateReversedPercentage(points: number) {
  const percentage = 33.33;

  if (points === 3) {
    return percentage;
  } else if (points === 2) {
    return 2 * percentage;
  } else if (points === 1) {
    return percentage;
  } else {
    return 0; // Return 0 for any points value outside the range of 1-3
  }
}