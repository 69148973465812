import type { UserJourneyConfig } from 'src/type';
import { beforeDiagramTour, diagramTourHandlers } from 'src/utils/user-tour-handlers';

export const EXAMPLE_PROJECT = {
  title: 'Example project',
  description:
    "🎮 This is your playground project! Feel free to experiment with all the features here or test out project settings. Don't worry about making mistakes - this is the perfect place to learn and explore how everything works. Have fun! 🚀",
  status: 'In Progress',
  category: 'Cloud Architecture',
  complexity: 'medium',
  autosave: false,
  //   content: `<p class="step-first-p">Welcome to your test project! This is the first paragraph where you can explore the capabilities of our documentation system. Feel free to experiment with different formatting options and see how the content renders.</p>
  // <p class="step-second-p">In this second paragraph, you'll notice that our system handles HTML content seamlessly. You can add more paragraphs, lists, code blocks, and other formatting elements as needed for your documentation needs.</p>`,
};

export const EXAMPLE_DOCUMENT = {
  title: 'Example document',
  complexity: 'medium',
  content: `<p class="step-document-view">Welcome to your test project! This is the first paragraph where you can explore the capabilities of our documentation system. Feel free to experiment with different formatting options and see how the content renders.</p>
<p class="step-second-p">In this second paragraph, you'll notice that our system handles HTML content seamlessly. You can add more paragraphs, lists, code blocks, and other formatting elements as needed for your documentation needs.</p>`,
};

export const projectCreationJourney: UserJourneyConfig = {
  steps: [
    {
      path: '/projects',
      steps: [
        {
          target: 'step-project-create-btn',
          title: 'Create New Project',
          content: 'We will create your first project together',
          nextLabel: 'Open Project Form',
        },
        {
          target: 'step-project-title-input',
          title: 'Project Title',
          content: 'We will name your project "Example Project"',
          nextLabel: 'Set Project Status',
        },
        {
          target: 'step-project-status-select',
          title: 'Project Status',
          content: 'We will set the initial status of your project',
          nextLabel: 'Choose Category',
        },
        {
          target: 'step-project-category-select',
          title: 'Project Category',
          content: 'We will select the most appropriate category',
          nextLabel: 'Set Complexity',
        },
        {
          target: 'step-project-complexity-select',
          title: 'Project Complexity',
          content: 'We will define how complex your project is',
          nextLabel: 'Add Description',
        },
        {
          target: 'step-project-description-input',
          title: 'Project Description',
          content: 'We will add a detailed description of your project',
          nextLabel: 'Save Project',
        },
        {
          target: 'step-project-save-btn',
          title: 'Save Project',
          content: 'We will save your project now',
          nextLabel: 'View Project',
        },
        {
          target: 'step-project-view',
          title: 'New Project',
          content: "Here's your newly created project!",
          nextLabel: 'Go to Documents',
        },
      ],
    },
    {
      path: '/projects/:id',
      steps: [
        {
          target: 'step-document-create-btn',
          title: 'Create Document',
          content: 'We will create your first document',
          nextLabel: 'Fill Document Details',
        },
        {
          target: 'step-document-title-input',
          title: 'Document Title',
          content: "First, let's give your document a meaningful title",
          nextLabel: 'Set Complexity',
        },
        {
          target: 'step-document-complexity-select',
          title: 'Document Complexity',
          content: "Now, let's set the complexity level for your document",
          nextLabel: 'Save Document',
        },
        {
          target: 'step-document-save-btn',
          title: 'Save Document',
          content: 'Save your document to store your changes',
          nextLabel: 'View Document',
        },
        {
          target: 'ck-content',
          title: 'View Document',
          content: "Here's your newly created document!",
          nextLabel: 'Show Autosave',
        },
        {
          target: 'step-document-autosave-indicator',
          title: 'Autosave',
          content: 'Your changes will be automatically saved as you type',
          nextLabel: 'Show AI Assistant',
        },
        {
          target: 'step-document-ai-assistant',
          title: 'AI Assistant',
          content: 'You can get help from our AI assistant while writing',
          nextLabel: 'Show Generate Feature',
        },
        {
          target: 'step-document-generate-btn',
          title: 'Generate Content',
          content: 'You can generate new content using AI',
          nextLabel: 'Show Validation',
        },
        {
          target: 'step-document-validate-btn',
          title: 'Validate Document',
          content: 'You can check your document for any issues',
          nextLabel: 'Show Save As',
        },
        {
          target: 'step-document-save-as-btn',
          title: 'Save As',
          content: 'You can save your document as a new version',
          nextLabel: 'Show Save',
        },
        {
          target: 'step-document-save-content-btn',
          title: 'Save',
          content: 'You can save your current changes',
          nextLabel: 'Finish Tour',
        },
      ],
    },
  ]
}

export const EXAMPLE_TCHART = {
  tbar_title: 'PowerBI Desktop vs PowerBI Pro',
  tbar_description: 'Comparison of PowerBI Desktop and Pro versions to determine the best fit for our team needs',
  tbar_status: 'In Progress',
  tbar_category: 'Enterprise Business Design',
  option_a: 'PowerBI Desktop',
  option_b: 'PowerBI Pro',
  assumptions: '',
  implications: '',
  final_decision: '',
};

export const EXAMPLE_T_CHART_UPDATE = {
  tbar_status: 'Concluded',
  final_decision:
    "PowerBI Pro was selected as the better option, primarily due to its enhanced collaboration features that enable team members to work cohesively with shared dashboards and reports. While PowerBI Desktop offers good cost-effectiveness, the collaborative capabilities of Pro better align with our team's needs for shared analytics and reporting.",
};

export const EXAMPLE_T_CHART_ARGUMENT = {
  argumentName: 'Cost Effectiveness',
  description:
    'Free desktop version for individual users, with reasonable pricing for Pro and Premium versions based on organizational needs.',
  argumentWeight: 2,
};

export const EXAMPLE_T_CHART_SUGGESTIONS_A = [
  {
    id: 'desktop-security',
    argumentName: 'Data Security Control',
    explanation:
      'Complete control over data security as all reports and data remain local on your machine, making it ideal for sensitive information handling.',
    argumentWeight: 3,
  },
  {
    id: 'desktop-offline',
    argumentName: 'Offline Capabilities',
    explanation:
      'PowerBI Desktop allows users to work offline, providing flexibility in environments with limited internet access.',
    argumentWeight: 2,
  },
  {
    id: 'desktop-processing',
    argumentName: 'Local Data Processing',
    explanation:
      'Enables efficient data processing directly on your machine, which can be beneficial for handling large datasets locally.',
    argumentWeight: 1,
  },
];

export const EXAMPLE_T_CHART_SUGGESTIONS_B = [
  {
    id: 'pro-collaboration',
    argumentName: 'Enhanced Collaboration',
    explanation:
      'PowerBI Pro offers advanced collaboration features, allowing multiple team members to work cohesively with shared dashboards and reports.',
    argumentWeight: 3,
  },
  {
    id: 'pro-scalability',
    argumentName: 'Scalability for Larger Teams',
    explanation:
      'Pro is designed to support the growing needs of larger organizations with extensive data requirements.',
    argumentWeight: 2,
  },
  {
    id: 'pro-integration',
    argumentName: 'Integration with Office 365',
    explanation:
      'Seamless integration with Office 365 enhances productivity by leveraging familiar tools and streamlined workflows.',
    argumentWeight: 1,
  },
];

export const tChartCreationJourney: UserJourneyConfig = {
  steps: [
    {
      path: '/projects/:id?active=t-chart',
      steps: [
        {
          target: 'step-tchart-create-btn',
          title: 'Create T-Chart',
          content:
            "Let's create a T-Chart to compare PowerBI versions! This tool will help us make a data-driven decision for you and your team 📊",
          nextLabel: 'Fill title',
        },
        {
          target: 'step-tchart-title-input',
          title: 'T-Chart Title',
          content: "We'll name this 'PowerBI Desktop vs PowerBI Pro' - clear and straight to the point! 🎯",
          nextLabel: 'Fill description',
        },
        {
          target: 'step-tchart-description-input',
          title: 'T-Chart Description',
          content:
            "Let's explain our goal: we need to compare PowerBI versions to find the perfect fit for our team's reporting needs. A good description helps others understand the purpose! 🎯",
          nextLabel: 'Set Status',
        },
        {
          target: 'step-tchart-status-select',
          title: 'T-Chart Status',
          content:
            "We'll set this as 'In Progress' since we'll be gathering feedback from the team before making the final decision 🔄",
          nextLabel: 'Select Category',
        },
        {
          target: 'step-tchart-category-select',
          title: 'T-Chart Category',
          content:
            "This falls under 'Enterprise Business Design' since we're making a strategic tool selection for our organization 🏢",
          nextLabel: 'Fill option A',
        },
        {
          target: 'step-tchart-optionA',
          title: 'T-Chart Option A',
          content:
            "Let's add 'PowerBI Desktop' as our first option. This is Microsoft's free version that we'll compare against Pro 💻",
          nextLabel: 'Fill option B',
        },
        {
          target: 'step-tchart-optionB',
          title: 'T-Chart Option B',
          content:
            "Now we'll add 'PowerBI Pro' as our second option. This is the premium version with additional features 🌟",
          nextLabel: 'Save T-Chart',
        },
        {
          target: 'step-tchart-save-btn',
          title: 'Save T-Chart',
          content:
            "Great! Let's save our comparison chart. Next, we can start adding arguments for each option to make our decision easier! 🎉",
          nextLabel: 'Add First Argument',
        },
        {
          target: 'step-tchart-add-argument-btn',
          title: 'Add Argument',
          content: "Let's add our first argument for PowerBI Desktop. We'll evaluate its features and assign a weight 📝",
          nextLabel: 'Name Argument',
        },
        {
          target: 'step-tchart-argument-name-input',
          title: 'Argument Name',
          content: "Give your argument a clear, concise name that describes the feature or aspect you're evaluating",
          nextLabel: 'Add Description',
        },
        {
          target: 'step-tchart-argument-description-input',
          title: 'Argument Description',
          content: 'Provide a detailed explanation of this argument and why it matters for the comparison',
          nextLabel: 'Set Weight',
        },
        {
          target: 'step-tchart-argument-weight-select',
          title: 'Argument Weight',
          content: 'Choose a weight from 1 to 3 to indicate how important this factor is in the decision-making process',
          nextLabel: 'Save Argument',
        },
        {
          target: 'step-tchart-argument-save-btn',
          title: 'Save Argument',
          content: "Great! Let's save this argument to our comparison chart",
          nextLabel: 'View Argument',
        },
        {
          target: 'step-tchart-argument-view',
          title: 'View Argument',
          content:
            'Perfect! You can now see your argument in the comparison chart. Add more arguments to build a comprehensive comparison! 🎯',
          nextLabel: 'Try AI Generation',
        },
        {
          target: 'step-tchart-generate-ai-btn',
          title: 'Generate Arguments with AI',
          content:
            'Need more ideas? Our AI assistant can generate relevant arguments based on your T-Chart configuration, including the title, description, and existing arguments. It will analyze both options and suggest balanced comparisons! 🤖✨',
          nextLabel: 'Accept Suggestion',
        },
        {
          target: 'step-tchart-suggestion-argument',
          title: 'Accept Suggestion',
          content: 'Click anywhere on the suggestion card to accept this argument and add it to your comparison. ✨',
          nextLabel: 'Show Reject Option',
        },
        {
          target: 'step-tchart-suggestion-reject',
          title: 'Reject Suggestion',
          content: 'Not quite what you need? Click the thumbs down icon to reject this suggestion! 👎',
          nextLabel: 'See results',
        },
        {
          target: 'step-tchart-ratio-bar',
          title: 'Weight Ratio Comparison',
          content:
            'This bar shows the weighted comparison between PowerBI Desktop and Pro. The ratio helps you visualize which option scores better based on your arguments and their weights! 📊',
          nextLabel: 'View Details',
        },
        {
          target: 'step-details-btn',
          title: 'T-Chart Details',
          content: 'Click here to view or modify your T-Chart configuration, including title, description, and status 📋',
          nextLabel: 'Edit Details',
        },
        {
          target: 'step-tchart-edit-btn',
          title: 'Edit T-Chart',
          content: "Let's update our T-Chart status now that we've completed our comparison! ✏️",
          nextLabel: 'Set Status',
        },
        {
          target: 'step-tchart-status-concluded',
          title: 'Mark as Concluded',
          content: "We've gathered enough information to make our decision. Let's set the status to 'Concluded' ✅",
          nextLabel: 'Add Final Decision',
        },
        {
          target: 'step-tchart-final-decision',
          title: 'Record Final Decision',
          content:
            "Based on our comparison, PowerBI Desktop achieves a better score. Let's document this decision and the key reasons why! 🎯",
          nextLabel: 'Save changes',
        },
        {
          target: 'step-tchart-update-btn',
          title: 'Update T-Chart',
          content: "Let's save our changes now!",
          nextLabel: 'Finalize T-Chart',
        },
        {
          target: 'step-tchart-list-btn',
          title: 'T-Charts List',
          content:
            'Click here to see all your T-Charts in one place. You can easily switch between different comparisons! 📑',
          nextLabel: 'Create Another T-Chart',
        },
        {
          target: 'step-tchart-new-btn',
          title: 'Create New T-Chart',
          content: 'Ready to make another comparison? Click here to create a new T-Chart! 🆕',
          nextLabel: 'Manage T-Charts',
        },
        {
          target: 'step-tchart-list-items',
          title: 'Manage T-Charts',
          content:
            'You can reorder T-Charts by dragging, delete them, or modify any existing comparison. Keep your decisions organized and up-to-date! 🔄',
          nextLabel: 'Finish Tour',
        },
      ],
    },
  ]
}

export const diagramJourney: UserJourneyConfig = {
  steps: [
    {
      path: '/projects/:id?active=diagrams',
      steps: [
        {
          target: 'step-diagram-create-btn',
          title: 'Create Diagram',
          content: "Let's start by creating a new diagram. Click the button to begin!",
          nextLabel: 'Fill Diagram Details',
        },
        {
          target: 'step-diagram-save-btn',
          title: 'Save Diagram',
          content:
            "We've prefilled the diagram with a suitable title and type. Now, click 'Save' to complete the process!",
          nextLabel: 'Save',
        },
        {
          target: 'step-diagram-search-bar',
          title: 'Search for Node',
          content:
            'Diagrams are made up of nodes. Nodes are the key elements that represent different entities or components in your diagram. Use the search bar to find a node you need.',
          nextLabel: 'Insert first node',
        },
        {
          target: 'step-diagram-node',
          title: 'Insert First Node',
          content: "Let's drag this node to our diagram.",
          nextLabel: 'Update diagram',
        },
        {
          target: 'step-edit-ai-btn',
          title: 'Edit with AI',
          content: "Now, let's use AI to modify our diagram. Click this button to open a chat with our AI assistant.",
          nextLabel: 'Open chat',
        },
        {
          target: 'chat-send-button',
          title: 'Send Instruction',
          content: "We've prefilled instructions for you. Click 'Send' and wait for the AI to respond.",
          nextLabel: 'Wait for AI response',
        },
        {
          target: 'diagram-patches-button',
          title: 'Apply Changes',
          content: 'The AI generated patches to our diagram. Click this button to apply them.',
          nextLabel: 'Apply',
        },
        {
          target: 'step-generate-artifact-btn',
          title: 'Generate Artifact',
          content: "You can easily generate a document from your diagram. Click this button to try it out.",
          nextLabel: 'See options',
        },
        {
          target: 'step-generate-artifact-dropdown',
          title: 'Summary Document',
          content: "Select the 'Summary Document' option to generate a concise overview.",
          nextLabel: 'Generate',
        },
        {
          target: 'step-generate-modal-btn',
          title: 'Fill Document Details',
          content: "Hit 'Save' to start generation process",
          nextLabel: 'View result',
        },
        {
          target: 'ck-content',
          title: 'Your Document',
          content: "Your document is ready!",
          nextLabel: 'Go back',
        },
        {
          target: 'step-validate-btn',
          title: 'Validate Diagram',
          content:
            "Use the 'Validate' button to check the design quality of your diagram and ensure everything is in order.",
          nextLabel: 'Show options',
        },
        {
          target: 'step-validation-dropdown',
          title: 'Validation Options',
          content:
            "Explore various validation options to suit your needs. For now, let's use 'Service Limitations' as an example.",
          nextLabel: 'Finish tour and view insights',
        },
      ],
    },
  ],
  handlers: diagramTourHandlers,
  beforeStart: beforeDiagramTour,
  backButtonEnabled: false
};
