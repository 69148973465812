import { useEffect, useState } from 'react';
import CustomButton from 'src/components/customButton';
import InputField from '../../../../../components/formFields/inputField';
import RadioGroup from '../../../../../components/formFields/radioGroup';
import { useTourHandlers } from 'src/hooks/useUserTour';
import { type } from 'src/utils/userTourUtils';
import { EXAMPLE_DOCUMENT, projectCreationJourney } from 'src/utils/user-tour';
import { classNames } from 'src/utils/utilities';

interface DocumentProps {
  onCloseModal: () => void;
  loading: boolean;
  onSubmitData: Function;
  editData: any;
}

export default function Document({ onCloseModal, loading, onSubmitData, editData }: DocumentProps) {
  const [title, setTitle] = useState('');
  const [isTitle, setIsTitle] = useState(false);
  const [complexity, setComplexity] = useState('low');

  useEffect(() => {
    if (editData) {
      if (editData?.title) setTitle(editData?.title);
      if (editData?.complexity) setComplexity(editData?.complexity);
    }
  }, [editData]);

  const onSubmitForm = (e: any) => {
    e.preventDefault();
    const payload = {
      title: title,
      complexity,
    };

    if (title) {
      onSubmitData(payload);
    } else {
      setIsTitle(true);
    }
  };

  const onChangeTitle = (value: string) => {
    setTitle(value);
    setIsTitle(false);
  };

  const complexityList = [
    { id: 1, value: 'low', title: 'Low' },
    { id: 2, value: 'medium', title: 'Medium' },
    { id: 3, value: 'complex', title: 'Complex' },
  ];

  useTourHandlers(projectCreationJourney, {
    'step-document-title-input': {
      setup: async () => {
        type('#title', EXAMPLE_DOCUMENT.title).then(() => {
          setTitle(EXAMPLE_DOCUMENT.title);
        });
      },
      beforePrev: () => {
        setTitle('');
      },
    },
    'step-document-complexity-select': {
      setup: async () => {
        setComplexity('medium');
      },
      beforePrev: () => {
        setComplexity('low');
      },
    },
  });

  return (
    <form
      className='flex h-full flex-col'
      onSubmit={onSubmitForm}
    >
      <div
        className={classNames(
          'space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 mb-3',
          'step-document-title-input'
        )}
      >
        <div>
          <label className='block text-xs font-medium leading-6 text-customDarkBlue'>Title</label>
        </div>
        <div className='sm:col-span-2'>
          <InputField
            type='text'
            id='title'
            error={isTitle}
            name='title'
            value={title}
            onChange={(e) => onChangeTitle(e.target.value)}
            data-auto-focus
          />
        </div>
      </div>

      <div
        className={classNames(
          'space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0',
          'step-document-complexity-select'
        )}
      >
        <div>
          <label className='block text-xs font-medium leading-6 text-customDarkBlue'>Complexity</label>
        </div>
        <div className='sm:col-span-2'>
          <RadioGroup
            onChangeRadio={setComplexity}
            value={complexity}
            radioList={complexityList}
          />
        </div>
      </div>

      {/* Action buttons */}
      <div className='w-full text-right mt-4 border-t pt-4'>
        <CustomButton
          text='Cancel'
          type='button'
          onClickBtn={onCloseModal}
          buttonType='secondary'
          btnStyle='mr-2'
        />
        <CustomButton
          text='Save'
          type='submit'
          onClickBtn={() => console.log()}
          loading={loading}
          buttonType='primary'
          btnStyle='step-document-save-btn'
        />
      </div>
    </form>
  );
}
