import React, { useState, useCallback } from "react";
import CustomButton from "src/components/customButton";
import TextareaField from "../../../components/formFields/textareaField";

interface FlowChartGenerateFormProps {
  onCloseModal: () => void;
  loading: boolean;
  onSubmitData: Function;
}

const FlowChartGenerateForm = React.memo(function FlowChartGenerateForm({
  onCloseModal,
  loading,
  onSubmitData,
}: FlowChartGenerateFormProps) {
  const [generateText, setGenerateText] = useState("");
  const [isError, setIsError] = useState(false);

  // Debounce the input change handler
  const debouncedOnChangeGenerateText = useCallback(
    (value: string) => {
      setGenerateText(value);
      if (value.length > 0) {
        setIsError(false);
      }
    },
    [setGenerateText, setIsError]
  );

  const onSubmitForm = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      if (generateText) {
        onSubmitData({
          prompt: generateText,
        });
      } else {
        setIsError(true);
      }
    },
    [generateText, onSubmitData]
  );

  return (
    <form className="flex h-full flex-col" onSubmit={onSubmitForm}>
      <div className="space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 mb-3">
        <div>
          <label className="block text-xs font-medium leading-6 text-customDarkBlue">
            Diagram Request Description
          </label>
        </div>
        <div className="sm:col-span-2">
          <TextareaField
            id="text"
            name="text"
            rows={5}
            value={generateText}
            placeholder="Describe what kind of diagram you want to generate. Be as detailed and descriptive as possible."
            onChange={(e) => debouncedOnChangeGenerateText(e.target.value)}
            style={{
              border: `${isError ? "solid 1px red" : "solid 1px #cacaca"}`,
            }}
          />
        </div>
      </div>

      {/* Action buttons */}
      <div className="w-full text-right mt-5">
        <CustomButton
          text="Cancel"
          type="button"
          onClickBtn={onCloseModal}
          buttonType="secondary"
          btnStyle="mr-2"
        />

        <CustomButton
          text="Save"
          type="submit"
          onClickBtn={() => {}}
          loading={loading}
          buttonType="primary"
        />
      </div>
    </form>
  );
});

export default FlowChartGenerateForm;
