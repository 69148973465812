import React, { memo, useEffect, useRef, useState } from 'react';
import Joyride, { ACTIONS, CallBackProps, EVENTS, Step, TooltipRenderProps } from 'react-joyride';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { classNames } from 'src/utils/utilities';
import CustomButton from '../customButton';
import { useRefValue } from 'src/hooks/useRefValue';
import type { Primitive, StepHandlers, TourHandlesMap } from 'src/type';
import { TourContext, TourNavigator, wait } from 'src/utils/userTourUtils';
import { useNavigate } from 'react-router-dom';

interface AppTourProps {
  steps: Step[];
  isNavigating: boolean;
  currentStepIndex: number;
  isReady: boolean;
  loading?: boolean;
  stepHandlersRef: React.MutableRefObject<Record<string, StepHandlers>>;
  handlesRef: React.MutableRefObject<Partial<TourHandlesMap>>;
  paramsRef: React.MutableRefObject<Record<string, Primitive>>;
  backButtonEnabled?: boolean;
  goTo: (stepIndex: number) => void;
  endJourney: () => void;
  setLoading: (isLoading: boolean) => void;
}

const CustomTooltip = ({
  backProps,
  closeProps,
  continuous,
  index,
  primaryProps,
  step,
  tooltipProps,
  size,
  loading,
  backButtonEnabled,
}: TooltipRenderProps & { loading: boolean; backButtonEnabled: boolean }) => {
  const { title: backTitle, onClick: onBackClick, ...otherBackProps } = backProps;
  const { title: primaryTitle, onClick: onPrimaryClick, ...otherPrimaryProps } = primaryProps;

  return (
    <div
      {...tooltipProps}
      className='rounded-xl bg-white p-4 shadow-lg max-w-[360px]'
    >
      <div className={classNames('flex justify-between items-center gap-3 pb-4 mb-3', 'border-b border-gray-300')}>
        <h4 className='text-md font-medium text-customDarkBlue'>{step.title}</h4>
        <button
          {...closeProps}
          className={classNames(
            'rounded-md bg-white text-gray-400 transition-colors hover:text-customLightBlue',
            'focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
          )}
        >
          <XMarkIcon
            className='size-6'
            aria-hidden
          />
        </button>
      </div>

      <div className='mb-4 mt-2 flex-1 text-sm/6 text-gray-500'>{step.content}</div>

      <div className='flex justify-between items-center'>
        <div className='shrink-0 mr-6 text-sm text-gray-500'>
          {index + 1} / {size}
        </div>

        <div className='shrink-0 flex gap-2'>
          {backButtonEnabled && index > 0 && (
            <CustomButton
              text={backTitle}
              onClickBtn={onBackClick}
              type='button'
              buttonType='secondary'
              buttonSize='sm'
              outlined={false}
              disabled={loading}
              {...otherBackProps}
            />
          )}
          {continuous && (
            <CustomButton
              text={primaryTitle}
              onClickBtn={onPrimaryClick}
              type='button'
              buttonType='primary'
              buttonSize='sm'
              outlined={false}
              loading={loading}
              {...otherPrimaryProps}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const AppTour: React.FC<AppTourProps> = ({
  steps,
  isNavigating,
  currentStepIndex,
  isReady,
  loading = false,
  stepHandlersRef,
  handlesRef,
  paramsRef,
  backButtonEnabled = true,
  goTo,
  endJourney,
  setLoading,
}) => {
  const stepInProgress = useRef(-1);
  const stepsRef = useRefValue(steps);
  const [isStopped, setIsStopped] = useState(true);
  const navigate = useNavigate();

  const isRunning = isReady && !isNavigating && !isStopped;

  const handleEndJourney = () => {
    stepInProgress.current = -1;
    setIsStopped(true);
    endJourney();
  };

  const getStepHandlers = (stepIndex: number): StepHandlers | null => {
    const step = stepsRef.current[stepIndex];

    if (!step) return null;

    return stepHandlersRef.current[(step.target as string).slice(1)] ?? {};
  };

  const executeHandler = (stepIndex: number, name: keyof StepHandlers) => {
    const stepHandlers = getStepHandlers(stepIndex);
    const context = new TourContext(handlesRef.current, paramsRef, setLoading);
    const navigator = new TourNavigator(paramsRef, navigate);

    return stepHandlers?.[name]?.(context, navigator);
  };

  const handleUserTour = async (data: CallBackProps) => {
    try {
      const { index, action, type } = data;

      // Handle error when target element is not found
      if (type === EVENTS.TARGET_NOT_FOUND) {
        console.error('Tour target element not found');
        handleEndJourney();

        return;
      }

      if (action === ACTIONS.SKIP || action === ACTIONS.CLOSE) {
        handleEndJourney();

        return;
      }

      if (action === ACTIONS.NEXT) {
        setIsStopped(true);

        const nextStepIndex = index + 1;
        stepInProgress.current = nextStepIndex;

        const nextStep = stepsRef.current[nextStepIndex];

        await executeHandler(index, 'complete');

        if (!nextStep) {
          handleEndJourney();

          return;
        }

        if (stepInProgress.current === nextStepIndex) {
          await executeHandler(index, 'beforePrevOrNext');
        }

        if (stepInProgress.current === nextStepIndex) {
          await executeHandler(index, 'beforeNext');
        }

        if (stepInProgress.current === nextStepIndex) {
          await executeHandler(nextStepIndex, 'setup');
        }

        await wait(0);

        if (stepInProgress.current === nextStepIndex) {
          goTo(nextStepIndex);
          setIsStopped(false);
        }
      }

      if (action === ACTIONS.PREV) {
        setIsStopped(true);

        const prevStepIndex = index - 1;
        stepInProgress.current = prevStepIndex;

        const prevStep = stepsRef.current[prevStepIndex];

        if (stepInProgress.current === prevStepIndex) {
          await executeHandler(index, 'beforePrevOrNext');
        }

        if (stepInProgress.current === prevStepIndex) {
          await executeHandler(index, 'beforePrev');
        }

        if (!prevStep) {
          handleEndJourney();

          return;
        }

        if (stepInProgress.current === prevStepIndex) {
          await executeHandler(prevStepIndex, 'undo');
        }

        if (stepInProgress.current === prevStepIndex) {
          await executeHandler(prevStepIndex, 'setup');
        }

        await wait(0);

        if (stepInProgress.current === prevStepIndex) {
          goTo(prevStepIndex);
          setIsStopped(false);
        }
      }
    } catch (error) {
      handleEndJourney();

      if (process.env.NODE_ENV === 'development') {
        throw error;
      } else {
        console.error('Error in handleUserTour:', error);
      }
    }
  };

  const executeHandlerRef = useRefValue(executeHandler);

  useEffect(() => {
    const initializeTour = async (stepIndex: number) => {
      await executeHandlerRef.current(stepIndex, 'setup');

      setIsStopped(false);
    };

    if (isReady && isStopped && !isNavigating && stepInProgress.current === -1) {
      initializeTour(0);
    }
  }, [executeHandlerRef, isNavigating, isReady, isStopped, handlesRef]);

  useEffect(() => {
    return () => {
      stepInProgress.current = -1;
    };
  }, []);

  return (
    <Joyride
      steps={steps}
      run={isRunning}
      stepIndex={currentStepIndex}
      callback={handleUserTour}
      disableOverlayClose
      tooltipComponent={(props) => (
        <CustomTooltip
          {...props}
          loading={loading}
          backButtonEnabled={backButtonEnabled}
        />
      )}
      continuous
      locale={{
        last: steps[steps.length - 1]?.locale?.next,
      }}
    />
  );
};

export default memo(AppTour);
