import React, { useEffect, HTMLAttributes, ReactNode, Fragment } from 'react';
import { classNames } from 'src/utils/utilities';

import controlIcon from 'src/assets/icons/control.svg';
import enterIcon from 'src/assets/icons/enter.svg';
import { platform } from 'src/utils/platform';
import { PlusIcon } from '@heroicons/react/24/solid';

type KeyType = string | { key: string; ui: ReactNode };

interface KeyboardShortcutProps extends HTMLAttributes<HTMLDivElement> {
  keys: KeyType[];
  onTrigger: (event: KeyboardEvent) => void;
  withSeparator?: boolean;
  themed?: boolean;
}

export const Keys: Record<string, KeyType> = {
  Control: {
    key: platform.isMac ? 'Command' : 'Control',
    ui: platform.isMac ? (
      <img
        src={controlIcon}
        className='size-4 opacity-50'
        alt='Command'
        aria-hidden
      />
    ) : (
      <span>Ctrl</span>
    ),
  },
  Enter: {
    key: 'Enter',
    ui: (
      <img
        src={enterIcon}
        className='size-4 opacity-50'
        alt='Enter'
        aria-hidden
      />
    ),
  },
};

const KeyboardShortcut: React.FC<KeyboardShortcutProps> = ({
  keys,
  onTrigger,
  className = '',
  withSeparator = false,
  themed = false,
  ...props
}) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const pressedKeys = keys.map((key) => (typeof key === 'string' ? key : key.key));
      const isShortcutTriggered = pressedKeys.every((key) => {
        return event.getModifierState(key) || event.key === key;
      });

      if (isShortcutTriggered) {
        event.preventDefault();
        onTrigger(event);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [keys, onTrigger]);

  return (
    <div
      className={classNames(
        'py-1 px-1.5 flex items-center gap-1 bg-white/50 rounded-[4px]',
        'text-[12px]/[1] font-semibold text-black/50',
        themed &&
          classNames(
            'ml-2 px-2 py-1 rounded-md ring-1 ring-inset bg-blue-50 ring-blue-600/20',
            'inline-flex items-center text-xs !font-normal text-blue-700'
          ),
        className
      )}
      {...props}
    >
      {keys.map((key, index) => (
        <Fragment key={index}>
          <kbd className={classNames('flex items-center justify-center')}>
            {typeof key === 'string' ? <span>{key}</span> : key.ui}
          </kbd>
          {withSeparator && index !== keys.length - 1 && (
            <span>
              <PlusIcon className='size-3' />
            </span>
          )}
        </Fragment>
      ))}
    </div>
  );
};

const ControlEnter: React.FC<Omit<KeyboardShortcutProps, 'keys'>> = ({ className, ...props }) => {
  if (!platform.isWindows && !platform.isLinux && !platform.isMac) {
    return null;
  }

  return (
    <KeyboardShortcut
      {...props}
      withSeparator={!platform.isMac}
      className={classNames(className)}
      keys={[Keys.Control, Keys.Enter]}
    />
  );
};

export default Object.assign(KeyboardShortcut, { ControlEnter });
