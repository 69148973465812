import { PlusIcon } from "@heroicons/react/20/solid";
import CustomButton from "../customButton";

import logoIcon from '../../assets/images/logo_icon.png';
import { ReactNode } from "react";

interface EmptyPageProps {
  title: string;
  subTitle: string;
  btnText?: string;
  onClickBtn?: any;
  icon?: any;
  imageHeight?: string;
  children?: ReactNode;
  btnStyle?: string;
}

export default function EmptyPage({
  title,
  subTitle,
  btnText,
  onClickBtn,
  icon,
  imageHeight = '200px',
  children,
  btnStyle,
}: EmptyPageProps) {
  return (
    <div className="text-center w-full max-w-[550px]">
      <img
        className={`mx-auto mb-5`}
        style={{ height: imageHeight }}
        src={icon ? icon : logoIcon}
        alt="Solutions Pilot Icon"
      />
      <h3 className="text-lg font-semibold text-customDarkBlue mb-2">
        {title}
      </h3>
      <p className="text-xs text-customLightBlue">{subTitle}</p>

      {onClickBtn && (
        <div className="mt-6">
          <CustomButton
            text={btnText}
            type="button"
            onClickBtn={() => onClickBtn()}
            beforeIcon={
              <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            }
            buttonType='primary'
            btnStyle={btnStyle}
          />
        </div>
      )}
      {children}
    </div>
  );
}
