import React, { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import RoundButton from '../../../../components/roundButton';
import ConfirmationModal from '../../../../components/confirmationModal';
import TChartBox from './tChartBox';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { getTbarsList, createTbar, updateTbar, deleteTbar, getTbarById } from '../../../../redux/tbars/tbarsApi';
import WrapperLoader from '../../../../components/wrapperLoader';
import { classNames, getLastIdFromUrl, getQueryParams } from '../../../../utils/utilities';
import { setSelectedTChart } from '../../../../redux/tbars/tbarsSlice';
import TBarForm from '../../../T-bar/TBarForm';
import CustomDrawer from '../../../../components/customDrawer';
import { EXAMPLE_TCHART, tChartCreationJourney } from 'src/utils/user-tour';
import { useTourHandlers } from 'src/hooks/useUserTour';
import { EXAMPLE_T_CHART_UPDATE } from 'src/utils/user-tour';

interface Item {
  tbar_analysis: any;
  content: string;
  created_at: string;
  document_order: number;
  id: string;
  project_id: string;
  title: string;
  updated_at: string;
  user_id: string;
}

interface TChartListProps {
  checkingDetailTabShow: Function;
}

const TChartList = ({ checkingDetailTabShow }: TChartListProps) => {
  const [open, setOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [selectedData, setSelectedData] = useState<any>(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [allTChartLoading, setAllTChartLoading] = useState(false);
  // const [openTChartModal, setOpenTChartModal] = useState(false);
  const [tChartsitems, setTChartsItems] = useState<Item[]>([]);
  const [isEditMode, setIsEditMode] = useState<any>(null);
  const [tbarFormLoader, setTbarFormLoader] = useState(false);
  const [tbarByIdLoader, setTbarByIdLoader] = useState(false);

  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { tBarsList, selectedTChart, tbarByIdRes } = useAppSelector((state) => state.tbars);

  useEffect(() => {
    const tChartsList: any = [...(tBarsList || [])];
    setTChartsItems(tChartsList || []);

    if (tBarsList?.length > 0 && !selectedTChart) {
      const queryStringObj = getQueryParams(window.location.href);

      const findItem = queryStringObj ? tBarsList?.find((data) => data.tbar_analysis?.id === queryStringObj?.id) : null;

      dispatch(setSelectedTChart(findItem || tBarsList[0]?.tbar_analysis));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tBarsList]);

  useEffect(() => {
    checkingDetailTabShow();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tChartsitems]);

  // useEffect(() => {
  //   if (tBarsList?.length > 0 && tbarDeleteRes) {
  //     dispatch(setSelectedTChart(tBarsList[0]?.tbar_analysis));
  //   }
  // }, [tbarDeleteRes]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          setAllTChartLoading(true);
          await dispatch(
            getTbarsList({
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
          setAllTChartLoading(false);
        }
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const queryStringObj = getQueryParams(window.location.href);

    if (queryStringObj?.active === 't-chart') {
      navigate(`${window.location.pathname}?active=t-chart&id=${selectedTChart?.id || queryStringObj?.id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTChart]);

  useEffect(() => {
    const queryStringObj = getQueryParams(window.location.href);

    if (tChartsitems?.length > 0 && queryStringObj?.active === 't-chart') {
      const findTChart = tChartsitems.find((data: any) => data?.tbar_analysis?.id === queryStringObj.id);

      dispatch(setSelectedTChart(findTChart?.tbar_analysis || tChartsitems[0]?.tbar_analysis));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tChartsitems]);

  const gettingTbarById = useCallback(
    (id: string) => {
      const fetchData = async () => {
        setTbarByIdLoader(true);
        try {
          const accessToken = await getAccessTokenSilently();
          if (accessToken)
            await dispatch(
              getTbarById({
                accessToken,
                tbarId: id,
                project_id: getLastIdFromUrl(window.location.pathname),
              })
            );
          setTbarByIdLoader(false);
        } catch (error) {
          console.error('Error getting access token:', error);
          setTbarByIdLoader(false);
        }
      };

      fetchData();
    },
    [dispatch, getAccessTokenSilently]
  );

  const onOpenModal = () => {
    setOpen(true);
    setIsEditMode(false);
  };

  const onCloseModal = () => {
    setOpen(false);
    setIsEditMode(false);
  };

  const onOpenEditModal = useCallback(
    (id: string) => {
      setIsEditMode(true);
      setOpen(true);

      if (tbarByIdRes?.tbar_analysis?.id !== id) {
        gettingTbarById(id);
      }
    },
    [gettingTbarById, tbarByIdRes?.tbar_analysis?.id]
  );

  const deletingTChart = () => {
    const fetchData = async () => {
      setDeleteLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            deleteTbar({
              accessToken,
              tbarId: selectedData?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          ).then(async (res) => {
            closeConfirmationModal();

            await dispatch(
              getTbarsList({
                accessToken,
                project_id: getLastIdFromUrl(window.location.pathname),
              })
            )
              .then((res) => {
                if (res?.payload?.data?.length > 0) {
                  dispatch(setSelectedTChart(res?.payload?.data[0]?.tbar_analysis));
                }
              })
              .catch((error) => console.log('error', error));
          });

          setDeleteLoader(false);
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        setDeleteLoader(false);
      }
    };

    fetchData();
  };

  const updatingTbar = (body: any) => {
    const fetchData = async () => {
      setTbarFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            updateTbar({
              body,
              accessToken,
              tbarId: tbarByIdRes?.tbar_analysis?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
          onCloseModal();
          setTbarFormLoader(false);

          await dispatch(
            getTbarsList({
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        setTbarFormLoader(false);
      }
    };

    return fetchData();
  };

  const creatingTbar = (body: any) => {
    const fetchData = async () => {
      setTbarFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
          throw new Error('No access token');
        }

        const res = await dispatch(
          createTbar({
            body,
            accessToken,
            project_id: getLastIdFromUrl(window.location.pathname),
          })
        );

        dispatch(
          getTbarsList({
            accessToken,
            project_id: getLastIdFromUrl(window.location.pathname),
          })
        ).catch((err) => console.log('Error fetching tbars list: ', err));

        const requestStatus = res?.meta?.requestStatus;
        const tChartId = res?.payload?.data?.tbar_analysis?.id;

        if (requestStatus === 'fulfilled' && tChartId) {
          onCloseModal();
          navigate({
            search: new URLSearchParams({
              active: 't-chart',
              id: tChartId,
            }).toString(),
          });
        }
      } catch (error) {
        console.error('Error creating tBar:', error);
      } finally {
        setTbarFormLoader(false);
      }
    };

    fetchData();
  };

  const onSubmitTbar = async (body: any) => {
    if (isEditMode) {
      updatingTbar(body);
    } else {
      creatingTbar(body);
    }
  };

  const deleteConfirmationModal = (data: any) => {
    setSelectedData(data);
    setDeleteConfirmation(true);
  };

  const closeConfirmationModal = () => {
    setDeleteConfirmation(false);
    setSelectedData(null);
  };

  const onSelectedTChart = async (data: any) => {
    dispatch(setSelectedTChart(data));
  };

  useEffect(() => {
    const listener = () => {
      if (tbarByIdRes?.tbar_analysis?.id) {
        onOpenEditModal(tbarByIdRes.tbar_analysis.id);
      }
    };

    document.addEventListener('t-chart:edit', listener);

    return () => document.removeEventListener('t-chart:edit', listener);
  }, [onOpenEditModal, tbarByIdRes?.tbar_analysis?.id]);

  useTourHandlers(tChartCreationJourney, {
    'step-tchart-update-btn': {
      complete: async () => {
        await updatingTbar({
          ...EXAMPLE_TCHART,
          ...EXAMPLE_T_CHART_UPDATE,
        });
      },
    },
  });

  return (
    <>
      <WrapperLoader loading={allTChartLoading}>
        <div className='p-0'>
          <div className='mb-3'>
            <h3 className='h-toolbar text-md mb-2 border-b border-zinc-100 flex items-center'>T-Chart</h3>
            <div
              onClick={() => onOpenModal()}
              className={classNames(
                'w-full p-2 border border-zinc-200 border-dashed rounded-md text-center cursor-pointer hover:border-blue-400',
                'step-tchart-new-btn'
              )}
            >
              <RoundButton onClickBtn={() => console.log('clicking...')} />
            </div>
          </div>

          <div
            className='overflow-y-hidden hover:overflow-y-auto transition-all'
            style={{ height: 'calc(100vh - 169px)' }}
          >
            <ul className={classNames('mt-3 grid grid-cols-1', 'step-tchart-list-items')}>
              {tChartsitems.map((item: any, index: number) => (
                <div key={index}>
                  <TChartBox
                    totalLength={tChartsitems?.length}
                    key={index}
                    data={item?.tbar_analysis}
                    onEdit={() => onOpenEditModal(item?.tbar_analysis)}
                    onDelete={() => deleteConfirmationModal(item?.tbar_analysis)}
                    deleteLoader={false}
                    selectedData={selectedData}
                    onSelectTChart={() => onSelectedTChart(item?.tbar_analysis)}
                    selectedTChart={selectedTChart}
                  />
                </div>
              ))}
            </ul>
          </div>
        </div>
      </WrapperLoader>

      <CustomDrawer.TChart
        open={open}
        onCloseModal={onCloseModal}
        isEditMode={isEditMode}
        className='tchart-drawer'
      >
        <WrapperLoader loading={tbarByIdLoader}>
          <TBarForm
            onCloseModal={onCloseModal}
            editData={tbarByIdRes?.tbar_analysis}
            onSubmitForm={onSubmitTbar}
            loader={tbarFormLoader}
            editMode={isEditMode}
          />
        </WrapperLoader>
      </CustomDrawer.TChart>

      <ConfirmationModal
        open={deleteConfirmation}
        closingModal={() => closeConfirmationModal()}
        onDeleting={deletingTChart}
        deleteLoader={deleteLoader}
        title='T-Chart Delete Confirmation'
        description={
          <span>
            Are you sure you want to delete <strong>{selectedData?.details?.TBarTitle}</strong> and all its components?
            This change is <strong className='text-red-700'> irreversible</strong>.
          </span>
        }
      />
    </>
  );
};

export default TChartList;
