import { useEffect, useState } from 'react';
import CustomButton from 'src/components/customButton';
import InputField from 'src/components/formFields/inputField';
import AutoResizeTextarea from '../autoResizeTextarea';
import { classNames } from 'src/utils/utilities';
import { useTourHandlers } from 'src/hooks/useUserTour';
import { EXAMPLE_T_CHART_ARGUMENT, tChartCreationJourney } from 'src/utils/user-tour';
import { type } from 'src/utils/userTourUtils';

interface ArgumentFormProps {
  onCloseModal: () => void;
  loading: boolean;
  onSubmitData: (payload: any) => void;
  editData: any;
}

export default function ArgumentForm({ onCloseModal, loading, onSubmitData, editData }: ArgumentFormProps) {
  const [argumentName, setArgumentName] = useState('');
  const [value, setValue] = useState<number>(1);
  const [description, setDescription] = useState('');
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (editData) {
      const { argumentName, argumentWeight, description } = editData;
      if (argumentName) setArgumentName(argumentName);
      if (argumentWeight) setValue(argumentWeight);
      if (description) setDescription(description);
    }
  }, [editData]);

  const onSubmitForm = (e?: React.FormEvent) => {
    e?.preventDefault();

    const payload = {
      argumentName,
      argumentWeight: value,
      description,
    };

    if (argumentName) {
      onSubmitData(payload);
    } else {
      setIsError(true);
    }
  };

  const onChangeArgument = (value: string) => {
    setArgumentName(value);
    setIsError(false);
  };

  const onChangeValue = (value: string) => {
    const data = parseInt(value);
    setValue(data);
  };

  useTourHandlers(tChartCreationJourney, {
    'step-tchart-argument-name-input': {
      beforePrev: () => setArgumentName(''),
      setup: async () => {
        type('#argument', EXAMPLE_T_CHART_ARGUMENT.argumentName).then(() => {
          setArgumentName(EXAMPLE_T_CHART_ARGUMENT.argumentName);
        })
      }
    },
    'step-tchart-argument-description-input': {
      beforePrev: () => setDescription(''),
      setup: async () => {
        type('#argument-description', EXAMPLE_T_CHART_ARGUMENT.description, { typingSpeed: 10 }).then(() => {
          setDescription(EXAMPLE_T_CHART_ARGUMENT.description);
        })
      }
    },
    'step-tchart-argument-weight-select': {
      beforePrev: () => setValue(1),
      setup: async () => {
        setValue(EXAMPLE_T_CHART_ARGUMENT.argumentWeight);
      }
    },
  })

  return (
    <form
      className='flex h-full flex-col'
      onSubmit={onSubmitForm}
    >
      <div
        className={classNames(
          'space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0',
          'step-tchart-argument-name-input'
        )}
      >
        <div>
          <label
            htmlFor='argument-name'
            className='block text-xs font-medium leading-6 text-customDarkBlue'
          >
            Argument
          </label>
        </div>

        <div className='sm:col-span-2'>
          <InputField
            type='text'
            id='argument'
            error={isError}
            name='argument'
            value={argumentName}
            onChange={(e) => onChangeArgument(e.target.value)}
            data-auto-focus
          />
          {isError && <div className='text-red-600 text-xs'>This field cannot be left empty.</div>}
        </div>
      </div>

      <div
        className={classNames(
          'space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 mt-4',
          'step-tchart-argument-description-input'
        )}
      >
        <div>
          <label
            htmlFor='description'
            className='block text-xs font-medium leading-6 text-customDarkBlue'
          >
            Description
          </label>
        </div>

        <div className='sm:col-span-2'>
          <AutoResizeTextarea
            id='argument-description'
            placeholder=''
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      </div>

      <div
        className={classNames(
          'space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 mt-4 items-center',
          'step-tchart-argument-weight-select'
        )}
      >
        <div>
          <label
            htmlFor='argument-value'
            className='block text-xs font-medium leading-6 text-customDarkBlue'
          >
            Value
          </label>
        </div>

        <div className='sm:col-span-2'>
          <div className='flex items-center justify-between'>
            <div className='flex items-center gap-x-2'>
              <input
                type='radio'
                name='valueOptions'
                value='1'
                checked={`${value}` === '1'}
                id='normal'
                onChange={(e) => onChangeValue(e.target.value)}
                className='h-4 w-4 border-gray-300 cursor-pointer focus-visible:ring-primaryLight checked:bg-primary transition-colors'
              />
              <label
                htmlFor='normal'
                className='block text-xs text-customDarkBlue cursor-pointer'
              >
                1-Normal
              </label>
            </div>

            <div className='flex items-center gap-x-2'>
              <input
                id='important'
                name='valueOptions'
                type='radio'
                value='2'
                checked={`${value}` === '2'}
                onChange={(e) => onChangeValue(e.target.value)}
                className='h-4 w-4 border-gray-300 cursor-pointer focus-visible:ring-primaryLight checked:bg-primary transition-colors'
              />
              <label
                htmlFor='important'
                className='block text-xs text-customDarkBlue cursor-pointer'
              >
                2-Important
              </label>
            </div>

            <div className='flex items-center gap-x-2'>
              <input
                id='critical'
                name='valueOptions'
                type='radio'
                value='3'
                checked={`${value}` === '3'}
                onChange={(e) => onChangeValue(e.target.value)}
                className='h-4 w-4 border-gray-300 cursor-pointer focus-visible:ring-primaryLight checked:bg-primary transition-colors'
              />
              <label
                htmlFor='critical'
                className='block text-xs text-customDarkBlue cursor-pointer'
              >
                3-Critical
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className='w-full text-right mt-5'>
        <CustomButton
          text='Cancel'
          type='button'
          onClickBtn={onCloseModal}
          btnStyle='mr-2'
          buttonType='secondary'
        />

        <CustomButton
          text='Save'
          type='submit'
          onClickBtn={() => console.log()}
          loading={loading}
          buttonType='primary'
          btnStyle="step-tchart-argument-save-btn"
        />
      </div>
    </form>
  );
}
