export const protocols_edges_icons = [
  {
    name: "Network Protocols",
    categoryIcon: "https://spnodedata.blob.core.windows.net/nodes/protocol_clean/protocol.svg",
    items: [
      {
        name: "HTTPS",
        url: "https://spnodedata.blob.core.windows.net/nodes/protocol_clean/https.svg",
        type: "Protocol/HTTPS"
      },
      {
        name: "SSH",
        url: "https://spnodedata.blob.core.windows.net/nodes/protocol_clean/ssh.svg",
        type: "Protocol/SSH"
      },
      {
        name: "SSL",
        url: "https://spnodedata.blob.core.windows.net/nodes/protocol_clean/ssl.svg",
        type: "Protocol/SSL"
      }
    ]
  }
];
