import React from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { classNames } from 'src/utils/utilities';

interface RoundButtonProps {
  onClickBtn: () => void;
  asSpan?: boolean;
  btnStyle?: string;
}

export default function RoundButton({ onClickBtn, asSpan = false, btnStyle }: RoundButtonProps) {
  const Element = asSpan ? 'span' : 'button';

  return (
    <Element
      onClick={onClickBtn}
      type={asSpan ? undefined : 'button'}
      className={classNames(
        'inline-block rounded-full bg-blue-500 p-1 text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600',
        btnStyle
      )}
    >
      <PlusIcon
        className='h-4 w-4'
        aria-hidden='true'
      />
    </Element>
  );
}
