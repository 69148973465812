import React, { useCallback, useRef } from "react";
import {
  BaseEdge,
  getBezierPath,
  getSmoothStepPath,
  getStraightPath,
  EdgeLabelRenderer,
  BuiltInNode,
  useReactFlow,
  useStore,
  type Edge,
  type XYPosition,
} from "@xyflow/react"; // Adjust the imports as needed

import {
  ControlPoint,
  type ControlPointData,
} from "../EditableEdge/ControlPoint";
import { getPath, getControlPoints } from "../EditableEdge/path";
import { Algorithm } from "../EditableEdge/constants";

const useIdsForInactiveControlPoints = (points: ControlPointData[]) => {
  const ids = useRef<string[]>([]);

  if (ids?.current?.length === points?.length) {
    return points.map((point, i) =>
      point.id ? point : { ...point, id: ids.current[i] }
    );
  } else {
    ids.current = [];

    return points.map((point, i) => {
      if (!point.id) {
        const id = window.crypto.randomUUID();
        ids.current[i] = id;
        return { ...point, id: id };
      } else {
        ids.current[i] = point.id;
        return point;
      }
    });
  }
};

export type EditableEdge = Edge<{
  algorithm?: Algorithm;
  points: ControlPointData[];
}>;

export function CircleAnimatedEdge({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,

  id,
  selected,
  source,
  target,
  markerStart,
  ...delegated
}: any) {
  let edgePath;

  const sourceOrigin = { x: sourceX, y: sourceY } as XYPosition;
  const targetOrigin = { x: targetX, y: targetY } as XYPosition;

  const { setEdges } = useReactFlow<BuiltInNode, EditableEdge>();
  const shouldShowPoints = useStore((store) => {
    const sourceNode = store.nodeLookup.get(source)!;
    const targetNode = store.nodeLookup.get(target)!;

    return selected || sourceNode.selected || targetNode.selected;
  });

  const setControlPoints = useCallback(
    (update: (points: ControlPointData[]) => ControlPointData[]) => {
      setEdges((edges) =>
        edges.map((e) => {
          if (e.id !== id) return e;
          // if (!isEditableEdge(e)) return e;

          const points = e.data?.points ?? [];
          const data = { ...e.data, points: update(points) };

          return { ...e, data };
        })
      );
    },
    [id, setEdges]
  );

  const pathPoints = [sourceOrigin, ...(data?.points || []), targetOrigin];
  const controlPoints = getControlPoints(pathPoints, data?.algorithm || '', {
    fromSide: sourcePosition,
    toSide: targetPosition,
  });
  const path = getPath(pathPoints, data.algorithm || '', {
    fromSide: sourcePosition,
    toSide: targetPosition,
  });

  const controlPointsWithIds = useIdsForInactiveControlPoints(controlPoints || []);

  // Dynamically select the edge type
  if (data?.edgeType === "straight") {
    edgePath = getStraightPath({
      sourceX,
      sourceY,
      targetX,
      targetY,
    })[0];
  } else if (data?.edgeType === "default") {
    edgePath = getBezierPath({
      sourceX,
      sourceY,
      targetX,
      targetY,
      sourcePosition,
      targetPosition,
    })[0];
  } else if (data?.edgeType === "step") {
    // Manually create step path (a simple polyline for step edges)
    edgePath = `M ${sourceX} ${sourceY} L ${(sourceX + targetX) / 2} ${sourceY} L ${(sourceX + targetX) / 2} ${targetY} L ${targetX} ${targetY}`;
  } else {
    edgePath = getSmoothStepPath({
      sourceX,
      sourceY,
      targetX,
      targetY,
      sourcePosition,
      targetPosition,
    })[0];
  }

  return (
    <>
      <BaseEdge
        path={data?.isEditableEdge ? path : edgePath}
        markerEnd={markerEnd}
        style={style}
        {...delegated}
        id={id}
        markerStart={markerStart}
      />

      {data?.isEditableEdge && (
        <>
          {shouldShowPoints &&
            controlPointsWithIds.map((point, index) => (
              <ControlPoint
                key={point.id}
                index={index}
                setControlPoints={setControlPoints}
                color="grey"
                {...point}
              />
            ))}
        </>
      )}
      <circle r="10" fill={`${data?.color}`}>
        <animateMotion
          dur={`${data?.animationSpeed}s`}
          repeatCount="indefinite"
          path={data?.isEditableEdge ? path : edgePath}
        />
      </circle>
    </>
  );
}

export function ImageOnEdge({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,

  id,
  selected,
  source,
  target,
  markerStart,
  ...delegated
}: any) {
  const sourceOrigin = { x: sourceX, y: sourceY } as XYPosition;
  const targetOrigin = { x: targetX, y: targetY } as XYPosition;

  const { setEdges } = useReactFlow<BuiltInNode, EditableEdge>();
  const shouldShowPoints = useStore((store) => {
    const sourceNode = store.nodeLookup.get(source)!;
    const targetNode = store.nodeLookup.get(target)!;

    return selected || sourceNode.selected || targetNode.selected;
  });

  const setControlPoints = useCallback(
    (update: (points: ControlPointData[]) => ControlPointData[]) => {
      setEdges((edges) =>
        edges.map((e) => {
          if (e.id !== id) return e;
          // if (!isEditableEdge(e)) return e;

          const points = e.data?.points ?? [];
          const data = { ...e.data, points: update(points) };

          return { ...e, data };
        })
      );
    },
    [id, setEdges]
  );

  const pathPoints = [sourceOrigin, ...[data.points || []], targetOrigin];
  const controlPoints = getControlPoints(pathPoints, data.algorithm || '', {
    fromSide: sourcePosition,
    toSide: targetPosition,
  });
  const path = getPath(pathPoints, data.algorithm || '', {
    fromSide: sourcePosition,
    toSide: targetPosition,
  });

  const controlPointsWithIds = useIdsForInactiveControlPoints(controlPoints || []);

  let edgePath: string;
  let labelX: number;
  let labelY: number;

  // Dynamically select the edge type
  if (data?.edgeType === "straight") {
    // Destructure the array returned by getStraightPath
    [edgePath, labelX, labelY] = getStraightPath({
      sourceX,
      sourceY,
      targetX,
      targetY,
    });
  } else if (data?.edgeType === "default") {
    [edgePath, labelX, labelY] = getBezierPath({
      sourceX,
      sourceY,
      targetX,
      targetY,
      sourcePosition,
      targetPosition,
    });
  } else if (data?.edgeType === "step") {
    // Manually create step path (polyline for step edges)
    edgePath = `M ${sourceX} ${sourceY} L ${(sourceX + targetX) / 2} ${sourceY} L ${(sourceX + targetX) / 2} ${targetY} L ${targetX} ${targetY}`;
    labelX = (sourceX + targetX) / 2; // Set label at the midpoint
    labelY = (sourceY + targetY) / 2; // Adjust as needed for label position
  } else {
    // Default to smoothstep
    [edgePath, labelX, labelY] = getSmoothStepPath({
      sourceX,
      sourceY,
      targetX,
      targetY,
      sourcePosition,
      targetPosition,
    });
  }

  // Calculate the center point of the path
  const getCenter = useCallback((path: string) => {
    const pathLength = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "path"
    );
    pathLength.setAttribute("d", path);
    const length = pathLength.getTotalLength();
    const center = pathLength.getPointAtLength(length / 2);
    return { x: center.x, y: center.y };
  }, []);

  // Get center coordinates from the actual path
  const center = getCenter(path);

  return (
    <>
      <BaseEdge
        path={data?.isEditableEdge ? path : edgePath}
        markerEnd={markerEnd}
        style={style}
        {...delegated}
        id={id}
        markerStart={markerStart}
      />

      {data?.isEditableEdge && (
        <>
          {shouldShowPoints &&
            controlPointsWithIds.map((point, index) => (
              <ControlPoint
                key={point.id}
                index={index}
                setControlPoints={setControlPoints}
                color="grey"
                {...point}
              />
            ))}
        </>
      )}
      <EdgeLabelRenderer>
        <div
          className="bg-white border border-zinc-400 p-1 rounded-md shadow-md"
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${data?.isEditableEdge ? center.x - 30 : labelX}px,${data?.isEditableEdge ? center.y - 30 : labelY}px)`,
            zIndex: 9000000000,
          }}
          title={`${data?.label}`}
        >
          <img
            src={`${data?.imgUrl}`}
            alt={`${data?.label}`}
            className="w-[35px]"
          />
        </div>
      </EdgeLabelRenderer>
    </>
  );
}

export function SimpleEdgeWithEditable({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,

  id,
  selected,
  source,
  target,
  markerStart,
  ...delegated
}: any) {
  let edgePath;

  const sourceOrigin = { x: sourceX, y: sourceY } as XYPosition;
  const targetOrigin = { x: targetX, y: targetY } as XYPosition;

  const { setEdges } = useReactFlow<BuiltInNode, EditableEdge>();
  const shouldShowPoints = useStore((store) => {
    const sourceNode = store.nodeLookup.get(source)!;
    const targetNode = store.nodeLookup.get(target)!;

    return selected || sourceNode.selected || targetNode.selected;
  });

  const setControlPoints = useCallback(
    (update: (points: ControlPointData[]) => ControlPointData[]) => {
      setEdges((edges) =>
        edges.map((e) => {
          if (e.id !== id) return e;
          // if (!isEditableEdge(e)) return e;

          const points = e.data?.points ?? [];
          const data = { ...e.data, points: update(points) };

          return { ...e, data };
        })
      );
    },
    [id, setEdges]
  );

  const pathPoints = [sourceOrigin, ...(data?.points || []), targetOrigin];
  const controlPoints = getControlPoints(pathPoints, data?.algorithm || '', {
    fromSide: sourcePosition,
    toSide: targetPosition,
  });
  const path = getPath(pathPoints, data?.algorithm || '', {
    fromSide: sourcePosition,
    toSide: targetPosition,
  });

  const controlPointsWithIds = useIdsForInactiveControlPoints(controlPoints || []);

  // Dynamically select the edge type
  if (data?.edgeType === "straight") {
    edgePath = getStraightPath({
      sourceX,
      sourceY,
      targetX,
      targetY,
    })[0];
  } else if (data?.edgeType === "default") {
    edgePath = getBezierPath({
      sourceX,
      sourceY,
      targetX,
      targetY,
      sourcePosition,
      targetPosition,
    })[0];
  } else if (data?.edgeType === "step") {
    // Manually create step path (a simple polyline for step edges)
    edgePath = `M ${sourceX} ${sourceY} L ${(sourceX + targetX) / 2} ${sourceY} L ${(sourceX + targetX) / 2} ${targetY} L ${targetX} ${targetY}`;
  } else {
    edgePath = getSmoothStepPath({
      sourceX,
      sourceY,
      targetX,
      targetY,
      sourcePosition,
      targetPosition,
    })[0];
  }

  return (
    <>
      <BaseEdge
        path={data?.isEditableEdge ? path : edgePath}
        markerEnd={markerEnd}
        style={style}
        {...delegated}
        id={id}
        markerStart={markerStart}
      />

      {data?.isEditableEdge && (
        <>
          {shouldShowPoints &&
            controlPointsWithIds.map((point, index) => (
              <ControlPoint
                key={point.id}
                index={index}
                setControlPoints={setControlPoints}
                color="grey"
                {...point}
              />
            ))}
        </>
      )}
    </>
  );
}