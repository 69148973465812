import { useContext } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import CustomButton from "../../../components/customButton";
import TextAreaField from "../../../components/formFields/textareaField";
import { classNames } from "../../../utils/utilities";
import { DrawerContext } from "src/contexts/drawer";
import KeyboardShortcut from "src/components/KeyboardShortcut";

interface NodeSettingFormProps {
  onCloseModal?: any;
  onSubmitForm: Function;
  loader: boolean;
}

export default function NodeSettingForm({
  onCloseModal,
  onSubmitForm,
  loader,
}: NodeSettingFormProps) {
  const { width: drawerWidth, insetBlock } = useContext(DrawerContext);

  const validationObject = {
    notes: yup.string().required("Notes is required"),
  };

  const validationSchema = yup.object().shape(validationObject);

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      notes: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const payloadObj = {
        notes: values?.notes,
      };

      onSubmitForm(payloadObj);
      onCloseModal();
    },
  });

  const eachFieldRow =
    "items-start space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5 border-b border-gray-200";

  return (
    <form
      className={classNames(
        "flex flex-col bg-white",
        insetBlock > 0 && "rounded-b-lg",
      )}
      onSubmit={formik.handleSubmit}
    >
      {/* container */}
      <div className="overflow-y-scroll h-[calc(100vh-213px)]">
        {/* Notes */}
        <div className={eachFieldRow}>
          <div className="flex items-center">
            <label
              htmlFor="notes"
              className="block text-xs font-medium leading-6 text-customDarkBlue"
            >
              Notes
            </label>
          </div>
          <div className="sm:col-span-2">
            <TextAreaField
              id="notes"
              {...formik.getFieldProps("notes")}
              placeholder="Enter a brief notes."
            />

            {formik.touched.notes && formik.errors.notes ? (
              <div className="text-red-600 text-xs">{formik.errors.notes}</div>
            ) : null}
          </div>
        </div>
      </div>

      <div
        className={classNames(
          "bg-white border-t border-gray-200 px-4 py-5 sm:px-6",
          insetBlock > 0 ? "rounded-b-lg" : "fixed bottom-0"
        )}
        style={{ width: drawerWidth - 40 }}
      >
        <div className="flex justify-end space-x-3">
          {onCloseModal && (
            <CustomButton
              type="button"
              buttonType="secondary"
              text="Cancel"
              onClickBtn={onCloseModal}
            />
          )}

          <CustomButton
            type="submit"
            onClickBtn={() => console.log("Clicking...")}
            text="Save"
            loading={loader}
            beforeIcon={
              <KeyboardShortcut.ControlEnter
                className="mr-2"
                onTrigger={formik.handleSubmit}
              />
            }
            buttonType="primary"
          />
        </div>
      </div>
    </form>
  );
}
