import { useRef, useState } from 'react';
import { ArrowLongLeftIcon, SparklesIcon, UserGroupIcon, UserIcon } from '@heroicons/react/24/outline';
import CustomModal from '../../../../components/customModal';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { setCurrentAiAssistantTab } from '../../../../redux/userAiAssistants/userAiAssistantsSlice';
import AiAssistantsData, { AssistantsHandle } from '../../../aiAssistants/aiAssistantsData';
import { classNames } from '../../../../utils/utilities';

const aiAssistantsTabsList: any = [
  { id: 2, name: 'My Assistants', current: false, icon: UserIcon },
  { id: 1, name: 'Community', current: false, icon: UserGroupIcon },
];

interface ChatAIModalProps {
  onCloseModal: Function;
  openModal: boolean;
  onUseTemplate: Function;
}

export default function ChatAIModal({ onCloseModal, openModal, onUseTemplate }: ChatAIModalProps) {
  const [showTempDetail, setShowTempDetail] = useState<any>(null);

  const dispatch = useAppDispatch();
  const { currentAiAssistantTab } = useAppSelector((state) => state.userAiAssistants);

  const assistantsHandle = useRef<AssistantsHandle | null>(null);

  return (
    <CustomModal
      open={openModal}
      onCloseModal={() => onCloseModal()}
      title={
        <div className='flex items-center'>
          {showTempDetail && (
            <div
              onClick={() => setShowTempDetail(null)}
              className='mr-3 border px-3 border-gray-200 text-customLightBlue rounded flex items-center justify-center cursor-pointer hover:bg-blue-400 hover:text-white hover:border-gray-800'
            >
              <ArrowLongLeftIcon className='h-4 mr-2' />
              <span>Back</span>
            </div>
          )}
          <SparklesIcon className='h-5 mr-2 text-customLightBlue' />
          AI Assistants Templates
        </div>
      }
      size='max-w-7xl h-[calc(100vh-3rem-41px)]'
    >
      <div className='w-full flex overflow-hidden'>
        <div className='w-[30%] border-r border-zinc-200 pr-4 pt-4 overflow-y-auto'>
          <nav aria-label='Tabs'>
            {aiAssistantsTabsList.map((tab: any) => (
              <div
                key={tab.id}
                onClick={() => {
                  dispatch(setCurrentAiAssistantTab(tab.id));
                  assistantsHandle.current?.closeDetails();
                }}
                className={classNames(
                  tab.id === currentAiAssistantTab
                    ? 'text-blue-500 bg-blue-50'
                    : 'text-gray-500 hover:text-blue-500 hover:bg-blue-50',
                  'rounded-md p-3 text-xs font-medium cursor-pointer mb-3 flex items-center transition-colors'
                )}
              >
                {tab.icon && (
                  <tab.icon
                    className={classNames(
                      tab.id === currentAiAssistantTab ? 'text-blue-500' : 'text-gray-500 group-hover:text-blue-500',
                      'h-6 w-6 mr-2'
                    )}
                  />
                )}
                <span>{tab.name}</span>
              </div>
            ))}
          </nav>
        </div>

        <div className='w-full overflow-y-auto'>
          {(currentAiAssistantTab === 1 || currentAiAssistantTab === 2) && (
            <AiAssistantsData
              isModal={true}
              onUseWithModal={onUseTemplate}
              tempType={(currentAiAssistantTab === 1 && 'community') || (currentAiAssistantTab === 2 && 'my') || ''}
              assistantsHandle={assistantsHandle}
            />
          )}
        </div>
      </div>
    </CustomModal>
  );
}
