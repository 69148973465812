import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import {
  ArrowDownCircleIcon,
  PencilIcon,
  TrashIcon,
  ArrowUpOnSquareStackIcon,
} from '@heroicons/react/24/outline';
import { useAppDispatch, useAppSelector } from '../../hooks';
import WrapperLoader from '../../components/wrapperLoader';
import SearchField from '../../components/searchField';

import TemplateListBox from '../../components/templateBox/templateListBox';
import TemplateDetailPage from '../../components/templateDetailPage';
import {
  getTemplatesList,
  deleteTemplate,
  getTemplateById,
  createProjectFromTemplate,
  getPublicTemplatesList,
  getPublicTemplateById,
  publishProjectTemplate,
  unPublishProjectTemplate,
} from '../../redux/templates/templatesApi';
import CustomButton from '../../components/customButton';
import CustomModal from '../../components/customModal';
import ProjectsSelection from './ProjectsSelectionForm';
import { createConversation } from '../../redux/chatGPT/chatGPTApi';
import CompleteEmptyPage from 'src/components/completeEmptyPage';
import CommunityleftMenu from '../communityPage/CommunityleftMenu';
import { createProject } from '../../redux/projects/projectsApi';
import CreateProjectForm from '../projects/CreationForm';
import ProjectAsTemplateForm from './ProjectAsTemplateForm';
import CustomDrawer from '../../components/customDrawer';
import { documentsList } from '../../utils/appdata';
import ConfirmationModal from '../../components/confirmationModal';
import PublishConfirmation from '../../components/publishConfirmation';
import SaveProjectAsTemplate from './SaveProjectAsTemplate';
import { CategoriesLeftSidebar, CategoriesLeftSidebarProps } from 'src/components/categoriesLeftSidebar';

import designTemplateSVG from '../../assets/empty_state/0-design-templates-empty-state.svg';

import { useAppSearchParams } from 'src/hooks/useAppSearchParams';
import { pipe } from 'src/utils/pipe';
import { clearableCategoriesList } from 'src/utils/utilities';
import { Category } from 'src/type';
import SelectField from 'src/components/formFields/selectField';
import { filter } from 'src/utils/filter';

// const typeFiltersList = [
//   { value: 'all', title: 'Clear', icon: XCircleIcon },
//   { value: 'project', title: 'Project', icon: LightBulbIcon },
//   { value: 'single-document', title: 'Single Document', icon: DocumentIcon },
// ];

const privacyFiltersList = [
  { value: 'all', title: 'Clear' },
  { value: 'private', title: 'Private' },
  { value: 'public', title: 'Public' },
];

interface TemplatesDataProps {
  isModal: boolean;
  onUseWithModal?: Function;
  tempType: string;
  selectedCategory?: Category;
}

interface LeftSideProps {
  selectedMainCategory: Category;
  setSelectedMainCategory: (category: Category) => void;
  checkTemplatesList?: unknown[];
  tempType: string;
  templatesByCategory: CategoriesLeftSidebarProps['groupedItems'];
}

function LeftSide({
  selectedMainCategory,
  setSelectedMainCategory,
  checkTemplatesList,
  tempType,
  templatesByCategory,
}: Readonly<LeftSideProps>) {
  if (tempType !== 'community') {
    // TODO: Remove this block once categories (CommunityleftMenu) are ready
    return null;
  }

  return (
    <div>
      {selectedMainCategory === 'all' && (checkTemplatesList?.length === 0 || !checkTemplatesList) ? (
        ''
      ) : (
        <div className='w-[270px]'>
          {tempType === 'community' ? (
            <div className='bg-white p-6 border-r border-gray-200 h-[calc(100vh_-_49px)]'>
              <CategoriesLeftSidebar
                setSelectedCategory={(category) => setSelectedMainCategory(category)}
                selectedCategory={selectedMainCategory}
                groupedItems={templatesByCategory}
              />
            </div>
          ) : (
            <CommunityleftMenu />
          )}
        </div>
      )}
    </div>
  );
}

const TemplatesData = ({ isModal, onUseWithModal, tempType, selectedCategory }: TemplatesDataProps) => {
  const [showTempDetail, setShowTempDetail] = useState<any>(null);
  const [allTemplatesLoader, setAllTemplatesLoader] = useState(false);
  const [selectProjectLoader, setSelectProjectLoader] = useState(false);
  const [projectSelectionModal, setProjectSelectionModal] = useState(false);
  const [selectedAIChatId, setSelectedAIChatId] = useState('');
  const [formLoader, setFormLoader] = useState(false);
  const [openProject, setOpenProject] = useState(false);
  const [openSaveAsTemp, setOpenSaveAsTemp] = useState(false);
  const [saveAsTempLoader, setSaveAsTempLoader] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [selectedId, setSelectedId] = useState<any>(null);
  const [confirmationLoader, setConfirmationLoader] = useState(false);
  const [publishingLoader, setPublishingLoader] = useState(false);
  const [publishConfirmation, setPublishConfirmation] = useState(false);
  const [tempDetailLoader, setTempDetailLoader] = useState(false);
  const [openCreateProjectFromTemp, setOpenCreateProjectFromTemp] = useState(false);
  const [publishTempModal, setPublishTempModal] = useState(false);
  const [checkTemplatesList, setCheckTemplatesList] = useState<any>([]);

  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { templatesList, getPublicTemplatesListRes } = useAppSelector((state) => state.templates);

  const [{ search, privacy, category }, searchParamsSetter] = useAppSearchParams<{
    search: string;
    privacy: string;
    category: Category;
    type: string;
  }>({ search: '', privacy: 'all', category: 'all', type: 'all' });

  const templates = (tempType === 'my' ? templatesList : getPublicTemplatesListRes) || [];
  const templatesByCategory = filter.groupByCategory(templates);

  const templatesCompleteList = pipe(
    () => templatesByCategory[category] || [] as any[],
    filter.byTitle(search),
    (templates) => {
      if (privacy === 'all' || tempType === 'community') return templates;

      return templates.filter((template: any) => (privacy === 'private' ? template.privacy : !template.privacy));
    },
    (templates) => {
      // TODO: Implement type filter
      return templates;
    }
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          setAllTemplatesLoader(true);

          if (tempType === 'my') await dispatch(getTemplatesList({ accessToken, query: '' }));
          if (tempType === 'community')
            await dispatch(
              getPublicTemplatesList({
                accessToken,
                query: '',
              })
            );

          setAllTemplatesLoader(false);
        }
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempType]);

  useEffect(() => {
    if (tempType === 'my') {
      setCheckTemplatesList(templatesList);
    }
    if (tempType === 'community') {
      setCheckTemplatesList(getPublicTemplatesListRes);
    }
  }, [templatesList, getPublicTemplatesListRes, tempType]);

  const onClosingProjectSelection = () => {
    setSelectedAIChatId('');
    setProjectSelectionModal(false);
  };

  const creatingAIChat = (project_id: string, templateType: string) => {
    const fetchData = async () => {
      try {
        setSelectProjectLoader(true);
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            createConversation({
              body: {},
              accessToken,
              project_id,
              conversation_configuration_id: selectedAIChatId,
              template_type: templateType,
            })
          )
            .then((res) => {
              if (res?.payload) {
                navigate(`/projects/${project_id}`, {
                  state: { conversation: res.payload.data }
                });
                onClosingProjectSelection();
              }
              setSelectProjectLoader(false);
            })
            .catch(() => {
              setSelectProjectLoader(false);
            });
        }
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };
    fetchData();
  };

  const creatingProject = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(createProject({ body, accessToken })).then((res) => {
            if (res) {
              setOpenProject(false);
              setFormLoader(false);
              // navigate(`/projects`);
            }
          });
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const savingDocumentAsTemplateData = (project_id: string, templateType: string) => {
    const fetchData = async () => {
      try {
        setSaveAsTempLoader(true);
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            createConversation({
              body: {},
              accessToken,
              project_id,
              conversation_configuration_id: selectedAIChatId,
              template_type: templateType,
            })
          )
            .then((res) => {
              if (res?.payload) {
                navigate(`/projects/${project_id}`, {
                  state: { conversation: res.payload.data }
                });
              }
              setOpenSaveAsTemp(false);
              setSaveAsTempLoader(false);
            })
            .catch(() => {
              setSaveAsTempLoader(false);
            });
        }
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };
    fetchData();
  };

  const deletingProjectTemplate = () => {
    const fetchData = async () => {
      setConfirmationLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(deleteTemplate({ accessToken, resource_id: selectedId }))
            .then(() => {
              setConfirmationLoader(false);
              deletingModalClose();
              setShowTempDetail(null);
            })
            .catch(() => {
              setConfirmationLoader(false);
            });
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        setConfirmationLoader(false);
      }
    };

    fetchData();
  };

  const gettingProjectTempDetail = () => {
    const fetchData = async () => {
      setTempDetailLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          if (tempType === 'my') {
            await dispatch(getTemplateById({ accessToken, id: showTempDetail?.id }));
          }

          if (tempType === 'community') {
            await dispatch(getPublicTemplateById({ accessToken, id: showTempDetail?.id }));
          }

          setTempDetailLoader(false);
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        setTempDetailLoader(false);
      }
    };

    fetchData();
  };

  const deletingModalOpen = (id: string) => {
    setDeleteConfirm(true);
    setSelectedId(id);
  };

  const deletingModalClose = () => {
    setDeleteConfirm(false);
    setSelectedId(null);
  };

  const publishingTemplate = () => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          setPublishingLoader(true);

          const payload = {
            title: showTempDetail?.title,
            status: showTempDetail?.status,
            category: showTempDetail?.category,
            complexity: showTempDetail?.complexity,
            description: showTempDetail?.description,
          };

          await dispatch(
            publishProjectTemplate({
              accessToken,
              id: showTempDetail?.id,
              body: payload,
            })
          )
            .then((res) => {
              if (res?.payload) {
                setShowTempDetail((prev: any) => {
                  return {
                    ...prev,
                    privacy: true,
                  };
                });

                setPublishConfirmation(false);
              }
              setPublishingLoader(false);
            })
            .catch(() => {
              setPublishingLoader(false);
            });
        }
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };

    fetchData();
  };

  const creatingProjectFromTemp = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            createProjectFromTemplate({
              body,
              accessToken,
              id: showTempDetail?.id,
            })
          ).then((res) => {
            if (res) {
              setOpenCreateProjectFromTemp(false);
              setFormLoader(false);
              // navigate(`/templates`);
            }
          });
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const onUsingTemplate = (data: any) => {
    setShowTempDetail(data);
    setOpenCreateProjectFromTemp(true);
  };

  const publishingProjectTemplate = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      setPublishingLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            publishProjectTemplate({
              body,
              accessToken,
              id: showTempDetail?.id,
            })
          )
            .then((res) => {
              if (res?.payload) {
                setShowTempDetail((prev: any) => {
                  return {
                    ...prev,
                    privacy: false,
                  };
                });

                setPublishTempModal(false);
              }
              setFormLoader(false);
              setPublishingLoader(false);
            })
            .catch(() => {
              setFormLoader(false);
              setPublishingLoader(false);
            });
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const unPublishingTemplate = () => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          setPublishingLoader(true);

          await dispatch(
            unPublishProjectTemplate({
              accessToken,
              id: showTempDetail?.id,
            })
          )
            .then((res) => {
              if (res?.payload) {
                setShowTempDetail((prev: any) => {
                  return {
                    ...prev,
                    privacy: true,
                  };
                });

                setPublishConfirmation(false);
              }
              setPublishingLoader(false);
            })
            .catch(() => {
              setPublishingLoader(false);
            });
        }
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };

    fetchData();
  };

  return (
    <>
      <div className='flex'>
        {!isModal && (
          <LeftSide
            tempType={tempType}
            checkTemplatesList={checkTemplatesList}
            selectedMainCategory={category}
            setSelectedMainCategory={(newCategory) => searchParamsSetter.set('category', newCategory)}
            templatesByCategory={templatesByCategory}
          />
        )}
        <div
          className='px-4'
          style={{
            width: '100%',
          }}
        >
          {!showTempDetail && (
            <div className='py-4 mb-2 border-b border-zinc-100'>
              {' '}
              {category === 'all' && (checkTemplatesList?.length === 0 || !checkTemplatesList) ? (
                ''
              ) : (
                <div className='flex items-center'>
                  <div className='w-80 mr-3'>
                    <SearchField
                      onSearchField={(value: string) => searchParamsSetter.set('search', value)}
                      searchValue={search}
                    />
                  </div>

                  {tempType === 'my' && (
                    <>
                      <div className='mr-3'>
                        <SelectField
                          title="Privacy"
                          options={privacyFiltersList}
                          onSelect={(selectedPrivacy) => searchParamsSetter.set('privacy', selectedPrivacy)}
                          value={privacy}
                          label='Privacy'
                          classes='py-[6px]'
                          containerClasses='w-fit'
                        />
                      </div>
                      <div className='mr-3'>
                        <SelectField
                          title="Category"
                          options={clearableCategoriesList}
                          onSelect={(newCategory) => searchParamsSetter.set('category', newCategory as Category)}
                          value={category}
                          label='Category'
                          classes='py-[6px]'
                          containerClasses='w-fit'
                        />
                      </div>
                    </>
                  )}

                  {/* <div className='mr-3'>
                    <SelectField
                      options={typeFiltersList}
                      onSelect={(newType) => searchParamsSetter.set('type', newType)}
                      value={type}
                      label='Type'
                      classes='py-[6px]'
                      containerClasses='w-fit'
                    />
                  </div> */}
                </div>
              )}
            </div>
          )}

          {showTempDetail && checkTemplatesList?.length > 0 && (
            <div className='mb-4 pb-4 border-b border-gray-200 text-right'>
              <div className='flex items-center justify-between'>
                <div className='flex items-center justify-between w-full'>
                  <>
                    <div className='flex'>
                      <CustomButton
                        text='Delete'
                        type='button'
                        onClickBtn={() => deletingModalOpen(showTempDetail?.id)}
                        beforeIcon={<TrashIcon className='h-4 mr-1' />}
                        loading={false}
                        buttonType="ghost"
                        btnStyle='ml-3'
                      />

                      <CustomButton
                        text={showTempDetail?.privacy === true ? 'Publish' : 'Unpublish'}
                        type='button'
                        onClickBtn={() => {
                          showTempDetail?.privacy === true ? setPublishTempModal(true) : unPublishingTemplate();
                        }}
                        beforeIcon={<ArrowUpOnSquareStackIcon className='h-4 mr-1' />}
                        loading={publishingLoader}
                        buttonType="secondary"
                        btnStyle='ml-3'
                      />

                      <CustomButton
                        text='Use'
                        type='button'
                        onClickBtn={() => setOpenCreateProjectFromTemp(true)}
                        beforeIcon={<ArrowDownCircleIcon className='h-4 mr-1' />}
                        loading={false}
                        buttonType="secondary"
                        btnStyle='whitespace-nowrap ml-3'
                      />

                      <CustomButton
                        text='Edit'
                        type='button'
                        onClickBtn={() => navigate(`/template/${showTempDetail?.id}/edit`)}
                        beforeIcon={<PencilIcon className='h-4 mr-1' />}
                        loading={false}
                        buttonType="secondary"
                        btnStyle='ml-3'
                      />
                    </div>
                  </>
                </div>
              </div>
            </div>
          )}

          <div className='ml-[-4px] mr-[-4px]'>
            {showTempDetail ? (
              <WrapperLoader loading={tempDetailLoader}>
                <TemplateDetailPage
                  gettingProjectTempDetail={gettingProjectTempDetail}
                  tempType={tempType}
                />
              </WrapperLoader>
            ) : (
              <WrapperLoader loading={allTemplatesLoader}>
                <div
                  className='w-full overflow-y-auto'
                  style={{
                    height: isModal ? 'calc(100vh - 250px)' : 'calc(100vh - 130px)',
                  }}
                >
                  {templatesCompleteList?.length > 0 ? (
                    <div className='grid grid-cols-1 [@media(min-width:1250px)]:grid-cols-3 [@media(min-width:1500px)]:grid-cols-4 [@media(min-width:2000px)]:grid-cols-5 gap-4'>
                      {templatesCompleteList?.map((data: any, index: number) => {
                        return (
                          <TemplateListBox
                            key={index}
                            data={data}
                            onShow={() => {
                              isModal
                                ? setShowTempDetail(data)
                                : (tempType === 'my' && navigate(`/template/${data?.id}`)) ||
                                  (tempType === 'community' && navigate(`/community-template/${data?.id}`));
                            }}
                            tempType={tempType}
                            onUse={onUsingTemplate}
                          />
                        );
                      })}
                    </div>
                  ) : (
                    <div
                      className='flex items-center justify-center'
                      style={{ height: 'calc(100vh - 110px)' }}
                    >
                      <CompleteEmptyPage
                        title='Approach documenting strategically'
                        subTitle='Any project you create, any document you create can always be saved as a template for future re-use. From here you can also publish your work and share it with comunity. To create a new template start with creating a new project.'
                        btnText='New Template'
                        onClickBtn={null}
                        icon={designTemplateSVG}
                        imageHeight='300px'
                      />
                    </div>
                  )}
                </div>
              </WrapperLoader>
            )}
          </div>
        </div>
      </div>

      <CustomModal
        open={projectSelectionModal}
        onCloseModal={() => onClosingProjectSelection()}
        title={
          <div>
            <h1 className='font-semibold text-lg mb-1'>Create a new Chat</h1>
            <p className='text-xs font-normal'>Select project and start a new chat</p>
          </div>
        }
        size='max-w-lg'
      >
        <ProjectsSelection
          onCloseModal={() => onClosingProjectSelection()}
          loading={selectProjectLoader}
          onSubmitData={creatingAIChat}
          templateType='solutionPilot'
        />
      </CustomModal>

      <CustomDrawer.Project
        open={openProject}
        onCloseModal={() => setOpenProject(false)}
      >
        <CreateProjectForm
          onCloseModal={() => setOpenProject(false)}
          editData={{
            title: showTempDetail?.title,
            status: 'Not Started',
            category: showTempDetail?.category,
          }}
          onSubmitForm={creatingProject}
          loader={formLoader}
          editMode={true}
          documentsList={documentsList}
        />
      </CustomDrawer.Project>

      <CustomModal
        open={openSaveAsTemp}
        onCloseModal={() => setOpenSaveAsTemp(false)}
        title={<h1 className='font-semibold'>Save Document as Template</h1>}
        size='max-w-lg'
      >
        <ProjectAsTemplateForm
          onCloseModal={() => setOpenSaveAsTemp(false)}
          loading={saveAsTempLoader}
          onSubmitData={savingDocumentAsTemplateData}
          templateType='community'
          titleValue={showTempDetail?.title}
        />
      </CustomModal>

      <ConfirmationModal
        open={deleteConfirm}
        closingModal={() => deletingModalClose()}
        onDeleting={deletingProjectTemplate}
        deleteLoader={confirmationLoader}
      />

      <PublishConfirmation
        open={publishConfirmation}
        closingModal={() => setPublishConfirmation(false)}
        onClickYes={showTempDetail?.privacy === true ? () => publishingTemplate() : () => unPublishingTemplate()}
        loading={publishingLoader}
        privacy={showTempDetail?.privacy}
      />

      <CustomDrawer.SaveProjectAsTemplate
        open={openCreateProjectFromTemp}
        onCloseModal={() => setOpenCreateProjectFromTemp(false)}
      >
        <SaveProjectAsTemplate
          onCloseModal={() => setOpenCreateProjectFromTemp(false)}
          onSubmitForm={creatingProjectFromTemp}
          loader={formLoader}
          projectData={showTempDetail}
        />
      </CustomDrawer.SaveProjectAsTemplate>

      <CustomDrawer
        open={publishTempModal}
        onCloseModal={() => setPublishTempModal(false)}
        title='Publish Template'
        description='You can Publish your template for public'
      >
        <SaveProjectAsTemplate
          onCloseModal={() => setPublishTempModal(false)}
          onSubmitForm={publishingProjectTemplate}
          loader={formLoader}
          projectData={showTempDetail}
        />
      </CustomDrawer>
    </>
  );
};

export default TemplatesData;
