import { CubeIcon, DocumentTextIcon } from '@heroicons/react/24/outline';
import { ActionGroupProps } from 'src/components/itemWithDropdown';
import type { ConversationContext } from 'src/type';
import { DefaultDropdownRenderer } from 'src/components/toolbar';

export const QUICK_CHAT_ASSISTANT = {
  agentName: '',
  chatTitle: 'Quick Chat',
  chatDescription: '',
  chatType: 'quick-chat',
  autoSendMessage: false
};

const specialChatAssistants = {
  document: {
    icon: DocumentTextIcon,
    validations: {
      limitations: {
        agentName: 'documents-limitations-checker',
        callToAction: 'Check for limitations',
        chatTitle: 'Document Limitations Checker',
        chatDescription: 'Evaluating technology and service offerings limitations in the provided document',
        userMessage: 'Check for technology and service offerings limitations in:',
      },
      improvements: {
        agentName: 'documents-improvment-checker',
        callToAction: 'Check for improvements',
        chatTitle: 'Document Improvements Checker',
        chatDescription: 'Exploring potential improvements in the provided document',
        userMessage: 'Explore potential improvements in:',
      },
    },
  },
  diagram: {
    icon: CubeIcon,
    validations: {
      limitations: {
        agentName: 'diagram-validator-service-limitations',
        callToAction: 'Service Limitations',
        chatTitle: 'Service Limitations Checker',
        chatDescription: 'Summarizing technical limitations of services described in the diagram.',
        userMessage: 'Diagram:',
      },
      designLimitations: {
        agentName: 'diagram-validator-design-limitations',
        callToAction: 'Design Limitations',
        chatTitle: 'Diagram Design Limitations Checker',
        chatDescription: 'Summarizing technical limitations of the design of the diagram.',
        userMessage: 'Diagram:',
      },
      reliability: {
        agentName: 'diagram-validator-reliability',
        callToAction: 'Reliability',
        chatTitle: 'Diagram Reliability Checker',
        chatDescription: 'Validating the integrity of the provided diagram from technical perspective',
        userMessage: 'Diagram:',
      },
      security: {
        agentName: 'diagram-validator-security',
        callToAction: 'Security',
        chatTitle: 'Diagram Security Checker',
        chatDescription: 'Validating the integrity of the provided diagram from security perspective',
        userMessage: 'Diagram:',
      },
      cost: {
        agentName: 'diagram-validator-cost-optimization',
        callToAction: 'Cost Optimization',
        chatTitle: 'Diagram Cost Optimization Checker',
        chatDescription: 'Validating the integrity of the provided diagram from cost perspective',
        userMessage: 'Diagram:',
      },
      performance: {
        agentName: 'diagram-validator-performance',
        callToAction: 'Performance',
        chatTitle: 'Diagram Performance Checker',
        chatDescription: 'Validating the integrity of the provided diagram from performance perspective',
        userMessage: 'Diagram:',
      },
      operationalExcellence: {
        agentName: 'diagram-validator-operational-excellence',
        callToAction: 'Operational Excellence',
        chatTitle: 'Diagram Operational Excellence Checker',
        chatDescription: 'Validating the integrity of the provided diagram from operational excellence perspective',
        userMessage: 'Diagram:',
      },
      // testValidator1: {
      //   agentName: 'diagram-validator',
      //   callToAction: 'Test validator 1',
      //   chatTitle: 'Diagram Test Validator 1',
      //   chatDescription: 'Validating the integrity of the provided diagram from test validator 1 perspective',
      //   userMessage: 'Validate the integrity of the provided diagram:',
      //   dropdownGroup: 'Test group',
      // },
      // testValidator2: {
      //   agentName: 'diagram-validator',
      //   callToAction: 'Test validator 2',
      //   chatTitle: 'Diagram Test Validator 2',
      //   chatDescription: 'Validating the integrity of the provided diagram from test validator 2 perspective',
      //   userMessage: 'Validate the integrity of the provided diagram:',
      //   dropdownGroup: 'Test group',
      // },
    },
  },
};

export type SpecialChatAssistantType = typeof specialChatAssistants;

export function getSpecialChatAssistant<
  G extends keyof SpecialChatAssistantType,
  V extends keyof SpecialChatAssistantType[G]['validations'] & string,
>(type: `${G}:${V}`): ConversationContext | null {
  const [group, validator] = type.split(':') as [G, V];
  const groupData = specialChatAssistants[group];
  const validatorData = (groupData as any)?.['validations']?.[validator];

  if (!validatorData) return null;

  return {
    ...validatorData,
    groupName: group,
    validatorName: validator,
    chatType: type,
    icon: groupData.icon,
  } satisfies ConversationContext;
}

export function getValidateList<G extends keyof SpecialChatAssistantType>(
  groupName: G,
  callback: (context: ConversationContext) => void
): Array<ActionGroupProps> {
  const { validations } = specialChatAssistants[groupName];
  const validationEntries = Object.entries(validations);

  // Check if any validation has a dropdownGroup
  const hasDropdownGroups = validationEntries.some(([_, context]) => 'dropdownGroup' in context);

  // If no dropdown groups exist, return simple array of actions
  if (!hasDropdownGroups) {
    return validationEntries.map(([validatorName, context], index) => {
      const type = `${groupName}:${validatorName}`;
      const conversationContext = getSpecialChatAssistant(type as any) as ConversationContext;
      return {
        id: index + 1,
        name: context.callToAction,
        onActionClick: () => callback(conversationContext),
      };
    });
  }

  // Process entries with groups as before
  const result: ActionGroupProps[] = [];
  const groupsInProgress: Record<string, ActionGroupProps> = {};

  validationEntries.forEach(([validatorName, context]) => {
    const dropdownGroup = 'dropdownGroup' in context ? context.dropdownGroup as string : null;
    const type = `${groupName}:${validatorName}`;
    const conversationContext = getSpecialChatAssistant(type as any) as ConversationContext;
    const action = {
      id: result.length + 1,
      name: context.callToAction,
      onActionClick: () => callback(conversationContext),
    };

    if (!dropdownGroup) {
      result.push(action);

      return;
    }

    if (!groupsInProgress[dropdownGroup]) {
      groupsInProgress[dropdownGroup] = {
        id: result.length + 1,
        name: dropdownGroup,
        actions: [],
        renderInToolbar: () => null,
        renderInDropdown: DefaultDropdownRenderer,
      };
      result.push(groupsInProgress[dropdownGroup]);
    }

    groupsInProgress[dropdownGroup].actions?.push(action);
  });

  return result;
}

export function isValidationChat(chatType: string): boolean {
  return !(chatType === 'chat' || chatType === 'quick-chat');
}
