import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch } from "../../../../hooks";
import {
  getDecisionMatrixList,
  createDecisionMatrix,
} from "../../../../redux/decisionMatrix/decisionMatrixApi";
import CustomDrawer from "../../../../components/customDrawer";
import CreationForm from "../../../decisionMatrix/CreationForm";
import CompleteEmptyPage from "../../../../components/completeEmptyPage";
import { getLastIdFromUrl } from "../../../../utils/utilities";

import decisionMatrixSvg from '../../../../assets/empty_state/0-decisions-matrix-empty-state.svg';

const DecisionMatrixEmpty = () => {
  const [open, setOpen] = useState(false);
  const [formLoader, setFormLoader] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();

  const onCloseModal = () => {
    setOpen(false);
  };

  const onOpenCreateModal = () => {
    setOpen(true);
  };

  const creatingDecisionMatrix = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            createDecisionMatrix({
              body,
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
          onCloseModal();
          setFormLoader(false);

          await dispatch(
            getDecisionMatrixList({
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const onSubmitDecisionMatrix = async (body: any) => {
    creatingDecisionMatrix(body);
  };

  return (
    <>
      <div
        className="flex items-center justify-center"
        style={{ height: "calc(100vh - 140px)" }}
      >
        <CompleteEmptyPage
          title="You don't have any Decision Matrix"
          subTitle="Get started by creating a new Decision Matrix"
          btnText="Create Decision Matrix"
          onClickBtn={onOpenCreateModal}
          icon={decisionMatrixSvg}
          imageHeight='250px'
        />
      </div>

      <CustomDrawer.DecisionMatrix
        open={open}
        onCloseModal={onCloseModal}
      >
        <CreationForm
          onCloseModal={onCloseModal}
          editData={null}
          onSubmitForm={onSubmitDecisionMatrix}
          loader={formLoader}
          editMode={false}
        />
      </CustomDrawer.DecisionMatrix>
    </>
  );
};

export default DecisionMatrixEmpty;
