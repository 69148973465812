import { useState, useEffect, useMemo, SetStateAction, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Analysis from './Analysis';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getTbarById } from '../../redux/tbars/tbarsApi';
import { getLastIdFromUrl, parseJSONFromAi, toCamelCase } from '../../utils/utilities';
import WrapperLoader from '../../components/wrapperLoader';
import TChartBar from './Toolbar';
import {
  createTbarArgument,
  deleteTbarArgument,
  getTbarArgumentsList,
  updateTbarArgument,
} from 'src/redux/tbarsArguments/tbarsArgumentsApi';
import { promptMessage } from 'src/redux/chatGPT/chatGPTApi';
import * as yup from 'yup';
import { nanoid } from '@reduxjs/toolkit';
import { useTourHandlers } from 'src/hooks/useUserTour';
import {
  EXAMPLE_T_CHART_SUGGESTIONS_A,
  EXAMPLE_T_CHART_SUGGESTIONS_B,
  tChartCreationJourney,
} from 'src/utils/user-tour';
import { wait, waitForElement } from 'src/utils/userTourUtils';
import { useUserTour } from 'src/contexts/userTour/UserTour.context';

const AiReplySchema = (() => {
  const argumentSchema = yup.object().shape({
    argumentName: yup.string().required(),
    argumentWeight: yup.number().oneOf([1, 2, 3]).required(),
    explanation: yup.string().required(),
  });

  return yup.object().shape({
    optionAList: yup.array().required().of(argumentSchema),
    optionBList: yup.array().required().of(argumentSchema),
  });
})();

export default function TBarDetails() {
  const [tbarByIdLoader, setTbarByIdLoader] = useState(false);
  const [optionAList, setOptionAList] = useState<any[] | null>(null);
  const [optionBList, setOptionBList] = useState<any[] | null>(null);

  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { tbarByIdRes, selectedTChart } = useAppSelector((state) => state.tbars);
  const { setTourLoading, paramsRef: tourParamsRef } = useUserTour();

  const detailsData = tbarByIdRes?.tbar_analysis;
  const { optionA, optionB } = useMemo(() => {
    const optionA = detailsData?.options[0];
    const optionB = detailsData?.options[1];

    return {
      optionA: optionA ? { ...optionA, type: 'optionA' } : null,
      optionB: optionB ? { ...optionB, type: 'optionB' } : null,
    };
  }, [detailsData]);
  const isTChartReady = !!(detailsData && (optionA || optionB) && optionAList && optionBList);

  const setArgumentsList = useCallback((type: string, callback: SetStateAction<any[] | null>) => {
    if (type === 'optionA') {
      setOptionAList(callback);
    }

    if (type === 'optionB') {
      setOptionBList(callback);
    }
  }, []);

  useEffect(() => {
    if (selectedTChart) {
      const fetchData = async () => {
        setTbarByIdLoader(true);
        try {
          const accessToken = await getAccessTokenSilently();
          if (accessToken)
            await dispatch(
              getTbarById({
                accessToken,
                tbarId: selectedTChart?.id,
                project_id: getLastIdFromUrl(window.location.pathname),
              })
            );
          setTbarByIdLoader(false);
        } catch (error) {
          console.error('Error getting access token:', error);
          setTbarByIdLoader(false);
        }
      };

      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTChart?.id]);

  useEffect(() => {
    const gettingArgumentsList = async (accessToken: string, option_id: string, type: string) => {
      await dispatch(
        getTbarArgumentsList({
          accessToken,
          option_id,
          tbar_id: detailsData?.id,
          project_id: getLastIdFromUrl(window.location.pathname),
        })
      )
        .then((res: any) => {
          setArgumentsList(type, res?.payload?.data?.map((item: any) => ({ ...item, source: 'user' })) || []);
        })
        .catch((error: unknown) => console.log('error ==>', error));
    };

    if (optionA?.option_id || optionB?.option_id) {
      const fetchData = async () => {
        try {
          const accessToken = await getAccessTokenSilently();
          if (accessToken) {
            const promises = [];

            if (optionA?.option_id) {
              promises.push(gettingArgumentsList(accessToken, optionA?.option_id, 'optionA'));
            }

            if (optionB?.option_id) {
              promises.push(gettingArgumentsList(accessToken, optionB?.option_id, 'optionB'));
            }

            await Promise.all(promises);
          }
        } catch (error) {
          console.error('Error getting access token:', error);
        }
      };

      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionA, optionB]);

  const updateArgument = useCallback(
    async (body: any, selectedOption: any, selectedArgument: any) => {
      try {
        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
          throw new Error('No access token');
        }

        const res = await dispatch(
          updateTbarArgument({
            body,
            accessToken,
            argument_id: selectedArgument?.id,
            option_id: selectedOption?.option_id,
            tbar_id: detailsData?.id,
            project_id: getLastIdFromUrl(window.location.pathname),
          })
        );

        const requestStatus = res?.meta?.requestStatus;

        if (requestStatus === 'rejected') {
          throw new Error('Request rejected');
        }

        const data = res?.payload?.data;

        if (!data) {
          throw new Error('No data');
        }

        setArgumentsList(selectedOption?.type, (current) => {
          if (!current) return null;

          const index = current.findIndex((item: any) => item.id === data.id);

          if (index === -1) {
            return current;
          }

          const updatedList = [...current];
          updatedList.splice(index, 1, data);

          return updatedList;
        });
      } catch (error) {
        console.error('Error updating argument:', error);

        throw error;
      }
    },
    [detailsData?.id, dispatch, getAccessTokenSilently, setArgumentsList]
  );

  const createArgument = useCallback(
    async (argument: any, selectedOption: any) => {
      try {
        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
          throw new Error('No access token');
        }

        const { source = 'user', id: argumentId, ...body } = argument;

        const res = await dispatch(
          createTbarArgument({
            body,
            accessToken,
            tbar_id: detailsData?.id,
            option_id: selectedOption?.option_id,
            project_id: getLastIdFromUrl(window.location.pathname),
          })
        );

        const requestStatus = res?.meta?.requestStatus;

        if (requestStatus === 'rejected') {
          throw new Error('Request rejected');
        }

        const data = toCamelCase(res?.payload?.data);

        if (!data) {
          throw new Error('No data');
        }

        setArgumentsList(selectedOption?.type, (current) => {
          if (source === 'user') {
            return [...(current || []), { ...data, source: 'user' }];
          }

          const index = current?.findIndex((item: any) => item.id === argumentId) ?? -1;

          if (index === -1) {
            return current;
          }

          const updatedList = [...(current || [])];
          updatedList.splice(index, 1, { ...data, source: 'user' });

          return updatedList;
        });

        return data;
      } catch (error) {
        console.error('Error creating argument:', error);

        throw error;
      }
    },
    [detailsData?.id, dispatch, getAccessTokenSilently, setArgumentsList]
  );

  const deleteArgument = useCallback(
    async (selectedOption: any, selectedArgument: any) => {
      if (selectedArgument.source === 'ai') {
        return setArgumentsList(
          selectedOption?.type,
          (current) => current?.filter((item: any) => item.id !== selectedArgument.id) || null
        );
      }

      try {
        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
          throw new Error('No access token');
        }

        const res = await dispatch(
          deleteTbarArgument({
            accessToken,
            argument_id: selectedArgument?.id,
            option_id: selectedOption?.option_id,
            tbar_id: detailsData?.id,
            project_id: getLastIdFromUrl(window.location.pathname),
          })
        );

        const requestStatus = res?.meta?.requestStatus;

        if (requestStatus === 'rejected') {
          throw new Error('Request rejected');
        }

        const argumentId = res?.payload?.data?.id;

        if (!argumentId) {
          throw new Error('No argument id');
        }

        setArgumentsList(
          selectedOption?.type,
          (current) => current?.filter((item: any) => item.id !== argumentId) || null
        );
      } catch (error) {
        console.error('Error deleting argument:', error);

        throw error;
      }
    },
    [detailsData?.id, dispatch, getAccessTokenSilently, setArgumentsList]
  );

  const generateArgumentsSuggestions = useCallback(async () => {
    if (!isTChartReady) return;

    try {
      const accessToken = await getAccessTokenSilently();

      if (!accessToken) {
        throw new Error('No access token');
      }

      const res = await dispatch(
        promptMessage({
          body: {
            prompt: JSON.stringify({
              title: detailsData?.details?.TBarTitle,
              category: detailsData?.details?.TBarCategory,
              description: detailsData?.details?.TBarDescription,
              optionA: {
                title: optionA?.option_title,
                arguments: optionAList?.map((arg) => arg?.argumentName) ?? [],
              },
              optionB: {
                title: optionB?.option_title,
                arguments: optionBList?.map((arg) => arg?.argumentName) ?? [],
              },
            }),
          },
          accessToken,
          type: 'tchart-generator',
        })
      );

      const requestStatus = res?.payload?.requestStatus;
      const reply = res?.payload?.data?.message;

      if (requestStatus === 'rejected') {
        throw new Error('Request rejected');
      }

      const replyData = parseJSONFromAi(reply);

      const { optionAList: aiOptionAList, optionBList: aiOptionBList } = await AiReplySchema.validate(replyData);

      setOptionAList((current) => [
        ...(current || []),
        ...aiOptionAList.map((argument: any) => ({ ...argument, id: nanoid(), source: 'ai' })),
      ]);
      setOptionBList((current) => [
        ...(current || []),
        ...aiOptionBList.map((argument: any) => ({ ...argument, id: nanoid(), source: 'ai' })),
      ]);
    } catch (error) {
      console.error('Error generating arguments suggestions:', error);

      throw error;
    }
  }, [
    detailsData,
    dispatch,
    getAccessTokenSilently,
    isTChartReady,
    optionA?.option_title,
    optionAList,
    optionB?.option_title,
    optionBList,
  ]);

  useTourHandlers(tChartCreationJourney, {
    'step-tchart-generate-ai-btn': {
      setup: () => {
        if (!tourParamsRef.current.aiGenerated) return;

        setOptionAList((current) => current?.filter((item: any) => item?.source !== 'ai') || null);
        setOptionBList((current) => current?.filter((item: any) => item?.source !== 'ai') || null);
        delete tourParamsRef.current.aiGenerated;
      },
      complete: async () => {
        setTourLoading(true);
        await wait(1000);

        setOptionAList((current) => [
          ...(current || []),
          ...EXAMPLE_T_CHART_SUGGESTIONS_A.map((argument: any) => ({ ...argument, source: 'ai' })),
        ]);
        setOptionBList((current) => [
          ...(current || []),
          ...EXAMPLE_T_CHART_SUGGESTIONS_B.map((argument: any) => ({ ...argument, source: 'ai' })),
        ]);
        setTourLoading(false);

        tourParamsRef.current.aiGenerated = 'true';
      },
      beforeNext: async () => {
        await waitForElement('.step-tchart-suggestion-argument');
      },
    },
    'step-tchart-suggestion-argument': {
      complete: async () => {
        const [{ id: firstSuggestionId }] = EXAMPLE_T_CHART_SUGGESTIONS_B;
        const argument = optionBList?.find((item: any) => item.id === firstSuggestionId);

        if (!argument) {
          throw new Error('Argument not found');
        }

        const { id, argumentName: argument_name, argumentWeight: argument_weight, explanation: description } = argument;

        setTourLoading(true);
        await createArgument({ id, argument_name, argument_weight, description, source: 'ai' }, optionB);
        setTourLoading(false);
      },
    },
    'step-tchart-suggestion-reject': {
      complete: async () => {
        const { id: firstSuggestionId } = EXAMPLE_T_CHART_SUGGESTIONS_A[0];
        const argument = optionAList?.find((item: any) => item.id === firstSuggestionId);

        if (!argument) {
          throw new Error('Argument not found');
        }

        deleteArgument(optionA, argument);
        await wait(300);
      },
    },
  });

  return (
    <WrapperLoader
      loading={tbarByIdLoader}
      className='bg-customGray'
    >
      <TChartBar
        isTChartReady={isTChartReady}
        generateArgumentsSuggestions={generateArgumentsSuggestions}
      />
      <div className='p-6 pb-0'>
        <div>
          <Analysis
            detailsData={detailsData}
            optionA={optionA}
            optionB={optionB}
            optionAList={optionAList}
            optionBList={optionBList}
            updateArgument={updateArgument}
            createArgument={createArgument}
            deleteArgument={deleteArgument}
          />
        </div>
      </div>
    </WrapperLoader>
  );
}
