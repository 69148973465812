import React, { useMemo, useEffect, useState } from 'react';
import Markdown, { type Components } from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import BlockAction from './blockAction';
import CustomButton from '../customButton';
import { classNames } from 'src/utils/utilities';
import { useRegisterTourHandle } from 'src/hooks/useUserTour';

interface MarkdownRendererProps {
  content?: string;
  addItemToTextEditor: Function;
  isStreamTarget?: boolean;
}

function PatchButton({
  serializedPatches,
  isStreamTarget = false,
}: Readonly<{ serializedPatches: string; isStreamTarget?: boolean }>) {
  const [isLoading, setIsLoading] = useState(isStreamTarget);

  const patches = useMemo(() => {
    try {
      const parsedPatches = JSON.parse(serializedPatches);

      return Array.isArray(parsedPatches) ? parsedPatches : [];
    } catch (error) {
      return [];
    }
  }, [serializedPatches]);

  useEffect(() => {
    if (!isStreamTarget) return;

    setIsLoading(true);

    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [serializedPatches, isStreamTarget]);

  const handleClick = () => {
    document.dispatchEvent(new CustomEvent('diagram:patch', { detail: { patches } }));
  };

  useRegisterTourHandle('PatchButton', {
    applyPatches: handleClick,
  });

  return (
    <CustomButton
      text={isLoading ? 'Processing...' : 'Apply changes'}
      onClickBtn={handleClick}
      type='button'
      btnStyle={classNames('not-prose !my-2 diagram-patches-button', !isLoading && 'ready')}
      loading={isLoading}
    />
  );
}

const remarkPlugins = [remarkGfm];

const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({ content = '', addItemToTextEditor, isStreamTarget }) => {
  const components: Components = useMemo(
    () => ({
      code({ children, className, ...rest }) {
        const match = /language-(\w+)/.exec(className || '');

        if (!match) {
          return (
            <code
              {...rest}
              className={`${className} break-words`}
            >
              {children}
            </code>
          );
        }

        if (match[1] === 'patch' && typeof children === 'string') {
          return (
            <PatchButton
              serializedPatches={children}
              isStreamTarget={isStreamTarget}
            />
          );
        }

        return (
          <div className='relative group not-prose'>
            <SyntaxHighlighter
              {...(rest as any)}
              PreTag='div'
              children={String(children).replace(/\n$/, '')}
              language={match[1]}
              style={okaidia}
            />

            <BlockAction
              textToCopy={String(children).replace(/\n$/, '')}
              addItemToTextEditor={() => addItemToTextEditor(String(children).replace(/\n$/, ''))}
            />
          </div>
        );
      },
      p({ children }) {
        if (typeof children !== 'string') {
          return <>{children}</>;
        }

        const regex = /==(.*?)==/g;
        const parts = children.split(regex).map((part, index) => {
          if (index % 2 === 0) return part;

          return part.replace(/^==|==$/, '');
        });

        return (
          <p>
            {parts.map((part, index) =>
              index % 2 === 1 ? (
                <span
                  key={index}
                  className='bg-yellow-200 px-1'
                >
                  {part}
                </span>
              ) : (
                part
              )
            )}
          </p>
        );
      },
      a({ href, children }) {
        return (
          <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
          >
            {children}
          </a>
        );
      },
    }),
    [addItemToTextEditor, isStreamTarget]
  );

  return (
    <Markdown
      remarkPlugins={remarkPlugins}
      components={components}
      className='prose'
    >
      {content}
    </Markdown>
  );
};

export default MarkdownRenderer;
