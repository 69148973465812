import { useState, useEffect } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import ItemWithDropdown from '../itemWithDropdown';
interface Option {
  value: string;
  title?: string;
  icon?: any;
  placeholder?: boolean;
}

interface SelectFieldProps {
  title?: string;
  options: Option[];
  value?: string; // Optional default value prop
  onSelect: (value: string) => void;
  classes?: string;
  containerClasses?: string;
  label?: string;
}

const EMPTY_OBJECT = {};

const SelectField: React.FC<SelectFieldProps> = ({ title, options, value, onSelect, label, classes, containerClasses }) => {
  const [selectedOption, setSelectedOption] = useState(value || options[0].value);

  useEffect(() => {
    if (value !== undefined) {
      setSelectedOption(value)
    }
  }, [value])

  const longestTitle = options.reduce((longest, option) => {
    const title = option.title || option.value;

    return title.length > longest.length ? title : longest;
  }, label || 'All');

  const SelectedIcon = options.find((option) => option.value === selectedOption)?.icon;
  const selectedTitle = options.find((option) => option.value === selectedOption)?.title || value;

  const actionsData = options.map((option, index) => ({
    id: index,
    name: option.title || option.value,
    icon: option.icon && (
      <option.icon className="mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue" />
    ),
    onActionClick: () => {
      setSelectedOption(option.value);
      onSelect(option.value);
    },
    optionData: option,
  }));

  return (
    <div className='relative'>
      <ItemWithDropdown
        title={title}
        actionsData={actionsData}
        className={containerClasses}
        buttonClassName={`inline-flex w-full justify-between items-center gap-1 rounded-md bg-white px-3 py-2 text-xs text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 ${classes}`}
        dropdownClassName='left-0 right-auto'
        transitionClasses={EMPTY_OBJECT}
      >
        {selectedOption === 'all' ? (
          <div
            data-title={longestTitle}
            className='relative capitalize after:content-[attr(data-title)] after:invisible pl-6'
          >
            <span className='absolute top-0 left-0'>{label || 'All'}</span>
          </div>
        ) : (
          <div className='flex items-center'>
            {SelectedIcon && <SelectedIcon className='h-4 w-4 text-gray-400 group-hover:text-gray-500 mr-2' />}
            <div
              data-title={longestTitle}
              className='relative capitalize after:content-[attr(data-title)] after:invisible'
            >
              <span className='absolute top-0 left-0'>{selectedTitle}</span>
            </div>
          </div>
        )}
        <ChevronDownIcon
          aria-hidden='true'
          className='h-5 w-5 text-gray-400'
        />
      </ItemWithDropdown>
    </div>
  );
};

export default SelectField;
