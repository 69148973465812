import { deleteRequest, getRequest, postRequest, putRequest } from '../../utils/api';

import { createAsyncThunk } from '@reduxjs/toolkit';

export const sendChatGPTMessage = createAsyncThunk(
  'sendChatGPTMessage/Create',
  async ({ body, accessToken, message }: any) => {
    try {
      const response = await postRequest({
        url: `/chatRequest`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      response.question = message;

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const getAllMessages = createAsyncThunk(
  'getAllMessagess/get',
  async ({ accessToken, project_id, conversation_id }: any) => {
    try {
      const response = await getRequest({
        url: `/aoaiCompletionHistory?project_id=${project_id}&conversation_id=${conversation_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const getAllThreadMessages = createAsyncThunk(
  'getAllThreadMessagess/get',
  async ({ accessToken, thread_id }: any) => {
    try {
      const response = await getRequest({
        url: `/ai/assistantStreamHistory?thread_id=${thread_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response) return;

      return {
        ...response,
        data: response.messages,
      };
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const promptMessage = createAsyncThunk(
  'promptMessage/Create',
  async ({ body, accessToken, type, signal }: any) => {
    try {
      const response = await postRequest({
        // url: `/aoaiCompletion?project_id=${project_id}&conversation_id=${conversation_id}`,
        url: `/agentCompletion?assistant=${type}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        signal,
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const promptStreamMessage = createAsyncThunk(
  'promptMessage/Create',
  async ({ body, accessToken, project_id, conversation_id }: any) => {
    try {
      const response = await postRequest({
        url: `/aoaiCompletionStream?project_id=${project_id}&conversation_id=${conversation_id}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const promptStreamMessageThread = createAsyncThunk<
  any,
  {
    body: {
      prompt: string;
    };
    accessToken: string;
    thread_id: string;
    project_id: string;
    conversation_id: string;
    agent_name: string;
  }
>('promptMessage/Create', async ({ body, accessToken, thread_id, project_id, conversation_id, agent_name }) => {
  const params = new URLSearchParams({
    thread_id,
    project_id,
    conversation_id,
    assistant: agent_name,
  });

  try {
    const response = await postRequest({
      url: `/ai/assistantStreamRequest?${params.toString()}`,
      payload: body,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response;
  } catch (error: any) {
    throw error.response;
  }
});

export const createConversation = createAsyncThunk(
  'createConversation/create',
  async ({
    body,
    accessToken,
    project_id,
    conversation_configuration_id = '',
    agent_name = '',
    template_type,
  }: any) => {
    try {
      const response = await postRequest({
        url: `/conversation?project_id=${project_id}&conversation_configuration_id=${conversation_configuration_id}&agent_name=${agent_name}&template_type=${template_type}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const updateConversation = createAsyncThunk(
  'updateConversation/update',
  async ({ body, accessToken, project_id, conversation_id }: any) => {
    try {
      const response = await putRequest({
        url: `/conversation?project_id=${project_id}&conversation_id=${conversation_id}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const getConversation = createAsyncThunk(
  'getConversation/get',
  async ({ accessToken, project_id, conversation_id }: any) => {
    try {
      const response = await getRequest({
        url: `/conversation?project_id=${project_id}&conversation_id=${conversation_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const getAllConversations = createAsyncThunk(
  'getConversations/get',
  async ({ accessToken, project_id }: any) => {
    try {
      const response = await getRequest({
        url: `/conversations?project_id=${project_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const deleteConversations = createAsyncThunk(
  'deleteConversations/delete',
  async ({ accessToken, project_id, conversation_id }: any) => {
    try {
      const response = await deleteRequest({
        url: `/conversation?project_id=${project_id}&conversation_id=${conversation_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const getAllAiAssistants = createAsyncThunk('getAllAiAssistants/get', async ({ accessToken }: any) => {
  try {
    const response = await getRequest({
      url: `/spAiTemplates`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response;
  } catch (error: any) {
    throw error.response;
  }
});

export const getSingleAiAssistant = createAsyncThunk(
  'getSingleAiAssistant/get',
  async ({ accessToken, resource_id }: any) => {
    try {
      const response = await getRequest({
        url: `/publicTemplate?id=${resource_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const getAllPublishedAiAssistants = createAsyncThunk(
  'getAllPublishedAiAssistants/get',
  async ({ accessToken, query }: any) => {
    try {
      const response = await getRequest({
        url: `/publicTemplates${query}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const customeAzureConnections = createAsyncThunk(
  "customeAzureConnections/Create",
  async ({ body, accessToken, tagName }: any) => {
    try {
      const response = await postRequest({
        url: `/azure/resources?tag=${tagName}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const promptMessageAzure = createAsyncThunk(
  'promptMessageAzure/Create',
  async ({ body, accessToken, type, signal }: any) => {
    try {
      const response = await postRequest({
        url: `/agentCompletion?assistant=${type}`,
        payload: {
          prompt: JSON.stringify(body)
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        signal,
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);
