import React, { useContext } from 'react';
import { BoltIcon, ArrowDownIcon, BugAntIcon, SparklesIcon, PhotoIcon, PencilIcon } from '@heroicons/react/24/outline';
import { useAppDispatch } from '../../../../../hooks';
import { setSelectedDocument } from '../../../../../redux/projects/projectsSlice';
// import Toolbar, { ToolbarItemType } from "../../../../../components/toolbar";
import { loadingIcon } from '../../../../../assets/icons';
import DropdownSelectionOption from '../../../../../components/dropdownSelectionOption';
import { DetailsContext } from 'src/contexts/details/context';
import ItemWithDropdown, { ActionProps } from '../../../../../components/itemWithDropdown';
import { useDownloadGraphImage } from '../../../../flowchart/flow/DownloadGraphImage';
import { getValidateList } from 'src/utils/special-chat-assistants';
import { Tooltip } from 'react-tooltip';
import { type GenerateItem, getGenerateList } from 'src/utils/generate-actions';
import { useRegisterTourHandle } from 'src/hooks/useUserTour';
import { ConversationContext } from 'src/type';

interface DiagramActionsProps {
  setOpenGenerateModal: Function;
  generateDocumentFromDiagram: (item: GenerateItem) => void;
  diagramSaveLoading: boolean;
  updatingProjectDiagram: Function;
  diagram: string;
  openSpecialChat: Function;
  setGenerateDiagramType: Function;
}

export default function DiagramActions({
  setOpenGenerateModal,
  generateDocumentFromDiagram,
  diagramSaveLoading,
  updatingProjectDiagram,
  openSpecialChat,
  setGenerateDiagramType,
}: DiagramActionsProps) {
  const { allDocuments, selectedDocument } = useContext(DetailsContext);
  const dispatch = useAppDispatch();

  const onSelectingDocument = (value: any) => {
    dispatch(setSelectedDocument(value));
  };
  const downloadGraphImage = useDownloadGraphImage();

  const handleOpenSpecialChat = async (conversationContext: ConversationContext) => {
    const diagramData = await updatingProjectDiagram();

    if (!diagramData) return;

    openSpecialChat(conversationContext, {
      entityId: diagramData?.id,
      data: JSON.stringify(diagramData.design),
    });
  };

  const validateList = getValidateList('diagram', handleOpenSpecialChat);

  const generateList = getGenerateList('diagram', generateDocumentFromDiagram);

  const onEditWithAi = async () => {
    const data = {
      chatTitle: 'Edit Diagram with AI',
      chatDescription: 'Edit the provided diagram with AI',
      chatType: 'chat',
      agentName: 'diagram-editor',
      autoSendMessage: false,
    };

    const diagramData = await updatingProjectDiagram();

    if (!diagramData) return;

    openSpecialChat(data, {
      entityId: diagramData?.id,
      data: JSON.stringify(diagramData.design),
    });
  };

  const generateDiagramsList: ActionProps[] = [
    {
      id: 1,
      name: 'Generate Diagram',
      onActionClick: () => {
        setOpenGenerateModal();
        setGenerateDiagramType('');
      },
    },
    {
      id: 2,
      name: 'Generate Azure Diagram',
      onActionClick: () => {
        setOpenGenerateModal();
        setGenerateDiagramType('azure');
      },
    },
  ];

  useRegisterTourHandle('DiagramActions', {
    openEditWithAi: onEditWithAi,
    openSpecialChat: handleOpenSpecialChat,
  });

  const buttonLabelText = (text: string) => {
    return <span className='text-gray-700 ml-2 text-[14px]'>{text}</span>;
  };

  const iconWrapper = 'cursor-pointer px-[14px] py-[3.15px]';

  return (
    <div className='absolute right-[8px] top-[8px] z-10'>
      <div className='flex items-center justify-center h-[38px]'>
        {/* <div className='group h-full flex bg-white border rounded-[10px] shadow-md border-[#e5e7eb] hover:border-[#2d61d2] hover py-[2.5px] mr-[8px]'>
          <div className={`flex items-center h-full ${iconWrapper}`}>
            <DropdownSelectionOption
              optionsList={allDocuments}
              selected={selectedDocument}
              setSelected={onSelectingDocument}
              btnText='Add Document'
              buttonClassName='text-xs font-medium text-customLightBlue hover:text-gray-700'
              className='flex'
              dropdownClassName='top-full h-full'
              mainIcon={CircleStackIcon}
              fromPage='diagram'
              dropdownPosition='left'
            />
          </div>
        </div> */}

        <div className='flex items-center bg-white h-full rounded-[10px] mr-[8px] shadow-md'>
          <div
            data-tooltip-id='edit-with-ai-tooltip'
            onClick={() => onEditWithAi()}
            className='group h-full flex border border-[#e5e7eb] hover:border-[#2d61d2] rounded-[10px] step-edit-ai-btn'
          >
            <div className={`flex items-center ${iconWrapper}`}>
              <PencilIcon className='h-[18px] text-zinc-800' />
            </div>
          </div>
        </div>

        <div className='flex items-center bg-white h-full mr-[8px] rounded-[10px] shadow-md'>
          <div
            data-tooltip-id='generate-artifact-tooltip'
            className='group relative h-full flex rounded-l-[10px] border border-[#e5e7eb] hover:border-[#2d61d2] hover'
          >
            <ItemWithDropdown
              title='Generate Artifact'
              actionsData={generateList}
              buttonClassName='h-full step-generate-artifact-btn'
              dropdownClassName='step-generate-artifact-dropdown'
            >
              <div className={`flex items-center h-full ${iconWrapper}`}>
                <SparklesIcon className='h-4 text-zinc-800 group-hover:text-blue-500' />
                {/* {buttonLabelText('Generate')}

                <ChevronDownIcon className='h-4 ml-2 stroke-gray-400' /> */}
              </div>
            </ItemWithDropdown>
          </div>
          <div
            data-tooltip-id='validate-design-tooltip'
            className='group relative h-full flex border border-[#e5e7eb] hover:border-[#2d61d2] hover'
          >
            <ItemWithDropdown
              title='Validate Design'
              actionsData={validateList}
              buttonClassName='h-full step-validate-btn'
              dropdownClassName='step-validation-dropdown'
            >
              <div className={`flex items-center h-full ${iconWrapper}`}>
                <BugAntIcon className='h-4 text-zinc-800 group-hover:text-red-500' />
                {/* {buttonLabelText('Validate')}

                <ChevronDownIcon className='h-4 ml-2 stroke-gray-400' /> */}
              </div>
            </ItemWithDropdown>
          </div>
          <div
            data-tooltip-id='generate-diagram-tooltip'
            className='group h-full flex rounded-r-[10px] border border-[#e5e7eb] hover:border-[#2d61d2] hover'
          >
            <ItemWithDropdown
              title='Generate Diagram'
              actionsData={generateDiagramsList}
              buttonClassName='h-full'
            >
              <div className={`flex items-center h-full ${iconWrapper}`}>
                <BoltIcon className='h-4 text-zinc-800 group-hover:text-yellow-500' />
                {/* {buttonLabelText('Generate')}

                <ChevronDownIcon className='h-4 ml-2 stroke-gray-400' /> */}
              </div>
            </ItemWithDropdown>
          </div>
        </div>

        <div className='flex items-center bg-white h-full rounded-[10px] shadow-md'>
          <div
            data-tooltip-id='save-screenshot-tooltip'
            className='group h-full flex border border-[#e5e7eb] hover:border-[#2d61d2] rounded-l-[10px]'
          >
            <div
              className={`flex items-center ${iconWrapper}`}
              onClick={() => downloadGraphImage()}
            >
              <PhotoIcon className='h-[18px] text-zinc-800' />
            </div>
          </div>

          <div
            data-tooltip-id='save-diagram-tooltip'
            className='group h-full flex border border-[#e5e7eb] hover:border-[#2d61d2] hover rounded-r-[10px]'
          >
            <div
              id='save-diagram-button-id'
              className={`flex items-center ${iconWrapper}`}
              onClick={() => updatingProjectDiagram()}
            >
              {diagramSaveLoading ? loadingIcon : <ArrowDownIcon className='h-4 text-zinc-800' />}
              {/* {buttonLabelText('Save')} */}
            </div>
          </div>
        </div>
      </div>

      <Tooltip
        className='z-[50]'
        place='top-end'
        id='edit-with-ai-tooltip'
        positionStrategy='fixed'
        offset={10}
      >
        Edit with AI
      </Tooltip>

      <Tooltip
        className='z-[50]'
        place='top-end'
        id='generate-artifact-tooltip'
        positionStrategy='fixed'
        offset={10}
      >
        Generate Artifact
      </Tooltip>

      <Tooltip
        className='z-[50]'
        place='top-end'
        id='validate-design-tooltip'
        positionStrategy='fixed'
        offset={10}
      >
        Validate Design
      </Tooltip>

      <Tooltip
        className='z-[50]'
        place='top-end'
        id='generate-diagram-tooltip'
        positionStrategy='fixed'
        offset={10}
      >
        Generate Diagram
      </Tooltip>

      <Tooltip
        className='z-[50]'
        place='top-end'
        id='save-screenshot-tooltip'
        positionStrategy='fixed'
        offset={10}
      >
        Save Screenshot
      </Tooltip>

      <Tooltip
        className='z-[50]'
        id='save-diagram-tooltip'
        place='top-end'
        positionStrategy='fixed'
        offset={10}
      >
        Ctrl + S for Save
      </Tooltip>
    </div>
  );
}
