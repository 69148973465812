import {
  BeakerIcon,
  BuildingLibraryIcon,
  DocumentPlusIcon,
  HomeIcon,
  InformationCircleIcon,
  LightBulbIcon,
  SparklesIcon,
  Square3Stack3DIcon,
  XMarkIcon,
  BookOpenIcon,
} from '@heroicons/react/24/outline';

import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import MenuItemsList from './menuItemsList';
import Tooltip from '../tooltipWrapper';
import mainLogo from '../../assets/images/solution_pilot.svg';
import { useAppDispatch } from '../../hooks';
import routeHierarchy from 'src/utils/routeHierarchy';
import SubscriptionBox from '../subscriptionBox';
import LoginUserInfo from '../loginUserInfo';
import { useAccessToken } from '../../contexts/AccessTokenContext';
import UserTourMenu from '../userTourMenu';

const navigationsList = [
  {
    id: 1,
    title: '',
    menuList: [
      { id: 1, name: 'Home', href: '/home', icon: HomeIcon, routeKey: routeHierarchy.home.name },
      {
        id: 2,
        name: 'Community',
        href: '/community-templates',
        icon: BeakerIcon,
        childrens: [
          {
            id: 21,
            name: 'Designs & Documents',
            href: '/community-templates',
            icon: LightBulbIcon,
            routeKey: routeHierarchy.communityTemplates.name,
          },
          {
            id: 21,
            name: 'Ai Prompts',
            href: '/community-ai-assistants',
            icon: SparklesIcon,
            routeKey: routeHierarchy.communityAiAssistants.name,
          },
        ],
      },
    ],
  },
  {
    id: 2,
    title: 'Projects',
    menuList: [
      {
        id: 3,
        name: 'Designs',
        href: '/projects',
        icon: LightBulbIcon,
        routeKey: routeHierarchy.projects.name,
      },
      {
        id: 4,
        name: 'Templates',
        href: '/templates',
        icon: Square3Stack3DIcon,
        routeKey: routeHierarchy.privateTemplates.name,
      },
      {
        id: 499,
        name: 'Document Components',
        href: '/document-components',
        icon: DocumentPlusIcon,
        routeKey: routeHierarchy.documentComponents.name,
      },
      {
        id: 5,
        name: 'Ai Prompts',
        href: '/ai-assistants',
        icon: SparklesIcon,
        routeKey: routeHierarchy.privateAiAssistants.name,
        childrens: [
          {
            id: 51,
            name: 'Prompt Book',
            href: '/ai-assistants',
            icon: BookOpenIcon,
            routeKey: routeHierarchy.privateAiAssistants.name,
          },
          // {
          //   id: 52,
          //   name: 'Playground',
          //   href: '/playground',
          //   icon: PaintBrushIcon,
          //   routeKey: routeHierarchy.playground.name,
          // },
        ],
      },
    ],
  },
];

interface SidebarProps {
  setSidebarOpen: Function;
  classNames: Function;
  sidebarOpen: boolean;
  setCollapseSidebar: Function;
  collapseSidebar: boolean;
}

export default function Sidebar({
  setSidebarOpen,
  classNames,
  sidebarOpen,
  setCollapseSidebar,
  collapseSidebar,
}: SidebarProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selectedIcon, setSelectedIcon] = useState<string | null>(null);

  const { userTokenInfo } = useAccessToken();

  function openInNewTab(url: string) {
    window.open(url, '_blank');
  }

  const navigationActions = [
    {
      id: 2,
      name: 'Documentation',
      href: `${process.env.REACT_APP_BASE_API_URL}/documentation`,
      icon: InformationCircleIcon,
    },
  ];

  const accountStatus = userTokenInfo?.app_meta?.account_status;

  return (
    <div
      id='main_side_bar'
      className='mt-[48px]'
    >
      <Transition
        show={sidebarOpen}
        as={Fragment}
      >
        <Dialog
          as='div'
          className='relative z-50 lg:hidden'
          onClose={() => setSidebarOpen(false)}
        >
          <TransitionChild
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-900/80' />
          </TransitionChild>

          <div className='fixed inset-0 flex'>
            <TransitionChild
              as={Fragment}
              enter='transition ease-in-out duration-300 transform'
              enterFrom='-translate-x-full'
              enterTo='translate-x-0'
              leave='transition ease-in-out duration-300 transform'
              leaveFrom='translate-x-0'
              leaveTo='-translate-x-full'
            >
              <DialogPanel className='relative mr-16 flex w-full max-w-xs flex-1'>
                <TransitionChild
                  as={Fragment}
                  enter='ease-in-out duration-300'
                  enterFrom='opacity-0'
                  enterTo='opacity-100'
                  leave='ease-in-out duration-300'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <div className='absolute left-full top-0 flex w-16 justify-center pt-5'>
                    <button
                      type='button'
                      className='-m-2.5 p-2.5'
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className='sr-only'>Close sidebar</span>
                      <XMarkIcon
                        className='h-6 w-6 text-white'
                        aria-hidden='true'
                      />
                    </button>
                  </div>
                </TransitionChild>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className='flex grow flex-col gap-y-5 px-[20px] pb-4 ring-1 ring-white/10'>
                  <div className='flex h-10 shrink-0 items-center'>
                    <img
                      className='h-4 w-auto cursor-pointer'
                      src={mainLogo}
                      alt='Solution Pilot'
                      onClick={() => navigate('/')}
                    />
                  </div>
                  <nav className='flex flex-1 flex-col'>
                    <ul className='flex flex-1 flex-col gap-y-4 pl-0'>
                      {navigationsList.map((item) => (
                        <MenuItemsList
                          key={item.id}
                          title={item.title}
                          classNames={classNames}
                          navigationsList={item.menuList}
                          collapseSidebar={false}
                        />
                      ))}
                    </ul>
                  </nav>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>

      <div
        className={`group relative lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:flex-col ${
          collapseSidebar ? 'w-[52px]' : 'lg:w-64'
        }`}
        style={{ top: '55px' }}
      >
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div
          className={`flex grow flex-col gap-y-5 ${collapseSidebar ? 'px-2' : 'px-[20px] overflow-y-auto'} pb-36 pt-3`}
        >
          <nav className={`flex flex-1 flex-col relative`}>
            <ul className={`flex flex-1 flex-col pl-0 ${collapseSidebar ? 'gap-y-[4px]' : 'gap-y-[34px]'}`}>
              {navigationsList.map((item) => (
                <MenuItemsList
                  key={item.id}
                  title={item.title}
                  classNames={classNames}
                  navigationsList={item.menuList}
                  collapseSidebar={collapseSidebar}
                />
              ))}
            </ul>
          </nav>
        </div>

        <div
          className={`${
            collapseSidebar ? 'py-4 w-[51px]' : 'p-[20px]'
          } absolute z-10 bottom-0 transition-all bg-[#fbfbfb] w-[calc(100%_-6px)]`}
        >
          {!collapseSidebar && accountStatus?.status === 'trialing' && (
            <div className='mb-[16px]'>
              <SubscriptionBox />
            </div>
          )}
          <ul>
            <li className={`mb-0 ${collapseSidebar ? 'ml-0' : ''}`}>
              <UserTourMenu>
                <Tooltip
                  text={collapseSidebar ? <span className='whitespace-nowrap'>Training</span> : ''}
                  position='right'
                >
                  <span
                    className={classNames(
                      'flex gap-x-3 text-[#14151A] hover:bg-[rgba(10,_15,_41,_0.04)] rounded-md px-[8px] py-[6px] text-xs leading-5 font-medium cursor-pointer items-center',
                      collapseSidebar ? 'items-center justify-center' : '',
                      'transition-colors [[data-open]_&]:text-primaryLight [[data-open]_&]:bg-[rgba(10,_15,_41,_0.04)]'
                    )}
                  >
                    <span className={classNames(
                      'text-[#14151A] opacity-50',
                      'transition-colors [[data-open]_&]:opacity-100 [[data-open]_&]:text-primaryLight'
                    )}>
                      <BuildingLibraryIcon className='w-[17px] h-[17px]' />
                    </span>

                    {!collapseSidebar && <span>Training</span>}
                  </span>
                </Tooltip>
              </UserTourMenu>
            </li>
            {navigationActions.map((actionBtn) => {
              const isSelected = selectedIcon === actionBtn.name;
              return (
                <li
                  key={actionBtn.name}
                  className={`mb-0 ${collapseSidebar ? 'ml-0' : ''}`}
                >
                  <Tooltip
                    text={collapseSidebar ? <span className='whitespace-nowrap'>{actionBtn.name}</span> : ''}
                    position='right'
                  >
                    <span
                      onClick={() => {
                        setSelectedIcon(actionBtn.name);
                        actionBtn?.href
                          ? openInNewTab(actionBtn?.href)
                          : dispatch(setCollapseSidebar(!collapseSidebar));
                      }}
                      className={`flex gap-x-3 text-[#14151A] hover:bg-[rgba(10,_15,_41,_0.04)] rounded-md px-[8px] py-[6px] text-xs leading-5 font-medium cursor-pointer items-center ${
                        collapseSidebar ? 'items-center justify-center' : ''
                      }`}
                    >
                      <span className={`text-[#14151A] ${isSelected ? '' : 'opacity-50'}`}>
                        <actionBtn.icon className='w-[17px] h-[17px]' />
                      </span>

                      {!collapseSidebar && <span>{actionBtn.name}</span>}
                    </span>
                  </Tooltip>
                </li>
              );
            })}
          </ul>
          <div className='mt-[20px]'>
            <LoginUserInfo collapseSidebar={collapseSidebar} />
          </div>
        </div>
      </div>
    </div>
  );
}
