import { PropsWithChildren, LiHTMLAttributes } from 'react';
import { PencilIcon, TrashIcon, HandThumbUpIcon, HandThumbDownIcon } from '@heroicons/react/24/outline';
import { classNames } from 'src/utils/utilities';
import ConfigurationGrid, { ConfigurationItemProps } from '../configurationGrid';

export interface BaseArgumentBoxProps extends Omit<LiHTMLAttributes<HTMLLIElement>, 'className' | 'color'> {
  name: string;
  value: number;
  className?: string;
  type?: string;
  variant?: 'user' | 'ai';
  status?: 'creating' | 'deleting' | null;
  color?: ConfigurationItemProps['variant'];
  description?: string;
}

interface UserArgumentProps extends BaseArgumentBoxProps {
  onEdit: () => void;
  onDelete: () => void;
}

interface AIArgumentProps extends BaseArgumentBoxProps {
  onThumbUp: () => void;
  onThumbDown: () => void;
}

type ArgumentBoxProps = Readonly<({ variant?: 'user' } & UserArgumentProps) | ({ variant?: 'ai' } & AIArgumentProps)>;

function BaseArgumentBox({
  variant = 'user',
  status,
  name,
  value,
  type,
  children,
  description,
  color,
  className,
  ...props
}: PropsWithChildren<BaseArgumentBoxProps>) {
  return (
    <ConfigurationGrid.Item
      title={name}
      value={value}
      description={description}
      variant={color}
      className={classNames(
        className,
        'mb-2 last:mb-0',
        type === 'swot' ? 'min-h-9' : 'min-h-12',
        variant === 'ai' && !status && 'opacity-50 transition-opacity hover:opacity-100'
      )}
      loading={status === 'deleting' || status === 'creating'}
      {...props}
    >
      <div
        className={classNames('flex-shrink-0 flex items-center', variant === 'ai' && 'step-tchart-suggestion-reject')}
      >
        {children}
      </div>
    </ConfigurationGrid.Item>
  );
}

export default function ArgumentBox({ variant = 'user', status, ...props }: ArgumentBoxProps) {
  const classes = {
    button: classNames(
      'group p-1.5 rounded cursor-pointer',
      'flex items-center justify-center',
      'bg-transparent text-customDarkBlue transition-colors enabled:hover:bg-zinc-100',
      'disabled:cursor-not-allowed'
    ),
    icon: 'h-4 w-4 text-customLightBlue transition-colors group-hover:text-customDarkBlue',
  };

  const isButtonDisabled = status === 'creating' || status === 'deleting';

  if (variant === 'user') {
    const { onEdit, onDelete, ...baseProps } = props as UserArgumentProps;

    return (
      <BaseArgumentBox
        variant='user'
        status={status}
        {...baseProps}
      >
        <button
          type='button'
          disabled={isButtonDisabled}
          onClick={onEdit}
          className={classes.button}
        >
          <PencilIcon className={classes.icon} />
        </button>
        <button
          type='button'
          disabled={isButtonDisabled}
          onClick={onDelete}
          className={classes.button}
        >
          <TrashIcon className={classes.icon} />
        </button>
      </BaseArgumentBox>
    );
  }

  if (variant === 'ai') {
    const { onThumbUp, onThumbDown, className, ...baseProps } = props as AIArgumentProps;

    return (
      <BaseArgumentBox
        variant='ai'
        status={status}
        {...baseProps}
        className={classNames('cursor-pointer', className)}
        onClick={onThumbUp}
      >
        <button
          type='button'
          disabled={isButtonDisabled}
          onClick={onThumbUp}
          className={classNames(classes.button, 'disabled:opacity-50')}
        >
          <HandThumbUpIcon className={classes.icon} />
        </button>
        <button
          type='button'
          disabled={isButtonDisabled}
          onClick={onThumbDown}
          className={classNames(classes.button, 'disabled:opacity-50')}
        >
          <HandThumbDownIcon className={classes.icon} />
        </button>
      </BaseArgumentBox>
    );
  }

  return null;
}
