import noop from 'lodash/noop';
import { createContext, MutableRefObject } from 'react';

export type TabType = 'documents' | 'diagrams' | 't-chart' | 'pros-cons' | 'decision-matrix' | 'swot' | 'settings';

interface DetailsContextType {
  loader: boolean;
  tabs: TabType[];
  allDocuments: any;
  selectedDocument: any;
  autosave: boolean;
  multiSelectedOption: number;
  editorRef: MutableRefObject<any | null>;
  hasTextEditor: boolean;
  selectDocument: (document: any) => void;
  getAllDocuments?: () => Promise<void>;
  createDocument: (document: any) => Promise<any>;
  deleteDocument: (documentId: string) => Promise<void>;
  updateDocument: (body: any, documentId?: string) => Promise<any>;
  toggleAutoSave: () => Promise<void>;
  setDocumentHtml: (htmlContent: string, documentId?: string) => void;
  switchMultiSelectedOption: (optionId: number) => void;
  setEditorRef: (editor: any | null) => void;
}

interface ProjectDetailsContextType extends DetailsContextType {
  type: 'project';
  project: any;
  saveProjectAsTemplate: (body: any) => Promise<void>;
}

interface TemplateDetailsContextType extends DetailsContextType {
  type: 'template';
  template: any;
}

type ContextType = ProjectDetailsContextType | TemplateDetailsContextType;

export const DetailsContext = createContext<ContextType>({
  loader: false,
  tabs: [],
  type: 'project',
  project: null,
  allDocuments: [],
  selectedDocument: null,
  autosave: false,
  multiSelectedOption: 1,
  editorRef: { current: null },
  hasTextEditor: false,
  selectDocument: noop,
  getAllDocuments: async () => {},
  createDocument: async () => {},
  updateDocument: async () => {},
  deleteDocument: async () => {},
  toggleAutoSave: async () => {},
  setDocumentHtml: noop,
  saveProjectAsTemplate: async () => {},
  switchMultiSelectedOption: noop,
  setEditorRef: noop,
});
