import React, { useCallback, memo, useMemo, useEffect, useRef } from "react";
import {
  NodeResizer,
  type NodeProps,
  useStore,
  Handle,
  Position,
  useKeyPress,
  useReactFlow,
  NodeToolbar,
} from "@xyflow/react";
import { Tooltip } from "react-tooltip";
import Shape from "./shape";
import ColorToolbar from "./colorToolbar";
import { type ShapeNode } from "./shape/types";
import NodeLabel from "./label";
import Popover from "../../../../components/popover";
import {
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
  PaintBrushIcon,
  TrashIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline";

// this will return the current dimensions of the node (measured internally by react flow)
function useNodeDimensions(id: string) {
  const node = useStore((state) => state.nodeLookup.get(id));
  return {
    width: node?.measured?.width || 0,
    height: node?.measured?.height || 0,
  };
}

function CustomShapeNode({ id, selected, data }: NodeProps<ShapeNode>) {
  const { color, type, textColor } = data;
  const { setNodes, deleteElements, getNodes } = useReactFlow();

  const { width, height } = useNodeDimensions(id);
  const shiftKeyPressed = useKeyPress("Shift");

  const parentId = useStore((store) => store.nodeLookup.get(id)?.parentId);

  const nodesList = getNodes();
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const fontSize = useMemo(() => {
    const baseSize = 14; // Default font size
    return Math.max(baseSize, Math.min(width) / 16); // Adjust font size
  }, [width]);

  // Get Complete Node Object
  const nodeObject = useMemo(
    () => nodesList.find((n) => n.id === id),
    [nodesList, id]
  );

  // Get Parent Object
  const parentObject = useMemo(
    () => nodesList.find((n) => n.id === parentId),
    [nodesList, parentId]
  );

  /**************************************
   ** Check Toolbar Position Code Start ***
   **************************************/

  // Define the node's width and height (static or dynamic based on the node)
  const nodeWidth = nodeObject?.measured?.width ?? 0;
  const nodeHeight = nodeObject?.measured?.height ?? 0;

  const nodePosition = nodeObject?.position ?? { x: 0, y: 0 };

  // Get the canvas transform and viewport size
  const [x, y, scale] = useStore((state) => state.transform);
  const viewportWidth =
    document.querySelector(".react-flow__renderer")?.clientWidth ?? 0; // Viewport width (React Flow wrapper width)
  const viewportHeight =
    document.querySelector(".react-flow__renderer")?.clientHeight ?? 0; // Viewport height (React Flow wrapper height)

  const toolbarOffset = nodeWidth; // Offset for the toolbar from the node

  // Calculate node position in the viewport
  const nodeViewportX = nodePosition?.x * scale + x + nodeWidth / 2;
  const nodeViewportY = nodePosition?.y * scale + y + nodeHeight / 2;

  // Determine the toolbar position based on the node's position in the viewport
  const determineToolbarPosition = () => {
    const isAtTop = nodeViewportY < toolbarOffset;
    const isAtBottom = viewportHeight - nodeViewportY < toolbarOffset;
    const isAtLeft = nodeViewportX < toolbarOffset;
    const isAtRight = viewportWidth - nodeViewportX < toolbarOffset;

    if (isAtTop) return "top"; // Place toolbar below the node
    if (isAtBottom) return "bottom"; // Place toolbar above the node
    if (isAtLeft) return "left"; // Place toolbar to the right
    if (isAtRight) return "right"; // Place toolbar to the left

    return "top"; // Default position
  };

  const toolbarPosition = determineToolbarPosition();

  /**************************************
   ** Check Toolbar Position Code End ***
   **************************************/

  useEffect(() => {
    adjustTextareaHeight();
  }, [nodeObject?.data?.label]);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    if (parentObject?.style?.zIndex) {
      setNodes((nds) => {
        // Find the maximum zIndex of nodes with the same parent
        const maxSiblingZIndex = nds
          .filter((n) => n.parentId === parentId)
          .reduce((max, n) => Math.max(max, Number(n.style?.zIndex || 0)), 0);

        return nds.map((n) =>
          n.id === id
            ? {
                ...n,
                style: {
                  ...n.style,
                  zIndex: maxSiblingZIndex + 1, // Set zIndex to max sibling zIndex + 1
                },
              }
            : n
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDelete = useCallback(() => {
    deleteElements({ nodes: [{ id }] });
  }, [deleteElements, id]);

  const onColorChange = useCallback(
    (color: string) => {
      setNodes((nodes) =>
        nodes.map((node) => {
          if (node.id === id) {
            return {
              ...node,
              data: {
                ...node.data,
                color,
              },
            };
          }
          return node;
        })
      );
    },
    [setNodes, id]
  );

  const onTextColorChange = useCallback(
    (color: string) => {
      setNodes((nodes) =>
        nodes.map((node) => {
          if (node.id === id) {
            return {
              ...node,
              data: {
                ...node.data,
                textColor: color,
              },
            };
          }
          return node;
        })
      );
    },
    [setNodes, id]
  );

  const findMaxZIndex = useCallback((nodesListArray: any[]) => {
    return nodesListArray.length > 0
      ? Math.max(
          ...nodesListArray
            .map((n) => n.style?.zIndex)
            .filter((z): z is number => z !== undefined)
        )
      : 0;
  }, []);

  const findMinZIndex = useCallback((nodesListArray: any[]) => {
    return nodesListArray.length > 0
      ? Math.min(
          ...nodesListArray
            .map((n) => n.style?.zIndex)
            .filter((z): z is number => z !== undefined)
        )
      : 0;
  }, []);

  function findClosestBiggerZIndex(
    selectedZIndex: number,
    nodesListArray: any[]
  ) {
    // Filter the array for zIndex values smaller than the selected one
    const biggestZIndices = nodesListArray
      .map((node) => node?.style?.zIndex)
      .filter((zIndex) => zIndex > selectedZIndex);

    // Return the maximum of the smaller zIndex values or null if none exists
    return biggestZIndices.length > 0 ? Math.min(...biggestZIndices) : 1300;
  }

  const bringNodeToFront = useCallback(
    (nodeId: string, absolute = false) => {
      setNodes((nds) => {
        const index = nds.findIndex((n) => n.id === nodeId);
        // if (index === -1 || (absolute && index === nds.length - 1)) return nds;

        const newNodes = [...nds];
        const node = newNodes[index];

        const maxZIndex =
          newNodes.length > 0
            ? Math.max(
                ...newNodes
                  .map((n) => n.style?.zIndex)
                  .filter((z): z is number => z !== undefined)
              )
            : 0;

        const greaterThenSelectedNode = findClosestBiggerZIndex(
          Number(node?.style?.zIndex),
          newNodes
        );

        if (absolute) {
          node.style = {
            ...node.style,
            zIndex: maxZIndex + 1000,
          };
        } else {
          node.style = {
            ...node.style,
            zIndex: greaterThenSelectedNode + 300,
          };
        }

        return newNodes;
      });
      handleNodeMouseEnter();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setNodes]
  );

  function findClosestSmallerZIndex(
    selectedZIndex: number,
    nodesListArray: any[]
  ) {
    // Filter the array for zIndex values smaller than the selected one
    const smallerZIndices = nodesListArray
      .map((node) => node?.style?.zIndex)
      .filter((zIndex) => zIndex < selectedZIndex);

    // Return the maximum of the smaller zIndex values or null if none exists
    return smallerZIndices.length > 0 ? Math.max(...smallerZIndices) : 1300;
  }

  const sendNodeToBack = useCallback(
    (nodeId: string, absolute = false) => {
      setNodes((nds) => {
        const index = nds.findIndex((n) => n.id === nodeId);
        if (index === -1) return nds;

        const newNodes = [...nds];
        const node = newNodes[index];

        const minZIndex =
          newNodes.length > 0
            ? Math.min(
                ...newNodes
                  .map((n) => n.style?.zIndex)
                  .filter((z): z is number => z !== undefined)
              )
            : 0;

        const lessThenSelectedNode = findClosestSmallerZIndex(
          Number(node?.style?.zIndex),
          newNodes
        );

        let newZIndex = absolute ? minZIndex - 300 : lessThenSelectedNode - 300;

        // Ensure the node's zIndex does not go below its parent's zIndex
        const parentNode = newNodes.find((n) => n.id === node.parentId);
        if (parentNode && newZIndex < Number(parentNode.style?.zIndex || 0)) {
          newZIndex = Number(parentNode.style?.zIndex || 0) + 1;
        }

        node.style = {
          ...node.style,
          zIndex: newZIndex,
        };

        return newNodes;
      });

      handleNodeMouseEnter();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setNodes]
  );

  const selectedNodesCount = nodesList.filter((node) => node.selected).length;

  const handleNodeMouseEnter = useCallback(
    (e?: any) => {
      // Clear selection of all nodes except the one being dragged
      if (!nodeObject?.selected && !e?.ctrlKey && selectedNodesCount <= 1) {
        setNodes((nds) =>
          nds.map((n) => ({
            ...n,
            selected: false,
          }))
        );
      }
    },
    [setNodes, nodeObject, selectedNodesCount]
  );

  const onChangeShapeLabel = useCallback(
    (value: string) => {
      setNodes((prevNodes) =>
        prevNodes.map((node) =>
          node.id === id
            ? {
                ...node,
                data: { ...node.data, label: value },
              }
            : node
        )
      );
    },
    [setNodes, id]
  );

  const iconStyle = "h-[20px] text-[#6f717c]";

  const iconWrapper =
    "cursor-pointer p-[10px] rounded-[5px] hover:bg-[#f3f4f5]";

  // Use values from node data, defaulting to 5 if not set
  // const rightHandles: any = data?.rightHandles ?? 5;
  // const leftHandles: any = data?.leftHandles ?? 5;
  // const topHandles: any = data?.topHandles ?? 5;
  // const bottomHandles: any = data?.bottomHandles ?? 5;

  // Add getPositions helper
  const getPositions = useMemo(() => {
    return () => {
      const countValue = 25;
      if (countValue <= 1) return [0.5];
      return Array.from({ length: countValue }, (_, i) => i / (countValue - 1));
    };
  }, []);

  return (
    <>
      <NodeResizer
        color={color}
        keepAspectRatio={shiftKeyPressed}
        isVisible={selected}
      />
      <Shape
        type={type}
        width={width - 4}
        height={height - 4}
        fill={color}
        strokeWidth={1}
        stroke={"white"}
        fillOpacity={1}
        onMouseEnter={handleNodeMouseEnter}
      />

      {/* Top Handles */}
      {getPositions().map((position) => (
        <Handle
          key={`top-target-${position}`}
          type="target"
          className="z-50"
          position={Position.Top}
          id={`top-target-${position}`}
          style={{ left: `${position * 100}%` }}
        />
      ))}
      {getPositions().map((position) => (
        <Handle
          key={`top-source-${position}`}
          type="source"
          className="z-50"
          position={Position.Top}
          id={`top-source-${position}`}
          style={{ left: `${position * 100}%` }}
        />
      ))}

      {/* Bottom Handles */}
      {getPositions().map((position) => (
        <Handle
          key={`bottom-target-${position}`}
          type="target"
          className="z-50"
          position={Position.Bottom}
          id={`bottom-target-${position}`}
          style={{ left: `${position * 100}%` }}
        />
      ))}
      {getPositions().map((position) => (
        <Handle
          key={`bottom-source-${position}`}
          type="source"
          className="z-50"
          position={Position.Bottom}
          id={`bottom-source-${position}`}
          style={{ left: `${position * 100}%` }}
        />
      ))}

      {/* Left Handles */}
      {getPositions().map((position) => (
        <Handle
          key={`left-target-${position}`}
          type="target"
          className="z-50"
          position={Position.Left}
          id={`left-target-${position}`}
          style={{ top: `${position * 100}%` }}
        />
      ))}
      {getPositions().map((position) => (
        <Handle
          key={`left-source-${position}`}
          type="source"
          className="z-50"
          position={Position.Left}
          id={`left-source-${position}`}
          style={{ top: `${position * 100}%` }}
        />
      ))}

      {/* Right Handles */}
      {getPositions().map((position) => (
        <Handle
          key={`right-target-${position}`}
          type="target"
          className="z-50"
          position={Position.Right}
          id={`right-target-${position}`}
          style={{ top: `${position * 100}%` }}
        />
      ))}
      {getPositions().map((position) => (
        <Handle
          key={`right-source-${position}`}
          type="source"
          className="z-50"
          position={Position.Right}
          id={`right-source-${position}`}
          style={{ top: `${position * 100}%` }}
        />
      ))}

      <NodeToolbar className="nodrag nowheel absolute top-[10px] left-0">
        <div className="bg-white p-[4px] mb-[10px] rounded-[7px] shadow-[0_0_15px_5px_rgba(0,0,0,0.1)] border border-[#e0e0e0] flex items-center justify-center">
          <Popover
            buttonContent={
              <div className={`${iconWrapper}`}>
                <DocumentTextIcon className={`${iconStyle}`} />
              </div>
            }
            popoverContent={
              <div className="relative flex flex-wrap">
                <ColorToolbar
                  onColorChange={onTextColorChange}
                  activeColor={textColor}
                />

                <div
                  className={`w-0 h-0 ${
                    (toolbarPosition === "bottom" &&
                      "bottom-[-19px] left-[105px] rotate-180") ||
                    (toolbarPosition === "top" &&
                      "bottom-[-19px] left-[105px] rotate-180") ||
                    (toolbarPosition === "left" &&
                      "top-[25px] left-[-18px] rotate-[35deg]") ||
                    (toolbarPosition === "right" &&
                      "top-[32px] right-[-22px] rotate-90") ||
                    "bottom-[-19px] left-[105px] rotate-180"
                  } border-l-[12px] border-l-transparent border-r-[12px] border-r-transparent border-b-[17px] border-b-white absolute`}
                />
              </div>
            }
            classes={`w-[280px] ${
              (toolbarPosition === "bottom" && "bottom-[45px] left-[-100px]") ||
              (toolbarPosition === "top" && "bottom-[45px] left-[-100px]") ||
              (toolbarPosition === "left" && "left-[185px] top-[-20px]") ||
              (toolbarPosition === "right" && "left-[-305px] bottom-[-40px]") ||
              "bottom-[64px] left-[-100px]"
            }`}
          />

          <Popover
            buttonContent={
              <div className={`${iconWrapper}`}>
                <PaintBrushIcon className={`${iconStyle}`} />
              </div>
            }
            popoverContent={
              <div className="relative flex flex-wrap">
                <ColorToolbar
                  onColorChange={onColorChange}
                  activeColor={color}
                />

                <div
                  className={`w-0 h-0 ${
                    (toolbarPosition === "bottom" &&
                      "bottom-[-19px] left-[105px] rotate-180") ||
                    (toolbarPosition === "top" &&
                      "bottom-[-19px] left-[105px] rotate-180") ||
                    (toolbarPosition === "left" &&
                      "top-[25px] left-[-18px] rotate-[35deg]") ||
                    (toolbarPosition === "right" &&
                      "top-[32px] right-[-22px] rotate-90") ||
                    "bottom-[-19px] left-[105px] rotate-180"
                  } border-l-[12px] border-l-transparent border-r-[12px] border-r-transparent border-b-[17px] border-b-white absolute`}
                />
              </div>
            }
            classes={`w-[280px] ${
              (toolbarPosition === "bottom" && "bottom-[45px] left-[-100px]") ||
              (toolbarPosition === "top" && "bottom-[45px] left-[-100px]") ||
              (toolbarPosition === "left" && "left-[147px] top-[-18px]") ||
              (toolbarPosition === "right" && "left-[-345px] bottom-[-40px]") ||
              "bottom-[64px] left-[-100px]"
            }`}
          />

          {nodesList?.length > 1 && (
            <div className="flex items-center justify-center gap-2">
              {Number(nodeObject?.style?.zIndex) < findMaxZIndex(nodesList) && (
                <>
                  {/* <div
                className={`${iconWrapper} order-1`}
                onClick={() => bringNodeToFront(id)}
                data-tooltip-id="move-up-node-tooltip"
              >
                <ChevronUpIcon className={`${iconStyle}`} />
              </div> */}

                  <div
                    className={`${iconWrapper} order-3`}
                    onClick={() => bringNodeToFront(id, true)}
                    data-tooltip-id="move-absolute-top-node-tooltip"
                  >
                    <ChevronDoubleUpIcon className={`${iconStyle}`} />
                  </div>
                </>
              )}

              {Number(nodeObject?.style?.zIndex) > findMinZIndex(nodesList) && (
                <>
                  {/* <div
                className={`${iconWrapper} order-2`}
                onClick={() => sendNodeToBack(id)}
                data-tooltip-id="move-down-node-tooltip"
              >
                <ChevronDownIcon className={`${iconStyle}`} />
              </div> */}

                  <div
                    className={`${iconWrapper} order-4`}
                    onClick={() => sendNodeToBack(id, true)}
                    data-tooltip-id="move-absolute-bottom-node-tooltip"
                  >
                    <ChevronDoubleDownIcon className={`${iconStyle}`} />
                  </div>
                </>
              )}
            </div>
          )}

          <div
            onClick={onDelete}
            className={`${iconWrapper}`}
            data-tooltip-id="delete-node-tooltip"
          >
            <TrashIcon className={`${iconStyle}`} />
          </div>
        </div>
      </NodeToolbar>

      <Tooltip className="z-[50]" id="delete-node-tooltip" place="top">
        Delete
      </Tooltip>

      <Tooltip className="z-[50]" id="edit-node-tooltip" place="top">
        Edit Setting
      </Tooltip>

      <Tooltip className="z-[50]" id="detach-node-tooltip" place="top">
        Detach
      </Tooltip>
      <NodeLabel
        placeholder="Text"
        fontSize={fontSize}
        value={`${nodeObject?.data?.label || ""}`}
        onChange={onChangeShapeLabel}
        textareaRef={textareaRef}
        textColor={textColor}
      />
    </>
  );
}

export default memo(CustomShapeNode);
