import {
  ArrowTopRightOnSquareIcon,
  TrashIcon,
  PaintBrushIcon,
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
  PencilIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import {
  Handle,
  NodeResizer,
  NodeToolbar,
  Position,
  useReactFlow,
  useStore,
} from "@xyflow/react";
import { memo, useEffect, useRef, useState, useMemo, useCallback } from "react";
import { Tooltip } from "react-tooltip";
import debounce from "lodash/debounce";
import { getRelativeNodesBounds, fontSizesOptions } from "./utils";
import useDetachNodes from "./useDetachNodes";
import Popover from "../../../components/popover";
import ColorToolbar from "./shapeNode/colorToolbar";

const lineStyle = { borderColor: "white" };

function TextNode({ id, data, width }: any) {
  const { border_color, border_style, background_color } = data;
  const [fontSize, setFontSize] = useState("3");
  const [textColor, setTextColor] = useState("black");
  const [isContentFocused, setContentFocused] = useState(false);

  const hasParent = useStore((store) => !!store.nodeLookup.get(id)?.parentId);
  const parentId = useStore((store) => store.nodeLookup.get(id)?.parentId);
  const { deleteElements, setNodes, getNodes } = useReactFlow();
  const detachNodes = useDetachNodes();

  const onDelete = () => deleteElements({ nodes: [{ id }] });
  const onDetach = () => detachNodes([id]);
  const resizeObserverRef = useRef<ResizeObserver | null>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const nodesList = getNodes();

  const { minWidth, minHeight } = useStore((store) => {
    const childNodes = Array.from(store.nodeLookup.values()).filter(
      (n) => n.parentId === id
    );
    const rect = getRelativeNodesBounds(childNodes);

    return {
      minWidth: rect.x + rect.width,
      minHeight: rect.y + rect.height,
      hasChildNodes: childNodes.length > 0,
    };
  }, isEqual);

  const handleResize = useMemo(
    () =>
      debounce(() => {
        // Any resize logic that might cause the ResizeObserver warning can be placed here.
      }, 100),
    []
  );

  // Get Complete Node Object
  const nodeObject = useMemo(
    () => nodesList.find((n) => n.id === id),
    [nodesList, id]
  );

  // Get Parent Object
  const parentObject = useMemo(
    () => nodesList.find((n) => n.id === parentId),
    [nodesList, parentId]
  );

  // Add these state values from node data
  const leftHandles = data?.leftHandles ?? 5;
  const rightHandles = data?.rightHandles ?? 5;
  const topHandles = data?.topHandles ?? 5;
  const bottomHandles = data?.bottomHandles ?? 5;

  // Add this helper function
  const getPositions = useMemo(() => {
    return (count: number) => {
      const countValue = 25;
      if (countValue <= 1) return [0.5];
      return Array.from({ length: countValue }, (_, i) => i / (countValue - 1));
    };
  }, []);

  /**************************************
   ** Check Toolbar Position Code Start ***
   **************************************/

  // Define the node's width and height (static or dynamic based on the node)
  const nodeWidth = nodeObject?.measured?.width ?? 0;
  const nodeHeight = nodeObject?.measured?.height ?? 0;

  const nodePosition = nodeObject?.position ?? { x: 0, y: 0 };

  // Get the canvas transform and viewport size
  const [x, y, scale] = useStore((state) => state.transform);
  const viewportWidth =
    document.querySelector(".react-flow__renderer")?.clientWidth ?? 0; // Viewport width (React Flow wrapper width)
  const viewportHeight =
    document.querySelector(".react-flow__renderer")?.clientHeight ?? 0; // Viewport height (React Flow wrapper height)

  const toolbarOffset = nodeWidth; // Offset for the toolbar from the node

  // Calculate node position in the viewport
  const nodeViewportX = nodePosition?.x * scale + x + nodeWidth / 2;
  const nodeViewportY = nodePosition?.y * scale + y + nodeHeight / 2;

  // Determine the toolbar position based on the node's position in the viewport
  const determineToolbarPosition = () => {
    const isAtTop = nodeViewportY < toolbarOffset;
    const isAtBottom = viewportHeight - nodeViewportY < toolbarOffset;
    const isAtLeft = nodeViewportX < toolbarOffset;
    const isAtRight = viewportWidth - nodeViewportX < toolbarOffset;

    if (isAtTop) return "top"; // Place toolbar below the node
    if (isAtBottom) return "bottom"; // Place toolbar above the node
    if (isAtLeft) return "left"; // Place toolbar to the right
    if (isAtRight) return "right"; // Place toolbar to the left

    return "top"; // Default position
  };

  const toolbarPosition = determineToolbarPosition();

  /**************************************
   ** Check Toolbar Position Code End ***
   **************************************/

  useEffect(() => {
    const element = document.getElementById(id);
    if (!element) return;

    if (resizeObserverRef.current) {
      resizeObserverRef.current.disconnect();
    }

    resizeObserverRef.current = new ResizeObserver(() => {
      handleResize();
    });

    resizeObserverRef.current.observe(element);

    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, [id, handleResize]);

  useEffect(() => {
    if (parentObject?.style?.zIndex) {
      setNodes((nds) => {
        // Find the maximum zIndex of nodes with the same parent
        const maxSiblingZIndex = nds
          .filter((n) => n.parentId === parentId)
          .reduce((max, n) => Math.max(max, Number(n.style?.zIndex || 0)), 0);

        return nds.map((n) =>
          n.id === id
            ? {
                ...n,
                style: {
                  ...n.style,
                  zIndex: maxSiblingZIndex + 1, // Set zIndex to max sibling zIndex + 1
                },
              }
            : n
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.innerHTML = data?.label;
    }
  }, [data?.label]);

  const onUpdateNodes = useMemo(
    () =>
      debounce(() => {
        setNodes((nodes) =>
          nodes.map((node) =>
            node.id === id
              ? {
                  ...node,
                  data: {
                    ...node.data,
                    label: `${contentRef.current?.innerHTML}` || data?.label,
                  },
                }
              : node
          )
        );
      }, 300),
    [id, data?.label, setNodes]
  );

  const applyBorderStyle = (style: string) => {
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              border_style: style,
            },
          };
        }

        return node;
      })
    );
  };

  const findMaxZIndex = useCallback((nodesListArray: any[]) => {
    return nodesListArray.length > 0
      ? Math.max(
          ...nodesListArray
            .map((n) => n.style?.zIndex)
            .filter((z): z is number => z !== undefined)
        )
      : 0;
  }, []);

  const findMinZIndex = useCallback((nodesListArray: any[]) => {
    return nodesListArray.length > 0
      ? Math.min(
          ...nodesListArray
            .map((n) => n.style?.zIndex)
            .filter((z): z is number => z !== undefined)
        )
      : 0;
  }, []);

  function findClosestBiggerZIndex(
    selectedZIndex: number,
    nodesListArray: any[]
  ) {
    // Filter the array for zIndex values smaller than the selected one
    const biggestZIndices = nodesListArray
      .map((node) => node?.style?.zIndex)
      .filter((zIndex) => zIndex > selectedZIndex);

    // Return the maximum of the smaller zIndex values or null if none exists
    return biggestZIndices.length > 0 ? Math.min(...biggestZIndices) : 1300;
  }

  const bringNodeToFront = useCallback(
    (nodeId: string, absolute = false) => {
      setNodes((nds) => {
        const index = nds.findIndex((n) => n.id === nodeId);
        // if (index === -1 || (absolute && index === nds.length - 1)) return nds;

        const newNodes = [...nds];
        const node = newNodes[index];

        const maxZIndex =
          newNodes.length > 0
            ? Math.max(
                ...newNodes
                  .map((n) => n.style?.zIndex)
                  .filter((z): z is number => z !== undefined)
              )
            : 0;

        const greaterThenSelectedNode = findClosestBiggerZIndex(
          Number(node?.style?.zIndex),
          newNodes
        );

        if (absolute) {
          node.style = {
            ...node.style,
            zIndex: maxZIndex + 1000,
          };
        } else {
          node.style = {
            ...node.style,
            zIndex: greaterThenSelectedNode + 300,
          };
        }

        return newNodes;
      });
      handleNodeMouseEnter();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setNodes]
  );

  function findClosestSmallerZIndex(
    selectedZIndex: number,
    nodesListArray: any[]
  ) {
    // Filter the array for zIndex values smaller than the selected one
    const smallerZIndices = nodesListArray
      .map((node) => node?.style?.zIndex)
      .filter((zIndex) => zIndex < selectedZIndex);

    // Return the maximum of the smaller zIndex values or null if none exists
    return smallerZIndices.length > 0 ? Math.max(...smallerZIndices) : 1300;
  }

  const sendNodeToBack = useCallback(
    (nodeId: string, absolute = false) => {
      setNodes((nds) => {
        const index = nds.findIndex((n) => n.id === nodeId);
        if (index === -1) return nds;

        const newNodes = [...nds];
        const node = newNodes[index];

        const minZIndex =
          newNodes.length > 0
            ? Math.min(
                ...newNodes
                  .map((n) => n.style?.zIndex)
                  .filter((z): z is number => z !== undefined)
              )
            : 0;

        const lessThenSelectedNode = findClosestSmallerZIndex(
          Number(node?.style?.zIndex),
          newNodes
        );

        let newZIndex = absolute ? minZIndex - 300 : lessThenSelectedNode - 300;

        // Ensure the node's zIndex does not go below its parent's zIndex
        const parentNode = newNodes.find((n) => n.id === node.parentId);
        if (parentNode && newZIndex < Number(parentNode.style?.zIndex || 0)) {
          newZIndex = Number(parentNode.style?.zIndex || 0) + 1;
        }

        node.style = {
          ...node.style,
          zIndex: newZIndex,
        };

        return newNodes;
      });

      handleNodeMouseEnter();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setNodes]
  );

  const selectedNodesCount = nodesList.filter((node) => node.selected).length;

  const handleNodeMouseEnter = useCallback(
    (e?: any) => {
      // Clear selection of all nodes except the one being dragged
      if (!nodeObject?.selected && !e?.ctrlKey && selectedNodesCount <= 1) {
        setNodes((nds) =>
          nds.map((n) => ({
            ...n,
            selected: false,
          }))
        );
      }
    },
    [setNodes, nodeObject, selectedNodesCount]
  );

  const onChangeBorderColor = (color: string) => {
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              border_color: color,
            },
          };
        }

        return node;
      })
    );
  };

  const onChangeTextColor = (color: string) => {
    document.execCommand("foreColor", false, color);
    setTextColor(color);
    // setNodes((nodes) =>
    //   nodes.map((node) => {
    //     if (node.id === id) {
    //       return {
    //         ...node,
    //         data: {
    //           ...node.data,
    //           color,
    //         },
    //       };
    //     }

    //     return node;
    //   })
    // );
  };

  const onChangeBackgroundColor = (color: string) => {
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              background_color: color,
            },
          };
        }

        return node;
      })
    );
  };

  const fontSizeMapping = [10, 13, 16, 18, 24, 32, 48]; // Approximate pixel values for font sizes 1 to 7

  const updateToolbarState = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const parentElement = range.commonAncestorContainer.parentElement;

      if (parentElement) {
        const computedStyle = window.getComputedStyle(parentElement);
        
        // Get the font size in pixels
        const fontSizePx = parseInt(computedStyle.fontSize, 10);

        // Find the closest matching font size number (1 to 7)
        const fontSizeNumber = fontSizeMapping.findIndex(
          (size) => size >= fontSizePx
        ) + 1;

        setFontSize(fontSizeNumber.toString());
        setTextColor(computedStyle.color);
      }
    }
  };

  const iconStyle = "h-[20px] text-[#6f717c]";

  const iconWrapper =
    "cursor-pointer p-[10px] rounded-[5px] hover:bg-[#f3f4f5]";

  const borderStyles = ["solid", "dashed", "dotted"];

  const onChangeFontSize = (size: number) => {
    document.execCommand("fontSize", true, size.toString());
    setFontSize(size.toString());
  };

  const handleDoubleClick = () => {
    setContentFocused(true);
    if (contentRef.current) {
      contentRef.current.focus();
    }
  };

  const handleMouseLeave = () => {
    setContentFocused(false);
    if (contentRef.current) {
      contentRef.current.blur();
    }
  };

  // Replace the existing Handle components with these
  return (
    <div
      onMouseEnter={handleNodeMouseEnter}
      onMouseLeave={() => {
        onUpdateNodes();
        handleMouseLeave();
      }}
      onDoubleClick={handleDoubleClick}
      className={`${isContentFocused ? "nodrag nowheel cursor-text" : ""} relative px-4 py-2 h-[100%] bg-transparent border-2 rounded-[11px]`}
      style={{
        borderColor: border_color,
        borderStyle: border_style,
        backgroundColor: background_color,
      }}
    >
      {/* <div
        ref={contentRef}
        contentEditable
        suppressContentEditableWarning
        onInput={onChangeNodeText}
        onKeyDown={handleKeyDown}
        className="nodrag nowheel p-2 bg-transparent border-0 text-zinc-900 text-xl font-semibold text-center resize-none h-full w-full"
        style={{
          boxShadow: "none",
          // height: "100%_!important",
          fontSize: `${fontSize}px`,
          lineHeight: "1.5",
          color,
        }}
        draggable={false}
      >
        {nodeText}
      </div> */}

      {/* Editable Area */}
      <div
        ref={contentRef}
        contentEditable
        onKeyUp={updateToolbarState}
        onMouseUp={updateToolbarState}
        className="p-4 rounded h-full w-full outline-none border-none text-editor-wrapper"
      >
        Start typing here...
      </div>

      {/* Top Handles */}
      {getPositions(topHandles).map((position) => (
        <Handle
          key={`top-target-${position}`}
          type="target"
          className="z-50"
          position={Position.Top}
          id={`top-target-${position}`}
          style={{ left: `${position * 100}%` }}
        />
      ))}
      {getPositions(topHandles).map((position) => (
        <Handle
          key={`top-source-${position}`}
          type="source"
          className="z-50"
          position={Position.Top}
          id={`top-source-${position}`}
          style={{ left: `${position * 100}%` }}
        />
      ))}

      {/* Bottom Handles */}
      {getPositions(bottomHandles).map((position) => (
        <Handle
          key={`bottom-target-${position}`}
          type="target"
          className="z-50"
          position={Position.Bottom}
          id={`bottom-target-${position}`}
          style={{ left: `${position * 100}%` }}
        />
      ))}
      {getPositions(bottomHandles).map((position) => (
        <Handle
          key={`bottom-source-${position}`}
          type="source"
          className="z-50"
          position={Position.Bottom}
          id={`bottom-source-${position}`}
          style={{ left: `${position * 100}%` }}
        />
      ))}

      {/* Left Handles */}
      {getPositions(leftHandles).map((position) => (
        <Handle
          key={`left-target-${position}`}
          type="target"
          className="z-50"
          position={Position.Left}
          id={`left-target-${position}`}
          style={{ top: `${position * 100}%` }}
        />
      ))}
      {getPositions(leftHandles).map((position) => (
        <Handle
          key={`left-source-${position}`}
          type="source"
          className="z-50"
          position={Position.Left}
          id={`left-source-${position}`}
          style={{ top: `${position * 100}%` }}
        />
      ))}

      {/* Right Handles */}
      {getPositions(rightHandles).map((position) => (
        <Handle
          key={`right-target-${position}`}
          type="target"
          className="z-50"
          position={Position.Right}
          id={`right-target-${position}`}
          style={{ top: `${position * 100}%` }}
        />
      ))}
      {getPositions(rightHandles).map((position) => (
        <Handle
          key={`right-source-${position}`}
          type="source"
          className="z-50"
          position={Position.Right}
          id={`right-source-${position}`}
          style={{ top: `${position * 100}%` }}
        />
      ))}

      <NodeResizer
        lineStyle={lineStyle}
        minHeight={minHeight}
        minWidth={minWidth}
        isVisible={true}
        handleStyle={{
          width: "12px",
          height: "12px",
          borderRadius: "50%",
          backgroundColor: "transparent",
          border: 0,
        }}
      />

      <NodeToolbar className="nodrag nowheel absolute top-[10px] left-0">
        <div className="bg-white p-[4px] mb-[10px] rounded-[7px] shadow-[0_0_15px_5px_rgba(0,0,0,0.1)] border border-[#e0e0e0] flex items-center justify-center">
          <Popover
            buttonContent={
              <div className={`${iconWrapper} flex items-center gap-1`}>
                <span className={`${iconStyle} line-height-[20px]`}>
                  {fontSizesOptions.find(
                    (size) => size.size === Number(fontSize)
                  )?.name}
                </span>
                <ChevronDownIcon className="h-3 w-3 text-[#6f717c]" />
              </div>
            }
            popoverContent={
              <div className="max-h-[200px] overflow-y-auto p-2">
                {fontSizesOptions.map((sizeObject) => (
                  <div
                    key={sizeObject.size}
                    className={`px-4 py-1 cursor-pointer hover:bg-gray-100 ${
                      fontSize === sizeObject.size.toString()
                        ? "bg-gray-100"
                        : ""
                    }`}
                    onClick={() => onChangeFontSize(sizeObject.size)}
                  >
                    {sizeObject.name}
                  </div>
                ))}
              </div>
            }
            isCloseOnClick={true}
            classes="w-[150px]"
          />

          <Popover
            buttonContent={
              <div className={`${iconWrapper}`}>
                <PencilIcon
                  className={`${iconStyle} hover:text-green-600 h-[18px]`}
                />
              </div>
            }
            popoverContent={
              <div className="relative p-[0.5px]">
                <p className="text-xs font-semibold text-zinc-500 mb-2">
                  Background Color:
                </p>
                <div className="flex flex-wrap">
                  <ColorToolbar
                    onColorChange={onChangeBackgroundColor}
                    activeColor={background_color}
                  />
                </div>

                <div
                  className={`w-0 h-0 ${
                    (toolbarPosition === "bottom" &&
                      "bottom-[-19px] left-[113px] rotate-180") ||
                    (toolbarPosition === "top" &&
                      "bottom-[-19px] left-[113px] rotate-180") ||
                    (toolbarPosition === "left" &&
                      "top-[50px] left-[-18px] rotate-[35deg]") ||
                    (toolbarPosition === "right" &&
                      "top-[60px] right-[-22px] rotate-90") ||
                    "bottom-[-19px] left-[105px] rotate-180"
                  } border-l-[12px] border-l-transparent border-r-[12px] border-r-transparent border-b-[17px] border-b-white absolute`}
                />
              </div>
            }
            classes={`w-[400px] ${
              (toolbarPosition === "bottom" && "bottom-[45px] left-[-110px]") ||
              (toolbarPosition === "top" && "bottom-[45px] left-[-110px]") ||
              (toolbarPosition === "left" && "left-[145px] top-[-45px]") ||
              (toolbarPosition === "right" && "left-[-425px] bottom-[-65px]") ||
              "bottom-[40px] left-[-110px]"
            }`}
          />

          <Popover
            buttonContent={
              <div className={`${iconWrapper}`}>
                <PencilIcon
                  className={`${iconStyle} hover:text-green-600 h-[18px]`}
                />
              </div>
            }
            popoverContent={
              <div className="relative p-[0.5px]">
                <p className="text-xs font-semibold text-zinc-500 mb-2">
                  Text Color:
                </p>
                <div className="flex flex-wrap">
                  <ColorToolbar
                    onColorChange={onChangeTextColor}
                    activeColor={textColor}
                  />
                </div>

                <div
                  className={`w-0 h-0 ${
                    (toolbarPosition === "bottom" &&
                      "bottom-[-19px] left-[113px] rotate-180") ||
                    (toolbarPosition === "top" &&
                      "bottom-[-19px] left-[113px] rotate-180") ||
                    (toolbarPosition === "left" &&
                      "top-[50px] left-[-18px] rotate-[35deg]") ||
                    (toolbarPosition === "right" &&
                      "top-[60px] right-[-22px] rotate-90") ||
                    "bottom-[-19px] left-[105px] rotate-180"
                  } border-l-[12px] border-l-transparent border-r-[12px] border-r-transparent border-b-[17px] border-b-white absolute`}
                />
              </div>
            }
            classes={`w-[400px] ${
              (toolbarPosition === "bottom" && "bottom-[45px] left-[-110px]") ||
              (toolbarPosition === "top" && "bottom-[45px] left-[-110px]") ||
              (toolbarPosition === "left" && "left-[145px] top-[-45px]") ||
              (toolbarPosition === "right" && "left-[-425px] bottom-[-65px]") ||
              "bottom-[40px] left-[-110px]"
            }`}
          />

          <Popover
            buttonContent={
              <div className={`${iconWrapper}`}>
                <PaintBrushIcon
                  className={`${iconStyle} hover:text-green-600 h-[18px]`}
                />
              </div>
            }
            popoverContent={
              <div className="relative p-[0.5px]">
                <p className="text-xs font-semibold text-zinc-500">
                  Border Style:
                </p>
                <ul className="flex flex-row gap-2 my-3">
                  {borderStyles.map((style, index) => (
                    <li key={index} onClick={() => applyBorderStyle(style)}>
                      <div
                        className={`border-2 border-zinc-800 w-[50px] h-[25px] p-2 cursor-pointer transition-all hover:scale-110 ${style === border_style ? "scale-110 shadow-lg" : ""}`}
                        style={{ borderStyle: style }}
                      ></div>
                    </li>
                  ))}
                </ul>
                <p className="text-xs font-semibold text-zinc-500 mb-2">
                  Border Color:
                </p>
                <div className="flex flex-wrap">
                  <ColorToolbar
                    onColorChange={onChangeBorderColor}
                    activeColor={border_color}
                  />
                </div>

                <div
                  className={`w-0 h-0 ${
                    (toolbarPosition === "bottom" &&
                      "bottom-[-19px] left-[113px] rotate-180") ||
                    (toolbarPosition === "top" &&
                      "bottom-[-19px] left-[113px] rotate-180") ||
                    (toolbarPosition === "left" &&
                      "top-[50px] left-[-18px] rotate-[35deg]") ||
                    (toolbarPosition === "right" &&
                      "top-[60px] right-[-22px] rotate-90") ||
                    "bottom-[-19px] left-[105px] rotate-180"
                  } border-l-[12px] border-l-transparent border-r-[12px] border-r-transparent border-b-[17px] border-b-white absolute`}
                />
              </div>
            }
            classes={`w-[400px] ${
              (toolbarPosition === "bottom" && "bottom-[45px] left-[-110px]") ||
              (toolbarPosition === "top" && "bottom-[45px] left-[-110px]") ||
              (toolbarPosition === "left" && "left-[145px] top-[-45px]") ||
              (toolbarPosition === "right" && "left-[-425px] bottom-[-65px]") ||
              "bottom-[40px] left-[-110px]"
            }`}
          />

          {nodesList?.length > 1 && (
            <div className="flex items-center justify-center gap-2">
              {Number(nodeObject?.style?.zIndex) < findMaxZIndex(nodesList) && (
                <>
                  <div
                    className={`${iconWrapper} order-3`}
                    onClick={() => bringNodeToFront(id, true)}
                    data-tooltip-id="move-absolute-top-node-tooltip"
                  >
                    <ChevronDoubleUpIcon className={`${iconStyle}`} />
                  </div>
                </>
              )}

              {Number(nodeObject?.style?.zIndex) > findMinZIndex(nodesList) && (
                <>
                  <div
                    className={`${iconWrapper} order-4`}
                    onClick={() => sendNodeToBack(id, true)}
                    data-tooltip-id="move-absolute-bottom-node-tooltip"
                  >
                    <ChevronDoubleDownIcon className={`${iconStyle}`} />
                  </div>
                </>
              )}
            </div>
          )}

          <div
            onClick={onDelete}
            className={`${iconWrapper}`}
            data-tooltip-id="delete-node-tooltip"
          >
            <TrashIcon className={`${iconStyle}`} />
          </div>
          {hasParent && (
            <div
              className={`${iconWrapper}`}
              data-tooltip-id="detach-node-tooltip"
            >
              <ArrowTopRightOnSquareIcon
                onClick={onDetach}
                className={`${iconStyle}`}
              />
            </div>
          )}
        </div>
      </NodeToolbar>

      <Tooltip className="z-[50]" id="delete-node-tooltip" place="top">
        Delete
      </Tooltip>

      <Tooltip className="z-[50]" id="detach-node-tooltip" place="top">
        Detach
      </Tooltip>
    </div>
  );
}

type IsEqualCompareObj = {
  minWidth: number;
  minHeight: number;
  hasChildNodes: boolean;
};

function isEqual(prev: IsEqualCompareObj, next: IsEqualCompareObj): boolean {
  return (
    prev.minWidth === next.minWidth &&
    prev.minHeight === next.minHeight &&
    prev.hasChildNodes === next.hasChildNodes
  );
}

export default memo(TextNode);
