import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ArrowDownCircleIcon,
  TrashIcon,
  ArrowUpOnSquareStackIcon,
  PencilIcon,
  PlusIcon,
  SparklesIcon,
} from '@heroicons/react/24/outline';
import { useAppDispatch, useAppSelector } from '../../hooks';
import WrapperLoader from '../../components/wrapperLoader';

import AIChatTempDetail from '../../components/aiChatTempDetail';
import CustomButton from '../../components/customButton';
import {
  getUserAllAiAssistants,
  createUserAiAssistant,
  updateUserAiAssistant,
  deleteUserAiAssistant,
  getUserAiAssistant,
  publishUserAiAssistant,
  unPublishUserAiAssistant,
  cloneFromTemplateAiPrompt,
} from '../../redux/userAiAssistants/userAiAssistantsApi';
import CustomDrawer from '../../components/customDrawer';
import ConfirmationModal from '../../components/confirmationModal';
import CreationForm from '../aiAssistants/CreationForm';
import PublishConfirmation from '../../components/publishConfirmation';
import { getLastIdFromUrl } from '../../utils/utilities';
import { createConversation } from '../../redux/chatGPT/chatGPTApi';
import CustomModal from '../../components/customModal';
import ProjectsSelection from '../aiAssistants/ProjectsSelectionForm';
import { isAiAssistantPage } from '../../utils/navigation';
import BrandedSelect from '../../components/brandedSelect';
import { useNotification } from '../../components/apiNotification';

type EditMode = 'create' | 'update' | 'publish';

const AiAssistantsDetails = () => {
  const [showTempDetail, setShowTempDetail] = useState<any>(null);
  const [allTemplatesLoader, setAllTemplatesLoader] = useState(false);
  const [projectSelectionModal, setProjectSelectionModal] = useState(false);
  const [selectedAIChatId, setSelectedAIChatId] = useState('');
  const [selectProjectLoader, setSelectProjectLoader] = useState(false);

  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState<EditMode | null>(null);
  const [formLoader, setFormLoader] = useState(false);
  const [assistantByIdLoader, setAssistantByIdLoader] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [selectedId, setSelectedId] = useState<any>(null);
  const [confirmationLoader, setConfirmationLoader] = useState(false);
  const [publishingLoader, setPublishingLoader] = useState(false);
  const [publishConfirmation, setPublishConfirmation] = useState(false);
  const [cloneAiPromptLoader, setCloneAiPromptLoader] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { getUserAiAssistantRes, getPublicAiAssistantRes } = useAppSelector((state) => state.userAiAssistants);
  const { addNotification } = useNotification();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          setAllTemplatesLoader(true);
          await dispatch(
            getUserAllAiAssistants({
              accessToken,
            })
          );

          setAllTemplatesLoader(false);
        }
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOpenEditModal = (id: string, mode: EditMode) => {
    setEditMode(mode);
    setOpen(true);
    gettingSingleAiAssistant(id);
  };

  const onCloseModal = () => {
    setOpen(false);
    setEditMode(null);
  };

  const updatingAIAssistant = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            updateUserAiAssistant({
              body,
              accessToken,
              resource_id: getLastIdFromUrl(window.location.pathname),
            })
          )
            .then((res) => {
              onCloseModal();
              setFormLoader(false);
              setShowTempDetail(res?.payload?.data);
            })
            .catch(() => {
              setFormLoader(false);
            });
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const creatingAIAssistant = (body: any) => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          setFormLoader(true);

          await dispatch(createUserAiAssistant({ body, accessToken }))
            .then(() => {
              onCloseModal();
              setFormLoader(false);
            })
            .catch(() => {
              setFormLoader(false);
            });
        }
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };

    fetchData();
  };

  const publishingAIAssistant = (body: any) => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          setFormLoader(true);

          const res = await dispatch(
            publishUserAiAssistant({
              accessToken,
              resource_id: getLastIdFromUrl(window.location.pathname),
              body,
            })
          );

          if (res?.payload) {
            setShowTempDetail((prev: any) => {
              return {
                ...prev,
                privacy: false,
              };
            });
          }
        }
      } catch (error) {
        console.error('Error getting access token:', error);
      } finally {
        onCloseModal();
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const unPublishingAIAssistant = () => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          setPublishingLoader(true);

          await dispatch(
            unPublishUserAiAssistant({
              accessToken,
              resource_id: getLastIdFromUrl(window.location.pathname),
            })
          )
            .then((res) => {
              if (res?.payload) {
                setShowTempDetail((prev: any) => {
                  return {
                    ...prev,
                    privacy: true,
                  };
                });

                setPublishConfirmation(false);
              }
              setPublishingLoader(false);
            })
            .catch(() => {
              setPublishingLoader(false);
            });
        }
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };

    fetchData();
  };

  const onSubmitAiAssistant = async (body: any) => {
    if (editMode === 'update') {
      updatingAIAssistant(body);
    } else if (editMode === 'create') {
      creatingAIAssistant(body);
    } else if (editMode === 'publish') {
      publishingAIAssistant(body);
    }
  };

  const gettingSingleAiAssistant = (id: string) => {
    const fetchData = async () => {
      setAssistantByIdLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) await dispatch(getUserAiAssistant({ accessToken, resource_id: id }));
        setAssistantByIdLoader(false);
      } catch (error) {
        console.error('Error getting access token:', error);
        setAssistantByIdLoader(false);
      }
    };

    fetchData();
  };

  const deletingAIAssistant = () => {
    const fetchData = async () => {
      setConfirmationLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(deleteUserAiAssistant({ accessToken, resource_id: selectedId }))
            .then(() => {
              setConfirmationLoader(false);
              deletingModalClose();
              setShowTempDetail(null);
              navigate('/ai-assistants');
            })
            .catch(() => {
              setConfirmationLoader(false);
            });
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        setConfirmationLoader(false);
      }
    };

    fetchData();
  };

  const deletingModalOpen = (id: string) => {
    setDeleteConfirm(true);
    setSelectedId(id);
  };

  const deletingModalClose = () => {
    setDeleteConfirm(false);
    setSelectedId(null);
  };

  const onOpeningProjectSelection = (id: string) => {
    setSelectedAIChatId(id);
    setProjectSelectionModal(true);
  };

  const onClosingProjectSelection = () => {
    setSelectedAIChatId('');
    setProjectSelectionModal(false);
  };

  const creatingAIChat = (project_id: string, templateType: string) => {
    const fetchData = async () => {
      setSelectProjectLoader(true);

      try {
        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
          throw new Error('No access token');
        }

        const res = await dispatch(
          createConversation({
            body: {},
            accessToken,
            project_id,
            conversation_configuration_id: selectedAIChatId,
            template_type: templateType,
          })
        );

        if (res?.payload) {
          navigate(
            {
              pathname: `/projects/${project_id}`,
              search: new URLSearchParams({ active: 'documents' }).toString(),
            },
            {
              state: { conversation: res.payload.data },
            }
          );
          onClosingProjectSelection();
        }
      } catch (error) {
        console.error('Error getting access token:', error);
      } finally {
        setSelectProjectLoader(false);
      }
    };
    fetchData();
  };

  const cloningAiPromptTemplate = () => {
    const fetchData = async () => {
      setCloneAiPromptLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            cloneFromTemplateAiPrompt({
              accessToken,
              id: getLastIdFromUrl(window.location.pathname),
            })
          )
            .then((res) => {
              if (res?.payload?.data) {
                const response = res?.payload?.data;
                setCloneAiPromptLoader(false);
                addNotification(
                  'Template Saved Successfully',
                  'We saved this template in your private Ai Templates. Click the button to see the details.',
                  'Show Prompt',
                  () => navigate(`/ai-assistant/${response?.id}`)
                );
              }
            })
            .catch(() => {
              setCloneAiPromptLoader(false);
            });
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        setCloneAiPromptLoader(false);
      }
    };

    fetchData();
  };

  return (
    <>
      <WrapperLoader loading={allTemplatesLoader}>
        <div>
          <div className='py-3 border-b border-gray-200 text-right'>
            <div className='px-6 flex items-center justify-between w-full'>
              <h3 className='text-sm font-semibold flex items-center text-customLightBlue'>
                <SparklesIcon className='mr-2 h-5 text-zinc-500 ' />

                {isAiAssistantPage(location, 'private') ? (
                  <span>{getUserAiAssistantRes?.title}</span>
                ) : (
                  <span>{getPublicAiAssistantRes?.title}</span>
                )}
              </h3>

              <div className='flex'>
                {isAiAssistantPage(location, 'private') ? (
                  <>
                    <BrandedSelect
                      className='ml-3'
                      icon={<ArrowUpOnSquareStackIcon />}
                      loading={publishingLoader || (editMode === 'publish' && formLoader)}
                      onOptionChange={({ value }) => {
                        if (value === 'publish') {
                          onOpenEditModal(showTempDetail?.id, 'publish');
                        } else if (value === 'unpublish') {
                          setPublishConfirmation(true);
                        }
                      }}
                      highlighted={getUserAiAssistantRes?.privacy === false}
                    >
                      <BrandedSelect.Option
                        value='publish'
                        title='Published'
                        description='Make this template public and available to the entire community.'
                        selected={getUserAiAssistantRes?.privacy === false}
                      />
                      <BrandedSelect.Option
                        value='unpublish'
                        title='Unpublished'
                        description='This template is private and only you have access to it.'
                        selected={getUserAiAssistantRes?.privacy === true}
                      />
                    </BrandedSelect>

                    <CustomButton
                      text='Delete'
                      type='button'
                      onClickBtn={() => deletingModalOpen(getUserAiAssistantRes?.id)}
                      beforeIcon={<TrashIcon className='h-4 mr-1' />}
                      loading={false}
                      buttonType='secondary'
                      btnStyle='ml-3'
                    />

                    <CustomButton
                      text='Edit'
                      type='button'
                      onClickBtn={() => onOpenEditModal(showTempDetail?.id, 'update')}
                      beforeIcon={<PencilIcon className='h-4 mr-1' />}
                      loading={false}
                      buttonType='secondary'
                      btnStyle='ml-3'
                    />
                    <CustomButton
                      text='Use'
                      type='button'
                      onClickBtn={() => onOpeningProjectSelection(getUserAiAssistantRes?.id)}
                      beforeIcon={<PlusIcon className='h-4 mr-1' />}
                      loading={selectProjectLoader}
                      buttonType='primary'
                      btnStyle='ml-3'
                    />
                  </>
                ) : (
                  <CustomButton
                    text='Save'
                    type='button'
                    onClickBtn={() => cloningAiPromptTemplate()}
                    beforeIcon={<ArrowDownCircleIcon className='h-4 mr-1' />}
                    loading={cloneAiPromptLoader}
                    buttonType='primary'
                  />
                )}
              </div>
            </div>
          </div>

          <div className='px-6'>
            <AIChatTempDetail
              onCancel={() => setShowTempDetail(null)}
              onUse={onOpeningProjectSelection}
              isModal={false}
              showTabs={false}
            />
          </div>
        </div>
      </WrapperLoader>

      <CustomDrawer.AiAssistant
        open={open}
        onCloseModal={onCloseModal}
        headerClassName='bg-gradient-primary'
        mode={editMode}
      >
        <WrapperLoader loading={assistantByIdLoader}>
          <CreationForm
            onCloseModal={onCloseModal}
            editData={getUserAiAssistantRes}
            onSubmitForm={onSubmitAiAssistant}
            loader={formLoader}
            editMode={editMode === 'update' || editMode === 'publish'}
            isPublishMode={editMode === 'publish'}
          />
        </WrapperLoader>
      </CustomDrawer.AiAssistant>

      <ConfirmationModal
        open={deleteConfirm}
        closingModal={() => deletingModalClose()}
        onDeleting={deletingAIAssistant}
        deleteLoader={confirmationLoader}
      />

      {getUserAiAssistantRes?.privacy === false && (
        <PublishConfirmation
          open={publishConfirmation}
          closingModal={() => setPublishConfirmation(false)}
          onClickYes={unPublishingAIAssistant}
          loading={publishingLoader}
          privacy={false}
        />
      )}

      {isAiAssistantPage(location, 'private') && (
        <CustomModal
          open={projectSelectionModal}
          onCloseModal={() => onClosingProjectSelection()}
          title={
            <div>
              <h1 className='font-semibold text-lg mb-1'>Create a new Chat</h1>
              <p className='text-xs font-normal'>Select project and start a new chat</p>
            </div>
          }
          size='max-w-lg'
        >
          <ProjectsSelection
            onCloseModal={() => onClosingProjectSelection()}
            loading={selectProjectLoader}
            onSubmitData={creatingAIChat}
            templateType='private'
          />
        </CustomModal>
      )}
    </>
  );
};

export default AiAssistantsDetails;
