import { classNames } from 'src/utils/utilities';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

interface RangeInputProps {
  value: string;
  onValueChange: (value: string) => void;
  label?: string;
  defaultValue?: string;
  min?: string;
  max?: string;
  step?: string;
  className?: string;
}

export function RangeInput({
  value,
  onValueChange,
  label,
  defaultValue,
  min = '0',
  max = '2',
  step = '0.01',
  className = '',
}: RangeInputProps) {
  const progress = ((Number(value) - Number(min)) / (Number(max) - Number(min))) * 100;

  return (
    <div className={classNames('group w-full flex flex-col items-center gap-3 text-customLightBlue', className)}>
      <div className='w-full flex items-center'>
        {label && <label className='text-sm'>{label}</label>}
        <div className='ml-auto flex items-center gap-2'>
          {defaultValue && Number(value) !== Number(defaultValue) && (
            <button
              onClick={() => onValueChange(defaultValue)}
              className='opacity-0 group-hover:opacity-100 transition-opacity'
            >
              <ArrowPathIcon className='w-4 h-4 text-gray-400 hover:text-gray-500' />
            </button>
          )}
          <input
            type='number'
            value={step.includes('.') ? Number(value).toFixed(2) : Number(value)}
            onChange={(e) => onValueChange(e.target.value)}
            min={min}
            max={max}
            step={step}
            style={{ width: `${Math.max(max.length * 10 + 16, 48)}px` }}
            className='no-controls h-6 px-2 text-[0.81rem] leading-none text-center border border-transparent transition-all group-hover:border-gray-200 focus:border-gray-400 focus:outline-none rounded-md'
          />
        </div>
      </div>

      <input
        type='range'
        value={value}
        onChange={(e) => onValueChange(e.target.value)}
        min={min}
        max={max}
        step={step}
        className='w-full'
        style={{ '--range-progress': `${progress}%` } as React.CSSProperties}
      />
    </div>
  );
}

export default RangeInput;
