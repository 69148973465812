// Node groups collection for Azure

export const azure_groups = [
  {
    name: "azure",
    style: {
      group_icon_bg_color: "#268FDD",
      group_border_style: "solid",
      group_bg_color: "#f3f3f3",
    },
    url: "azure-a",
    subdirectory: "other",
    resourceProvider: "Microsoft.Resources/resourceGroups"
  },
  {
    name: "subscription",
    style: {
      group_icon_bg_color: "#0f172a",
      group_border_style: "solid",
      group_bg_color: "#f3f3f3",
    },
    url: "subscriptions",
    subdirectory: "general",
    resourceProvider: "Microsoft.Resources/subscriptions"
  },
  {
    name: "resource_group",
    style: {
      group_icon_bg_color: "#0f172a20",
      group_border_style: "solid",
      group_bg_color: "#f3f3f3",
    },
    url: "resource-groups",
    subdirectory: "general",
    resourceProvider: "Microsoft.Resources/resourceGroups"
  },
  {
    name: "vNet",
    style: {
      group_icon_bg_color: "#0f172a",
      group_border_style: "solid",
      group_bg_color: "#f3f3f3"
    },
    url: "virtual-networks",
    subdirectory: "networking",
    resourceProvider: "Microsoft.Network/virtualNetworks"
  },
  {
    name: "Subnet",
    style: {
      group_icon_bg_color: "#0f172a",
      group_border_style: "dashed",
      group_bg_color: "#f3f3f3"
    },
    url: "subnet",
    subdirectory: "networking",
    resourceProvider: "Microsoft.Network/virtualNetworks/subnets"
  },
  {
    name: "AKS",
    style: {
      group_icon_bg_color: "#0f172a20",
      group_border_style: "solid",
      group_bg_color: "#f3f3f3",
    },
    url: "kubernetes-services",
    subdirectory: "containers",
    resourceProvider: "Microsoft.ContainerService/managedClusters"
  },
  {
    name: "ASP",
    style: {
      group_icon_bg_color: "#0f172a",
      group_border_style: "solid",
      group_bg_color: "#f3f3f3",
    },
    url: "app-service-plans",
    subdirectory: "web",
    resourceProvider: "Microsoft.Web/serverFarms"
  },
  {
    name: "Azure DevOps",
    style: {
      group_icon_bg_color: "#0f172a",
      group_border_style: "solid",
      group_bg_color: "#f3f3f3",
    },
    url: "azure-devops",
    subdirectory: "azure_devops",
    resourceProvider: "Microsoft.DevOps/organizations"
  },
];
