import { PaperAirplaneIcon, PlusIcon } from '@heroicons/react/24/outline';
import { StopCircleIcon } from '@heroicons/react/24/solid';
import { noop } from 'lodash';
import { FormEvent, KeyboardEvent, MutableRefObject, useImperativeHandle, useRef, useState } from 'react';
import { classNames } from 'src/utils/utilities';
import { GradientProgressLoader } from '../loader';
import { HtmlReferenceBox } from 'src/pages/projectsDetails/leftSideBar/chatGPT/userMessage';
import { useRegisterTourHandle } from 'src/hooks/useUserTour';

export type InputRef = {
  textareaEl: HTMLTextAreaElement | null;
  containerEl: HTMLDivElement | null;
  clear(): void;
  write(text: string): void;
  moveCursorToEnd(): void;
};

interface ChatGPTInputProps {
  inputRef: MutableRefObject<InputRef | null> | ((ref: InputRef) => void);
  onSubmit: Function;
  messageLoader: boolean;
  onStop: Function;
  htmlReference?: string | null;
}

export default function ChatGPTInput({ inputRef, onSubmit, messageLoader, onStop, htmlReference }: ChatGPTInputProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [isInputEmpty, setIsInputEmpty] = useState(true);

  const handleChange = ({ currentTarget }: { currentTarget: HTMLTextAreaElement }) => {
    currentTarget.style.height = 'auto';
    currentTarget.style.height = `${currentTarget.scrollHeight}px`;

    setIsInputEmpty(currentTarget.value.trim() === '');
  };

  useImperativeHandle(inputRef, () => ({
    textareaEl: textareaRef.current,
    containerEl: containerRef.current,
    clear: () => {
      if (textareaRef.current) {
        textareaRef.current.value = '';
        handleChange({ currentTarget: textareaRef.current });
      }
    },
    write: (text: string) => {
      if (textareaRef.current) {
        const { value } = textareaRef.current;
        textareaRef.current.value = value ? `${value}\n\n${text}` : text;
        handleChange({ currentTarget: textareaRef.current });
      }
    },
    moveCursorToEnd: () => {
      const { current: textareaEl } = textareaRef;

      if (!textareaEl) return;

      const { value } = textareaEl;

      textareaEl.focus();
      textareaEl.setSelectionRange(value.length, value.length);
    },
  }));

  const handleSubmit = (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    onSubmit();
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSubmit();
    }
  };

  useRegisterTourHandle('ChatGPTInput', {
    setMessage: (message: string) => {
      if (textareaRef.current) {
        textareaRef.current.value = message;
        handleChange({ currentTarget: textareaRef.current });
      }
    },
    sendMessage: handleSubmit,
  });

  const classes = {
    button: classNames(
      `group/btn p-1 rounded-md flex items-center justify-center text-xs font-semibold`,
      'transition-colors enabled:hover:bg-zinc-100 enabled:focus-visible:outline enabled:focus-visible:outline-2'
    ),
    disabled: 'disabled:opacity-50 disabled:cursor-not-allowed',
    icon: 'size-6 text-customDarkBlue transition-colors group-enabled/btn:group-hover/btn:text-blue-600',
  };

  return (
    <div
      ref={containerRef}
      className='flex items-start'
    >
      <div className='min-w-0 flex-1'>
        <form
          action='#'
          onSubmit={handleSubmit}
          className={classNames(
            'group px-3 rounded-lg shadow-sm ring-1 ring-inset ring-gray-300 bg-white',
            'transition-colors focus-within:ring-2 focus-within:ring-blue-600'
          )}
        >
          <div className='py-3'>
            {htmlReference && <HtmlReferenceBox html={htmlReference} />}
            <div className={classNames('max-h-[155px] overflow-auto')}>
              <label
                htmlFor='message'
                className='sr-only'
              >
                Type...
              </label>
              <textarea
                ref={textareaRef}
                name='message'
                rows={1}
                id='message'
                className={classNames(
                  'block w-full h-6 p-0 px-1 resize-none border-0 bg-transparent',
                  'text-customDarkBlue placeholder:text-gray-400 focus:ring-0 text-base leading-6'
                )}
                placeholder='Start typing and ask anything...'
                autoFocus={true}
                onChange={handleChange}
                onKeyDown={handleKeyPress}
              />
            </div>
          </div>

          <div className='w-full pb-3'>
            <span
              aria-hidden
              className={classNames(
                'relative block w-full h-px bg-zinc-200 mb-2',
                !messageLoader &&
                  classNames(
                    'after:absolute after:inset-0 after:bg-blue-600 after:rounded-full after:z-10',
                    'after:transition-transform after:ease-linear after:duration-0 after:scale-x-0',
                    'group-focus-within:after:duration-200 group-focus-within:after:scale-x-100'
                  )
              )}
            >
              {messageLoader && <GradientProgressLoader className='absolute left-0 top-1/2 -translate-y-1/2' />}
            </span>
            <div className='flex items-center justify-end'>
              <div className='mr-2 pr-2 border-r border-zinc-200 flex items-center gap-2'>
                <button
                  type='button'
                  disabled={!!messageLoader}
                  className={classNames(classes.button, classes.disabled)}
                >
                  <PlusIcon className={classNames(classes.icon)} />
                </button>
              </div>
              <button
                type={messageLoader ? 'button' : 'submit'}
                disabled={isInputEmpty && !messageLoader}
                onClick={() => (messageLoader ? onStop() : noop)}
                className={classNames(classes.button, classes.disabled, messageLoader && 'hover:!bg-transparent', 'chat-send-button')}
              >
                {messageLoader ? (
                  <StopCircleIcon className={classNames(classes.icon, '!text-customDarkBlue')} />
                ) : (
                  <PaperAirplaneIcon className={classNames(classes.icon)} />
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
