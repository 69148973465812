import { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { DocumentTextIcon } from '@heroicons/react/24/outline';
import InputField from '../../../components/formFields/inputField';
import CustomButton from '../../../components/customButton';
import SelectField from '../../../components/formFields/selectField';
import TextAreaField from '../../../components/formFields/textareaField';
import {
  categoriesList,
  classNames,
  getCategoryTitle,
  getCategoryValue,
  getStatusTitle,
  getStatusValue,
  statusList,
} from '../../../utils/utilities';
import RadioGroup from '../../../components/formFields/radioGroup';
import { Category } from 'src/type';
import { DrawerContext } from 'src/contexts/drawer';
import KeyboardShortcut from 'src/components/KeyboardShortcut';
import { useTourHandlers } from 'src/hooks/useUserTour';
import { type TypeOptions, type } from 'src/utils/userTourUtils';
import { EXAMPLE_PROJECT, projectCreationJourney } from 'src/utils/user-tour';

interface TBarFormProps {
  onCloseModal?: any;
  editData: any;
  onSubmitForm: Function;
  loader: boolean;
  editMode: boolean;
  isProjectDetail?: boolean;
  documentsList?: any;
}

export default function ProjectForm({
  onCloseModal,
  editData,
  onSubmitForm,
  loader,
  editMode,
  isProjectDetail,
  documentsList,
}: TBarFormProps) {
  const [selectedComplexity, setSelectedComplexity] = useState('low');
  const [selectedStatus, setSelectedStatus] = useState('Not Started');
  const [selectedCategory, setSelectedCategory] = useState('Cloud Architecture');

  const { width: drawerWidth, insetBlock } = useContext(DrawerContext);

  const validationObject = {
    title: yup.string().required('Title is required'),
    description: yup.string().required('Description is required'),
  };

  const validationSchema = yup.object().shape(validationObject);

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: editData?.title ?? '',
      description: editData?.description ?? '',
    },
    validationSchema,
    onSubmit: (values) => {
      const payloadObj = {
        title: values?.title,
        status: selectedStatus,
        category: selectedCategory,
        complexity: selectedComplexity,
        description: values?.description,
        autosave: editData?.autosave || false,
      };

      onSubmitForm(payloadObj);
    },
  });

  const complexityList = [
    { id: 1, value: 'low', title: 'Low' },
    { id: 2, value: 'medium', title: 'Medium' },
    { id: 3, value: 'complex', title: 'Complex' },
  ];

  useEffect(() => {
    if (editData?.complexity) {
      setSelectedComplexity(editData?.complexity);
      setSelectedCategory(editData?.category || 'Cloud Architecture');
      setSelectedStatus(editData?.status || 'Not Started');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);

  const typeFormikField = async (field: string, text: string, options?: TypeOptions) => {
    await type(`#${field}`, text, options);
    formik.setFieldValue(field, text);
  }

  useTourHandlers(projectCreationJourney, {
    'step-project-title-input': {
      setup: async () => {
        typeFormikField('title', EXAMPLE_PROJECT.title);
      },
      beforePrev: () => {
        formik.setFieldValue('title', '');
      }
    },
    'step-project-status-select': {
      setup: () => {
        setSelectedStatus(EXAMPLE_PROJECT.status);
      },
      beforePrev: () => {
        setSelectedStatus('Not Started');
      }
    },
    'step-project-category-select': {
      setup: () => {
        setSelectedCategory(EXAMPLE_PROJECT.category);
      },
      beforePrev: () => {
        setSelectedCategory('Cloud Architecture');
      }
    },
    'step-project-complexity-select': {
      setup: () => {
        setSelectedComplexity(EXAMPLE_PROJECT.complexity);
      },
      beforePrev: () => {
        setSelectedComplexity('low');
      }
    },
    'step-project-description-input': {
      setup: () => {
        typeFormikField('description', EXAMPLE_PROJECT.description, { typingSpeed: 5 });
      },
      beforePrev: async () => {
        await formik.setFieldValue('description', '');
      }
    },
  });

  const eachFieldRow =
    'items-start space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5 border-b border-gray-200';

  return (
    <form
      className={classNames(
        'flex flex-col bg-white',
        !isProjectDetail && 'shadow-xl',
        insetBlock > 0 && 'rounded-b-lg'
      )}
      onSubmit={formik.handleSubmit}
    >
      {/* container */}
      <div
        className={isProjectDetail ? '' : 'overflow-y-scroll'}
        style={{
          height: isProjectDetail
            ? '100%'
            : editMode
              ? `calc(100vh - ${165 + 2 * insetBlock}px)`
              : `calc(100vh - ${164 + 2 * insetBlock}px)`,
        }}
      >
        {/* Title */}
        <div className={classNames(eachFieldRow, 'step-project-title-input')}>
          <div className='flex items-center'>
            <label
              htmlFor='title'
              className='block mr-3 text-xs font-medium leading-6 text-customDarkBlue'
            >
              Title
            </label>
          </div>
          <div className='sm:col-span-2'>
            <InputField
              type='text'
              id='title'
              {...formik.getFieldProps('title')}
              placeholder='Enter project name'
            />

            {formik.touched.title && formik.errors.title ? (
              <div className='text-red-600 text-xs'>{formik.errors.title}</div>
            ) : null}
          </div>
        </div>

        {/* Status */}
        <div className={classNames(eachFieldRow, 'step-project-status-select')}>
          <div>
            <label
              htmlFor='status'
              className='block text-xs font-medium leading-6 text-customDarkBlue'
            >
              Status
            </label>
          </div>
          <div className='sm:col-span-2'>
            <SelectField
              options={statusList}
              value={getStatusValue(selectedStatus)}
              onSelect={(statusValue) => setSelectedStatus(getStatusTitle(statusValue))}
            />
          </div>
        </div>

        {/* Category */}
        <div className={classNames(eachFieldRow, 'step-project-category-select')}>
          <div>
            <label
              htmlFor='category'
              className='block text-xs font-medium leading-6 text-customDarkBlue'
            >
              Category
            </label>
          </div>
          <div className='sm:col-span-2'>
            <SelectField
              options={categoriesList}
              value={getCategoryValue(selectedCategory)}
              onSelect={(categoryValue) => setSelectedCategory(getCategoryTitle(categoryValue as Category))}
            />
          </div>
        </div>

        {/* Complexity */}
        <div className={classNames(eachFieldRow, 'step-project-complexity-select')}>
          <div className='flex items-center'>
            <label
              htmlFor='complexity'
              className='block text-xs font-medium leading-6 text-customDarkBlue'
            >
              Complexity
            </label>
          </div>

          <div className={`sm:col-span-2 ${isProjectDetail ? 'ml-[10px]' : ''}`}>
            <RadioGroup
              onChangeRadio={setSelectedComplexity}
              value={selectedComplexity}
              radioList={complexityList}
            />
          </div>
        </div>

        {/* Description */}
        <div className={classNames(eachFieldRow, 'step-project-description-input')}>
          <div className='flex items-center'>
            <label
              htmlFor='description'
              className='block text-xs font-medium leading-6 text-customDarkBlue'
            >
              Description
            </label>
          </div>
          <div className='sm:col-span-2'>
            <TextAreaField
              id='description'
              {...formik.getFieldProps('description')}
              placeholder='Enter a brief description.'
            />

            {formik.touched.description && formik.errors.description ? (
              <div className='text-red-600 text-xs'>{formik.errors.description}</div>
            ) : null}
          </div>
        </div>

        {/* Documents List */}
        {documentsList?.length > 0 && (
          <div className='px-4 mb-8 sm:px-6 sm:py-5'>
            <div className='block text-xs font-medium leading-6 text-customDarkBlue sm:mt-1.5 mb-3'>Documents List</div>
            {documentsList.map((data: any) => (
              <div
                key={data.id}
                className='group text-xs flex items-center mb-4 hover:text-amber-500'
              >
                <DocumentTextIcon className='mr-2 h-4 text-zinc-500 group-hover:text-amber-500' />
                <span>{data.title}</span>
              </div>
            ))}
          </div>
        )}
      </div>

      {isProjectDetail ? (
        <div className='pl-6 py-5 text-right'>
          <CustomButton
            type='submit'
            onClickBtn={() => console.log('Clicking...')}
            text='Save'
            loading={loader}
            beforeIcon={
              <KeyboardShortcut.ControlEnter
                className='mr-2'
                onTrigger={formik.handleSubmit}
              />
            }
            buttonType='primary'
          />
        </div>
      ) : (
        <div
          className={classNames(
            'bg-white border-t border-gray-200 px-4 py-5 sm:px-6',
            insetBlock > 0 ? 'rounded-b-lg' : 'fixed bottom-0'
          )}
          style={{ width: `${drawerWidth - 40}px` }}
        >
          {/* Action buttons */}
          <div className='flex justify-end space-x-3'>
            {onCloseModal && (
              <button
                type='button'
                className='rounded-md bg-white px-3 py-2 text-xs font-semibold text-customDarkBlue shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                onClick={onCloseModal}
              >
                Cancel
              </button>
            )}

            <CustomButton
              type='submit'
              onClickBtn={() => console.log('Clicking...')}
              text='Save'
              loading={loader}
              beforeIcon={
                <KeyboardShortcut.ControlEnter
                  className='mr-2'
                  onTrigger={formik.handleSubmit}
                />
              }
              buttonType='primary'
              btnStyle='step-project-save-btn'
            />
          </div>
        </div>
      )}
    </form>
  );
}
