import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MainHeader from "../components/header";
import Sidebar from "../components/sidebar";
import { classNames } from "../utils/utilities";
import {
  setCollapseSidebar,
  setShowPricingModal,
  setShowCloseModalOption,
} from "../redux/profile/profileSlice";
import { useAppSelector, useAppDispatch } from "../hooks";
import { AccessTokenProvider } from "../contexts/AccessTokenContext";
import { PricingCards } from "../components/pricingTable";
import CustomModal from "../components/customModal";

interface MainLayoutProps {
  children: React.ReactNode | React.ReactNode[];
}

export default function MainLayout({ children }: MainLayoutProps) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const dispatch = useAppDispatch();
  const location = useLocation();
  const {
    collapseSidebar,
    userTokenInfo,
    showPricingModal,
    showCloseModalOption,
  } = useAppSelector((state) => state.profile);
  const accountStatus = userTokenInfo?.app_meta?.account_status;

  useEffect(() => {
    if (
      accountStatus?.status === "paused" ||
      accountStatus?.status === "canceled"
    ) {
      dispatch(setShowPricingModal(true));
      dispatch(setShowCloseModalOption(false));
    }

    // if (accountStatus?.status === "trialing") {
    //   dispatch(setShowPricingModal(true));
    //   dispatch(setShowCloseModalOption(true));
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountStatus]);

  const onClosePricingModal = () => {
    dispatch(setShowPricingModal(false));
    dispatch(setShowCloseModalOption(false));
  };

  return (
    <AccessTokenProvider>
      <div className="overflow-x-hidden">
        <Sidebar
          classNames={classNames}
          setSidebarOpen={setSidebarOpen}
          sidebarOpen={sidebarOpen}
          setCollapseSidebar={setCollapseSidebar}
          collapseSidebar={collapseSidebar}
        />

        <div>
          <MainHeader
            setSidebarOpen={setSidebarOpen}
            collapseSidebar={collapseSidebar}
            setCollapseSidebar={setCollapseSidebar}
          />

          <div
            id="main_content"
            style={{ boxShadow: "0 0 5px 2px rgba(0, 0, 0, 0.07)" }}
            className={`h-[calc(100vh_-49px)] overflow-hidden bg-white rounded-tl-md ${collapseSidebar ? "lg:ml-[52px]" : "lg:ml-64"} pb-6 ${
              location.pathname === "/home" ||
              location.pathname.includes("template") ||
              location.pathname.includes("ai-assistant") ||
              location.pathname.includes("profile") ||
              location.pathname.includes("document-components")
                ? "mt-[0px]"
                : "mt-[49px]"
            }`}
          >
            <main>{children}</main>
          </div>
        </div>
      </div>

      {showPricingModal && (
        <CustomModal
          open={showPricingModal}
          onCloseModal={() =>
            showCloseModalOption ? onClosePricingModal() : {}
          }
          title={`${showCloseModalOption ? "Upgrade Subscription" : ""}`}
          size="w-full max-w-[1500px]"
        >
          <div className="pt-[40px] pb-[30px]">
            <PricingCards />
          </div>
        </CustomModal>
      )}
    </AccessTokenProvider>
  );
}
