import { type NavigateFunction } from 'react-router-dom';
import type { ITourContext, ITourNavigator, Primitive, TourHandlesMap } from 'src/type';

export class TourContext implements ITourContext {
  constructor(
    private handles: Partial<TourHandlesMap>,
    private params: { current: Record<string, Primitive> },
    private loadingHandler: (isLoading: boolean) => void
  ) {}

  getHandle<T extends keyof TourHandlesMap>(componentName: T): TourHandlesMap[T] {
    const handle = this.handles[componentName];

    if (!handle) {
      throw new Error(`Handle for ${componentName} component not found. Please check if the component is mounted.`);
    }

    return handle;
  }

  setParam(key: string, value: Primitive) {
    this.params.current[key] = value;
  }

  getParam<T extends Primitive = Primitive>(key: string) {
    return this.params.current[key] as T | null;
  }

  deleteParam(key: string) {
    delete this.params.current[key];
  }

  setLoading(isLoading: boolean) {
    this.loadingHandler(isLoading);
  }
}

export class TourNavigator implements ITourNavigator {
  public location: Location;

  constructor(
    private params: { current: Record<string, Primitive> },
    public navigate: NavigateFunction
  ) {
    this.location = window.location;
  }

  getParam<T extends Primitive = Primitive>(key: string) {
    return this.params.current[key] as T | null;
  }
}

export function wait(ms: number) {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

export interface TypeOptions {
  typingSpeed?: number;
}

export async function type(selector: string, text: string, { typingSpeed = 50 }: TypeOptions = {}) {
  // Type each character with a small delay
  let currentValue = '';

  const input = document.querySelector(selector);

  if (!(input instanceof HTMLInputElement || input instanceof HTMLTextAreaElement)) {
    throw new Error(`Input element with selector "${selector}" not found`);
  }

  input.value = '';

  for (let i = 0; i < text.length; i++) {
    currentValue += text[i];
    input.value = currentValue;
    await wait(typingSpeed);
  }
}

interface WaitForElementOptions {
  timeout?: number;
  interval?: number;
}

export async function waitForElement(
  selector: string,
  { timeout = 5000, interval = 100 }: WaitForElementOptions = {}
): Promise<HTMLElement> {
  const startTime = Date.now();

  while (Date.now() - startTime < timeout) {
    const element = document.querySelector<HTMLElement>(selector);

    if (element) {
      return element;
    }

    await wait(interval);
  }

  throw new Error(`Element with selector "${selector}" not found after ${timeout}ms`);
}

export async function waitUntilElementRemoved(
  selector: string,
  { timeout = 5000, interval = 100 }: WaitForElementOptions = {}
): Promise<void> {
  const startTime = Date.now();

  while (Date.now() - startTime < timeout) {
    const element = document.querySelector<HTMLElement>(selector);

    if (!element) {
      return;
    }

    await wait(interval);
  }

  throw new Error(`Element with selector "${selector}" still present after ${timeout}ms`);
}
