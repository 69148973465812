import { useAuth0 } from '@auth0/auth0-react';
import { memo, useContext, useEffect, useMemo } from 'react';
import { useState } from 'react';
import RawText from 'src/components/formatter/rawText';
import { getDiagramsList } from 'src/redux/diagrams/diagramsApi';
import { DetailsContext } from 'src/contexts/details/context';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { MessageContext } from 'src/type';
import { getSpecialChatAssistant } from 'src/utils/special-chat-assistants';
import { classNames, getLastIdFromUrl } from 'src/utils/utilities';

interface ValidationMessageProps {
  isEntityLoading: boolean;
  entityTitle: string;
  chatType: string;
}

interface SpecificValidationMessageProps {
  chatType: string;
  messageContext: MessageContext;
}

export function HtmlReferenceBox({ html }: Readonly<{ html: string }>) {
  return (
    <div className={classNames('ck-content', 'mb-2 p-3 border border-gray-300 rounded-lg shadow-sm bg-white')}>
      <div
        className={classNames('!mb-0 line-clamp-4')}
        style={{ fontSize: '13px' }}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </div>
  );
}

function ValidationMessage({ isEntityLoading, entityTitle, chatType }: Readonly<ValidationMessageProps>) {
  const specialChatContext = getSpecialChatAssistant(chatType as any);

  if (!specialChatContext) return null;

  const { userMessage, icon: Icon } = specialChatContext;

  return (
    <p className='text-sm text-gray-600 break-words py-1'>
      <span>{userMessage}</span>
      {isEntityLoading ? (
        <div className='h-[38px] w-full mt-2 bg-blue-200 animate-pulse rounded-md' />
      ) : (
        <div className='flex items-center mt-2 p-3 border border-gray-300 rounded-lg shadow-sm bg-white'>
          <Icon className='h-6 w-6 text-blue-500 mr-3' />
          <span className='text-sm font-semibold text-gray-700'>{entityTitle}</span>
        </div>
      )}
    </p>
  );
}

function DocumentValidationMessage({
  chatType,
  messageContext: { entityId: documentId },
}: Readonly<SpecificValidationMessageProps>) {
  const [areDocumentsLoading, setAreDocumentsLoading] = useState(false);
  const { allDocuments, getAllDocuments } = useContext(DetailsContext);

  useEffect(() => {
    if (!allDocuments) {
      setAreDocumentsLoading(true);
      getAllDocuments?.()?.then(() => setAreDocumentsLoading(false));
    }
  }, [allDocuments, getAllDocuments]);

  const documentTitle = allDocuments?.find((doc: any) => doc.id === documentId)?.title;

  return (
    <ValidationMessage
      chatType={chatType}
      isEntityLoading={areDocumentsLoading}
      entityTitle={documentTitle || 'Document Not Found'}
    />
  );
}

function DiagramValidationMessage({
  chatType,
  messageContext: { entityId: diagramId },
}: Readonly<SpecificValidationMessageProps>) {
  const [areDiagramsLoading, setAreDiagramsLoading] = useState(false);
  const { getDiagramsListRes } = useAppSelector((state) => state.diagrams);
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchDiagrams = async () => {
      if (getDiagramsListRes?.length) return;

      try {
        setAreDiagramsLoading(true);
        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
          throw new Error('No access token');
        }

        await dispatch(
          getDiagramsList({
            accessToken,
            project_id: getLastIdFromUrl(window.location.pathname),
          })
        );
      } catch (error) {
        console.error('Error fetching diagrams:', error);
      } finally {
        setAreDiagramsLoading(false);
      }
    };

    fetchDiagrams();
  }, [getDiagramsListRes, getAccessTokenSilently, dispatch]);

  const diagramTitle = getDiagramsListRes?.find((diagram) => diagram.id === diagramId)?.title;

  return (
    <ValidationMessage
      chatType={chatType}
      isEntityLoading={areDiagramsLoading}
      entityTitle={diagramTitle || 'Diagram Not Found'}
    />
  );
}

function DynamicValidationMessage({ chatType, messageContext }: Readonly<SpecificValidationMessageProps>) {
  const groupName = chatType.split(':')[0];

  const Component = (() => {
    switch (groupName) {
      case 'document':
        return DocumentValidationMessage;
      case 'diagram':
        return DiagramValidationMessage;
      default:
        return null;
    }
  })();

  if (!Component) return null;

  return (
    <Component
      chatType={chatType}
      messageContext={messageContext}
    />
  );
}

function MessageWithHtmlReference({ content }: Readonly<{ content: string }>) {
  const [htmlReference, userMessage] = useMemo(() => {
    const htmlMatch = content.match(/```html\n([\s\S]*?)```\n?(.*)/);

    return htmlMatch ? [htmlMatch[1], htmlMatch[2].trim()] : ['', content];
  }, [content]);

  return (
    <p className='text-base text-gray-600 break-words py-1'>
      <HtmlReferenceBox html={htmlReference} />
      <RawText content={userMessage} />
    </p>
  );
}

function UserMessage({ content: dirtyContent, chatType }: Readonly<{ content: string; chatType: string }>) {
  const content = dirtyContent.replace(/\/\*[\s\S]*?\*\//g, '').trim();

  const validationContext = (() => {
    const isSpecialMessage = content.startsWith('>>pilot');

    if (!isSpecialMessage) return null;

    return JSON.parse(content.slice(7));
  })();

  if (validationContext) {
    return (
      <DynamicValidationMessage
        chatType={chatType}
        messageContext={validationContext}
      />
    );
  }

  if (content.startsWith('```html')) {
    return <MessageWithHtmlReference content={content} />;
  }

  return (
    <p className='text-base text-gray-600 break-words py-1'>
      <RawText content={content} />
    </p>
  );
}

export default Object.assign(memo(UserMessage), { displayName: 'UserMessage' });
