export const vendor_nodes = [
  {
    name: "Other",
    categoryIcon:
      "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/backlog.svg",
    items: [
      "auth0",
      "stripe",
      "open-ai",
      "gitlab",
      "sendgrid",
      "confluence",
      "cloud-flare",
      "wordpress",
      "aws",
      "azure",
      "gcp",
      "microsoft",
      "kubernetes",
      "linkedin",
      "google-analytics",
      "jira",
      "slack",
      "msteams",
      "dropbox",
    ],
  },
  {
    name: "GitHub",
    categoryIcon:
      "https://spnodedata.blob.core.windows.net/nodes/vendor_clean/github/github-logo.svg",
    items: [
      "github-actions",
      "github-runner",
      "billing-and-licencing",
      "code-security",
      "github-connect",
      "github-logo",
      "identity-provider",
      "organization",
      "people",
      "policies"
    ],
  },
  {
    name: "open_ai",
    categoryIcon:
      "https://spnodedata.blob.core.windows.net/nodes/vendor_clean/open_ai/open-ai.svg",
    items: [
      "open-ai",
      "assistant",
      "project",
      "chat",
      "realtime",
      "text-to-speach",
      "service-account",
    ],
  },
];