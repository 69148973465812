import { FC, ReactNode, useState, useMemo, useEffect, useRef, useCallback, memo } from 'react';
import { UserTourContext } from './UserTour.context';
import type { Primitive, StepHandlers, TourHandlesMap, UserJourneyConfig } from 'src/type';
import { useLocation, useNavigate } from 'react-router-dom';
import AppTour from 'src/components/appTour';
import type { Step } from 'react-joyride';
import { TourNavigator } from 'src/utils/userTourUtils';

interface UserTourProviderProps {
  children: ReactNode;
}

const UserTourProvider: FC<UserTourProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const stepHandlersRef = useRef<Record<string, StepHandlers>>({});
  const handlesRef = useRef<Partial<TourHandlesMap>>({});
  const paramsRef = useRef<Record<string, Primitive>>({});

  const [isLoading, setIsLoading] = useState(false);
  const [journey, setJourney] = useState<UserJourneyConfig | null>(null);
  const [isReady, setIsReady] = useState(false);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const currentStepPath = useMemo(
    () =>
      journey?.steps.find(({ steps }, index) => {
        const prevStepsCount = journey.steps.slice(0, index).reduce((acc, { steps }) => acc + steps.length, 0);

        return currentStepIndex < prevStepsCount + steps.length;
      })?.path ?? null,
    [journey, currentStepIndex]
  );

  const isValidPath = useMemo(() => {
    if (!currentStepPath || !pathname) return false;

    const pathPattern = currentStepPath.split('?')[0].split('/');
    const currentPathSegments = pathname.split('?')[0].split('/');

    if (pathPattern.length !== currentPathSegments.length) return false;

    return pathPattern.every((segment, index) => {
      if (segment.startsWith(':')) return true;
      return segment === currentPathSegments[index];
    });
  }, [currentStepPath, pathname]);

  const joyrideSteps = useMemo((): Step[] => {
    if (!journey) return [];

    return journey.steps.reduce<Step[]>((acc, { steps }) => {
      const journeySteps = steps.map<Step>(({ target, title, content, backLabel = 'Back', nextLabel = 'Next' }) => ({
        target: `.${target}`,
        title,
        content,
        locale: { back: backLabel, next: nextLabel },
        disableBeacon: true,
      }));

      return [...acc, ...journeySteps];
    }, []);
  }, [journey]);

  const loadJourney = useCallback(async (newJourney: UserJourneyConfig, params: Record<string, Primitive> = {}) => {
    paramsRef.current = params;

    if (newJourney.handlers) {
      stepHandlersRef.current = newJourney.handlers;
    }

    if ('beforeStart' in newJourney) {
      await newJourney.beforeStart?.(new TourNavigator(paramsRef, navigate));
    }

    setCurrentStepIndex(0);
    setJourney(newJourney);
  }, [navigate]);

  const markUserTourAsReady = useCallback(() => {
    setIsReady(true);
  }, []);

  const endJourney = useCallback(() => {
    setJourney(null);
    setCurrentStepIndex(0);
    paramsRef.current = {};
    stepHandlersRef.current = {};
  }, []);

  const goTo = useCallback((stepIndex: number) => {
    setCurrentStepIndex(stepIndex);
  }, []);

  const addStepHandler = useCallback((target: string, handlers: StepHandlers) => {
    stepHandlersRef.current[target] = handlers;
  }, []);

  const removeStepHandler = useCallback((target: string) => {
    delete stepHandlersRef.current[target];
  }, []);

  const addHandle = useCallback(<T extends keyof TourHandlesMap>(key: T, handle: TourHandlesMap[T]) => {
    handlesRef.current[key] = handle;
  }, []);

  const removeHandle = useCallback(<T extends keyof TourHandlesMap>(key: T) => {
    delete handlesRef.current[key];
  }, []);

  useEffect(() => {
    if (!journey) return;

    const isNewJourneyVersion = 'beforeStart' in journey;

    if (isNewJourneyVersion) return;

    if (!isValidPath && currentStepPath) {
      setIsReady(false);

      try {
        const [stepPath, existingQuery] = currentStepPath.split('?');
        const queryString = existingQuery ? `?${existingQuery}` : '';

        const resolvedPath = stepPath
          .split('/')
          .map((segment) => {
            if (!segment.startsWith(':')) return segment;

            const paramKey = segment.slice(1);
            const paramValue = paramsRef.current[paramKey];

            if (!paramValue) {
              throw new Error(`Missing parameter ${paramKey} in userTour paramsRef`);
            }

            return paramValue;
          })
          .join('/');

        navigate(`${resolvedPath}${queryString}`);
      } catch (error) {
        if (process.env.NODE_ENV === 'development') {
          throw error;
        } else {
          console.error(error);
          endJourney();
        }
      }
    }
  }, [journey, currentStepPath, isValidPath, navigate, endJourney, pathname]);

  const memoizedValue = useMemo(
    () => ({
      journey,
      loadJourney,
      paramsRef,
      addStepHandler,
      removeStepHandler,
      addHandle,
      removeHandle,
      markUserTourAsReady,
      setTourLoading: setIsLoading,
    }),
    [journey, loadJourney, addStepHandler, removeStepHandler, addHandle, removeHandle, markUserTourAsReady]
  );

  return (
    <UserTourContext.Provider value={memoizedValue}>
      {children}
      {journey && (
        <AppTour
          isNavigating={!isValidPath}
          steps={joyrideSteps}
          currentStepIndex={currentStepIndex}
          isReady={isReady}
          loading={isLoading}
          stepHandlersRef={stepHandlersRef}
          handlesRef={handlesRef}
          paramsRef={paramsRef}
          backButtonEnabled={journey.backButtonEnabled}
          goTo={goTo}
          endJourney={endJourney}
          setLoading={setIsLoading}
        />
      )}
    </UserTourContext.Provider>
  );
};

export default Object.assign(memo(UserTourProvider), {
  displayName: 'UserTourProvider',
});
