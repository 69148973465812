import { MutableRefObject, createContext, useContext } from 'react';
import type { Primitive, StepHandlers, TourHandlesMap, UserJourneyConfig } from 'src/type';

interface UserTourContextType {
  journey: UserJourneyConfig | null;
  loadJourney: (journey: UserJourneyConfig, params?: Record<string, Primitive>) => void;
  paramsRef: MutableRefObject<Record<string, Primitive>>;
  markUserTourAsReady: () => void;
  addStepHandler: (target: string, handlers: StepHandlers) => void;
  removeStepHandler: (target: string) => void;
  addHandle: <T extends keyof TourHandlesMap>(key: T, handle: TourHandlesMap[T]) => void;
  removeHandle: <T extends keyof TourHandlesMap>(key: T) => void;
  setTourLoading: (loading: boolean) => void;
}

export const UserTourContext = createContext<UserTourContextType | undefined>(undefined);

export const useUserTour = () => {
  const context = useContext(UserTourContext);

  if (context === undefined) {
    throw new Error('useUserTour must be used within a UserTourProvider');
  }

  return context;
};
