// misc_nodes.js

export const misc_nodes = [
  {
    name: "Other",
    categoryIcon:
      "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/backlog.svg",
    items: [
      "ableton-dark",
      "ableton-light",
      "activitypub-dark",
      "activitypub-light",
      "actix-dark",
      "actix-light",
      "adonis",
      "aftereffects",
      "aiscript-dark",
      "aiscript-light",
      "alpinejs-dark",
      "alpinejs-light",
      "anaconda-dark",
      "anaconda-light",
      "androidstudio-dark",
      "androidstudio-light",
      "angular-dark",
      "angular-light",
      "ansible",
      "apollo",
      "apple-dark",
      "apple-light",
      "appwrite",
      "arch-dark",
      "arch-light",
      "arduino",
      "astro",
      "atom",
      "audition",
      "autocad-dark",
      "autocad-light",
      "aws-dark",
      "aws-light",
      "azul",
      "azure-dark",
      "azure-light",
      "babel",
      "bash-dark",
      "bash-light",
      "bevy-dark",
      "bevy-light",
      "bitbucket-dark",
      "bitbucket-light",
      "blender-dark",
      "blender-light",
      "bootstrap",
      "bsd-dark",
      "bsd-light",
      "bun-dark",
      "bun-light",
      "c",
      "cassandra-dark",
      "cassandra-light",
      "clion-dark",
      "clion-light",
      "clojure-dark",
      "clojure-light",
      "cloudflare-dark",
      "cloudflare-light",
      "cmake-dark",
      "cmake-light",
      "codepen-dark",
      "codepen-light",
      "coffeescript-dark",
      "coffeescript-light",
      "cpp",
      "crystal-dark",
      "crystal-light",
      "cs",
      "css",
      "cypress-dark",
      "cypress-light",
      "d3-dark",
      "d3-light",
      "dart-dark",
      "dart-light",
      "debian-dark",
      "debian-light",
      "deno-dark",
      "deno-light",
      "devto-dark",
      "devto-light",
      "discord",
      "discordbots",
      "discordjs-dark",
      "discordjs-light",
      "django",
      "docker",
      "dotnet",
      "dynamodb-dark",
      "dynamodb-light",
      "eclipse-dark",
      "eclipse-light",
      "elasticsearch-dark",
      "elasticsearch-light",
      "electron",
      "elixir-dark",
      "elixir-light",
      "elysia-dark",
      "elysia-light",
      "emacs",
      "ember",
      "emotion-dark",
      "emotion-light",
      "expressjs-dark",
      "expressjs-light",
      "fastapi",
      "fediverse-dark",
      "fediverse-light",
      "figma-dark",
      "figma-light",
      "firebase-dark",
      "firebase-light",
      "flask-dark",
      "flask-light",
      "flutter-dark",
      "flutter-light",
      "forth",
      "fortran",
      "gamemakerstudio",
      "gatsby",
      "gcp-dark",
      "gcp-light",
      "gherkin-dark",
      "gherkin-light",
      "git",
      "github-dark",
      "github-light",
      "githubactions-dark",
      "githubactions-light",
      "gitlab-dark",
      "gitlab-light",
      "gmail-dark",
      "gmail-light",
      "godot-dark",
      "godot-light",
      "golang",
      "gradle-dark",
      "gradle-light",
      "grafana-dark",
      "grafana-light",
      "graphql-dark",
      "graphql-light",
      "gtk-dark",
      "gtk-light",
      "gulp",
      "haskell-dark",
      "haskell-light",
      "haxe-dark",
      "haxe-light",
      "haxeflixel-dark",
      "haxeflixel-light",
      "heroku",
      "hibernate-dark",
      "hibernate-light",
      "html",
      "htmx-dark",
      "htmx-light",
      "idea-dark",
      "idea-light",
      "illustrator",
      "instagram",
      "ipfs-dark",
      "ipfs-light",
      "java-dark",
      "java-light",
      "javascript",
      "jenkins-dark",
      "jenkins-light",
      "jest",
      "jquery",
      "julia-dark",
      "julia-light",
      "kafka",
      "kali-dark",
      "kali-light",
      "kotlin-dark",
      "kotlin-light",
      "ktor-dark",
      "ktor-light",
      "kubernetes",
      "laravel-dark",
      "laravel-light",
      "latex-dark",
      "latex-light",
      "less-dark",
      "less-light",
      "linkedin",
      "linux-dark",
      "linux-light",
      "lit-dark",
      "lit-light",
      "lua-dark",
      "lua-light",
      "markdown-dark",
      "markdown-light",
      "mastodon-dark",
      "mastodon-light",
      "materialui-dark",
      "materialui-light",
      "matlab-dark",
      "matlab-light",
      "maven-dark",
      "maven-light",
      "mint-dark",
      "mint-light",
      "misskey-dark",
      "misskey-light",
      "mongodb",
      "mysql-dark",
      "mysql-light",
      "neovim-dark",
      "neovim-light",
      "nestjs-dark",
      "nestjs-light",
      "netlify-dark",
      "netlify-light",
      "nextjs-dark",
      "nextjs-light",
      "nginx",
      "nim-dark",
      "nim-light",
      "nix-dark",
      "nix-light",
      "nodejs-dark",
      "nodejs-light",
      "notion-dark",
      "notion-light",
      "npm-dark",
      "npm-light",
      "nuxtjs-dark",
      "nuxtjs-light",
      "obsidian-dark",
      "obsidian-light",
      "ocaml",
      "octave-dark",
      "octave-light",
      "opencv-dark",
      "opencv-light",
      "openshift",
      "openstack-dark",
      "openstack-light",
      "p5js",
      "perl",
      "photoshop",
      "php-dark",
      "php-light",
      "phpstorm-dark",
      "phpstorm-light",
      "pinia-dark",
      "pinia-light",
      "pkl-dark",
      "pkl-light",
      "plan9-dark",
      "plan9-light",
      "planetscale-dark",
      "planetscale-light",
      "pnpm-dark",
      "pnpm-light",
      "postgresql-dark",
      "postgresql-light",
      "postman",
      "powershell-dark",
      "powershell-light",
      "premiere",
      "prisma",
      "processing-dark",
      "processing-light",
      "prometheus",
      "pug-dark",
      "pug-light",
      "pycharm-dark",
      "pycharm-light",
      "python-dark",
      "python-light",
      "pytorch-dark",
      "pytorch-light",
      "qt-dark",
      "qt-light",
      "r-dark",
      "r-light",
      "rabbitmq-dark",
      "rabbitmq-light",
      "rails",
      "raspberrypi-dark",
      "raspberrypi-light",
      "react-dark",
      "react-light",
      "reactivex-dark",
      "reactivex-light",
      "redhat-dark",
      "redhat-light",
      "redis-dark",
      "redis-light",
      "redux",
      "regex-dark",
      "regex-light",
      "remix-dark",
      "remix-light",
      "replit-dark",
      "replit-light",
      "rider-dark",
      "rider-light",
      "robloxstudio",
      "rocket",
      "rollupjs-dark",
      "rollupjs-light",
      "ros-dark",
      "ros-light",
      "ruby",
      "rust",
      "sass",
      "scala-dark",
      "scala-light",
      "scikitlearn-dark",
      "scikitlearn-light",
      "selenium",
      "sentry",
      "sequelize-dark",
      "sequelize-light",
      "sketchup-dark",
      "sketchup-light",
      "solidity",
      "solidjs-dark",
      "solidjs-light",
      "spring-dark",
      "spring-light",
      "sqlite",
      "stackoverflow-dark",
      "stackoverflow-light",
      "styledcomponents",
      "sublime-dark",
      "sublime-light",
      "supabase-dark",
      "supabase-light",
      "svelte",
      "svg-dark",
      "svg-light",
      "swift",
      "symfony-dark",
      "symfony-light",
      "tailwindcss-dark",
      "tailwindcss-light",
      "tauri-dark",
      "tauri-light",
      "tensorflow-dark",
      "tensorflow-light",
      "terraform-dark",
      "terraform-light",
      "threejs-dark",
      "threejs-light",
      "twitter",
      "typescript",
      "ubuntu-dark",
      "ubuntu-light",
      "unity-dark",
      "unity-light",
      "unrealengine",
      "v-dark",
      "v-light",
      "vala",
      "vercel-dark",
      "vercel-light",
      "vim-dark",
      "vim-light",
      "visualstudio-dark",
      "visualstudio-light",
      "vite-dark",
      "vite-light",
      "vitest-dark",
      "vitest-light",
      "vscode-dark",
      "vscode-light",
      "vscodium-dark",
      "vscodium-light",
      "vuejs-dark",
      "vuejs-light",
      "vuetify-dark",
      "vuetify-light",
      "webassembly",
      "webflow",
      "webpack-dark",
      "webpack-light",
      "webstorm-dark",
      "webstorm-light",
      "windicss-dark",
      "windicss-light",
      "windows-dark",
      "windows-light",
      "wordpress",
      "workers-dark",
      "workers-light",
      "xd",
      "yarn-dark",
      "yarn-light",
      "yew-dark",
      "yew-light",
      "zig-dark",
      "zig-light"
    ],
  },

  {
    name: "Security",
    categoryIcon:
      "https://spnodedata.blob.core.windows.net/nodes/misc_clean/security/attention.svg",
    items: [
      "account",
      "attention",
      "cctv",
      "computer",
      "cookies",
      "download",
      "find",
      "firewall",
      "ftp",
      "key",
      "laptop",
      "lock",
      "money",
      "network",
      "padlock",
      "password",
      "pattern",
      "problem",
      "protection",
      "setting",
      "shield",
      "spam",
      "spy",
      "transfer",
      "umbrella",
      "unlock",
      "user",
      "virus",
      "vpn",
      "warning"
    ],
  },
  {
    name: "Communication",
    categoryIcon:
      "https://spnodedata.blob.core.windows.net/nodes/misc_clean/communication/browser.svg",
    items: [
      "browser",
      "chat",
      "computer",
      "connectivity",
      "connector",
      "email",
      "fax",
      "head",
      "headphone",
      "internet",
      "landline",
      "laptop",
      "link",
      "location",
      "mailbox",
      "megaphone",
      "microphone",
      "modem",
      "network",
      "pager",
      "radio",
      "satellite",
      "server",
      "share",
      "smartphone",
      "speaker",
      "tv",
      "webcam",
      "wifi"
    ],
  },
  {
    name: "Hardware",
    categoryIcon:
      "https://spnodedata.blob.core.windows.net/nodes/misc_clean/hardware/computer.svg",
    items: [
      "battery",
      "bluetooth",
      "cable",
      "case",
      "cd",
      "chip",
      "computer",
      "cooling",
      "cpu",
      "diode",
      "download",
      "earphone",
      "fan",
      "folder",
      "ftp",
      "gpu",
      "joystick",
      "lan",
      "laptop",
      "mainboard",
      "modem",
      "monitor",
      "network",
      "plug",
      "printer",
      "resistance",
      "router",
      "search",
      "server",
      "shield",
      "shutdown",
      "smartphone",
      "ssd",
      "turntable",
      "upload",
      "vga"
    ],
  }
];
