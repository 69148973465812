import React, { useState, useRef, useEffect } from "react";

// Utility function to detect click outside the component
function useOutsideClick(
  ref: React.RefObject<HTMLDivElement>,
  onClickOutside: () => void
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickOutside();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClickOutside]);
}

interface PopoverProps {
  buttonContent: React.ReactNode;
  popoverContent: React.ReactNode; // The content inside the popover
  classes?: string;
  isCloseOnClick?: boolean;
}

const Popover: React.FC<PopoverProps> = ({
  buttonContent,
  popoverContent,
  classes,
  isCloseOnClick,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);

  // Handle outside click to close popover
  useOutsideClick(popoverRef, () => setIsVisible(false));

  return (
    <div className="relative flex items-center">
      <button
        id="popover_button"
        type="button"
        onClick={() => setIsVisible(!isVisible)}
        className="border-0"
        style={{ background: "transparent", padding: 0 }}
      >
        {buttonContent}
      </button>

      {isVisible && (
        <div
          ref={popoverRef}
          onClick={() => {
            isCloseOnClick ? setIsVisible(false) : console.log("Clicking...");
          }}
          className={`absolute z-50 inline-block text-sm transition-opacity duration-300 pb-[20px] ${classes}`}
        >
          <div
            className={`bg-white rounded-lg shadow-[0_0_10px_10px_rgba(0,0,0,0.03)] animate-fade-in`}
          >
            <div className="p-1">{popoverContent}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Popover;
